import React from 'react';

import {format} from 'date-fns';

import Icon from '@material-tailwind/react/Icon';

import {isValidJson, isValidDate} from 'utils';
import { useGlobalState } from 'state-pool';

export default function CartSimple({cartItems}) {
    const [books] = useGlobalState('books');

    cartItems = cartItems && Array.isArray(cartItems) && cartItems.filter(ci => ci ? true : false);
    
    function getBookName(id, internationalBookId) {

        if(internationalBookId) {
            const associatedBook = books.internationalBooks.find(b => b.id === internationalBookId);

            if(associatedBook && associatedBook.book_title) return associatedBook.book_title;

            return 'Cannot find book';
        } else {
            const associatedBook = books.localBooks.find(b => b.id === id);

            if(associatedBook && associatedBook.book_title) return associatedBook.book_title;

            return 'Cannot find book';
        }
    }
    
    return (
        <div className="xs-max-w-80vw">
            {cartItems && Array.isArray(cartItems) && cartItems.length > 0 ? (
                <div>
                    <h2 className="text-xl font-bold pl-2">Your Cart</h2>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full justify-items-stretch">
						<p className="px-2 hidden md:block font-bold pt-2">Product</p>{" "}
						<p className="px-2 font-bold pt-2">Price</p>{" "}
						<p className="px-2 font-bold pt-2">Book Name</p>
						<p className="px-2 hidden md:block font-bold pt-2">Date</p>
                        {cartItems.map(({book_title, book_id, international_book_id, price, product, misc, created_at}, i) => (
                            <React.Fragment key={i}>
                                <p className="px-2 hidden md:block">
                                    {misc && isValidJson(misc) && JSON.parse(misc)[0] && JSON.parse(misc)[0].name}
                                </p>{" "}
                                <p className="px-2">R{misc && isValidJson(misc) && JSON.parse(misc)[0] && JSON.parse(misc)[0].cost.toFixed(2)}</p>{" "}
                                <p className="px-2">
                                    {/* {misc && isValidJson(misc) && JSON.parse(misc)[0] && JSON.parse(misc)[0].description} 
                                    <span className="block md:hidden">
                                        ({misc && isValidJson(misc) && JSON.parse(misc)[0] && JSON.parse(misc)[0].name})
                                    </span> */}
                                    {books && books.internationalBooks && getBookName(book_id, international_book_id)}
                                </p>
                                <p className="px-2 hidden md:block"><span className="text-gray-500 italic text-xs">{created_at && isValidDate(new Date(created_at)) && format(new Date(created_at), 'dd/MM')}</span></p>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="w-full flex flex-col items-center justify-center">
                    <Icon name="info" size="4xl" />
                    <h2 className="text-2xl">No Items</h2>
                </div>
            )}

        </div>
    )
}
