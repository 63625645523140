import { podApi } from 'api/pod';
import { ImageUpload } from 'types';
import { getCurrentUser } from 'utils/auth';
import { getMd5 } from 'utils';

import { } from 'utils';

export async function uploadUserImage(file: ImageUpload, id: number) {
    try {
        const res = await podApi.post(`/user/uploadprofileimg/${id}`, file);

        if (res) return res;

        return false;

    } catch (err) {
        console.log(err);

        return;
    }
}

// START UPLOAD

export async function uploadBook(
    localBookId: number,
    internationalBookId: number,
    // coverBase64: string,
    thumbnailBase64: string,
    // bookBase64: string,
    type: string,
    coverNeeded: boolean,
    thumbnailNeeded: boolean,
    formattingNeeded: boolean,
    bleedingNeeded: boolean,
    subtitle: string,
    author: string,
    contributors: string[],
    publicationDate: string,
    onSaleDate: string,
    language: string,
    genre: string,
    keywords: string[],
    bisacCode: string,
    shortDescription: string,
    longDescription: string,
    mainDescription: string,
    copyrightHolder: string,
    copyrightDate: string,
    recommendedAudience: string,
    indicatedAudienceWarning: string,
    audioFiles: any[],
    spotUvNeeded: boolean,

    heavyGraphics: boolean,
    conversionNeeded: boolean,
) {
    if (type) {
        const path = `/user/uploadbook${type}`;

        const body = {
            local_bookId: localBookId ? localBookId : null,
            international_bookId: internationalBookId ? internationalBookId : null,
            cover_needed: coverNeeded,
            thumbnail_needed: thumbnailNeeded,
            formatting_needed: formattingNeeded,
            bleeding_needed: bleedingNeeded,
            cover: null,
            thumbnail: thumbnailBase64,
            book: null,
            subtitle,
            author,
            contributors,
            publicationDate,
            onSaleDate,
            language,
            genre,
            keywords,
            bisacCode,
            shortDescription,
            longDescription,
            mainDescription,
            copyrightHolder,
            copyrightDate,
            recommendedAudience,
            indicatedAudienceWarning,
            audio_files: (audioFiles && Array.isArray(audioFiles) && (audioFiles.length > 0)) ? audioFiles : null,
            spot_uv_needed: spotUvNeeded,
            heavy_graphics: heavyGraphics,
            conversion_needed: conversionNeeded,
        };

        try {
            const res = await podApi.post(path, body);

            if (res && res.data && res.data.statusCode === 0) {
                return res.data;
            }

            // @ts-ignore
            if (res && res.statusCode === 0) {
                return res;
            }

            return false;
        } catch (err) {
            console.log(err);

            return false;
        }

    } else {
        return false;
    }

}

// GET {{url}}/session/packet

// Authorization: Bearer {{token}}

// {

//     "session_id": 231,

//     "packet_count":400,

//     "packet_contents" : "the 3million characters of the packet",

//     "md5_packet": "d",

//     "local_bookId": null,

//     "international_bookId": 1,

//     "file_signature": "md5 of whole packet"

// }

export async function packetUploadInit(
    // null,
    // packetCount,
    // null,
    // localBookId,
    // internationalBookId,
    // bookSignature

    packetCount: number,
    localBookId: number,
    internationalBookId: number,
    // bookSignature: string
) {

    const path = '/session/packet';
    const body = {
        "packet_count": packetCount ? (packetCount + 1) : null,
        "local_bookId": localBookId ? localBookId : null,
        "international_bookId": internationalBookId ? internationalBookId : null,
        "file_signature": null
    };

    try {
        const res = await podApi.post(path, body);

        console.log(res);

        if (res && res.data && res.data.status === 0) {
            console.log(res.data);

            return res.data;
        }

        // @ts-ignore
        if (res && res.status === 0) {
            console.log(res);

            return res;
        }

        return false;
    } catch (err) {
        console.log(err);

        return false;
    }
}

export async function packetUpload(
    // null,
    // packetCount,
    // null,
    // localBookId,
    // internationalBookId,
    // bookSignature

    sessionId: number,
    packetContents: string,
    localBookId: number,
    internationalBookId: number,
) {
    const md5Signature = packetContents ? getMd5(packetContents) : null;

    const path = `/session/packet?session_id=${sessionId}`;
    const body = {
        "session_id": sessionId ? sessionId : null,
        "packet_contents": packetContents,
        "md5_packet": md5Signature,
        "local_bookId": localBookId ? localBookId : null,
        "international_bookId": internationalBookId ? internationalBookId : null,
    };

    try {
        const res = await podApi.post(path, body);

        console.log(res);

        if (res && res.data && res.data.status === 0) {
            console.log(res.data);

            return res.data;
        }

        // @ts-ignore
        if (res && res.status === 0) {
            console.log(res);

            return res;
        }

        if (res && res.data && res.data.statusCode === 0) {
            console.log(res.data);

            return res.data;
        }

        // @ts-ignore
        if (res && res.statusCode === 0) {
            console.log(res);

            return res;
        }

        return false;
    } catch (err) {
        console.log(err);

        return false;
    }
}

export async function handlePacketUpload(
    // null,
    // packetCount,
    // null,
    // localBookId,
    // internationalBookId,
    // bookSignature

    sessionId: number,
    bookContents: { file_name: string, file_data: File },
    localBookId: number,
    internationalBookId: number,
    packetCount: number,

) {
    console.log(bookContents);

    localStorage.setItem('BOOK_UPLOAD_STATUS', '0%');
    return new Promise(async (resolve, reject) => {
        const path = `/session/packet?session_id=${sessionId}`;

        let index = 0;

        let fb64 = '';

        let fileToB64 = await getBlobBase64(bookContents.file_data);

        // @ts-ignore
        // fileToB64 = fileToB64.split(',')[1];

        while (index <= Math.ceil(packetCount)) {
            // @ts-ignore
            let packetContents = getPacket(index, fileToB64, 1000000, bookContents.file_data.length);

            // console.log('BLOB', blob);

            // const buffer = await blob.arrayBuffer();

            // const arrayBuffer = new Uint8Array(buffer).buffer;

            // console.log(arrayBuffer);


            // let packetContents = await getBlobBase64(blob);
            // console.log(packetContents);

            // @ts-ignore
            // if(index === 2) console.log('------->', fileToB64.slice(3000000, fileToB64.length), packetContents)

            // @ts-ignore
            const md5Signature = packetContents ? getMd5(packetContents) : null;

            // @ts-ignore
            // const removedHeader = packetContents.split(',')[1];

            // console.log(index, removedHeader);

            // fb64 += '<===============>';
            fb64 += packetContents;

            const body = {
                "session_id": sessionId ? sessionId : null,
                "packet_contents": packetContents,
                "md5_packet": md5Signature,
                "local_bookId": localBookId ? localBookId : 1,
                "international_bookId": internationalBookId ? internationalBookId : 1,
                "file_name": bookContents.file_name
            };


            try {
                const res = await podApi.post(path, body);

                // console.log(res, index, Math.ceil(packetCount));

                if (res && res.data && res.data.statusCode === 0) {
                    localStorage.setItem('BOOK_UPLOAD_STATUS', `${(((index + 1) / Math.ceil(packetCount) * 100)).toFixed()}%`);

                    // index++;

                    index = res.data.sendPacket;
                }

                if (!res.data.sendPacket) {
                    // @ts-ignore
                    console.log(fb64);

                    return resolve('Successfull');
                }

            } catch (err) {
                console.log(err);

                return resolve(false);
            }
        }
    });
}

export async function handlePacketUpload2(
    // null,
    // packetCount,
    // null,
    // localBookId,
    // internationalBookId,
    // bookSignature

    bookContents: { file_name: string, file_data: File },
    packetCount: number,

) {
    // console.log(bookContents);



    // localStorage.setItem('BOOK_UPLOAD_STATUS', '0%');
    return new Promise(async (resolve, reject) => {

        let index = 0;

        let fb64 = '';

        let fileToB64 = await getBlobBase64(bookContents.file_data);

        // @ts-ignore
        console.log(fileToB64.length, fileToB64.split(',')[1]);


        // @ts-ignore
        // fileToB64 = fileToB64.split(',')[1];

        while (index <= Math.ceil(packetCount)) {
            console.log(index)
            // @ts-ignore
            let blob = getPacket(index, bookContents.file_data, 1000000);

            // console.log('BLOB', blob);

            // const buffer = await blob.arrayBuffer();

            // const arrayBuffer = new Uint8Array(buffer).buffer;

            // console.log(arrayBuffer);


            let packetContents = await getBlobBase64(blob);
            // console.log(packetContents);

            // @ts-ignore
            const removedHeader = packetContents;
            // @ts-ignore
            fb64 += removedHeader.replace('data:application/octet-stream;base64,', '');

            // @ts-ignore
            // if(index === Math.ceil(packetCount)) console.log(packetContents);

            index++;

            if (index === Math.ceil(packetCount)) {
                // @ts-ignore
                console.log(fb64.length, fb64);

                return resolve('Success');
            }
        }
    });
}

export async function handlePacketUploadCover(
    coverContents: { file_name: string, file_data: File },
    packetCount: number,

) {
    // console.log(bookContents);

    // localStorage.setItem('BOOK_UPLOAD_STATUS', '0%');
    return new Promise(async (resolve, reject) => {

        let index = 0;

        let fb64 = '';

        let fileToB64 = await getBlobBase64(coverContents.file_data);

        // @ts-ignore
        console.log(fileToB64.length, fileToB64.split(',')[1]);


        // @ts-ignore
        // fileToB64 = fileToB64.split(',')[1];

        while (index <= Math.ceil(packetCount)) {
            console.log(index)
            // @ts-ignore
            let blob = getPacket(index, coverContents.file_data, 1000000);

            console.log('BLOB', blob);

            const buffer = await blob.arrayBuffer();

            const arrayBuffer = new Uint8Array(buffer).buffer;

            console.log(arrayBuffer);

            let packetContents = await getBlobBase64(blob);
            console.log(packetContents);

            // @ts-ignore
            const removedHeader = packetContents;
            // @ts-ignore
            fb64 += removedHeader.replace('data:application/octet-stream;base64,', '');

            // @ts-ignore
            // if(index === Math.ceil(packetCount)) console.log(packetContents);

            index++;

            if (index === Math.ceil(packetCount)) {
                // @ts-ignore
                console.log(fb64.length, fb64);

                return resolve('Success');
            }
        }
    });
}

function getPacket(index: number, file: File, limit: number) {
    const from = index * limit;
    const to = (from + limit);

    console.log(from, to);

    return file.slice(from, to);
}

function getBlobBase64(blob: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = (e) => {
            // @ts-ignore
            return resolve(e.target.result);
        }

        reader.readAsDataURL(blob);
    });
}

function dataURItoBlob(dataURI: any) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

}

export async function uploadCompletion() {

}

// END UPLOAD

// export async function getUserBooks(userId: number) {
//     try {
//         const res = await podApi.get(`/user/getuserbooks`);

//         if(res && res.data && Array.isArray(res.data)){ 

//             const values = await Promise.all(res.data.map(async (book: Book) => {
//                 if(book && book.id && book.user_id) {
//                     const files = await getBookFiles(book.id);

//                     if(files && files.results && Array.isArray(files.results)) {
//                         return {...book, files: files.results};
//                     } else {
//                         return book;
//                     }
//                 }
//             }));

//             console.log(values);

//             return values;
//         };

//         // return;

//     } catch(err) {
//         console.log(err);
//     }
// }

export async function getBookFiles(bookId: number, isInternational: boolean, isLocal: boolean) {
    try {
        const res = await podApi.get(`/user/viewusersbookinfo/${bookId}/${isLocal}-${isInternational}`);

        if (res && res.data) return res.data;

        if (res) return res;

    } catch (err) {
        console.log(err);
    }
}

export async function viewUsersBookContract(bookId: number) {
    try {
        const res = await podApi.get(`/user/viewusersbookcontract/${bookId}`);

        if (res && res.data) return res.data;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function viewUsersBookContractV2(localBookId: number, internationalBookId: number) {

    const body = {
        local_bookId: localBookId ? localBookId : null,
        international_bookId: internationalBookId ? internationalBookId : null
    }

    try {
        const res = await podApi.post(`/user/viewsignedcontract`, body);

        if (res && res.data) return res.data;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function createTransaction(userBookIds: number[], intBookIds: number[], amount: number, userId: number, transactionRef: string, couponId: number) {

    const body = {
        transaction_ref: transactionRef,
        coupon_id: couponId,
        user_id: userId,
        amount,
        user_book_id: userBookIds,
        user_international_book_id: intBookIds
    };

    try {
        const res = await podApi.post(`/user/createtransaction`, body);

        if (res && res.data) return res.data;

        return false;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function editUser(firstName: string, lastName: string, phoneNumber: string) {

    const body = {
        name_first: firstName,
        name_last: lastName,
        phone_main: phoneNumber
    }

    try {
        const res = await podApi.put(`/user/edituser`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function editUserEmail(email: string) {

    const body = {
        email: email
    };

    const currentUser = getCurrentUser();

    try {
        const res = await podApi.put(`/user/edituser`, { ...body, ...currentUser });

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function editUserBankDetails(
    //Users Details
    firstName: string | null | undefined,
    lastName: string | null | undefined,
    // username: string | null | undefined,
    email: string | null | undefined,
    phoneNumber: string | null | undefined,
    //Banking Details
    bankName: string | null | undefined,
    branchCode: string | null | undefined,
    branchName: string | null | undefined,
    accountName: string | null | undefined,
    accountType: string | null | undefined,
    accountNumber: string | null | undefined,
    internationalSwiftCode: string | null | undefined,
    internationalIbanNumber: string | null | undefined,
    internationalBankAddress: string | null | undefined,
    paypal: string | null | undefined,
    //Signing Details
    publisherName: string | null | undefined,
    publisherEmail: string | null | undefined,
    publisherPhoneNumber: string | null | undefined,
    contactName: string | null | undefined,
    businessRegistrationNumber: string | null | undefined,
    postalAddress: string | null | undefined,
    physicalAddress: string | null | undefined,

) {

    const body = {
        name_first: firstName ? firstName : null,
        name_last: lastName ? lastName : null,
        // username: username ? username : null,
        userEmail: email ? email : null,
        phone_main: phoneNumber ? phoneNumber : null,
        misc: {
            bank_name: bankName ? bankName : null,
            brance_code: branchCode ? branchCode : null,
            branch_name: branchName ? branchName : null,
            account_name: accountName ? accountName : null,
            account_type: accountType ? accountType : null,
            account_number: accountNumber ? accountNumber : null,
            international_swift_code: internationalSwiftCode ? internationalSwiftCode : null,
            international_iban_number: internationalIbanNumber ? internationalIbanNumber : null,
            international_bank_address: internationalBankAddress ? internationalBankAddress : null,
            paypal: paypal ? paypal : null,
            //Signing Details
            publish_name: publisherName ? publisherName : null,
            email: publisherEmail ? publisherEmail : null,
            telephone: publisherPhoneNumber ? publisherPhoneNumber : null,
            contact_person: contactName ? contactName : null,
            reg_id_no: businessRegistrationNumber ? businessRegistrationNumber : null,
            postal_address: postalAddress ? postalAddress : null,
            physical_address: physicalAddress ? physicalAddress : null,
        }
    }

    try {
        const res = await podApi.put(`/user/requestchange`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function updatePassword(password: string, confirmPassword: string) {

    const body = {
        confirm_password: confirmPassword,
        password: password
    };

    try {
        const res = await podApi.put(`/user/pwd`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function verifyCoupon(couponCode: string) {

    const body = {
        coupon_code: couponCode
    }

    try {
        const res = await podApi.post(`/user/verifycartcoupon`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function uploadSignature(localBookId: number, internationalBookId: number, signature: string, formDetails: any, audioFiles: string[]) {

    const {
        publisherName,
        publisherRegId,
        publisherPhysicalAddress,
        publisherPostalAddress,
        publisherTelephone,
        publisherEmail,
        publisherContactPerson,
        isSelfPublisher,
        printName,
        signedPlace,
        bankName,
        branchCode,
        accountName,
        accountType,
        accountNumber,
        bankAddress,
        ibanNumber,
        swiftCode,
        paypal,
        initials,

        branchName,

        author,
        contributors
    } = formDetails;

    const body = {
        local_bookId: localBookId ? localBookId : null,
        international_bookId: internationalBookId ? internationalBookId : null,
        filedata: signature,
        publish_name: publisherName,
        reg_id_no: publisherRegId,
        physical_address: publisherPhysicalAddress,
        postal_address: publisherPostalAddress,
        telephone: publisherTelephone,
        email: publisherEmail,
        contact_person: publisherContactPerson,
        print_name: printName,
        signed_place: signedPlace,
        bank_name: bankName,
        brance_code: branchCode,
        account_name: accountName,
        account_type: accountType,
        account_number: accountNumber,
        international_bank_address: bankAddress ? bankAddress : null,
        international_iban_number: ibanNumber ? ibanNumber : null,
        international_swift_code: swiftCode ? swiftCode : null,
        paypal: paypal ? paypal : null,
        initial_base64: initials,
        branch_name: branchName ? branchName : 'N/A',
        authors: author ? [author] : null,
        contributors: contributors ? contributors : null,
        is_self_publisher: isSelfPublisher,
    };

    try {
        const res = await podApi.post(`/user/uploadsignature`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function uploadSignatureAppendix(localBookId: number, internationalBookId: number, signature: string, formDetails: any) {

    const {
        publisherName,
        publisherRegId,
        publisherPhysicalAddress,
        publisherPostalAddress,
        publisherTelephone,
        publisherEmail,
        publisherContactPerson,
        printName,
        signedPlace,
        bankName,
        branchCode,
        accountName,
        accountType,
        accountNumber,
        bankAddress,
        ibanNumber,
        swiftCode,
        paypal,
        initials
    } = formDetails;

    const body = {
        local_bookId: localBookId ? localBookId : null,
        international_bookId: internationalBookId ? internationalBookId : null,
        filedata: signature,
        publish_name: publisherName,
        reg_id_no: publisherRegId,
        physical_address: publisherPhysicalAddress,
        postal_address: publisherPostalAddress,
        telephone: publisherTelephone,
        email: publisherEmail,
        contact_person: publisherContactPerson,
        print_name: printName,
        signed_place: signedPlace,
        bank_name: bankName,
        brance_code: branchCode,
        account_name: accountName,
        account_type: accountType,
        account_number: accountNumber,
        international_bank_address: bankAddress,
        international_iban_number: ibanNumber,
        international_swift_code: swiftCode,
        paypal: paypal,
        initial_base64: initials,
        branch_name: 'okay'
    }

    try {
        const res = await podApi.post(`/user/uploadsignatureappendix`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function postTransaction(tref: string) {

    const body = {
        transaction_ref: tref
    };

    try {
        const res = await podApi.post(`/user/posttransaction`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

// /user/quoteinfo

export async function getQuoteInfo() {

    try {
        const res = await podApi.get(`/user/quoteinfo`);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}


export async function viewSignedContract(localBookId: number, internationalBookId: number) {

    const body = {
        local_bookId: localBookId,
        international_bookId: internationalBookId
    }

    try {
        const res = await podApi.post(`/user/viewsignedcontract`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

// POST  {{url}}/admin/user/addtousercart

// Authorization: Bearer {{token}}

// Content-Type: application/json

// {

//         "bookId": 42,

//         "userId": 1,

//         "productId": 2, | null for custom

//         //!for custom cart leave productid null and fill in the bottom part

//         "cost": 200,

//         "name": "100extrapage",

//         "description": "for having more pgaes in your book",

//         "discount":0,

//         "discount_type":1

// ###
// GET {{url}}/user/getbookorders?skip=0&takee=100
// Authorization: Bearer {{token}}

// ###
// GET {{url}}/user/getsaleperbook/:bookID
// Authorization: Bearer {{token}}

export async function getBookSales(bookId: number | string) {

    try {
        const res = await podApi.get(`/user/getsaleperbook/${bookId}`);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function getBookOrders(bookId: number | string) {

    try {
        const res = await podApi.get(`/user/getordersperbook/${bookId}`);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function getAllBookOrders(skip: number | string, take: number | string) {

    try {
        const res = await podApi.get(`/user/getbookorders?${skip}&take=${take}`);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function forgotPassword(email: string) {

    try {
        const res = await podApi.put(`/user/forgotpwd/${email}`);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

// PUT {{url}}/user/pwdlinkcheck/:id/:token

// Authorization: Bearer {{token}}

// {

//     "confirm_password": "dawd",

//     "password":"dwa"

// }

export async function passwordLinkCheck(id: string, token: string, password: string, confirmPassword: string) {

    const body = {
        confirm_password: confirmPassword,
        password
    };

    try {
        const res = await podApi.put(`/user/pwdlinkcheck/${id}/${token}`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function changePassword(id: string, confirmPassword: string, password: string) {

    try {

        const body = {
            confirm_password: confirmPassword,
            password
        };

        const res = await podApi.put(`/user/pwdchange/${id}`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

// PUT {{url}}/user/pwdchange/:id

// Authorization: Bearer {{token}}

// Content-Type: application/json

// {

//     "confirm_password":"345",

//     "password":"345"

// }

// /user/pwdlinkcheck/9/d5a46debb5f938350aac60a7fd7169e6

// https://pod-front.vercel.app/forgot-password?id=23&token=3b1439f4d870e3943dfd4f1cafeabb64&verify=0

// GET {{url}}/user/getordersperbook/609

// Authorization: Bearer {{token}}



// /posttransactionpayfast/:txref/:token/:status
// success | failed | cancelled
// returns tokens

export async function postTransactionPayfast(tref: string, token: string, status: string) {

    const body = {
        transaction_ref: tref
    };

    try {
        const res = await podApi.post(`/user/posttransactionpayfast/${tref}/${token}/${status}`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

//     DELETE /users/archivebook/:bookid/:islocal
//     islocal = true/false
export async function archiveBook(bookId: number, isLocal: boolean) {

    try {
        const res = await podApi.delete(`/user/archivebook/${bookId}/${isLocal}`);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function deleteBook(bookId: number, isLocal: boolean) {

    try {
        const res = await podApi.delete(`/user/deletebook/${bookId}/${isLocal}`);

        if (res && res.data) {
            // Retrieve the currentUser object from localStorage
            const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

            // Check if the currentUser object has a cart array
            if (currentUser && Array.isArray(currentUser.cart)) {
                // Filter out the item with the matching bookId
                currentUser.cart = currentUser.cart.filter((item: any) => {
                    return isLocal ? item.book_id !== bookId : item.international_book_id !== bookId;
                });

                // Update the currentUser in localStorage
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
            }
            
            window.location.reload();

            return res.data;

        }

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function resignContract(bookId: number, isLocal: boolean, signedPlace: string, signatureBase64: string, initialsBase64: string) {

    const body = {
        filedata: signatureBase64,
        initial_base64: initialsBase64,
        signedPlace
    };

    try {
        const res = await podApi.put(`/user/resign/${bookId}/${isLocal}`, body);

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return;

    } catch (err) {
        console.log(err);

        return;
    }
}

export async function getBooksForRenewal() {
    try {
        const res = await podApi.get('/user/booksrenewal')

        if (res && res.data) return res.data

        if (res) return res;

        return
    } catch (e) {
        console.log(e)
    }
}

export async function getQuote(body: any) {

    const res = await podApi.post('/user/getquote', body)

    if (res && res.data && res.data.statusCode === 0 && res.data.localRoyalty && (res.data.localBookId || res.data.internationalBookId || res.data.bookID)) {
        // const { localRoyalty, internationRoyalty } = res.data;

        return res.data;
    } else {
        return false;
    }
    // });
}
//This is going to look for any isbn that may be in use
export async function checkIsbn(isbn: any) {
    try {
        const res = await podApi.post(`/user/getvalidisbn`, { isbn })

        if (res && res.data) return res.data;

        if (res && !res.data) return res;

        return
    } catch (e) {
        console.log(e)
    }
}
