import React, { useState, useEffect, useRef } from 'react';

import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import CardInputSearchGenre from './CardInputSearchGenre';

import Modal from 'components/general/Modal';

import Input from "@material-tailwind/react/Input";

import md5 from 'md5';

export default function DropdownInputSearch({ value, setValue, placeholder, capitalize, options, color, error, onFocus, noMatches, initialValueDisplay, showOnInitialClick, tabIndex, dropdownClassName, showGenres, setShowGenres, onToggleGenres }) {
    const [rerender, setRerender] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState(() => { if (value && value.text) { return value.text; } return value || ''; });
    const id = md5(placeholder);

    // Detect user input, persists across rerenders
    const inputRef = useRef(null);

    useEffect(() => {
        if (!rerender) setRerender(true);
    }, [rerender]);



    // Final selection, sets value and query and closes the dropdown
    const onOptionClick = (option) => {
        if (option && option.value)
            setValue(option.value);
        setQuery(option.label);
        resetDropdown();
    }

    function resetDropdown() {
        setRerender(!rerender);
        setIsOpen(false);
    }


    // Shows the dropdown, called once when the input is not empty
    function showDropdown() {
        const dropdownEl = document.getElementById(id);

        if (dropdownEl) {
            dropdownEl.click();
        }
        if (!isOpen) {
            setIsOpen(true);
        }
    }

    // Detect user input, shows the dropdown once
    function handleInput(event) {
        const newQuery = event.target.value;
        setQuery(newQuery);

        // If the input is not empty, show the dropdown
        if (newQuery && !isOpen) {
            showDropdown()
        } else if (!newQuery) {
            resetDropdown();
        }
    }

    // Looking for changes in input field
    function _onChange(e) {
        const newQuery = e.target.value;
        setQuery(newQuery);

        if (newQuery) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }

    }

    const onInputClick = () => {

        setQuery('');
        setValue('');
        setIsOpen(false);
    }

    useEffect(() => {
        if (initialValueDisplay !== query && typeof initialValueDisplay === 'string') setQuery(initialValueDisplay);
    }, [initialValueDisplay]);


    return (
        <>
            <Input
                outline={false}
                value={query}
                type="text"
                color="red"
                size="regular"
                placeholder={placeholder}
                onChange={(e) => _onChange(e)}
                onClick={onInputClick}
                ref={inputRef}
                onInput={handleInput}
                onFocus={onFocus}
                error={error}
                style={{ textTransform: capitalize ? "capitalize" : "" }}
                tabIndex={tabIndex}
            />

            <div className="h-0">
                {rerender && (
                    <>
                        <Dropdown
                            color={color ? color : "red"}
                            placement="bottom-start"
                            id={id}
                            buttonType="link"
                            size="regular"
                            rounded={false}
                            block={false}
                            ripple="light"
                            buttonText=""
                            className={`hover:bg-green hover:text-white invisible h-0 p-0 m-0 ${dropdownClassName}`}
                        >
                            {/* Searches for both Subheading name and bisaccode, returns subheading name  */}
                            {options &&
                                Array.isArray(options) &&
                                options.length > 0 &&
                                options.filter(
                                    (opt) =>
                                        opt.label.toLowerCase().includes(query.toLowerCase()) ||
                                        (opt.value &&
                                            opt.value.bisacText &&
                                            opt.value.bisacText
                                                .toLowerCase()
                                                .includes(query.toLowerCase()))
                                ).length > 0 ? (
                                options
                                    .filter(
                                        (opt) =>
                                            opt.label.toLowerCase().includes(query.toLowerCase()) ||
                                            (opt.value &&
                                                opt.value.bisacText &&
                                                opt.value.bisacText
                                                    .toLowerCase()
                                                    .includes(query.toLowerCase()))
                                    )
                                    .map(({ label, value }, i) => (
                                        <React.Fragment key={md5(label + JSON.stringify(value))}>
                                            <DropdownItem
                                                color={color ? color : "red"}
                                                ripple="light"
                                                onClick={() => onOptionClick({ value, label })}
                                            >
                                                <span className={capitalize ? "capitalize" : ""}>
                                                    {label}
                                                </span>
                                            </DropdownItem>
                                        </React.Fragment>
                                    ))
                            ) : (
                                <p className="p-4 text-gray">
                                    {noMatches ? noMatches : "No matches for your search term"}
                                </p>
                            )}
                        </Dropdown>

                        {/* Shows the modal when Show Genres is clicked */}
                        {showGenres && (
                            <>
                                <div className="h-5" />
                                {/* Card displaying subject headings if genre button is clicked 12/05/2023 */}
                                <Modal
                                    className={`max-w-3xl text-left`}
                                    showModal={showGenres}
                                    setShowModal={setShowGenres}
                                    size="md"
                                >
                                    {/* Relevant fields shown in modal (Genres, Subcategories) */}
                                    <div className="mb-4 text-left Sw-full">
                                        <CardInputSearchGenre
                                            className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown calc-input"
                                            options={options}
                                            capitalize={true}
                                            tabIndex={4}
                                            onToggleGenres={onToggleGenres}
                                            onOptionClick={onOptionClick}
                                            color={color}
                                        />
                                    </div>
                                </Modal>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
