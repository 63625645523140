import { useState } from 'react';

import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import { editUserEmail } from 'routes/userRoutes';
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router';

export default function UpdateEmailModalForm({ setShowModal }) {
    const [email, setEmail] = useState('');
    const history = useHistory();
    const { refreshUserDetails } = useAuth();
    //Email place holder
    const [phEmail, setPhEmail] = useState("Enter your new email")

    function onDoneClick() {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setPhEmail("Enter your new email ")
            editUserEmail(email).then(res => {
                if (res && res.statusCode === 0) {
                    refreshUserDetails();

                    setShowModal(false);

                    sessionStorage.clear();

                    localStorage.clear();

                    history.push('/');

                    return;
                }

                return;
            });
        } else {
            setPhEmail("Please enter a valid email address")
        }
    }

    return (
        <div className="px-3 md:pb-8 flex relative justify-center">
            <div>
                <h3 className="text-4xl font-bold">Update email address</h3>
                <div className="h-2" />
                <Paragraph >
                    Enter your new email address below
                </Paragraph>
                <div className="h-2" />
                <div className="mb-4 text-left">
                    <Input
                        outline={false}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="text"
                        color="red"
                        placeholder={phEmail}
                        size="regular"
                    />
                </div>

                <Button
                    color="red"

                    className="hover:bg-red hover:text-white w-full hover:shadow-xl hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onDoneClick}
                >
                    Done
                    <Icon name="arrow_forward" size="sm" />
                </Button>
                <div className="h-5" />
                <Paragraph >
                    When clicking done, you'll be logged out of the system.
                </Paragraph>
                <Paragraph >
                    We'll send an email to the new email provided, click on the link emailed to you to log back in to the system.
                </Paragraph>
            </div>
        </div>
    );
}
