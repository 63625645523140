import { useEffect } from "react";

interface ActiveTabInfo {
  tabID: string;
  timestamp: number;
}

const generateTabID = () => {
  return `tab-${Math.random()
    .toString(36)
    .substr(2, 9)}-${new Date().getTime()}`;
};

const useTabManagement = () => {
  useEffect(() => {
    const tabID = generateTabID();
    const timestamp = new Date().getTime();
    sessionStorage.setItem("tabID", tabID);
    setActiveTab(tabID, timestamp);

    const check = () => checkTabID(tabID, timestamp);
    window.addEventListener("storage", check);
    const interval = setInterval(check, 10000); // Check every 10 seconds

    return () => {
      window.removeEventListener("storage", check);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    checkAndRedirectUploadProcess();
  }, []);
};

const setActiveTab = (tabID: string, timestamp: number) => {
  localStorage.setItem("activeTabInfo", JSON.stringify({ tabID, timestamp }));
};

const checkTabID = (currentTabID: string, currentTimestamp: number) => {
  const activeTabInfoRaw = localStorage.getItem("activeTabInfo");
  if (activeTabInfoRaw === null) {
    return; // Essential data missing
  }

  const activeTabInfo = JSON.parse(activeTabInfoRaw) as ActiveTabInfo;
  const now = new Date().getTime();

  if (
    currentTabID !== activeTabInfo.tabID &&
    now - activeTabInfo.timestamp < 10000
  ) {
    redirectToTabClosePage();
  } else if (currentTabID !== activeTabInfo.tabID) {
    setActiveTab(currentTabID, now);
  }
};

const redirectToTabClosePage = () => {
  window.location.href = "/tab-error";
};

const checkAndRedirectUploadProcess = () => {
  const currentPath = window.location.pathname;
  const firstPagePath = "/new-book/publishing-rights";
  if (currentPath.startsWith("/new-book/") && currentPath !== firstPagePath) {
    redirectToFirstPage();
  }
};

const redirectToFirstPage = () => {
  window.location.href = "/new-book/publishing-rights";
};

export default useTabManagement;
