import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UpdateController } from './UpdateController';
import Alert from "@material-tailwind/react/ClosingAlert";
import AlertModal from 'components/general/AlertModal';

const UpdateAvailable = () => {
    const { show, refresh } = UpdateController();
    const location = useLocation();
    const [showForceRefreshModal, setShowForceRefreshModal] = useState(false);
    const [isCheckingPath, setIsCheckingPath] = useState(true); // State to manage the initial path check

    const paths = [
        '/login',
        '/register',
        '/dashboard',
        '/checkout/cart',
        '/orders',
        '/books',
        '/bulk-upload'
    ];

    useEffect(() => {
        const pathRequiresForceRefresh = paths.includes(location.pathname);
        setShowForceRefreshModal(show && pathRequiresForceRefresh);
        setIsCheckingPath(false); // Set checking to false after determining path requirement
    }, [show, location.pathname]);

    if (isCheckingPath) {
        return null; // Optionally, return a loader or null if you don't want to show anything during the check
    }

    return (
        <>
            {show && !showForceRefreshModal && (
                <Alert style={style} color="lightBlue">
                    <span>
                        <b>Update available</b> A new version is available, click{" "}
                        <span className="font-bold underline cursor-pointer" onClick={refresh}>refresh</span>
                        {" "}to activate
                    </span>
                </Alert>
            )}
            {showForceRefreshModal && (
                <AlertModal
                    showModal={true}
                    setShowModal={() => window.location.reload()}
                    title={"New Version"}
                    message={"There is a new version for the website, the page will be refreshed automatically"}
                />
            )}
        </>
    );
};

const style = {
    position: "fixed",
    bottom: "-1em",
    width: '100%',
    zIndex: 99999999
};

export default UpdateAvailable;
