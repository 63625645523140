import { useState, useEffect, useCallback } from 'react';

import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Paragraph from "@material-tailwind/react/Paragraph";
import Label from "@material-tailwind/react/Label";

import Modal from 'components/general/Modal';
import Dropdown from 'components/inputs/Dropdown';
import DropdownInputSearch from 'components/inputs/DropdownInputSearchSizes';

import { useHistory } from 'react-router-dom';
import HelpText from 'components/general/HelpText';

import helpText from 'static-data/help-text';
// import { useLocalStorage } from 'usehooks-ts';

import useSessionStorage from 'hooks/useSessionStorage';

import { useLocalStorage } from 'usehooks-ts';

import { lengthLimiter } from 'utils';

import SIZES from 'static-data/size-offering';
import { filter, set } from 'lodash';

const COLOR_OPTIONS = [
    {
        text: "Colour",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        type: 'Colo'
    },
    {
        text: "Black & White",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        type: 'B&W'
    },
];

const ORIENTATION_OPTIONS = [
    {
        label: "Portrait",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'portrait'
    },
    {
        label: "Landscape",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'landscape'
    },
];

const COVER_FINISH_OPTIONS = [
    {
        label: "Matt",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Matt'
    },
    {
        label: "Gloss",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Gloss'
    },
];

const BINDING_OPTIONS = [
    {
        label: "Pur Bound",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Pur Bound'
    },
    {
        label: "Saddle Stitch",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Saddle Stitch'
    },
];

const ICT_OPTIONS = [
    '50lb/74gsm white | SMALL PAPERBACK',
    '50lb/74gsm white | LARGE PAPERBACK',
    '50lb/74gsm white | SMALL HARDCOVER',
    '50lb/74gsm white | LARGE HARDCOVER',

    '70lb/104gsm white | SMALL PAPERBACK',
    '70lb/104gsm white | LARGE PAPERBACK',
    '70lb/104gsm white | SMALL HARDCOVER',
    '70lb/104gsm white | LARGE HARDCOVER',

    '50lb/74gsm CRÃˆME | SMALL HARDCOVER',

    '50lb/74gsm creme | SMALL PAPERBACK',
    '50lb/74gsm creme | LARGE PAPERBACK',
    '50lb/74gsm creme | SMALL HARDCOVER',
    '50lb/74gsm creme | LARGE HARDCOVER',

    '70lb/104gsm creme | SMALL PAPERBACK',
    '70lb/104gsm creme | LARGE HARDCOVER',
    '70lb/104gsm creme | SMALL PAPERBACK',
    '70lb/104gsm creme | LARGE HARDCOVER',
];


export default function Calculator({
    error,
    setError,
    showAllErrors,
    setShowAllErrors,
    focusedField,
    //Careful one is a function and one is a state
    onFocusField,
    stage,
    setCompleted,
    completed,
    numPages,
    setNumPages,
    size,
    setSize,
    bookPrice,
    setBookPrice,
    paperSide,
    options,
    royalties,
    pagePathname,
    color,
    setColor,
    pageFinish,
    isLocal,
    isInternational,
    localPaperType,
    setLocalPaperType,
    localCoverPaperType,
    setLocalCoverPaperType,
    internationalPaperType,
    setInternationalPaperType,
    internationalCoverPaperType,
    setInternationalCoverPaperType,
    intCoverOption,
    intCoverType,
    bookWidth,
    setBookWidth,
    bookHeight,
    setBookHeight,
    canSupportCoverTypes, // array of types that are supported (hardcover, paperback) based on min pages of page types selected -  also show user if text is supported on spine
    orientation,
    setOrientation,
    coverFinish,
    setCoverFinish,
    internationalBookPrice,
    setInternationalBookPrice,
    binding,
    setBinding,
    spiralBoundNeeded,
    numPagesLimitExceeded,
    //For the paperType overlay
    paperTypesFiltered,
    setPaperTypesFiltered,
    internationalPaperTypesFiltered,
    setInternationalPaperTypesFiltered,
}) {
    const [showNegativeRoyaltyError, setShowNegativeRoyaltyError] = useState(false);
    const [localPaperTypeOptions, setLocalPaperTypeOptions] = useSessionStorage('lpto', []);
    const [localCoverTypeOptions, setLocalCoverTypeOptions] = useState([]);
    const [internationalCoverTypeOptions, setInternationalCoverTypeOptions] = useState([]);
    const [showLocalCoverTypeOptions, setShowLocalCoverTypeOptions] = useState(false);
    const [showLocalPaperTypeOptions, setShowLocalPaperTypeOptions] = useState(false);
    const [formattedLocalCoverPaperTypeOptions, setFormattedLocalCoverPaperTypeOptions] = useState([]);
    const [internationalSizesSorted, setInternationalSizesSorted] = useState([]);
    const [intSizesDisplay, setIntSizesDisplay] = useState([]);
    const [intSize, setIntSize] = useState('');
    const [sizeTemp, setSizeTemp] = useLocalStorage('calc_sizeTemp', null);

    const [saddleStitchAlert, setSaddleStitchAlert] = useState(false);
    const history = useHistory();

    // LOCAL 
    useEffect(() => {
        if (options && options.localPaperTypes && Array.isArray(options.localPaperTypes)) {
            let tempLocalPaperTypeOptions = options['localPaperTypes'];

            if (size) {
                tempLocalPaperTypeOptions = tempLocalPaperTypeOptions.filter(opt => {
                    return opt && opt.name.toLowerCase().includes(size.toLowerCase());
                });
            }

            if (color) {
                tempLocalPaperTypeOptions = tempLocalPaperTypeOptions.filter(opt => {
                    return opt && opt.name.toLowerCase().includes(color.toLowerCase());
                });
            }

            if (orientation === 'landscape') {
                tempLocalPaperTypeOptions = tempLocalPaperTypeOptions.filter(opt => {
                    return opt && opt.name.toLowerCase().includes(orientation.toLowerCase());
                });
            }

            //If number of pages exist and is a number then we filter the array to only show options that have a max page less than or equal to the number of pages
            if (numPages && !isNaN(Number(numPages))) {
                tempLocalPaperTypeOptions = tempLocalPaperTypeOptions.filter(opt => {
                    return opt && opt.maxPage >= Number(numPages);
                });
                //If the length of the filtered array is 0 then we know that the number of pages is too much for the paper type
                if (tempLocalPaperTypeOptions.length === 0) {
                    setPaperTypesFiltered(tempLocalPaperTypeOptions.length);
                }
                // Set page acceptance based on the length of the filtered array
                setPaperTypesFiltered(tempLocalPaperTypeOptions.length);
            }

            setShowLocalPaperTypeOptions(true);

            tempLocalPaperTypeOptions = tempLocalPaperTypeOptions.map(opt => {
                return { text: opt.id, label: opt.name, value: opt }
            });

            setLocalPaperTypeOptions(tempLocalPaperTypeOptions);

            let tempLocalCoverTypeOptions = options['localBookTypes']

            tempLocalCoverTypeOptions = tempLocalCoverTypeOptions.map(opt => {
                return { text: opt.id, label: opt.name, value: opt }
            });

            setLocalCoverTypeOptions(tempLocalCoverTypeOptions);

        }
    }, [options, size, color, orientation, numPages]);

    // INTERNATIONAL
    useEffect(() => {
        if (options && options.internationalPaperTypes && Array.isArray(options.internationalPaperTypes)) {
            let tempInternationalPaperTypeOptions = options['internationalPaperTypes'];

            tempInternationalPaperTypeOptions = tempInternationalPaperTypeOptions.map(opt => {
                return { text: opt.id, label: opt.name, value: opt, shortenedLabel: opt.label };
            });

            let unique = {};

            const sortedIct = sortIct(ICT_OPTIONS, tempInternationalPaperTypeOptions);

            let sizes = sortedIct.map(opt => {
                if (opt && opt.label && typeof opt.label === 'string') {
                    const startSlice = opt.label.indexOf('(') + 1;
                    const endSlice = opt.label.indexOf(')');

                    const sliced = opt.label.slice(startSlice, endSlice).replace('mm', '').trim();

                    if (unique[sliced] && Array.isArray(unique[sliced])) {
                        unique[sliced] = [...unique[sliced], opt];
                    } else {
                        unique[sliced] = [opt];
                    }

                    return sliced;

                }
            });

            sizes = [...new Set(sizes)].map((size, i) => {

                if (size && typeof size === 'string') {
                    const slicedArr = size.split('x');

                    const height = slicedArr[0] && Number(slicedArr[0].trim());
                    const width = slicedArr[1] && Number(slicedArr[1].trim());
                    let orientation = width > height ? 'landscape' : 'portrait';

                    if (height === width) orientation = 'square';

                    return {
                        height,
                        width,
                        orientation,
                        options: unique[size]
                    };

                }
            });

            sizes = sizes.sort((a, b) => (a.height - b.height));

            setInternationalSizesSorted([...sizes]);

            let tempInternationalCoverTypeOptions = options['internationalBookTypes'];

            tempInternationalCoverTypeOptions = tempInternationalCoverTypeOptions.map(opt => {
                return { text: opt.id, label: opt.name, value: opt }
            });

            setInternationalCoverTypeOptions([...tempInternationalCoverTypeOptions]);

        }
    }, [options, size, color, intCoverOption, intCoverType]);

    const clearError = useCallback((key) => {
        if (key && typeof key === 'string' && error) {
            const tempErrors = error;

            tempErrors[key] = null;

            setError({ ...tempErrors });
        }

        return;

    }, [error]);

    useEffect(() => {

        if (paperSide) clearError('paperSide');

        if (localPaperType) clearError('localPaperType');

        if (localCoverPaperType) clearError('localCoverPaperType');

        if (internationalPaperType) clearError('internationalPaperType');

        if (internationalCoverPaperType) clearError('internationalCoverPaperType');

        // if(intCoverOption) clearError('intCoverOption');

        if (intCoverType) clearError('intCoverType');

        if (size) clearError('size');

        if (internationalCoverTypeOptions && Array.isArray(internationalCoverTypeOptions) && size && intCoverType && pageFinish) {
            const selectedOption = internationalCoverTypeOptions.find((opt, i) => {
                const simpleSize = size.toLowerCase() === 'a4' ? 'large' : 'small';
                const simpleColor = color.toLowerCase() === 'b&w' ? color : 'COLOR';

                return opt && opt.label && opt.label.toLowerCase().includes(simpleSize.toLowerCase()) && opt.label.toLowerCase().includes(intCoverType.toLowerCase()) && opt.label.toLowerCase().includes(simpleColor.toLowerCase())
            });

            if (selectedOption && selectedOption.value && selectedOption.value.id) {
                setInternationalCoverPaperType(selectedOption.value.id);
            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paperSide, localCoverPaperType, localPaperType, internationalPaperType, internationalCoverPaperType, size, intCoverType, color, pageFinish, orientation]);

    function getLocalCoverPaperTypeOptions() {
        if (!size) return localCoverTypeOptions;

        if (localCoverTypeOptions && Array.isArray(localCoverTypeOptions) && size && typeof size === 'string') {
            const returnArr = localCoverTypeOptions.filter(({ label }) => label.includes(size.toUpperCase()));

            if (returnArr.length === 1 && returnArr[0] && returnArr[0].value) {
                setLocalCoverPaperType(returnArr[0].value);

                setShowLocalCoverTypeOptions(false);

                return;
            } else if (returnArr.length === 2 && returnArr[0] && returnArr[0].value && returnArr[1] && returnArr[1].value) {

                if (orientation && typeof orientation === 'string') {

                    if (orientation === 'landscape') {
                        const selected = returnArr.find(opt => opt && opt.label && typeof opt.label === 'string' && opt.label.toLowerCase().includes('landscape'));

                        if (selected && selected.label) {

                            setLocalCoverPaperType(selected.value);

                            setShowLocalCoverTypeOptions(false);

                            return;

                        }
                    } else {
                        const selectedAlt = returnArr.find(opt => opt && opt.label && typeof opt.label === 'string' && !opt.label.toLowerCase().includes('landscape'));
                        if (selectedAlt && selectedAlt.label) {

                            setLocalCoverPaperType(selectedAlt.value);

                            setShowLocalCoverTypeOptions(false);

                            return;

                        }
                    }
                }
            }

            setFormattedLocalCoverPaperTypeOptions(returnArr);
        }
    }

    useEffect(() => {
        if (isLocal) {
            getLocalCoverPaperTypeOptions();
        }
    }, [size, orientation]);

    function sortIct(order, arr) {
        let sorted = [];

        order.forEach(o => {
            arr.forEach((item, i) => {
                if (item.label.toUpperCase().includes(o.toUpperCase())) {
                    sorted.push(item);
                }
            });
        })

        return sorted;
    }

    const resetPaperTypeDD = () => {
        if (isLocal) {
            setLocalPaperType(null);
        }

        if (isInternational) {
            setInternationalPaperType(null);
        }

        if (isLocal && isInternational) {
            setLocalPaperType(null);
            setInternationalPaperType(null);
        }
    }

    useEffect(() => {
        if (sizeTemp && sizeTemp.label) {
            setSizeTemp(sizeTemp.label);
        }
    }, [sizeTemp]);

    useEffect(() => {
        if (numPages && binding) {
            if (numPages && !isNaN(Number(numPages)) && (Number(numPages) > 96) && binding && (binding === 'Saddle Stitch')) {

                setSaddleStitchAlert(true);

                setBinding('');

                return;
            }

            setSaddleStitchAlert(false);

            return;

        }
    }, [numPages, binding]);

    // useEffect(() => {
    //     if (numPagesLimitExceeded) {
    //         let tempErrors = {};
    //         const key = 'numPages';
    //         tempErrors[key] = 'Number of pages exceeds maximum allowed for this paper type.';
    //         const firstEl = document.querySelector(`#${key}`);
    //         if (firstEl) {
    //             firstEl.scrollIntoView({ behavior: "smooth", block: "start" });
    //         }
    //         setError({ ...tempErrors });
    //         return;
    //     } else {
    //         setError({});
    //     }
    // }, [numPagesLimitExceeded]);

    //Inter
    useEffect(() => {
        let sortedInternationalPaper = options && options.internationalPaperTypes
        if (sizeTemp && sizeTemp.value) {
            setBookHeight(sizeTemp.value.height);
            setBookWidth(sizeTemp.value.width);

            let ori = Number(sizeTemp.value.height) > Number(sizeTemp.value.width) ? 'portrait' : 'landscape';

            if (sizeTemp.value.height === sizeTemp.value.width) ori = 'square';

            setOrientation(ori);

            if (sizeTemp.value.template) setSize(sizeTemp.value.template);

            if (isInternational) {
                setIntSize(`${sizeTemp.value.height}mm x ${sizeTemp.value.width}mm`);
                //Here we are getting options which is a passed down resource for all the sizes available and returning it as an array of objects
                const intSizes = options && options.internationalPaperTypes && options.internationalPaperTypes.filter(pt => {

                    if (pt && (typeof pt.name === 'string') && (pt.name.indexOf(sizeTemp.value.height) !== -1) && (pt.name.indexOf(sizeTemp.value.width) !== -1) && (pt.name.includes(`${sizeTemp.value.height} x ${sizeTemp.value.width}`))) {
                        const hIndex = pt.name.indexOf(sizeTemp.value.height);
                        const wIndex = pt.name.indexOf(sizeTemp.value.width);

                        if (hIndex < wIndex || hIndex === wIndex) {
                            return true;
                        }
                    }
                });

                let intSizesFormatted = intSizes && intSizes.map(opt => ({ text: opt.id, label: opt.name, value: opt, shortenedLabel: opt.name, name: opt.name }));
                let filterByColor = [];

                if (!intSizesFormatted) return;
                //Filter by color
                if (color) {
                    filterByColor = intSizesFormatted.filter(opt => {
                        if (opt && opt.label && (typeof opt.label === 'string')) {
                            if (color.toLowerCase() === 'b&w' && opt.label.toLowerCase().includes('b&w')) return true;

                            if (color.toLowerCase() !== 'b&w' && !opt.label.toLowerCase().includes('b&w') && opt.label.toLowerCase().includes(color.toLowerCase())) return true;

                            return false;

                        }
                    });
                    setIntSizesDisplay([...filterByColor]);
                    return;
                };
                //Filter by pageNumbers
                if (numPages && !isNaN(Number(numPages))) {
                    filterByColor = filterByColor.filter(opt => {
                        return opt && opt.value && opt.value.maxPage >= Number(numPages);
                    });
                    //If the length of the filtered array is 0 then we know that the number of pages is too much for the paper type
                    if (filterByColor.length === 0) {
                        setInternationalPaperTypesFiltered(filterByColor.length);
                    }
                    // Set page acceptance based on the length of the filtered array
                    setInternationalPaperTypesFiltered(filterByColor.length);
                }

                setIntSizesDisplay([...intSizesFormatted]);
                return;
            }
        } else if (sizeTemp && sizeTemp !== null && sizeTemp !== undefined) {
            //we could use local storage or session storage to store them from above but ive invested to much into this >_< unless you want to change it
            //Here we are getting options which is a passed down resource for all the sizes available and returning it as an array of objects
            if (options && options.internationalPaperTypes && Array.isArray(options.internationalPaperTypes)) {
                let internationalPaperType = options['internationalPaperTypes'].map(opt => {
                    return { text: opt.id, label: opt.name, value: opt, shortenedLabel: opt.label };
                });

                //Here we are splitting and trimming the array of option to have a sorted array of sizes to compare with the sizeTemp
                //We could have filters both sizeTemp and this object but i thought it be better to keep sizeTemp  the same so we don't get any issues
                const internationalSizesOnly = internationalPaperType.map(opt => {
                    if (opt && opt.label && typeof opt.label === 'string') {
                        const startSlice = opt.label.indexOf('(') + 1;
                        const endSlice = opt.label.indexOf(')');
                        const sliced = opt.label.slice(startSlice, endSlice).trim().replace(' x ', 'x').replace(" mm", "mm");
                        return sliced;
                    }
                });

                // Here we create an array to store found matches
                let foundMatches = [];

                //If we find a match we push it to the foundMatches array. Ask senpai
                internationalSizesOnly.forEach((item, index) => {
                    if (item === sizeTemp) {
                        foundMatches.push(internationalPaperType[index]);
                    }
                });

                //We have two option black or color we need to sort out the options based on the color selected 
                if (color) {
                    foundMatches = foundMatches.filter(opt => {
                        if (opt && opt.label && (typeof opt.label === 'string')) {
                            if (color.toLowerCase() === 'b&w' && opt.label.toLowerCase().includes('b&w')) return true;

                            if (color.toLowerCase() !== 'b&w' && !opt.label.toLowerCase().includes('b&w') && opt.label.toLowerCase().includes(color.toLowerCase())) return true;

                            return false;

                        }
                    });
                }
                //Filter by pageNumbers
                if (numPages && !isNaN(Number(numPages))) {
                    foundMatches = foundMatches.filter(opt => {
                        return opt && opt.value && opt.value.maxPage >= Number(numPages);
                    });
                    //If the length of the filtered array is 0 then we know that the number of pages is too much for the paper type
                    if (foundMatches.length === 0) {
                        setInternationalPaperTypesFiltered(foundMatches.length);
                    }
                    // Set page acceptance based on the length of the filtered array
                    setInternationalPaperTypesFiltered(foundMatches.length);
                }
                // If no matches are  then big l but we need to display something to the user
                if (foundMatches.length === 0) {
                    foundMatches.push({ text: "N/A", label: "No matches found", shortenedLabel: "No matches found" });
                }
                //Set the found matches to the state
                setIntSizesDisplay(foundMatches);
            }
        } else {
            if (numPages && !isNaN(Number(numPages))) {
                sortedInternationalPaper = sortedInternationalPaper.filter(opt => {
                    return opt && opt.maxPage >= Number(numPages);
                });
                //If the length of the filtered array is 0 then we know that the number of pages is too much for the paper type
                if (sortedInternationalPaper.length === 0) {
                    setInternationalPaperTypesFiltered(sortedInternationalPaper.length);
                }
                // Set page acceptance based on the length of the filtered array
                setInternationalPaperTypesFiltered(sortedInternationalPaper.length);
            }

        }

    }, [(sizeTemp && sizeTemp.label), color, coverFinish, internationalPaperType, numPages]);

    return (
        <div className="w-full text-lef " id="royalty">
            <h2 className="ml-1 text-4xl font-bold mb-5">Royalty Calculator</h2>
            <div className="flex">
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="numPages"><span className="italic bold text-red mr-2">*</span>Page Count</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </div>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={numPages}
                            onChange={e => {
                                const value = e.target.value;
                                if (!isNaN(value) && Number(value) >= 0) {
                                    lengthLimiter(value, numPages, 5, setNumPages);
                                    resetPaperTypeDD(); // reset local paper type to initial state
                                } else if (value === '') {
                                    lengthLimiter(value, numPages, 5, setNumPages);
                                }
                            }}
                            color="red"
                            placeholder="Number of pages"
                            size="large"
                            type="text"
                            step="1"
                            onWheel={(e) => e.target.blur()}
                            error={showAllErrors && error && error.numPages || focusedField === 'numPages' && error && error.numPages || error.numPagesLimitExceeded || null}
                            onFocus={() => onFocusField('numPages')}
                            tabIndex={1}
                        />
                    </div>
                    {/* {numPages && (isInternational || isLocal) && Number(numPages) < 18 && <div className="flex items-center w-full">
                        <p className="text-red-500">A minimum of 18 number of pages is required</p>
                    </div>} */}
                </CardBody>
            </Card>
            <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.numberOfPagesUnder}</p>
            <div className="h-10" />
            <div className="flex">
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="sizeTemp"><span className="italic bold text-red mr-2">*</span>Book Dimensions</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </div>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>
                <CardBody>
                    <DropdownInputSearch
                        color="red"
                        className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                        initialValueDisplay={sizeTemp}
                        setValue={setSizeTemp}
                        placeholder={"Select book size (height x width)"}
                        options={SIZES.filter(s => ((isLocal && !isInternational) && s.isLocal) || ((!isLocal && isInternational) && s.isInternational) || ((isLocal && isInternational) && s.isInternational && s.isLocal)).map(s => ({ ...s, value: s }))}
                        onFocus={() => { clearError('sizeTemp'); resetPaperTypeDD(); }}
                        error={showAllErrors && error && error.sizeTemp || focusedField === 'sizeTemp' && error && error.sizeTemp || null}
                        dropdownClassName="ddup"
                        showRegion={isLocal && isInternational}
                        tabIndex="1"
                    />
                    <div className="h-5" />
                    <div className="flex flex-col items-start md:flex-row md:items-center w-full">
                        {orientation && <Label className="mt-2 md:mt-0" color="green">{orientation}</Label>}
                    </div>
                </CardBody>
            </Card>
            <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.bookDimensionsUnder}</p>

            <div className="h-10" />

            <div className="flex">
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="color"><span className="italic bold text-red mr-2">*</span>Choose whether your text will be in colour or black & white</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </div>
            <div className="h-5" />
            <div className="w-full flex calc-input" tabIndex={4}>
                {COLOR_OPTIONS.map((co, i) => (
                    <div key={i} onClick={() => { setColor(co.type); resetPaperTypeDD(); }} className={`w-1/2 cursor-pointer ${i === 0 ? "mr-5" : ""}`}>
                        <div className={`w-full bg-white rounded-xl overflow-hdden shadow-md p-4 text-left ${color === co.type ? "border-2 border-primary" : ""}`}>
                            <div className="p-4">
                                <div className="w-full h-full flex items-center justify-center">
                                    {co.text}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {showAllErrors && error && error.color && <p className="text-red text-left my-4">{error.color}</p>}
            <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.printColorUnder}</p>
            <div className="h-10" />
            {/* {!orientation && <>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="orientation"><span className="italic bold text-red mr-2">*</span>Book Orientation</p>
                <div className="h-5" />
                <div className="w-full flex calc-input" tabIndex={5}>
                    {ORIENTATION_OPTIONS.map((oo, i) => (
                        <div key={i} onClick={() => setOrientation(oo.value)} className={`w-1/2 cursor-pointer ${i === 0 ? "mr-5" : ""}`}>
                            <div className={`w-full bg-white rounded-xl overflow-hdden shadow-md p-4 text-left ${orientation === oo.value ? "border-2 border-primary" : ""}`}>
                                <div className="p-4">
                                    <div className="w-full h-full flex items-center justify-center">
                                        {oo.label}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {error && error.orientation && <p className="text-red text-left my-4">{error.orientation}</p>}

                <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.orientationUnder}</p>

                <div className="h-10" />
            </>} */}
            <div className="flex">
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="color"><span className="italic bold text-red mr-2">*</span>Cover Finish</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </div>
            <div className="h-5" />
            <div className="w-full flex calc-input" tabIndex={6}>
                {COVER_FINISH_OPTIONS.map((cfo, i) => (
                    <div key={i} onClick={() => { setCoverFinish(cfo.value); resetPaperTypeDD(); }} className={`w-1/2 cursor-pointer ${i === 0 ? "mr-5" : ""}`}>
                        <div className={`w-full bg-white rounded-xl overflow-hdden shadow-md p-4 text-left ${coverFinish === cfo.value ? "border-2 border-primary" : ""}`}>
                            <div className="p-4">
                                <div className="w-full h-full flex items-center justify-center">
                                    {cfo.label}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {showAllErrors && error && error.coverFinish && <p className="text-red text-left my-4">{error.coverFinish}</p>}
            <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.coverFinishUnder}</p>

            <div className="h-10" />

            {/* {isLocal && !spiralBoundNeeded && (
                <>
                
                    <div className="flex">
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="binding"><span className="italic bold text-red mr-2">*</span>Binding Option</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </div>

                    <div className="h-5" />
                    <div className="w-full flex calc-input" tabIndex={7}>
                        {BINDING_OPTIONS.map((bo, i) => (
                            <div key={i} onClick={() => setBinding(bo.value)} className={`w-1/2 cursor-pointer ${i === 0 ? "mr-5" : ""}`}>
                                <div className={`w-full bg-white rounded-xl overflow-hdden shadow-md p-4 text-left ${binding === bo.value ? "border-2 border-primary" : ""}`}>  
                                    <div className="p-4">
                                        <div className="w-full h-full flex items-center justify-center">
                                            {bo.label}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {error && error.binding && <p className="text-red text-left my-4">{error.binding}</p>}

                    <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.bindingUnder}</p>

                    <div className="h-10" />
                </>
            )} */}
            {isLocal && (
                <>
                    <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="localPaperType">Local Options</p>

                    {showLocalPaperTypeOptions && showLocalCoverTypeOptions && (
                        <div className="flex">
                            <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="binding"><span className="italic bold text-red mr-2">*</span>Local Paper & Cover Type</p>
                            <p className="italic text-xs text-red ml-1 pt-1">required</p>
                        </div>
                    )}
                    {showLocalPaperTypeOptions && !showLocalCoverTypeOptions && (
                        <div className="flex">
                            <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="binding"><span className="italic bold text-red mr-2">*</span>Local Paper Type</p>
                            <p className="italic text-xs text-red ml-1 pt-1">required</p>
                        </div>
                    )}
                    {!showLocalPaperTypeOptions && showLocalCoverTypeOptions && (
                        <div className="flex">
                            <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="binding"><span className="italic bold text-red mr-2">*</span>Local Cover Type</p>
                            <p className="italic text-xs text-red ml-1 pt-1">required</p>
                        </div>
                    )}

                    {showLocalPaperTypeOptions && <div className="max-w-full relative calc-input">
                        <Dropdown tabIndex={8} className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                            value={localPaperType}
                            setValue={setLocalPaperType}
                            placeholder="Select your local market paper type"
                            options={localPaperTypeOptions} />
                        <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.localMarketPaperTypeUnder}</p>
                        {showAllErrors && error && error.localPaperType && <p className="text-red text-left my-4">{error.localPaperType}</p>}
                        <div className="h-5" />
                    </div>}
                    {/* {canSupportCoverTypes && Array.isArray(canSupportCoverTypes) && canSupportCoverTypes.length > 0 && <Label color={canSupportCoverTypes.includes('hardcover') ? "green" : "red"}>
                        {canSupportCoverTypes.includes('hardcover') && <div className="flex items-center">
                            <Icon name="info" color="green" size="sm" />
                            <span className="text-green-500 ml-2 lowercase">Number of pages with this paper type allows text on spine</span>
                        </div>}
                        {!canSupportCoverTypes.includes('hardcover') && <div className="flex items-center">
                            <Icon name="info" color="red" size="xl" />
                            <span className="text-red-500 ml-2 lowercase">Number of pages with this paper type does not allow for text on spine</span>
                        </div>}
                    </Label>}
                    {canSupportCoverTypes && color && orientation && coverFinish && localPaperType && Array.isArray(canSupportCoverTypes) && canSupportCoverTypes.length === 0 && <Label color={"blue"}>
                        <div className="flex items-center">
                            <Icon name="info" color="blue" size="xl" />
                            <span className="text-blue-500 ml-2 lowercase">Number of pages with this paper type only allows for saddlestitch binding</span>
                        </div>
                    </Label>} */}
                    <div className="h-5" />

                    {showLocalCoverTypeOptions && <div className="max-w-full relative calc-input">
                        <Dropdown className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                            value={localCoverPaperType}
                            setValue={setLocalCoverPaperType}
                            placeholder="Select your local market cover type"
                            options={formattedLocalCoverPaperTypeOptions}
                        />
                        <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.localMarketCoverTypeUnder}</p>
                        {error && error.localCoverPaperType && <p className="text-red text-left my-4">{error.localCoverPaperType}</p>}
                    </div>}

                    <div className="h-5" />

                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.bookPriceTooltip}>
                        <p className="text-gray-600 ml-1 text-xl leading-normal mb-2"><span className="italic bold text-red mr-2">*</span>Local Recommended Selling Price</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left`}>
                        <CardBody>
                            <div className="mb-4 text-left flex" id="bookPrice">
                                <span className="mt-2 mr-4 font-light text-2xl">R</span>
                                <Input
                                    type="text"
                                    outline={false}
                                    value={bookPrice}
                                    onChange={(e) => { if (e.target.value.match(/^\d{0,8}(\.\d{0,2})?$/)) setBookPrice(e.target.value) }}
                                    color="red"
                                    placeholder={`Your books local recommended selling price`}
                                    size="large"
                                    error={showAllErrors && error && error.bookPrice || focusedField === 'bookPrice' && error && error.bookPrice || null}
                                    onFocus={() => onFocusField('bookPrice')}
                                    onWheel={(e) => e.target.blur()}
                                    tabIndex={8}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </>
            )}

            {isInternational && isLocal && <div className="h-5" />}

            {isInternational && (
                <>
                    <div className="h-5" />
                    <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">International Options</p>
                    <div className="h-5" />
                    <div className="flex">
                        <p className="text-gray-600 ml-1 text-xl leading-normal mb-2" id="binding"><span className="italic bold text-red mr-2">*</span>International Paper Type</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </div>
                    <div className="max-w-full relative calc-input" id="internationalPaperType">
                        <Dropdown
                            tabIndex={9}
                            className="w-full shadow-md h-auto md:h-20 justify-start bg-white text-black font-normal dropdown"
                            value={internationalPaperType} setValue={setInternationalPaperType}
                            placeholder="Select your international market paper type"
                            options={intSizesDisplay ? intSizesDisplay : []} />
                        <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.internationalMarketPaperType}</p>
                        {error && error.internationalPaperType && <p className="text-red text-left my-4">{error.internationalPaperType}</p>}
                    </div>

                    <div className="h-5" />
                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.bookPriceTooltip}>
                        <p className="text-gray-600 ml-1 text-xl leading-normal mb-2"><span className="italic bold text-red mr-2">*</span>International Recommended Selling Price</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left`}>
                        <CardBody>
                            <div className="mb-4 text-left flex" id="internationalbookPrice">
                                <span className="mt-2 mr-4 font-light text-2xl">USD</span>
                                <Input
                                    outline={false}
                                    value={internationalBookPrice}
                                    onChange={(e) => { if (e.target.value.match(/^\d{0,8}(\.\d{0,2})?$/)) setInternationalBookPrice(e.target.value) }}
                                    color="red"
                                    placeholder={`Your books international recommended selling price`}
                                    size="large"
                                    type="text"
                                    error={showAllErrors && error && error.internationalBookPrice || focusedField === 'internationalBookPrice' && error && error.internationalBookPrice || null}
                                    onFocus={() => onFocusField('internationalBookPrice')}
                                    onWheel={(e) => e.target.blur()}
                                    tabIndex={10}
                                    minLength={1}
                                    min="1"
                                    max="9"
                                />
                            </div>
                        </CardBody>
                    </Card>
                    <div className="h-5" />
                </>
            )}
            {/* {numPagesLimitExceeded && <p className="text-red text-left my-4">Please see errors above</p>} */}
            <div className="h-16" />
            {/* <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-left mb-3 md:mb-auto md:w-1/4 hover:pl-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={() => history.goBack()}
            >
                <Icon name="arrow_back" size="sm" />
                Back
            </Button>
            <Button
                color={`${numPagesLimitExceeded ? 'gray' : 'red'}`}
                className={`w-full float-right md:w-1/4 ${numPagesLimitExceeded ? 'pointer-events-none' : 'hover:bg-red hover:text-white hover:bg-red hover:pr-2'}`}
                buttonType="outline"
                size="lg"
                ripple="dark"
                disabled={numPagesLimitExceeded}
                onClick={onContinueClick}
            >
                Continue
                <Icon name="arrow_forward" size="sm" />
            </Button> */}
            {/* <div style={{ height: '15rem' }} /> */}
            <Modal
                showModal={showNegativeRoyaltyError}
                setShowModal={setShowNegativeRoyaltyError}
                size="lg"
            >
                <div className="p-5">
                    <h3 className="font-light text-xl mb-5">
                        Your royalty fees, based on your selected options is a negative value.
                    </h3>
                    <Paragraph>
                        Try editing book price, paper type and cover type until you get a positive royalty fee before moving on.
                    </Paragraph>
                    <div className="h-5" />
                    <Button
                        color="red"
                        className="w-full"
                        buttonType="filled"
                        size="lg"
                        ripple="dark"
                        onClick={() => setShowNegativeRoyaltyError(false)}
                    >
                        Okay
                    </Button>
                </div>
            </Modal>

            <Modal
                showModal={saddleStitchAlert}
                setShowModal={setSaddleStitchAlert}
                size="lg"
            >
                <div className="p-5">
                    <h3 className="font-light text-xl mb-5">
                        Maximum number of pages for saddle stitch binding is 96.
                    </h3>
                    <div className="h-5" />
                    <Button
                        color="red"
                        className="w-full"
                        buttonType="filled"
                        size="lg"
                        ripple="dark"
                        onClick={() => setSaddleStitchAlert(false)}
                    >
                        Okay
                    </Button>
                </div>
            </Modal>
        </div>
    );
}