import {useEffect} from 'react';

import Icon from '@material-tailwind/react/Icon';

import {parseQueryString} from 'utils';
import {postTransaction, postTransactionPayfast} from 'routes/userRoutes';
import {useAuth} from 'hooks/useAuth';

export default function Success() {
    const { getUserDetails } = useAuth();

    // const cartBookIds = sessionStorage.getItem('current_cart_book_ids') && isValidJson(sessionStorage.getItem('current_cart_book_ids')) && JSON.parse(sessionStorage.getItem('current_cart_book_ids'));

    const lastTransactionRef = localStorage.getItem('last_transaction_ref');

    // last_transaction_ref

    useEffect(() => {

        const pageQueries = parseQueryString();

        if(pageQueries && (typeof pageQueries === 'object') && pageQueries.token && pageQueries.id && pageQueries.tref) {
            const {tref, token, id} = pageQueries;

            postTransactionPayfast(tref, token, 'success').then(res => {
                console.log(res);

                getUserDetails();
            });

            for (let i = localStorage.length - 1; i >= 0; i--) {
                const key = localStorage.key(i);
                if (!['access-token', 'refresh-token', 'currentUser', 'activeTabInfo'].includes(key)) {
                    localStorage.removeItem(key);
                }
            }

        } else if(lastTransactionRef) {
            postTransaction(lastTransactionRef).then(() => {
                getUserDetails();
            });

            for (let i = localStorage.length - 1; i >= 0; i--) {
                const key = localStorage.key(i);
                if (!['access-token', 'refresh-token', 'currentUser', 'activeTabInfo'].includes(key)) {
                    localStorage.removeItem(key);
                }
            }
        }
    }, []);

    return (
        <div className="max-w-3xl h-screen w-full p-8 z-30 rounded-xl shadow-md-grey">
            <div className="flex flex-col items-center h-96 justify-center">
                <Icon name="check_circle" size="9xl" color="green" />
                <div className="h-8" />
                <h2 className="text-2xl">
                    Your items have been successfully processed
                </h2>   
                <p className="text-center">Your book is now in the proofing phase where we will review your book submission and contact you via our support chat if any revisions need to be made.</p>
            </div>
        </div>
    )
}
