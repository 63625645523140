import { useState, useEffect, useRef, useCallback } from 'react'

import { useGlobalState } from 'state-pool'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'

import { useHistory, useLocation } from 'react-router-dom'

import Button from '@material-tailwind/react/Button'
import Icon from '@material-tailwind/react/Icon'
import Card from '@material-tailwind/react/Card'
import CardBody from '@material-tailwind/react/CardBody'
import Checkbox from '@material-tailwind/react/Checkbox'
import Modal from 'components/general/Modal'

import { podApi } from 'api/pod'
import { uploadSignature, uploadSignatureAppendix } from 'routes/userRoutes'

import HelpTextWrapper from 'components/general/HelpTextWrapper'
import FillAndSign from 'components/fill-and-sign/FillAndSign'
import PdfModal from 'components/document-handling/PdfModal'
import HelpText from 'components/general/HelpText'
import PlacesAutocomplete from 'components/inputs/PlacesAutocomplete'
import SignatureModal from 'components/sign/SignatureModal'
import helpText from 'static-data/help-text';
import { useAlert } from 'hooks/useAlert';
import AlertModal from 'components/general/AlertModal'


export default function AcceptTermsSign({
    stage,
    localBookId,
    internationalBookId,
    acceptTerms,
    setAcceptTerms,
    signature,
    setSignature,
    setCompleted,
    completed,
    second,
    pagePathname,
    options,
    initials,
    setInitials,
    signedPlace,
    setSignedPlace,
    formDetails,
    audioFiles
}) {
    const [showModal, setShowModal] = useState(false)
    const [contract, setContract] = useState('')
    const [showContract, setShowContract] = useState(false)
    //Viewing terms
    const [terms, setTerms] = useState('')
    const [showTerms, setShowTerms] = useState(false)
    //
    const [showInitialsModal, setShowInitialsModal] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [error, setError] = useState({})

    // const [error, setError] = useState({});
    const [currentUser] = useGlobalState('currentUser')
    const location = useLocation()
    const viewerRef = useRef()
    const [showLoading, setShowLoading] = useState(false);

    const history = useHistory()

    const { newAlert } = useAlert();

    const isMobile = window.innerWidth < 767;

    useEffect(() => {
        // fetchContract()
    }, [])

    useEffect(() => {
        if (terms) {
            renderContract(contract)
            clickPosition()
        }
    }, [contract])

    function openContract() {
        setShowContract(true)
    }

    const clearError = useCallback((key) => {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError({ ...tempErrors });
        }

        return;
    }, [error]);

    const onContinueClick = () => {
        let tempErrors = {};

        setShowLoading(true);

        if (!signedPlace) {
            tempErrors['signedPlace'] = 'Please enter the signed place'
        }
        if (!initials || !signature || !signedPlace) {
            setShowLoading(false);
            setShowWarning(true);
            return;
        }

        // if(!acceptTerms) {
        //     tempErrors['acceptTerms'] = 'Please accept '
        // }
        if (tempErrors && typeof tempErrors === 'object' && (Object.keys(tempErrors).length > 0)) {
            setError(tempErrors);
            return;
        }

        if (second) {
            uploadSignatureAppendix(localBookId, internationalBookId, signature, formDetails, audioFiles).then(
                (res) => {
                    setShowLoading(true);
                    if (
                        res &&
                        res.description &&
                        res.description === 'Successful'
                    ) {
                        setCompleted([...completed, stage])
                        history.push(`/${pagePathname}/submission-complete`)

                        return
                    }

                    return
                },
            )

            return
        }

        uploadSignature(localBookId, internationalBookId, signature, formDetails, audioFiles).then((res) => {
            if (res && typeof res.statusCode === 'number' && res.statusCode === 0) {
                let next = second
                    ? `/${pagePathname}/submission-complete`
                    : `/${pagePathname}/contract`

                next =
                    second && location.pathname.includes('my-first-book')
                        ? `/${pagePathname}/submission-complete`
                        : next

                history.push(next)
                setCompleted([...completed, stage])

                return
            } else {
                newAlert('danger', 'Something went wrong', '');
            }
            // error handling...
        })
    }

    const fetchContract = () => {
        podApi.get('/user/viewcontract').then((res) => {
            const { data } = res
            setContract(data)
        })
    }

    async function renderContract(url) {
        const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true })
        // document.getElementById('pdf').src = pdfDataUri;
    }

    async function modifyPdf(x, y) {
        const existingPdfBytes = await fetch(contract).then((res) =>
            res.arrayBuffer(),
        )

        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const { width, height } = firstPage.getSize()
        firstPage.drawText(currentUser && currentUser.email, {
            x: x,
            y: height - y,
            size: 10,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })

        const pdfBytes = await pdfDoc.save()

        const pdfDocChanged = await PDFDocument.load(pdfBytes)

        const pdfDataUri = await pdfDocChanged.saveAsBase64({ dataUri: true })

        setContract(pdfDataUri)
    }

    function clickPosition() {
        if (viewerRef && viewerRef.current) {
            viewerRef.current.onclick = function (e) {
                let rect = e.target.getBoundingClientRect()
                let x = e.clientX - rect.left //x position within the element.
                let y = e.clientY - rect.top //y position within the element.

                modifyPdf(x, y)
            }
        }
    }

    function openPdfModal() {
        setShowTerms(true)
    }

    return (
        <div className="w-full text-left">
            <h3 className="text-4xl font-bold ">Accept Terms and Sign</h3>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>
                <CardBody>
                    <p className="text-xl">
                        Please review and accept our{' '}
                        <span
                            className="text-red font-bold text-xl cursor-pointer"
                            onClick={openPdfModal}
                        >
                            Terms & Conditions
                        </span>
                    </p>
                    <PdfModal
                        file="/assets/pdf/Terms_and_Conditions_Print_On_Demand.pdf"
                        title="Please review and accept our Terms & Conditions"
                        showModal={showTerms}
                        setShowModal={setShowTerms}
                    />
                    <div className="h-10" />
                    {contract && <FillAndSign file={contract} setFile={setContract} />}
                    <div className="mb-4 text-left flex items-center">
                        <Checkbox
                            color="red"
                            value={acceptTerms}
                            className="flex-shrink-0"
                            onChange={() => setAcceptTerms(!acceptTerms)}
                            text=""
                            id="checkbox"
                            checked={acceptTerms}
                        />
                        <p className="ml-1 bold">I have read through and accept Print on Demand's Terms & Conditions</p>
                    </div>
                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.acceptTermsSign && helpText.newBook.acceptTermsSign.signedPlaceTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal"><span className="italic bold text-red mr-2">*</span>Enter the location where the signing occurred.</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <div className="mb-4 text-left">
                        <PlacesAutocomplete
                            value={signedPlace}
                            setValue={setSignedPlace}
                            placeholder="Signed place (address)"
                            error={error && error.signedPlace}
                            onFocus={() => clearError('signedPlace')}
                            id="pacg-sign"
                        />
                    </div>
                    <div className="h-2" />
                    <div className="h-8" />
                    <div className="w-full grid grid-cols-1 gap-5 md:grid-cols-2">
                        <HelpTextWrapper
                            placement="top"
                            width="100%"
                            helpText={(!signature) ? "Click to draw your signature" : "Click to change your signature"}
                            hideHelp={isMobile}
                        >
                            <div className="rounded-xl border-gray-300 border-2  cursor-pointer p-5" onClick={() => setShowModal(true)}>
                                {signature ? (
                                    <>
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                                            Your Signature
                                        </p>
                                        <img
                                            className="h-32 w-auto"
                                            alt="user_signature"
                                            src={signature}
                                        />
                                        <div className="h-5" />
                                    </>
                                ) : (
                                    <div className="w-full flex flex-col items-center justify-center h-48" >
                                        <Icon name="create" size="6xl" color="gray" />
                                        <div className="h-2" />
                                        <p>Click to sign</p>
                                    </div>
                                )}
                            </div>
                        </HelpTextWrapper>
                        <HelpTextWrapper
                            placement="top"
                            width="100%"
                            helpText={!signature ? "Click to draw your initials" : "Click to change your initials"}
                            hideHelp={isMobile}
                        >
                            <div className="rounded-xl border-gray-300 border-2  cursor-pointer p-5" onClick={() => setShowInitialsModal(true)}>
                                {initials ? (
                                    <>
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                                            Your Initials
                                        </p>
                                        <img
                                            className="h-32 w-auto"
                                            alt="user_initials"
                                            src={initials}
                                        />
                                        <div className="h-5" />
                                    </>
                                ) : (
                                    <div className="w-full  flex flex-col items-center justify-center h-48" >
                                        <Icon name="create" size="6xl" color="gray" />
                                        <div className="h-2" />
                                        <p>Click to sign initials</p>
                                    </div>
                                )}
                            </div>
                        </HelpTextWrapper>
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-10" />
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-left mb-3 md:mb-auto md:w-1/4 hover:pl-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={() => history.goBack()}
            >
                <Icon name="arrow_back" size="sm" />
                Back
            </Button>
            <HelpTextWrapper
                helpText="Please enter the required fields"
                hideHelp={acceptTerms && !!signature}
                placement="bottom"
            >
                <Button
                    color="red"
                    className={` ${!acceptTerms || !signature
                        ? 'cursor-not-allowed'
                        : 'hover:bg-red hover:text-white hover:pr-2'
                        }  w-full md:w-1/4 float-right`}
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    disabled={!acceptTerms}
                    onClick={
                        onContinueClick
                    }
                >
                    {completed.includes(stage) ? 'Next' : 'Continue'}
                    <Icon name="arrow_forward" size="sm" />
                </Button>
            </HelpTextWrapper>
            <div className="h-16" />
            <SignatureModal
                show={showModal}
                setShow={setShowModal}
                save={(sigBase64) => setSignature(sigBase64)}
                title="Your Signature"
                subText="Draw your signature and click save"
            />
            <SignatureModal
                show={showInitialsModal}
                setShow={setShowInitialsModal}
                save={(sigBase64) => setInitials(sigBase64)}
                title="Your Initials"
                subText="Draw your initials and click save"
            />
            <PdfModal
                file={contract}
                title={
                    currentUser && currentUser.firstname + ' ' + currentUser.lastname
                }
                showModal={showContract}
                setShowModal={setShowContract}
            />
            <Modal
                showModal={showLoading}
                setShowModal={setShowLoading}
                size="sm"
            >
                <img
                    style={{ top: '12px' }}
                    className="h-20 w-auto relative"
                    alt="loading"
                    src="/images/loading.gif"
                />
            </Modal>
            <AlertModal
                showModal={showWarning}
                setShowModal={setShowWarning}
                size="lg"
                height="lg"
                title="Before proceeding we need you to enter your location and your signature or initials"
            >
            </AlertModal>
        </div>
    )
}
