import {useEffect} from 'react';

import Icon from '@material-tailwind/react/Icon';
import Button from '@material-tailwind/react/Button';

import {parseQueryString} from 'utils';
import {postTransactionPayfast} from 'routes/userRoutes';
import { Link } from 'react-router-dom';

export default function Cancel() {
    useEffect(() => {

        const pageQueries = parseQueryString();

        if(pageQueries && (typeof pageQueries === 'object') && pageQueries.token && pageQueries.id && pageQueries.tref) {
            const {tref, token} = pageQueries;

            postTransactionPayfast(tref, token, 'cancelled').then(res => {
                console.log(res);
            });

        }
    }, []);

    return (
        <div className="max-w-3xl h-screen w-full p-8 z-30 rounded-xl shadow-md-grey">
            <div className="flex flex-col items-center h-96 justify-center">
                <Icon name="error" size="9xl" color="red" />
                <div className="h-8" />
                <h2 className="text-2xl">
                    You cancelled the transaction
                </h2>
                <div className="h-5" />
                <Link to="/checkout/cart">
                    <Button
                        color="blue"
                        className="w-full col-span-3"
                        buttonType="outline"
                        ripple="dark"
                    >
                        Back to cart
                    </Button>
                </Link>
            </div>
        </div>
    )
}
