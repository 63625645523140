import LogRocket from "logrocket";
import { useEffect, useState } from "react";

// routing
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";

//PWA components
import UpdateAvailable from "components/pwa/updatePwa/UpdateAvailable";

// pages
import Modal from "components/general/Modal";
import NotFound from "pages/404";
import ActivateAccountInfo from "pages/ActivateAccountInfo";
import BookNew from "pages/BookNew";
import Books from "pages/Books";
import Cart from "pages/Cart";
import Dashboard from "pages/Dashboard";
import ForgotPassword from "pages/ForgotPassword";
import Login from "pages/Login";
import Orders from "pages/Orders";
import Profile from "pages/Profile";
import ProfileConfirmation from "pages/ProfileConfirmation";
import Register from "pages/Register";
import Renewals from "pages/Renewals";
import ResetPassword from "pages/ResetPassword";
import ResignContract from "pages/ResignContract";
import Sales from "pages/Sales";
import Support from "pages/Support";
import Transactions from "pages/Transactions";
import VerifyAccount from "pages/VerifyAccount";

import UpdatingProfileInformation from "components/profile/UpdateProfile";

import BulkUpload from "pages/BulkUpload";

import Documents from "pages/Documents";

// profile components
import ChangePassword from "components/profile/ChangePassword";
import UpdateEmail from "components/profile/UpdateEmail";

// ui components
import Alert from "components/general/Alert";
// import Footer from 'components/Footer';
import Sidebar from "components/layout/Sidebar";

// manage global state hook
import { useGlobal } from "hooks/useGlobalState";
import { useGlobalState } from "state-pool";

// utility functions
// import { requestNotificationPermission, isIos } from 'utils';

// scrolls to top when page changes
import ScrollToTop from "components/general/ScrollToTop";
import ShowSideBarController from "components/general/ShowSideBarController";

// Offline alert
import OfflineAlert from "components/general/OfflineAlert";

// routing
import PrivateRoute from "components/routing/PrivateRoute";

import Chat from "components/chat/Chat";

// global styling
import "theme/core.css";
import "theme/fonts.css";
import "theme/utilities.css";
// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";

// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";
import { getBooksForRenewal } from "routes/userRoutes";
import TabError from "pages/TabError";

function App() {
  const { load } = useGlobal();
  const [currentUser] = useGlobalState("currentUser");
  const [showSideBar, setShowSidebar] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const history = useHistory();


  useEffect(() => {
    if (
      currentUser &&
      currentUser.id &&
      typeof currentUser.user_status === "number" &&
      currentUser.user_status === 1
    ) {
      // load users funds - identifies user through

      // Uncomment when pushing to git
      LogRocket.identify(currentUser.id, {
          name: currentUser.firstname + ' ' + currentUser.lastname, // Assuming `name` exists on currentUser
          email: currentUser.email, // Assuming `email` exists on currentUser
      });
      load(`/user/quoteinfo`, "resources");

      load(`/user/getallsale`, "sales");
      load(`/user/getbookorders?skip=0&take=100`, "orders");
      load(`/voiceover/getallvoiceoverartists`, "voiceArtists");

      load(`/user/getuserbooks/`, "books");
      load(`/user/pricinglist`, "prices");

      return;

      // /user/pricinglist
      // set up auto refresh
      // load users transactions
      // load(`/transactions/${id}`, 'transactions');
    }
    //This checks if the user needs to activate their account.
    if (
      currentUser &&
      currentUser.id &&
      typeof currentUser.user_status === "number" &&
      currentUser.user_status === 0 &&
      !window.location.pathname.includes("activate-account-info")
    ) {
      window.location.href = "/activate-account-info";
    }
    //This check and locks the user to the resign contract page if they have books to renew and resign
    checkForActivation();
    // if (currentUser && currentUser.admin_confirmation === 1) {
    //     window.location.href = `/profile-confirmation?currentUser=${currentUser.id}`
    // }

    // try{
    //     getBooksForRenewal().then((val) => {
    //         if (val?.results.length > 0 && !window.location.href.includes('resign-contract')){
    //             window.location.href = '/resign-contract'
    //         }
    //     })
    // } catch (e) {

    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const checkForActivation = async () => {
    try {
      // Check user status and path conditions
      if (
        currentUser &&
        currentUser.id &&
        typeof currentUser.user_status === "number" &&
        currentUser.user_status === 2 &&
        !window.location.pathname.includes("activate-account-info")
      ) {
        setShowLoading(true); // Set loading state to true

        // Await result from getBooksForRenewal
        const val = await getBooksForRenewal();

        if (
          val?.results.length > 0 &&
          !window.location.href.includes("resign-contract")
        ) {
          window.location.href = "/resign-contract";
        } else {
          console.log("No Books found."); // Log this if no books are found
        }

        setShowLoading(false); // Set loading state to false
      }
    } catch (e) {
      setShowLoading(false); // Ensure loading state is set to false in case of any error
      console.log(e);
    }
  };

  const tabErrorUrl = "/tab-error";

  if (window.location.pathname.includes(tabErrorUrl)) {
    return <TabError />;
  }

  return (
    <>
      <div className="h-screen">
        <div>
          <OfflineAlert />
          <Router>
            {showSideBar && <Sidebar />}
            <UpdateAvailable />
            <ScrollToTop />
            <ShowSideBarController setShowSidebar={setShowSidebar} />
            <div className={showSideBar ? "md:ml-64" : ""}>
              <Switch>
                <Route exact path="/">
                  {<Redirect to={currentUser ? "/dashboard" : "/login"} />}
                </Route>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/support" component={Support} />
                <PrivateRoute
                  exact
                  path="/transactions"
                  component={Transactions}
                />
                <PrivateRoute exact path="/books" component={Books} />
                <PrivateRoute
                  exact
                  path="/bulk-upload"
                  component={BulkUpload}
                />

                <Route exact path="/new-book">
                  <Redirect to="/new-book/publishing-rights" />
                </Route>
                <Route exact path="/new-book/:stage" component={BookNew} />
                <Route exact path="/my-first-book">
                  <Redirect to="/my-first-book/publishing-rights" />
                </Route>
                <Route exact path="/my-first-book/:stage" component={BookNew} />
                <PrivateRoute exact path="/documents" component={Documents} />
                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute
                  exact
                  path="/profile/update-email"
                  component={UpdateEmail}
                />
                <PrivateRoute
                  exact
                  path="/profile/change-password"
                  component={ChangePassword}
                />
                <PrivateRoute
                  exact
                  path="/profile/update-users"
                  component={UpdatingProfileInformation}
                />
                <PrivateRoute
                  exact
                  path="/profile-confirmation"
                  component={() => (
                    <ProfileConfirmation setShowSidebar={setShowSidebar} />
                  )}
                />

                <Route exact path="/checkout">
                  <Redirect to="/checkout/cart" />
                </Route>
                <Route exact path="/checkout/:stage" component={Cart} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/verify" component={VerifyAccount} />
                <Route exact path="/register">
                  <Redirect to="/register/email-password" />
                </Route>
                <Route exact path="/register/:stage" component={Register} />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />
                <Route exact path="/reset-password" component={ResetPassword} />
                <PrivateRoute exact path="/renewal" component={Renewals} />
                <PrivateRoute
                  exact
                  path="/resign-contract"
                  component={() => (
                    <ResignContract setShowSidebar={setShowSidebar} />
                  )}
                />
                <Route
                  exact
                  path="/activate-account-info"
                  component={() => (
                    <ActivateAccountInfo setShowSidebar={setShowSidebar} />
                  )}
                />

                <PrivateRoute exact path="/orders" component={Orders} />
                <PrivateRoute exact path="/sales" component={Sales} />
                <Route component={NotFound} />
                <Route path="/tab-error" component={TabError} />
              </Switch>
            </div>
            {currentUser && <Chat />}
          </Router>
          <Alert />
        </div>
      </div>
      <Modal
        showModal={showLoading}
        setShowModal={setShowLoading}
        size="sm"
        title="Fetching books for resigning contract"
        onClose={() => {
          /* handle modal close logic here */
        }}
      >
        <div className="flex flex-col justify-center items-center h-full">
          <img
            className="h-14 w-auto mb-4" // Add some margin bottom for spacing
            alt="loading"
            src="/images/loading.gif"
          />
          <p className="text-gray-600">
            Please wait while we fetch the books...
          </p>
        </div>
      </Modal>
    </>
  );
}

export default App;
