const books = [
    {
        id: 0,
        product: 'Book of One',
        createdAt: new Date(),
        isApproved: true,
        bookName: 'My Book',
        numPages: 365,
        coverDesign: false,
        coverProvided: true,
        coverLink: 'https://edit.org/images/cat/book-covers-big-2019101610.jpg',
        needsProofReading: false,
        bookDocument: 'some-link.pdf',
        binding: 'Wuro',
        coverPaperType: '230gsm Sulphate Board',
        textPaperType: '70gsm Bond',
        textColor: 'color',
        paperSide: 'double',
        height: 300,
        width: 150,
        bookSize: 'A4'
    },
    {
        id: 1,
        product: 'Book of One',
        createdAt: new Date(),
        isApproved: true,
        bookName: 'Futurama',
        numPages: 365,
        coverDesign: false,
        coverProvided: true,
        coverLink: 'https://static-cse.canva.com/blob/142565/Blue-Orange-and-Yellow-Cool-Memoir_Inspirational-Book-Cover.jpg',
        needsProofReading: false,
        bookDocument: 'some-link.pdf',
        binding: 'Wuro',
        coverPaperType: '230gsm Sulphate Board',
        textPaperType: '70gsm Bond',
        textColor: 'color',
        paperSide: 'double',
        height: 300,
        width: 150,
        bookSize: 'A4'
    },
];

export default books;