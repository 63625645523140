import { useState, useEffect, useRef } from 'react';

import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

import { parseQueryString, setPageTitle } from 'utils';

export default function VerifyAccount() {
    const [verificationFailed, setVerificationFailed] = useState(false);
    const [hasVerified, setHasVerified] = useState(false);
    const { verify } = useAuth();
    const history = useHistory();

    const supportEmailRef = useRef();
    
    useEffect(() => {
        setPageTitle('Verify Account');
    }, []);

    useEffect(() => {
        const pageQueries = parseQueryString();

        if(pageQueries && typeof pageQueries === 'object' && pageQueries.id && pageQueries.token) {
            const {id, token} = pageQueries;

            verify(id, token).then(res => {
                // success case
                if(res && res.statusCode === 0) {
                    setHasVerified(true);

                // failure case
                } else {
                    setHasVerified(true);
                    setVerificationFailed(true);
                }     
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="bg-image h-screen px-3 md:px-8 flex items-center justify-center flex-col relative overflow-hidden">
            {hasVerified ? <>
                <div className="h-2" />
                <div className="max-w-3xl text-center w-full bg-white p-8 z-30 rounded-xl shadow-md-grey">
                    <div className="w-full flex justify-center py-2">
                        <img alt="logo" src="/logo.png" className="h-20 w-auto" />
                    </div>
                    <div className="h-6" />
                    {!verificationFailed ? (
                        <h2 className="text-2xl">
                            Congratulations your account has been verified. Click login to login and access your account.
                        </h2>
                    ) : (
                        <h2 className="text-2xl">
                            Something went wrong while attempting to verify your account.
                        </h2>
                    )}
                    <div className="h-2" />
                    <div className="h-10" />
                    {!verificationFailed ? (
                        <Button
                            color="red"
                            className="border-black-50 w-full hover:text-white hover:bg-red hover:shadow-xl hover:pr-2"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={() => history.push('/login')}
                        >
                            Login Now
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                        ) : (
                            <>
                                <Button
                                    color="red"
                                    className="border-black-50 w-full hover:text-white hover:bg-red hover:shadow-xl"
                                    buttonType="outline"
                                    size="lg"
                                    ripple="dark"
                                    onClick={() => supportEmailRef && supportEmailRef.current && supportEmailRef.current.click()}
                                >
                                    Contact support
                                </Button>
                                <a href="mailto:bo1@printondemand.co.za" className="invisible h-0" ref={supportEmailRef}>Email Support</a>
                            </>
                    )}

                    <div className="h-5" />
                </div>
                {/* <Modal
                    showModal={incorrectDetailsShow}
                    setShowModal={setIncorrectDetailsShow}
                    size="sm"
                >
                    <div className="p-5">
                        <h3 className="font-light text-xl mb-5">
                            Login unsuccessful
                            </h3>
                        <Paragraph>
                            The email or password you entered is incorrect or you haven't registered yet. Either{" "}
                            <Link className="text-red cursor-pointer text-base font-normal" to="/register">register</Link>{" "}or{" "}
                            <span className="text-red cursor-pointer font-normal" to="/register" onClick={() => setIncorrectDetailsShow(false)}>try again</span>.
                            </Paragraph>
                        <div className="h-5" />
                        <Button
                            color="red"
                            className="w-full"
                            buttonType="filled"
                            size="lg"
                            ripple="dark"
                            onClick={() => setIncorrectDetailsShow(false)}
                        >
                            Okay
                        </Button>
                    </div>
                </Modal> */}
            </> : (
                <div className="w-full h-96 flex items-center justify-center">
                    <img alt="loading" src="/images/loading.gif" />
                </div>
            )}
        </div>
    );
}
