import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { useEventListener } from 'usehooks-ts';

import AdminNavbar from 'components/layout/AdminNavbar';
import Icon from '@material-tailwind/react/Icon';
import Button from '@material-tailwind/react/Button';

import InstallButton from 'components/pwa/InstallButton';

import { useAuth } from 'hooks/useAuth';
// import useUniqueTab from 'hooks/useUniqueTab';
import useTabManagement from 'hooks/useTabManagement';

// NOTE: "sb-noclose" is a class created & used for the purpose of determining which elements shouldn't close the sidebar(mobile view) when clicked

export default function Sidebar() {
    const [showSidebar, setShowSidebar] = useState('-right-64');
    const { logout } = useAuth();
    // useUniqueTab();
    useTabManagement();

    useEventListener('click', (e) => {
        if (
            showSidebar !== '-right-64' &&
            e && e.target && e.target.classList &&
            !e.target.classList.contains('sb-noclose')
        ) setShowSidebar('-right-64');
    });

    return (
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen bg-white fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl w-64 py-4 px-6 transition-all duration-300 sb-noclose mobile-z-max`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative sb-noclose">
                    <div className="w-full flex justify-center">
                        <Link
                            to="/"
                            className="mt-2 sb-noclose py-6"
                        >
                            <img alt="logo" src="/favicon.png" />
                        </Link>
                    </div>
                    <div className="flex flex-col sb-noclose">

                        <ul className="flex-col min-w-full flex list-none">
                            <li className="rounded-lg mb-4">
                                <NavLink
                                    to="/dashboard"
                                    exact
                                    className="flex items-center gap-4 text-sm hover:bg-gray-500 hover:bg-opacity-20 font-light px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-red-500 shadow-md text-white"
                                >
                                    <Icon name="dashboard" size="2xl" />
                                    Dashboard
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/new-book"
                                    className="flex items-center gap-4 text-sm hover:bg-gray-500 hover:bg-opacity-20 font-light px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-red-500 shadow-md text-white"
                                >
                                    <Icon name="add" size="2xl" />
                                    Upload book
                                </NavLink>
                            </li>
                            {/* -------CM---------
            Disabled bulk upload until futher notice 
            Remember to uncomment the Link to*/}
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/bulk-upload"
                                    className="flex items-center gap-4 text-sm hover:bg-gray-500 hover:bg-opacity-20 font-light px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-red-500 shadow-md text-white"
                                >
                                    <Icon name="publish" size="2xl" />
                                    Bulk book upload
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/books"
                                    className="flex items-center gap-4 text-sm hover:bg-gray-500 hover:bg-opacity-20 font-light px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-red-500 shadow-md text-white"
                                >
                                    <Icon name="menu_book" size="2xl" />
                                    Your books
                                </NavLink>
                            </li>
                            {/* <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/sales"
                                    className="flex items-center gap-4 text-sm hover:bg-gray-500 hover:bg-opacity-20 font-light px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-red-500 shadow-md text-white"
                                >
                                    <Icon name="monetization_on" size="2xl" />
                                    Sales
                                </NavLink>
                            </li> */}
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/orders"
                                    className="flex items-center gap-4 text-sm hover:bg-gray-500 hover:bg-opacity-20 font-light px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-red-500 shadow-md text-white"
                                >
                                    <Icon name="local_offer" size="2xl" />
                                    Orders
                                </NavLink>
                            </li>
                            {/* <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/documents"
                                    className="flex items-center gap-4 text-sm hover:bg-gray-500 hover:bg-opacity-20 font-light px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-red-500 shadow-md text-white"
                                >
                                    <Icon name="insert_drive_file" size="2xl" />
                                    Documents
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/transactions"
                                    className="flex items-center gap-4 text-sm hover:bg-gray-500 hover:bg-opacity-20 font-light px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-red-500 shadow-md text-white"
                                >
                                    <Icon name="sync_alt" size="2xl" />
                                    Transactions
                                </NavLink>
                            </li> */}
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/profile"
                                    className="flex items-center gap-4 text-sm hover:bg-gray-500 hover:bg-opacity-20 font-light px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-red-500 shadow-md text-white"
                                >
                                    <Icon name="person" size="2xl" />
                                    Profile
                                </NavLink>
                            </li>
                            {/* <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/support"
                                    className="flex items-center gap-4 text-sm hover:bg-gray-500 hover:bg-opacity-20 font-light px-4 py-3 rounded-lg"
                                    activeClassName="hover:bg-opacity-100 bg-red-500 shadow-md text-white"
                                >
                                    <Icon name="help" size="2xl" />
                                    Support
                                </NavLink>
                            </li> */}
                            <li className="block md:hidden">
                                <Button onClick={logout} color="red" className="inline float-right w-full mb-2" buttonType="outline">
                                    <Icon name="logout" color="red" size="2xl" />
                                    logout
                                </Button>
                            </li>
                            <InstallButton className="block md:hidden" />
                        </ul>

                        <ul className="flex-col min-w-full list-none absolute bottom-0 hidden md:flex">
                            <li>
                                <Button onClick={logout} color="red" className="inline float-right border-red w-full mb-2" buttonType="outline">
                                    <Icon name="logout" color="red" size="2xl" />
                                    logout
                                </Button>
                            </li>
                            <InstallButton />
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
