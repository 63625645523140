import TransactionsTable from 'components/transactions/TransactionsTable';

export default function Transactions() {
    return (
        <>
            <div className="px-3 md:px-8 h-auto mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TransactionsTable />
                    </div>
                </div>
            </div>
        </>
    );
}
