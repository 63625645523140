import Alert from "@material-tailwind/react/ClosingAlert";
import Button from '@material-tailwind/react/Button';

import {useAlert} from 'hooks/useAlert';

const AlertMesage = () => {
    const {
        showAlert,
        alertMessage,
        alertTitle,
        alertType,
        alertAction,
        actionText,
        setShowAlert
    } = useAlert();

    // const alertIcons = {
    //     info: Info,
    //     success: Success,
    //     warning: Warning,
    //     danger: ErrorIcon
    // }

    const colors = {
        info: "lightBlue",
        success: "green",
        warning: "orange",
        danger: "red"
    }

    return (
        <>
            {showAlert && (
                <Alert style={style} color={colors[alertType]} onClick={() => setShowAlert(false)}>
                    <span>
                        <b>{alertTitle}</b> {alertMessage}
                    </span>
                    {alertAction && <Button
                        color="red"
                        className="ml-auto justify-self-end mr-2"
                        buttonType="link"
                        size="lg"
                        ripple="dark"
                        onClick={alertAction}
                    >
                        {actionText}
                    </Button>}
                </Alert>
            )}
        </>
    )
}

const style = {
    position: "fixed", 
    bottom: "-1em", 
    width: "100%", 
    zIndex: 9999
}

export default AlertMesage;