import Card from '@material-tailwind/react/Card';

export default function BookSales({ sales }) {

    return (
        <>
            <div className="h-5" />
            <div className="grid grid-cols-1 lg:gap-5 xl:grid-cols-6">
                <div className="xl:col-start-1 xl:col-end-7">

                        <div className="flex flex-col md:flex-row mb-5">
                            <div className="w-full">
                                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                                    <div className="flex items-center justify-center flex-col h-full py-1">
                                        {/* <HelpText helpText={helpText && helpText.books && helpText.books.royaltyFeeTooltip} iconSize="2xl" iconColor="white" placement="top"> */}
                                            <h1 className="text-white font-bold text-xl md:text-4xl font-bold">
                                                {sales && `R ${sales.profit}`}
                                            </h1>
                                        {/* </HelpText> */}
                                        <h3 className="text-white">Profit</h3>
                                    </div>
                                </Card>
                            </div>

                            <div className="w-full ml-5">
                                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                                    <div className="flex items-center justify-center flex-col h-full py-1">
                                        {/* <HelpText helpText={helpText && helpText.books && helpText.books.royaltyFeeTooltip} iconSize="2xl" iconColor="white" placement="top"> */}
                                            <h1 className="text-white font-bold text-xl md:text-4xl font-bold">
                                                {sales && sales.quantity}
                                            </h1>
                                        {/* </HelpText> */}
                                        <h3 className="text-white">Total Sold</h3>
                                    </div>
                                </Card>
                            </div>

                            <div className="w-full ml-5">
                                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                                    <div className="flex items-center justify-center flex-col h-full py-1">
                                        {/* <HelpText helpText={helpText && helpText.books && helpText.books.royaltyFeeTooltip} iconSize="2xl" iconColor="white" placement="top"> */}
                                            <h1 className="text-white font-bold text-xl md:text-4xl font-bold">
                                                {sales && sales.returned}
                                            </h1>
                                        {/* </HelpText> */}
                                        <h3 className="text-white">Total Returned</h3>
                                    </div>
                                </Card>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row mb-5">
                            <div className="w-full">
                                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                                    <div className="flex items-center justify-center flex-col h-full py-1">
                                        {/* <HelpText helpText={helpText && helpText.books && helpText.books.royaltyFeeTooltip} iconSize="2xl" iconColor="white" placement="top"> */}
                                            <h1 className="text-white font-bold text-xl md:text-4xl font-bold">
                                                {sales && `R ${sales.total_royalties}`}
                                            </h1>
                                        {/* </HelpText> */}
                                        <h3 className="text-white">Total Royalties</h3>
                                    </div>
                                </Card>
                            </div>

                            <div className="w-full ml-5">
                                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                                    <div className="flex items-center justify-center flex-col h-full py-1">
                                        {/* <HelpText helpText={helpText && helpText.books && helpText.books.royaltyFeeTooltip} iconSize="2xl" iconColor="white" placement="top"> */}
                                            <h1 className="text-white font-bold text-xl md:text-4xl font-bold">
                                                {sales && `R ${sales.total_revenue}`}
                                            </h1>
                                        {/* </HelpText> */}
                                        <h3 className="text-white">Total Cost</h3>
                                    </div>
                                </Card>
                            </div>

                            <div className="w-full ml-5">
                                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                                    <div className="flex items-center justify-center flex-col h-full py-1">
                                        {/* <HelpText helpText={helpText && helpText.books && helpText.books.royaltyFeeTooltip} iconSize="2xl" iconColor="white" placement="top"> */}
                                            <h1 className="text-white font-bold text-xl md:text-4xl font-bold">
                                                {sales && sales.returned}
                                            </h1>
                                        {/* </HelpText> */}
                                        <h3 className="text-white">Total Revenue</h3>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}
