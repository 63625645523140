import { useState, useEffect } from 'react';

import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Paragraph from '@material-tailwind/react/Paragraph';
import Icon from '@material-tailwind/react/Icon';
import SuccessfulSubmission from 'components/reset-password/SuccessfulSubmission';

import { passwordLinkCheck } from 'routes/userRoutes';

import { parseQueryString, setPageTitle } from 'utils';

// TODO: resetPassword function from useAuth
// import {useAuth} from 'hooks/useAuth';

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState({});
    const [userId, setUserId] = useState(null);
    const [hasVerified, setHasVerified] = useState(false);
    const [verificationFailed, setVerificationFailed] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    useEffect(() => {
        setPageTitle('Reset Password');
    }, []);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    function onResetClick() {
        let tempErrors = {};

        if (!password) {
            tempErrors.password = 'Please enter a new password';
        }

        if (!confirmPassword) {
            tempErrors.confirmPassword = 'Please confirm your new password';
        }

        if (password !== confirmPassword) {
            if (confirmPassword) {
                tempErrors.confirmPassword = 'passwords must match';
            }
        }

        if (password === confirmPassword) {
            let passwordCheck = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
            if (!passwordCheck.test(password)) {
                tempErrors.password = 'Your password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and must be a minimum of eight characters long.';
            }
        }

        if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }

        if (!userId) return;

        const pageQueries = parseQueryString();

        if (pageQueries && typeof pageQueries === 'object' && pageQueries.id && pageQueries.token) {
            const { id, token } = pageQueries;

            passwordLinkCheck(id, token, confirmPassword, password).then(res => {
                if (res && typeof res === 'object' && res.description === "Password changed") {
                    setSuccess(true);
                }
            });
        }
    }

    useEffect(() => {
        const pageQueries = parseQueryString();

        if (pageQueries && typeof pageQueries === 'object' && pageQueries.id && pageQueries.token) {
            const { id, token } = pageQueries;

            setUserId(id);

            // passwordLinkCheck(id, token).then(res => {
            //     // success case
            //     if(res && res.statusCode === 0) {
            //         setHasVerified(true);

            //     // failure case
            //     } else {
            //         setHasVerified(true);
            //         setVerificationFailed(true);
            //     }     
            // });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="h-screen px-3 md:px-8 flex items-center justify-center flex-col relative overflow-hidden">
            {/* <div className="absolute -bottom-20 -right-20" style={{width: '20rem', height: 'auto'}}>
                <SvgSplash className="fill-red w-full h-auto" />
            </div> */}
            {!success && (
                <>
                    {/* <div className="absolute -bottom-20 -right-20" style={{width: '20rem', height: 'auto'}}>
                        <SvgSplash className="fill-red w-full h-auto" />
                    </div> */}
                    <h3 className="text-4xl font-bold">Reset Password</h3>
                    <div className="h-2" />
                    <Paragraph>Welcome back, enter and confirm your new password to continue</Paragraph>
                </>
            )}
            <div className="h-2" />
            {!success && (
                <div className="max-w-3xl text-center w-full">

                    <div className="h-2" />
                    <div className="flex" >
                        <div className={`${error && error.password ? "mb-8" : "mb-4"} text-left w-full`}>
                            <Input
                                outline={false}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type={passwordShown ? "text" : "password"}
                                color="red"
                                placeholder="New password"
                                className={`${error && error.password ? 'mb-5' : ''}`}
                                size="regular"
                                error={error && error.password}
                            />
                        </div>
                        <div className='ml-5'>
                            <Button
                                color="red"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                icononly="true"
                                ripple="light"
                                onClick={togglePassword}
                            >
                                {!passwordShown ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    </svg>
                                )
                                }

                            </Button>
                        </div>
                    </div>

                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            type={passwordShown ? "text" : "password"}
                            color="red"
                            placeholder="Confirm password"
                            size="regular"
                            error={error && error.confirmPassword}
                        />

                    </div>
                    <div className="h-10" />
                    <Button
                        color="red"
                        className="w-full hover:bg-blue hover:text-white hover:bg-red hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onResetClick}
                    >
                        Continue
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                </div>
            )}
            {success && <SuccessfulSubmission />}
        </div>
    );
}
