import React, { useState } from 'react';

import Icon from "@material-tailwind/react/Icon";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import md5 from 'md5';

export default function CardInputSearchGenre({ options, onToggleGenres, onOptionClick, color }) {
    const [selectedSubjectHeading, setSelectedSubjectHeading] = useState('');

    // Gets all the unique subject headings 15/05/2023
    const uniqueSubjectHeadings = [...new Set(options?.map(opt => opt.value.subjectHeadingTrim).filter(opt => opt.toLowerCase() !== 'please select a genre'))];

    const handleSubjectHeadingClick = (subjectHeading) => {
        setSelectedSubjectHeading(subjectHeading);
    }

    // Making sure the values (object + label) are the same as DropdownInputSearch (onOptionClick) 16/05/2023
    const handleGenreItemClick = (option) => {
        const { text, subjectHeadingTrim, subCategoryTrim } = option;

        const value = {
            id: option.id,
            subCategoryTrim,
            subjectHeadingTrim,
            text,
        };
        onOptionClick({ value, label: option.text });

        // Drops the module window
        onToggleGenres()
    };

    return (
        <>
            {selectedSubjectHeading ? (
                // Second 'page' show genres, subcategories 15/5/2023
                <div className='w-full'>
                    <div className='flex items-center justify-between w-full'>
                        <div className="flex items-center cursor-pointer">
                            <Icon name="arrow_back" size="base" onClick={() => setSelectedSubjectHeading('')} />
                        </div>
                        <h2 className="font-bold flex-grow text-center">{selectedSubjectHeading}</h2>
                        <div className="flex items-center cursor-pointer">
                            <Icon name="close" size="base" onClick={onToggleGenres} />
                        </div>
                    </div>

                    <div className="h-5" />
                    <hr></hr>
                    <div className="h-5" />

                    <ul className="grid grid-cols-3 gap-2">
                        {/* Lists subcategories, clicking passes the object through to onOptionClick 15/05/2023*/}
                        {/* use sort function after .filter to sort the subcategories alphabetically ~Z */}
                        {options
                            .filter(opt => opt.value.subjectHeadingTrim === selectedSubjectHeading && opt.value.subjectHeadingTrim !== 'Please select a genre')
                            .sort((a, b) => a.value.subCategoryTrim.localeCompare(b.value.subCategoryTrim))
                            .map(( {value}, i ) => (
                                // <div >
                                    <li key={i}>
                                        <DropdownItem ripple="light" key={md5(value.subCategoryTrim)} color={color ? color : "red"} onClick={() => handleGenreItemClick(value)}>
                                            {value.subCategoryTrim}
                                        </DropdownItem>
                                    </li>
                                // </div>
                            ))}

                    </ul>
                </div>
            ) : (
                // First 'page' show genres 15/5/2023
                <div className='w-full'>
                    <div className='flex items-center justify-between w-full'>
                        <div className="flex items-center cursor-pointer">
                            <Icon name="arrow_back" size="base" onClick={onToggleGenres} />
                        </div>
                        <h2 className="font-bold flex-grow text-center">Genres</h2>
                        <div className="flex items-center cursor-pointer">
                            <Icon name="close" size="base" onClick={onToggleGenres} />
                        </div>
                    </div>

                    <div className="h-5" />
                    <hr></hr>
                    <div className="h-5" />

                    {/* Shows categories (~53), clicking shows next page (Subcategories) 15/05/2032 */}
                    <ul className="grid grid-cols-3 gap-2">
                        {/* fixed sorting problem by adding .sort() ~Z */}
                        {uniqueSubjectHeadings.sort().map((subjectHeading, i) => (
                            <React.Fragment key={i}>
                                <li>
                                    <DropdownItem ripple="light" key={md5(subjectHeading)} color={color ? color : "red"} onClick={() => handleSubjectHeadingClick(subjectHeading)}>
                                        {subjectHeading}
                                    </DropdownItem>
                                </li>
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            )}
            {uniqueSubjectHeadings.length === 0 && (
                <p className="p-4 text-gray">No subject headings available.</p>
            )}
        </>
    )
}
