import decode from 'jwt-decode';
import { podApi } from '../api/pod';
import axios from 'axios';
import { store } from 'state-pool';

import { isValidJson } from './index';

export async function checkTokens() {
    const accessToken = localStorage.getItem('access-token');
    const refreshToken = localStorage.getItem('refresh-token');

    if (!accessToken && !refreshToken) {
        // logout();

        return Promise.resolve(true);
    }

    const accessExpired = checkJwt(accessToken);
    const refreshExpired = checkJwt(refreshToken);

	// console.log(accessExpired, refreshExpired, refreshToken, accessToken)

    if (accessExpired && !refreshExpired && refreshToken) {
		// console.log('hello')
        try {
            const newTokens = await refreshAccessToken(refreshToken);
			// console.log(newTokens)
            if (newTokens) {
                localStorage.setItem('access-token', newTokens.accessToken);
                localStorage.setItem('refresh-token', newTokens.refreshToken);
                return newTokens;
            }
        } catch (error) {
			// console.log(error)
            logout();
            return false;
        }
    }

    if (accessExpired && refreshExpired && accessToken && refreshToken) {
        logout();

        return Promise.resolve(true);
    }

    if ((accessExpired || !accessToken) && window.location.pathname !== '/login') {
        logout();

        return Promise.resolve(true);
    }

    if (!accessExpired && !refreshExpired && accessToken && refreshToken) {
        const tokens = {
            accessToken,
            refreshToken
        };

        return Promise.resolve(tokens);
    }

    return Promise.resolve(true);
}

async function refreshAccessToken(refreshToken: any) {
    const accessToken = localStorage.getItem('access-token');

	const headers = {
        'Authorization': `Bearer ${accessToken}`
    };

    const body = {
        token: refreshToken
    };
    try {
		const response: any = await axios.post(`${process.env.REACT_APP_API_URL}/authO/refresh`, body, { headers });

		// console.log(response, 'hello')
        return response.data;
    } catch (error) {
		console.log(error)
        throw new Error("Unable to refresh token");
    }
}

function checkJwt(token?: string | null) {

    if (!token) token = localStorage.getItem('access-token');

    if (token) {
        const decoded: { exp: number } = decode(token);

        if (new Date(decoded.exp * 1000) < new Date()) {
            return true;
        }

        return false;
    }

    return false;
}

async function getAccessToken(refreshToken: string) {
    const accessToken = localStorage.getItem('access-token');
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    };

    const body = {
        token: refreshToken
    };

    try {
        const res: any = await axios.post(`${process.env.REACT_APP_API_URL}/authO/refresh`, body, { headers });

        if (res && res.data) {
            if (res.data.accessToken) localStorage.setItem('access-token', res.data.accessToken);
            if (res.data.refreshToken) localStorage.setItem('refresh-token', res.data.refreshToken);

            return Promise.resolve({
                accessToken: res.data.accessToken,
                refreshToken: res.data.refreshToken,
            });


        } else if (res && !(res.data) && res.accessToken && res.refreshToken) {

            if (res.accessToken) localStorage.setItem('access-token', res.accessToken);

            if (res.refreshToken) localStorage.setItem('refresh-token', res.refreshToken);

            return Promise.resolve({

                accessToken: res.accessToken,

                refreshToken: res.refreshToken,
            });
        }

        return Promise.resolve(false);
    } catch {
        logout();

        return Promise.resolve(false);
    }
}

export async function logout() {
    const accessToken = localStorage.getItem('access-token');
    const refreshToken = localStorage.getItem('refresh-token');

    if (refreshToken && typeof refreshToken === 'string' && accessToken && typeof accessToken === 'string') {
        await podApi.delete('/authO/logout', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data: {
                token: refreshToken
            }
        });
        store.setState("currentUser", null);
        sessionStorage.clear();
        localStorage.clear();
    }

    window.location.href = "/login";
    return;
}

export function getCurrentUser() {
    let currentUser = localStorage.getItem('currentUser');

    if (currentUser && isValidJson(currentUser)) {
        return JSON.parse(currentUser)
    }

    return;
}