import { useEffect, useState } from "react";

import BalanceInfo from "components/dashboard/BalanceInfo";
import DepositNow from "components/dashboard/DepositNow";
import Options from "components/dashboard/Options";
import Options2 from "components/dashboard/Options2";
import Options3 from "components/dashboard/Options3";

import AlertModal from "components/general/AlertModal";
import { createTransaction, getBooksForRenewal } from "routes/userRoutes";
import { useGlobalState } from "state-pool";
import { parseQueryString, setPageTitle } from "utils";
// import { useAuth } from 'hooks/useAuth';
import { podApi } from "api/pod";

import { browserName } from "react-device-detect";

export default function Dashboard() {
  // const {currentUser} = useAuth();
  const [showPayout, setShowPayout] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showCompatibility, setShowCompatibility] = useState(false);
  const [userBalance, setUserBalance] = useState(0);

  const [currentUser] = useGlobalState("currentUser");

  const isCompatibleBrowser = ["Chrome", "Firefox", "Edge", "Safari"].includes(
    browserName
  );

  if (currentUser && currentUser.admin_confirmation === 1) {
    window.location.href = `/profile-confirmation?currentUser=${currentUser.id}`;
  }

  useEffect(() => {
    // @ts-ignore
    const pageQueries = parseQueryString();

    if (pageQueries && typeof pageQueries === "object" && pageQueries.tx_ref) {
      // API

      createTransaction(
        [{ id: 1, name: "Book of One" }],
        [74],
        1,
        1,
        pageQueries.tx_ref
      );
    }

    setPageTitle("Dashboard");
  }, []);

  // useEffect(() => {
  //     try {
  //         getBooksForRenewal().then((val) => {
  //             if (val?.results.length > 0 && !window.location.href.includes('resign-contract')) {
  //                 window.location.href = '/resign-contract'
  //             }
  //         })
  //     } catch (e) {

  //     }
  // }, [])

  useEffect(() => {
    try {
      setUserBalance(JSON.parse(localStorage.getItem("currentUser"))?.balance);
      const hasTriggered = JSON.parse(sessionStorage.getItem("payout_trigger"));
      if (userBalance > 100 && !hasTriggered) {
        setShowPayout(true);
        sessionStorage.setItem("payout_trigger", "true");
      }
    } catch {
      //console.log('Error getting users balance')
    }
  }, [userBalance]);

  const MAX_RETRIES = 5;

  useEffect(() => {
    let retries = 0;
    const checkCurrentUser = () => {
      const initialLogin = JSON.parse(
        localStorage.getItem("currentUser")
      ).initial_login;
      if (initialLogin === 0) {
        setShowWelcome(true);
      } else if (retries < MAX_RETRIES) {
        retries++;
        setTimeout(checkCurrentUser, 5000);
      }
    };
    checkCurrentUser();
  }, []);

  useEffect(() => {
    const browserCompatibilitySeen = sessionStorage.getItem(
      "browser_compatibility_seen"
    );

    if (!isCompatibleBrowser && !browserCompatibilitySeen) {
      setShowCompatibility(true);
      sessionStorage.setItem("browser_compatibility_seen", "true");
    }
  }, []);

  function updateUser() {
    try {
      podApi
        .post("user/initiallogin", { initial_login: 1 })
        .then((val) => {
          // Get current user data from session storage
          let currentUser = JSON.parse(
            localStorage.getItem("currentUser") || "{}"
          );

          // Update only the initial_login in the currentUser object
          currentUser.initial_login = val.data.initial_login || 1; // Assuming the backend sends back the updated initial_login value

          // Store updated currentUser data back into session storage
          localStorage.setItem("currentUser", JSON.stringify(currentUser));

          setShowWelcome(false);
        })
        .catch((err) => {
          //console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className="bg-gray-200 px-3 md:px-8 h-40" />
      {showPayout && (
        <AlertModal
          showModal={showPayout}
          setShowModal={setShowPayout}
          title={"Payout to user"}
          message={`Your balance is currently R ${
            userBalance || 0
          }, payouts will occur in March, June, September, December.`}
        />
      )}
      {showWelcome && (
        <AlertModal
          showModal={showWelcome}
          setShowModal={updateUser}
          title={"Welcome to Print on Demand."}
          message={`We are delighted to have the opportunity to collaborate with you. Should you require any assistance, please don't hesitate to reach out to our dedicated team via the chat function`}
        />
      )}
      {showCompatibility && (
        <AlertModal
          showModal={showCompatibility}
          setShowModal={setShowCompatibility}
          title={"Browser Compatibility"}
          message={`Please make sure you are on Chrome, Firefox, Safari or Edge. Before uploading your book.`}
        />
      )}
      <div className="px-3 md:px-8 -mt-24">
        <div className="container mx-auto max-w-full">
          {/* <h2 className="font-bold text-5xl mb-2 ml-1">Welcome, {currentUser && currentUser.name_first}</h2>
                    <div className="w-full mb-5" style={{borderWidth: 0.5, borderBottom: '1px solid #00000050'}} /> */}
          <div className="xl:col-start-1 xl:col-end-4">
            <BalanceInfo />
            <div className="h-5" />
            <DepositNow />
            <div className="h-5" />
          </div>
          <div className="xl:col-start-4 xl:col-end-7">
            <Options />
            <Options2 />
            {/* <Options3 /> */}
          </div>
          <div className="xl:col-start-1 xl:col-end-4 mb-14"></div>
          <div className="xl:col-start-4 xl:col-end-7 mb-14"></div>
        </div>
      </div>
    </>
  );
}
