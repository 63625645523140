import {
    // useEffect,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';

import Button from '@material-tailwind/react/Button';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Icon from '@material-tailwind/react/Icon';
import Input from '@material-tailwind/react/Input';
import Label from "@material-tailwind/react/Label";
import Paragraph from "@material-tailwind/react/Paragraph";
import Textarea from '@material-tailwind/react/Textarea';

// import Modal from 'components/general/Modal';
import HelpText from 'components/general/HelpText';
// import HelpTextWrapper from 'components/general/HelpTextWrapper';

import Modal from 'components/general/Modal';
import DayPickerInput from 'components/inputs/DayPickerInput';
import Dropdown from 'components/inputs/Dropdown';
import DropdownInput from 'components/inputs/DropdownInput';
import DropdownInputSearch from 'components/inputs/DropdownInputSearch';
import DropdownInputSearchAddNew from 'components/inputs/DropdownInputSearchAddNew';

import { useHistory } from 'react-router-dom';

import helpText from 'static-data/help-text';
import LANGUAGES from 'static-data/languages';

import books from 'sample-data/books';
import { useGlobalState } from 'state-pool';
import { getWordCount, wordLimiter } from 'utils';

import { useEventListener } from 'usehooks-ts';

const GENRES = [
    {
        label: "Drama",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'drama'
    },
    {
        label: "Action",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'action'
    },
];

const RECOMMENDED_AUDIENCE_OPTIONS = [
    {
        label: "General Trade",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'General Trade'
    },
    {
        label: "Professional",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Professional'
    },
    {
        label: "Juvenile",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Juvenile'
    },
    {
        label: "Young Adult",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Young Adult'
    },
];

const AUDIENCE_WARNING_OPTIONS = [
    {
        label: "00 – unrated",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: '00 – unrated'
    },
    {
        label: "01 - Any Adult audience",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: '01 - Any Adult audience'
    },
    {
        label: "02 - Content warning",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: '02 - Content warning'
    },
    {
        label: "03 - Content warning (sex)",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: '03 - Content warning (sex)'
    },
    {
        label: "04 - Content warning (violence)",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: '04 - Content warning (violence)'
    },
    {
        label: "05 - Content warning (drug taking)",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: '05 - Content warning (drug taking)'
    },
    {
        label: "06 - Content warning (language)",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: '06 - Content warning (language)'
    },
    {
        label: "07 - Content warning (intolerance)",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: '07 - Content warning (intolerance)'
    },
];

const ROLE_OPTIONS = [
    {
        label: "Co-Author",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Co-Author'
    },
    {
        label: "Editor",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Editor'
    },
    {
        label: "Proofreader",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Proofreader'
    },
    {
        label: "Foreword by",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Foreword by'
    },
    {
        label: "Afterword by",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Afterword by'
    },
];

// const LANGUAGES = [
//     {
//         label: "English",
//         helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
//         value: 'english'
//     },
//     {
//         label: "Afrikaans",
//         helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
//         value: 'afrikaans'
//     },
// ];

export default function Calculator({
    subtitle,
    setSubtitle,
    author,
    setAuthor,
    contributors,
    setContributors,
    publicationDate,
    setPublicationDate,
    onSaleDate,
    setOnSaleDate,
    language,
    setLanguage,
    genre,
    setGenre,
    keywords,
    setKeywords,
    bisacCode,
    setBisacCode,
    shortDescription,
    setShortDescription,
    longDescription,
    setLongDescription,
    mainDescription,
    setMainDescription,
    copyrightHolder,
    setCopyrightHolder,

    copyrightDate,
    setCopyrightDate,

    recommendedAudience,
    setRecommendedAudience,
    indicatedAudienceWarning,
    setInicatedAudienceWarning,
    completed,
    setCompleted,
    stage,
    pagePathname,

    bookFormat

}) {
    const [error, setError] = useState({});

    const [contributorName, setContributorName] = useState('');
    const [contributorRole, setContributorRole] = useState('');
    const [showBackModal, setShowBackModal] = useState(false);

    const [keyword, setKeyword] = useState('');

    const keyWordRef = useRef();
    const contributorRef = useRef();
    const metaDataFormRef = useRef();

    const isMobile = window.innerWidth < 767;

    // const isComplete = completed.includes(stage);

    const history = useHistory();

    const clearError = useCallback((key) => {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError({ ...tempErrors });
        }

        return;
    }, [error]);

    function addContributorToList() {

        if (contributorName) {
            setContributors([...contributors, { name: contributorName, role: contributorRole }]);

            setContributorName('');
            setContributorRole('');
        }

    }

    function addKeywordToList(word) {

        if (word || keyword) {
            setKeywords([...keywords, keyword]);

            setKeyword('');
        }

    }

    function removeKeyword(kw) {
        // console.log(kw)

        if (kw && typeof kw === 'string') {
            const removed = keywords.filter(k => k !== kw);

            setKeywords([...removed]);
        }
    };

    function removeContributor(cn) {
        // console.log(removeKeyword)

        if (cn && typeof cn === 'string') {
            const removed = contributors.filter(({ name }) => name !== cn);

            setContributors([...removed]);
        }
    };

    useEffect(() => {
        if (keyword.includes(';')) {
            const keywordArr = keyword.split(';');

            if (keywordArr && Array.isArray(keywordArr) && !keywordArr[keywordArr.length - 1]) {
                keywordArr.pop();
            }

            const trimmedStrings = keywordArr.map(k => k.trim());
            const removedEmptyStr = trimmedStrings.filter(k => k !== '');

            setKeywords([...keywords, ...removedEmptyStr]);

            setKeyword('');

            // const word = keyword.replace(';', '');
            // addKeywordToList(word);
        }
    }, [keyword]);

    // useEffect(() => {
    //     if(contributorName.includes(';')) {
    //         addKeywordToList();
    //     }
    // }, [keyword]);

    useEventListener('keydown', (e) => {
        if (e && e.key && e.key === 'Enter' && e.target && e.target.id && e.target.id.includes('contributor')) {
            addContributorToList();
        }

        if (e && e.key && e.key === 'Enter' && e.target && e.target.id && e.target.id.includes('keyword')) {
            addKeywordToList();
        }
    }, metaDataFormRef);

    /* ---------------Navigation Control----------------- */
    /* -------On Continue Click------- */
    const onContinueClick = () => {
        const nameRegex = /^(?=.{2,})[a-zA-Z]+(([',. \-()[\]a-zA-Z ])?[a-zA-Z]*)*$/;
        //2-30 characters, numbers, forward slash and hyphen
        const nameAndNumberRegex = /^[a-zA-Z0-9 ]{2,30}$/;
        let tempErrors = {};

        if (bookFormat === 'print' && !author.trim()) {
            tempErrors['author'] = `Please enter your book's author`;
        }

        if (bookFormat === 'print' && author.trim() && !nameRegex.test(author.trim())) {
            tempErrors['author'] = `Please enter a valid author name with no special characters and numbers`;
        }

        // if(!contributors || (contributors && Array.isArray(contributors) && contributors.length === 0) || (contributors && !Array.isArray(contributors))) {
        //     tempErrors['contributors'] = `Please add at least one contributor`;
        // }

        if (!keywords || (keywords && Array.isArray(keywords) && keywords.length === 0) || (keywords && !Array.isArray(keywords))) {
            tempErrors['keywords'] = `Please add at least one keyword`;
        }

        if (!publicationDate) {
            tempErrors['publicationDate'] = `Please select your book's publication date`;
        }

        // if(!onSaleDate) {
        //     tempErrors['onSaleDate'] = `Please select your book's on sale date`;
        // }

        if (!language) {
            tempErrors['language'] = `Please select your book's language`;
        }

        // if(!genre) {
        //     tempErrors['genre'] = `Please select your book's genre`;
        // }

        // if(!subtitle) {
        //     // tempErrors['subtitle'] = `Please enter your book's sub-title`;
        //     // TODO make sure api request still works

        //     setSubtitle(null);
        // }

        // if(!bisacCode) {
        //     tempErrors['bisacCode'] = `Please enter your book's BISAC code`;
        // }

        // if(!shortDescription) {
        //     tempErrors['shortDescription'] = `Please enter your book's short description`;
        // }

        // if(!longDescription) {
        //     tempErrors['longDescription'] = `Please enter your book's long description`;
        // }

        if (!mainDescription.trim()) {
            tempErrors['mainDescription'] = `Please enter your book's main description`;
        }

        if (!copyrightHolder.trim()) {
            tempErrors['copyrightHolder'] = `Please enter your book's copyright holder`;
        }
        if (copyrightHolder.trim() && !nameRegex.test(copyrightHolder.trim())) {
            tempErrors['copyrightHolder'] = `Please enter a valid name with no special characters and no numbers`;
        }

        if (!copyrightDate) {
            tempErrors['copyrightDate'] = `Please select your book's on copyright date`;
        }

        if (!recommendedAudience) {
            tempErrors['recommendedAudience'] = `Please enter your book's recommended audience`;
        }

        if (!indicatedAudienceWarning) {
            tempErrors['indicatedAudienceWarning'] = `Please enter your book's indicated audience warning`;
        }

        if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            const firstElementKey = Object.keys(tempErrors)[0];

            const firstEl = document.querySelector(`#${firstElementKey}`);

            if (firstEl) {
                firstEl.scrollIntoView({ behavior: "smooth", block: "start" });
            }

            setError(tempErrors);

            return;
        }

        setAuthor(author.trim());
        setSubtitle(subtitle.trim());
        setShortDescription(shortDescription.trim());
        setLongDescription(longDescription.trim());
        setMainDescription(mainDescription.trim());
        setCopyrightHolder(copyrightHolder.trim());

        // confirm-bleed-cover

        // Disabled Confirm Bleed Cover - Disabled request for thumbnail and cover
        // const next = `/${pagePathname}/confirm-bleed-cover`;
            const next = `/${pagePathname}/upload-assets`;

        history.push(next);

        setCompleted([...completed, stage]);

        // if(bookFormat === 'print') history.push(`/${pagePathname}/calculator`);

        // if(bookFormat === 'ebook') history.push(`/${pagePathname}/e-book`);

        // if(bookFormat === 'audiobook') history.push(`/${pagePathname}/audio-book`);

    }
    /* -------/On Continue Click/------- */

    /* -------Going back------- */
    const goHome = () => {
        history.push('/dashboard');
    };
    //Block the user from going back using router-dom 
    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (action === 'POP') { // only block backward navigation
                setShowBackModal(true);
                return false; // prevent navigation
            }
        });

        return () => {
            unblock();
        };
    }, []);
    /* -------/Going back/------- */
    /* ---------------/Navigation Control/----------------- */


    return (
        <form className="w-full text-left" autoComplete='off' onSubmit={e => e.preventDefault()}>
            <h2 className="ml-1 text-4xl font-bold mb-5">Additional Details</h2>

            {bookFormat === 'print' && <>
                <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.authorTooltip}>
                    <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="author"><span className="italic bold text-red mr-2">*</span>Author</p>
                    <p className="italic text-xs text-red ml-1 pt-1">required</p>
                </HelpText>
                <div className="h-5" />
                <Card className={`max-w-3xl text-left bg-white`}>
                    <CardBody>
                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={author}
                                onChange={e => setAuthor(e.target.value)}
                                color="red"
                                placeholder="Author Name E.g. John Smith"
                                size="large"
                                type="text"
                                onFocus={() => clearError('author')}
                                error={error && error.author}
                            />
                        </div>
                        <div className="h-2" />
                    </CardBody>
                </Card>
                <div className="h-5" />

                <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.contributorsTooltip}>
                    <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">Contributors{" "}</p>
                </HelpText>
                <div className="h-5" />
                <Card className={`max-w-3xl text-left bg-white`}>
                    <CardBody>
                        <div className="mb-4 text-left relative">
                            <Input
                                outline={false}
                                value={contributorName}
                                onChange={e => setContributorName(e.target.value)}
                                color="red"
                                placeholder="Contributor Full Name E.g. Jane Smith"
                                size="large"
                                type="text"
                                autoComplete="off"
                                className="contributor-input"
                                onFocus={() => clearError('contributors')}
                                id="contributor-1"
                            />
                        </div>
                        <div className="mb-4 text-left relative">
                            <DropdownInputSearchAddNew
                                color="red"
                                className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown contributor-input"
                                value={contributorRole}
                                setValue={setContributorRole}
                                placeholder="Contributor Role"
                                options={ROLE_OPTIONS}
                                onlyValue={true}
                                onFocus={() => clearError('contributorRole')}
                                inputId="contributor-2"
                            // ref={contributorRef}
                            // error={error && error.contributorRole}
                            />
                            <div className="w-full flex items-center">
                                <Icon name="help" size="xs" color="gray" />
                                <p className="text-xs text-gray-500 ml-1">Click for options or enter and add a custom role</p>
                            </div>
                            {error && error.contributors && <div className="h-4" />}
                        </div>
                        <div className="flex items-center">
                            <Icon name="info" size="sm" color="gray" />
                            <p className="ml-2 text-gray-500">Remember to click "Add Contributor" after entering their full name and role</p>
                        </div>
                        <div className="w-full flex justify-end">
                            <Button
                                color="red"
                                className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/3 mt-4"
                                buttonType="outline"
                                size="lg"
                                ripple="dark"
                                onClick={addContributorToList}
                                type="button"
                            >
                                <Icon name="add" size="sm" />
                                Add Contributor
                            </Button>
                        </div>
                        {contributors && Array.isArray(contributors) && contributors.length > 0 && <div className="h-5" />}
                        {contributors && Array.isArray(contributors) && contributors.length > 0 && contributors.map((c, i) => (
                            <Label key={i} color="lightBlue" className="mb-2">
                                <div className="h-full flex items-center">
                                    <span className="font-bold capitalize">
                                        {c.name}
                                    </span>
                                    <span className="italic lowercase ml-1">
                                        {c.role}
                                    </span>
                                    <span className="material-icons ml-1 cursor-pointer text-base leading-none" onClick={() => removeContributor(c.name)}>cancel</span>
                                </div>
                            </Label>
                        ))}
                        <div className="h-2" />
                    </CardBody>
                </Card>
                <div className="h-5" />
            </>}

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.keywordsTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="keywords"><span className="italic bold text-red mr-2">*</span>Keywords</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left relative">
                        <Input
                            outline={false}
                            value={keyword}
                            onChange={e => setKeyword(e.target.value)}
                            color="red"
                            placeholder="E.g. cool; funny;"
                            size="large"
                            type="text"
                            autoComplete="off"
                            onFocus={() => clearError('keywords')}
                            error={error && error.keywords}
                            id="keyword-input"
                        // ref={keyWordRef}
                        />
                        {error && error.keywords && <div className="h-4" />}
                    </div>
                    {/* <div className="flex items-center">
                        <Icon name="info" size="sm" color="gray" />
                        <p className="ml-2 text-gray-500">Press "Enter" to add a keyword to the list</p>
                    </div> */}

                    <div className="flex flex-wrap">
                        {keywords && Array.isArray(keywords) && keywords.length > 0 && keywords.map((k, i) => (
                            <Label key={i} color="lightBlue" className="mt-2">
                                <div className="h-full flex items-center">
                                    <span className="lowercase">
                                        {k}
                                    </span>
                                    <span className="material-icons ml-1 cursor-pointer text-base leading-none" onClick={() => removeKeyword(k)}>cancel</span>
                                </div>
                            </Label>
                        ))}
                    </div>
                    <div className="w-full flex justify-end">
                        <Button
                            color="red"
                            className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/3 mt-4"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={addKeywordToList}
                            type="button"
                        >
                            <Icon name="add" size="sm" />
                            Add Keyword
                        </Button>
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.publicationDateTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="publicationDate"><span className="italic bold text-red mr-2">*</span>Publication Date</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <DayPickerInput
                            outline={false}
                            day={publicationDate}
                            setDay={setPublicationDate}
                            color="red"
                            placeholder="Click to select date"
                            size="large"
                            onFocus={() => clearError('publicationDate')}
                            error={error && error.publicationDate}
                            id="publication"
                        />
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.onSaleDateTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="onSaleDate">On Sale Date</p>
                {/* <p className="italic text-xs text-red ml-1 pt-1">required</p> */}
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <DayPickerInput
                            outline={false}
                            day={onSaleDate}
                            setDay={setOnSaleDate}
                            color="red"
                            placeholder="Click to select date"
                            size="large"
                            onFocus={() => clearError('onSaleDate')}
                            error={error && error.onSaleDate}
                            id="on-sale"
                        />
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />
            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.languageTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="language"><span className="italic bold text-red mr-2">*</span>Language</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <DropdownInputSearch
                            className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                            noMatches="No languages match your search term"
                            onFocus={() => clearError('language')}
                            error={error && error.language}
                            value={language}
                            setValue={setLanguage}
                            placeholder="Start typing to select your book's language"
                            options={LANGUAGES}
                            capitalize={true}
                        />
                    </div>
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.subTitleTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-0">Sub Title{" "}</p>
            </HelpText>
            <div className="flex items-center ml-1">
                <Icon name="info" size="sm" color="gray" />
                <p className="ml-2 text-gray-500">Please note that the sub title you enter here will be exactly how it will appear on ecommerce platforms.</p>
            </div>
            <p className="ml-2 text-gray-500">Please double-check that your casing, punctuation, and spelling are correct before proceeding.</p>

            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={subtitle}
                            onChange={e => setSubtitle(e.target.value)}
                            color="red"
                            placeholder="Your book's sub title"
                            size="large"
                            type="text"
                            onFocus={() => clearError('subtitle')}
                            error={error && error.subtitle}
                        />
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            {/* <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.bisacCodeTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">BISAC Code</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>  
                <CardBody>
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={bisacCode}
                            onChange={e => setBisacCode(e.target.value)}
                            color="red"
                            placeholder="BISAC Code"
                            size="large"
                            type="text"
                            onFocus={() => clearError('bisacCode')}
                            error={error && error.bisacCode}
                        />
                        {error && error.bisacCode && <div className="h-5" />}
                        <p className="text-xs mt-2">Your BISAC code is prepopulated based on the genre you chose, you may change it if you wish</p>
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" /> */}

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.shortDescriptionTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">Short Description{" "}</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="my-4 text-left">
                        <Textarea
                            outline={false}
                            value={shortDescription}
                            // onChange={e => getWordCount(shortDescription) <= 200 ? setShortDescription(e.target.value) : () => {}}
                            onChange={e => wordLimiter(e.target.value, shortDescription, 200, setShortDescription)}

                            color="red"
                            placeholder="Your book's short description"
                            type="text"
                            onFocus={() => clearError('shortDescription')}
                            error={error && error.shortDescription}
                        />
                        {error && error.shortDescription && <div className="h-5" />}
                        <p className={`${getWordCount(shortDescription) <= 200 ? 'text-gray-600' : 'text-red'} ml-1 text-md mt-2`}>{getWordCount(shortDescription)}/200</p>
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            {/* <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.longDescriptionTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">Long Description{" "}</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>  
                <CardBody>
                    <div className="my-4 text-left">
                        <Textarea
                            outline={false}
                            value={longDescription}
                            // onChange={e => getWordCount(longDescription) ? setLongDescription(e.target.value) : () => {}}
                            onChange={e => wordLimiter(e.target.value, longDescription, 4000, setLongDescription)}

                            color="red"
                            placeholder="Your book's long description"
                            size="large"
                            type="text"
                            onFocus={() => clearError('longDescription')}
                            error={error && error.longDescription}
                        />
                        {error && error.longDescription && <div className="h-5" />}
                        <p className={`${getWordCount(longDescription) <= 4000 ? 'text-gray-600' : 'text-red'} ml-1 text-md mt-2`}>{getWordCount(longDescription)}/4000</p>
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" /> */}

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.mainDescriptionTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="mainDescription"><span className="italic bold text-red mr-2">*</span>Main Description</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="my-4 text-left">
                        <Textarea
                            outline={false}
                            value={mainDescription}
                            // onChange={e => getWordCount(mainDescription) <= 512 ? setMainDescription(e.target.value) : () => {}}
                            onChange={e => wordLimiter(e.target.value, mainDescription, 512, setMainDescription)}

                            color="red"
                            placeholder="Your book's main description"
                            size="large"
                            type="text"
                            onFocus={() => clearError('mainDescription')}
                            error={error && error.mainDescription}
                        />
                        {error && error.mainDescription && <div className="h-5" />}
                        <p className={`${getWordCount(mainDescription) <= 512 ? 'text-gray-600' : 'text-red'}text-gray-600 ml-1 text-md mt-2`}>{getWordCount(mainDescription)}/512</p>
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.copyrightHolderTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="copyrightHolder"><span className="italic bold text-red mr-2">*</span>Copyright Holder</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={copyrightHolder}
                            onChange={e => setCopyrightHolder(e.target.value)}
                            color="red"
                            placeholder="Your book's copyright holder"
                            size="large"
                            type="text"
                            onFocus={() => clearError('copyrightHolder')}
                            error={error && error.copyrightHolder}
                        />
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.copyrightDateTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="copyrightDate"><span className="italic bold text-red mr-2">*</span>Copyright Date</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <DayPickerInput
                            outline={false}
                            day={copyrightDate}
                            setDay={setCopyrightDate}
                            color="red"
                            placeholder="Click to select date"
                            size="large"
                            onFocus={() => clearError('copyrightDate')}
                            error={error && error.copyrightDate}
                            id="copyright-date"
                        />
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.recommendedAudienceTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="recommendedAudience"><span className="italic bold text-red mr-2">*</span>Recommended Audience</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <DropdownInput
                            color="red"
                            className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                            value={recommendedAudience}
                            setValue={setRecommendedAudience}
                            placeholder={isMobile ? "Recommended audience" : "Select your book's recommended audience"}
                            options={RECOMMENDED_AUDIENCE_OPTIONS}
                            onlyValue={true}
                            onFocus={() => clearError('recommendedAudience')}
                            error={error && error.recommendedAudience}
                        />

                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.additionalDetails && helpText.newBook.additionalDetails.indicatedAudienceWarningTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="indicatedAudienceWarning"><span className="italic bold text-red mr-2">*</span>Indicated Audience Warning</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <DropdownInput
                            color="red"
                            className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                            value={indicatedAudienceWarning}
                            setValue={setInicatedAudienceWarning}
                            placeholder={isMobile ? "Indicated audience warning" : "Select your book's indicated audience warning"}
                            options={AUDIENCE_WARNING_OPTIONS}
                            onlyValue={true}
                            onFocus={() => clearError('indicatedAudienceWarning')}
                            error={error && error.indicatedAudienceWarning}
                        />
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <div className="h-16" />
            <Button
                color="gray"
                className="w-full float-left mb-3 md:mb-auto md:w-1/4 hover:bg-transparent hover:opacity-20"
                buttonType="outline"
                size="lg"
                ripple="dark"
            >
                <Icon name="arrow_back" size="sm" />
                Back
            </Button>
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/4 hover:pr-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={onContinueClick}
            >
                {completed.includes(stage) ? "Next" : "Continue"}
                <Icon name="arrow_forward" size="sm" />
            </Button>
            <div className="h-16" />
            {/* ---------------Modal---------------- */}
            {/* ------Back navigation------ */}
            <Modal
                showModal={showBackModal}
                setShowModal={setShowBackModal}
                size="md"
            >
                <div className="p-5">
                    <h3 className="font-light text-xl mb-5">
                        Do you wish to go back to the dashboard?
                    </h3>
                    <Paragraph>
                        Please note that you may return to finalize this at your convenience. Simply navigate to 'Your Books' and select 'Continue Upload Process'.
                    </Paragraph>
                    <div className="h-5" />
                    <Button
                        color="red"
                        className="w-full"
                        buttonType="filled"
                        size="lg"
                        ripple="dark"
                        onClick={() => setShowBackModal(false)}
                    >
                        No, stay here
                    </Button>
                    <div className="h-5" />
                    <Button
                        color="gray"
                        className="w-full"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={() => goHome()}
                    >
                        Yes, go back
                    </Button>
                </div>
            </Modal>
            {/* -------------------Modal---------------------- */}
        </form>
    );
}
