import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { useHistory } from 'react-router-dom';

import helpText from 'static-data/help-text';

import { capitalize } from 'utils';

export default function ConfirmOptions({ book, stage, completed, setCompleted, submit, isLocal, isInternational }) {
    let internationalPaperTypeLabel = '';

    const history = useHistory();

    const onContinueClick = () => {
        submit();
        setCompleted([...completed, stage]);
    }

    if (book && book.internationalPaperType && book.internationalPaperType.name && typeof book.internationalPaperType.name === 'string') {
        const startSliceIndex = book.internationalPaperType.name.lastIndexOf(')') + 1;
        const endSliceIndex = book.internationalPaperType.name.indexOf('|');

        const label = book.internationalPaperType.name
            .slice(startSliceIndex, endSliceIndex)
            .replace('-', '')
            .trim();

        internationalPaperTypeLabel = label;
    }

    function resolveCoverPaperTypeName(str) {
        if (str && typeof str === 'string') {
            if (!str.includes('(')) return str;

            if (str.includes('(')) {
                const endSlice = str.indexOf('(');

                try {
                    const returnStr = str.slice(0, endSlice).trim();

                    return returnStr;
                } catch (err) {
                    console.log(err);

                    return str;
                }
            }
        } else {
            return '';
        }
    }

    return (
        <div className="w-full text-left">
            <h3 className="text-4xl font-bold ">Confirm your specifications</h3>
            <p className="text-gray-500 text-xs ml-1 mt-2">{helpText && helpText.newBook && helpText.newBook.confirmOptions && helpText.newBook.confirmOptions.infoTop}</p>
            <div className="h-5" />
            <div className="w-full rounded-xl overflow-hidden bg-white p-8 shadow-2xl mb-7 cursor-pointer">

                <div className="w-full text-left">
                    <div className="gs-12">
                        <div className="xs gs-1-2">
                            <p className="font-light">Book name</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="font-bold">{book.title}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="font-light">Format</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="font-bold">{book.bookFormat && (typeof book.bookFormat === 'string') && (book.bookFormat.toLowerCase() === 'ebook' ? 'eBook' : capitalize(book.bookFormat))}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="font-light">Number of Pages</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="font-bold">{book.numPages}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="font-light">Genre</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="font-bold capitalize">{book.genre && book.genre.text}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="font-light">BISAC Code</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="font-bold">{book.bisacCode ? book.bisacCode : ''}</p>
                        </div>
                        {/* {book && typeof book.isbnNumberNeeded === 'boolean' && (
                            <>
                                <div className="xs gs-1-2">
                                    <p className="font-light">ISBN Number Needed</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.isbnNumberNeeded ? "Yes" : "No"}</p>
                                </div>
                            </>
                        )} */}
                        {!book.isbnNumberNeeded && book.isbnNumber && (
                            <>
                                <div className="xs gs-1-2">
                                    <p className="font-light">ISBN Number</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.isbnNumber}</p>
                                </div>
                            </>
                        )}
                        {book && book.bookFormat === 'print' && book && book.localCoverPaperType && book.localPaperType && (
                            <>
                                <div className="xs gs-1-2">
                                    <p className="font-light">Local Cover Paper Type</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.localCoverPaperType && resolveCoverPaperTypeName(book.localCoverPaperType.name)}</p>
                                </div>
                                <div className="xs gs-1-2">
                                    <p className="font-light">Local Paper Type</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.localPaperType && book.localPaperType.name}</p>
                                </div>
                            </>
                        )}
                        {book && book.bookFormat === 'print' && book && book.internationalPaperType && (
                            <>
                                <div className="xs gs-1-2">
                                    <p className="font-light">International Paper Type</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{internationalPaperTypeLabel}</p>
                                </div>
                            </>
                        )}
                        {book && book.bookFormat === 'audiobook' && book.recordingRequired && (
                            <>
                                <div className="xs gs-1-2">
                                    <p className="font-light">Voice Over Artist</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold capitalize">{book.voiceArtistName}</p>
                                </div>
                            </>
                        )}
                        {book && book.bookFormat === 'print' && (
                            <>
                                <div className="xs gs-1-2">
                                    <p className="font-light">Book Height</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.bookHeight}{book.bookHeight && 'mm'}</p>
                                </div>
                                <div className="xs gs-1-2">
                                    <p className="font-light">Book Width</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.bookWidth}{book.bookWidth && 'mm'}</p>
                                </div>
                            </>
                        )}
                        {book.size === 'custom' && (
                            <>
                                <div className="xs gs-1-2">
                                    <p className="font-light">Height</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.height}{book.height && 'mm'}</p>
                                </div>
                                <div className="xs gs-1-2">
                                    <p className="font-light">Width</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.width}{book.height && 'mm'}</p>
                                </div>
                            </>
                        )}
                        {book.bookFormat === "audiobook" && (
                            <>
                                <div className="xs gs-1-2">
                                    <p className="font-light">Audio Recording Required</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.recordingRequired ? "Yes" : "No"}</p>
                                </div>
                            </>
                        )}

                        <div className="xs gs-1-2">
                            <p className="font-light">ISBN Number Needed</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="font-bold">{book.isbnNumberNeeded ? "Yes" : "No"}</p>
                        </div>

                        {book && book.bookFormat === 'ebook' && <>
                            <div className="xs gs-1-2">
                                <p className="font-light">Heavy Graphics</p>
                            </div>
                            <div className="xs gs-2-2 text-right">
                                <p className="font-bold">{book.heavyGraphics ? 'Yes' : 'No'}</p>
                            </div>
                        </>}
                        {book && book.bookFormat === 'print' && isLocal && (
                            <>
                                {/* <div className="xs gs-1-2">
                                    <p className="font-light">Spot UV Needed</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.spotUvNeeded ? 'Yes' : 'No'}</p>
                                </div>
                                 */}
                                <div className="xs gs-1-2">
                                    <p className="font-light">Duplex Cover Needed</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.duplexNeeded ? 'Yes' : 'No'}</p>
                                </div>

                                <div className="xs gs-1-2">
                                    <p className="font-light">Soft Touch Matt Lamination Needed</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.softTouchMattLaminationNeeded ? 'Yes' : 'No'}</p>
                                </div>

                                <div className="xs gs-1-2">
                                    <p className="font-light">Spiral Bound Needed</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.spiralBoundNeeded ? 'Yes' : 'No'}</p>
                                </div>
                            </>
                        )}
                        {book && book.bookFormat === 'print' && isInternational && (
                            <>
                                <div className="xs gs-1-2">
                                    <p className="font-light">Duplex Cover Needed</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.duplexNeededInt ? 'Yes' : 'No'}</p>
                                </div>

                                <div className="xs gs-1-2">
                                    <p className="font-light">Perforation Needed</p>
                                </div>
                                <div className="xs gs-2-2 text-right">
                                    <p className="font-bold">{book.perforationNeededInt ? 'Yes' : 'No'}</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="h-5" />
            {/* <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-left mb-3 md:mb-auto md:w-1/4 hover:pl-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={() => history.goBack()}
            >
                <Icon name="arrow_back" size="sm" />
                Back
            </Button>
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/4 hover:pr-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={onContinueClick}
            >
                Continue
                <Icon name="arrow_forward" size="sm" />
            </Button> */}
            <div className="h-10" />
        </div>
    )
}
