import {useState} from 'react';

import Icon from '@material-tailwind/react/Icon';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export default function PdfViewer({file, ref, style, ...rest}) {
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(0);

    function onLoadSuccess({_pdfInfo, _transport})  {

        if(_pdfInfo && _pdfInfo.numPages) setNumPages(_pdfInfo.numPages);
    }

    function nextPage() {
        if(pageNumber !== numPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    function prevPage() {
        if(pageNumber !== 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    return (
        <div className="relative" style={style ? style : {maxWidth: '87vw'}}>
            <div className="flex justify-center items-end">
                <div className="h-12 border-gray rounded-lg bg-white w-4/5 shadow-xl mb-6 flex items-center" style={{borderWidth: '0.5px'}}>
                    <div className={`justify-self-start ml-5 h-5 w-5 z-50 ${pageNumber !== 1 ? "cursor-pointer" : "cursor-not-allowed"}`} onClick={prevPage} >
                        <Icon name="arrow_back" color={pageNumber === 1 ? "gray" : ""} />
                    </div>
                    
                    <div className="justify-self-center ml-auto">
                        page {pageNumber}/{numPages}
                    </div>

                    <div className={`justify-self-end ml-auto mr-5 h-5 w-5 ${pageNumber === numPages ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={nextPage}>
                        <Icon name="arrow_forward" color={pageNumber === numPages ? "gray" : ""} />
                    </div>
                </div>
            </div>
            {file && <Document ref={ref} file={file} onLoadSuccess={onLoadSuccess} style={{maxWidth: '100vw'}}>
                <Page
                    pageNumber={pageNumber}
                    {...rest}
                />
            </Document>}
        </div>
    )
}
