import {useRef} from 'react';

import Tooltips from '@material-tailwind/react/Tooltips';
import TooltipsContent from '@material-tailwind/react/TooltipsContent';

const HelpTextWrapper = ({children, helpText, hideHelp, className, style, placement, width}) => {
    const helpRef = useRef();

    return (
        <div className={className} style={style}>
            <div ref={helpRef} className="cursor-pointer">
                {children}
            </div>
            
            {!hideHelp && <Tooltips placement={placement ? placement : "right"} ref={helpRef}>
                <div style={{width: width ? width : 200}}>
                    <TooltipsContent>{helpText}</TooltipsContent>
                </div>
            </Tooltips>}
        </div>
        )
};

export default HelpTextWrapper;