import { useEffect } from 'react';

import { setPageTitle } from 'utils';

import BalanceInfo from 'components/dashboard/BalanceInfo';
import ProfileDetails from 'components/profile/ProfileDetails';
import ProfileActions from 'components/profile/ProfileActions';

export default function Dashboard() {

    useEffect(() => {
        setPageTitle('Profile');
    }, []);

    return (
        <>
            <div className="bg-transparent px-3 md:px-8 h-24" />

            <div className="px-3 md:px-8 -mt-20">
                <div className="container mx-auto max-w-3xl">
                    <div className="grid grid-cols-1 xl:grid-cols-6">
                        <div className="xl:col-start-1 xl:col-end-7 mb-5">
                            <BalanceInfo />
                            <ProfileDetails />
                            <ProfileActions />
                        </div>
                        {/* Below for 2 columns */}
                        {/* <div className="xl:col-start-4 xl:col-end-7 px-4 mb-5">
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}
