import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import AddCoupon from 'components/add-coupon/AddCoupon';
import BackButton from 'components/buttons/BackButton';
import Modal from 'components/general/Modal';

import { useGlobalState } from 'state-pool';

import { isValidJson, openChat } from 'utils';

import CartDetails from 'components/cart/CartDetails';
import Payment from 'components/cart/Payment';
import Success from 'components/cart/Success';

import { createTransaction } from 'routes/userRoutes';

import AlertModal from 'components/general/AlertModal';

import Cancel from 'components/cart/Cancel';
import { useLocalStorage } from 'usehooks-ts';
import { setPageTitle } from 'utils';

export default function Cart() {
    const [currentUser] = useGlobalState('currentUser');
    const helpRef = useRef();
    const [showAddCoupon, setShowAddCoupon] = useLocalStorage('cart_coupon', false);
    const [couponId, setCouponId] = useLocalStorage('cart_coupon_id', null);
    const [couponDetails, setCouponDetails] = useLocalStorage('cart_coupon_details', null);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertDetails, setAlertDetails] = useLocalStorage('cart_alert_details', null);
    const { stage } = useParams();
    const history = useHistory();
    const [paymentDetails, setPaymentDetails] = useLocalStorage('cart_payment_details', null);
    const [_, setChatPreload] = useGlobalState('chatPreload');

    const [books] = useGlobalState('books');

    useEffect(() => {
        setPageTitle('Your Cart');
    }, []);

    const getBookName = (id) => {
        const bookName = books.internationalBooks.find(x => x.id === id) || books.localBooks.find(x => x.id === id);

        if (bookName && bookName.book_title) return bookName.book_title;

        return ''
    }

    const cartItems = currentUser && currentUser.cart && Array.isArray(currentUser.cart) && currentUser.cart.filter(({ misc }) => misc && isValidJson(misc) && JSON.parse(misc)[0] && JSON.parse(misc)[0].cost);

    function getTotalCost() {
        let tempTotalCost = 0;

        if (cartItems && Array.isArray(cartItems)) {
            cartItems.forEach(({ misc }) => {
                if (misc && isValidJson(misc) && JSON.parse(misc)[0] && JSON.parse(misc)[0].cost) {
                    tempTotalCost += JSON.parse(misc)[0].cost;
                }
            });
        }

        return tempTotalCost;
    }

    const total = getTotalCost();

    function getLocalBookIds() {
        const userBookIds = cartItems.map(({ book_id }) => book_id);

        const userBookIdsDuplicatesRemoved = [...new Set(userBookIds)];

        return userBookIdsDuplicatesRemoved;
    }

    function getIntBookIds() {
        const intUserBookIds = cartItems.map(({ international_book_id }) => international_book_id);

        const intUserBookIdsDuplicatesRemoved = [...new Set(intUserBookIds)];

        return intUserBookIdsDuplicatesRemoved;
    }

    function onProceedClick() {
        // if(!hasSufficientFunds) history.push('/checkout/payment');

        // if(hasSufficientFunds) {
        const bookIds = getLocalBookIds();
        const intBookIds = getIntBookIds();

        const userId = currentUser && currentUser.id;

        const tref = `BAL-${new Date().getTime()}`;

        createTransaction(bookIds, intBookIds, total, userId, tref, couponId).then(res => {
            // RES (payment needed): {"statusCode":7,"amountToPay":1634,"cartTotal":1066,"couponDeduction":2}
            // RES (no payment needed): {"statusCode":0,"amountToPay":1634,"cartTotal":1066,"couponDeduction":2}

            if (res && typeof res.amountToPay === 'number' && res.cartTotal && typeof res.couponDeduction === 'number' && res.statusCode === 0) {
                const { amountToPay, cartTotal, couponDeduction } = res;

                setPaymentDetails({ amountToPay, cartTotal, couponDeduction });

                localStorage.setItem('current_cart_book_ids', JSON.stringify(bookIds));

                localStorage.setItem('last_transaction_ref', tref);

                // openChat();
                history.push('/checkout/success');
            }

            if (res && res.amountToPay && (typeof res.cartTotal === 'number') && (typeof res.couponDeduction === 'number') && res.statusCode === 7) {
                const { amountToPay, cartTotal, couponDeduction } = res;

                setPaymentDetails({ amountToPay, cartTotal, couponDeduction });

                localStorage.setItem('current_cart_book_ids', JSON.stringify(bookIds));

                localStorage.setItem('last_transaction_ref', tref);

                // openChat();
                history.push('/checkout/payment');
            }

            // if(res && (typeof res.amountToPay === 'number') && res.amountToPay === 0 && res.statusCode === 0) {
            //     const {amountToPay, cartTotal, couponDeduction} = res;

            //     setPaymentDetails({amountToPay, cartTotal, couponDeduction});

            //     localStorage.setItem('current_cart_book_ids', JSON.stringify(bookIds));

            //     localStorage.setItem('last_transaction_ref', tref);

            //     // openChat();
            //     history.push('/checkout/success');
            // }

            // RES (already used coupon): {"description":"already used coupon","statusCode":"C15"}
            if (res && res.description && res.statusCode === "C15") {
                setAlertDetails({
                    title: 'Invalid coupon code applied',
                    message: 'You have already used this coupon code'
                });
            }
        });
        // };
    }

    function onRemoveClick(name, bookId) {
        const bookTitle = getBookName(bookId)
        setChatPreload(`I would like to remove Item: ${name} ${bookTitle && 'of Book: ' + bookTitle} from my cart`);
        setInterval(() => {
            setChatPreload('')
        }, 500);
    }

    return (
        <div className="px-3 md:px-8 flex justify-center relative overflow-hidden">
            {stage !== 'payment' && stage !== "success" && stage !== "cancelled" && <div className="absolute top-5 left-5 z-50">
                <BackButton />
            </div>}
            <div className="h-2" />
            {stage === "cart" && <CartDetails couponDetails={couponDetails} setCouponDetails={setCouponDetails} setCouponId={setCouponId} cartItems={cartItems} total={total} onProceedClick={onProceedClick} onRemoveClick={onRemoveClick} />}
            {stage === "success" && <Success paymentDetails={paymentDetails} />}
            {stage === "cancelled" && <Cancel paymentDetails={paymentDetails} />}
            {stage === "payment" && <Payment paymentDetails={paymentDetails} currentUser={currentUser} total={total} helpRef={helpRef} cartItems={cartItems} />}
            <Modal
                showModal={showAddCoupon}
                setShowModal={setShowAddCoupon}
                size="lg"
            >
                <AddCoupon setShowModal={setShowAddCoupon} />
            </Modal>
            <AlertModal showModal={showAlertModal} setShowModal={setShowAlertModal} title={alertDetails && alertDetails.title} message={alertDetails && alertDetails.message} />
        </div>
    );
}
