import {useState} from 'react';
import {Link} from 'react-router-dom';

import { Paragraph, Heading3 } from "@material-tailwind/react";
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { useGlobalState } from 'state-pool';

import { podApi } from 'api/pod';
import { useAuth } from 'hooks/useAuth';

export default function Welcome() {
    const [code, setCode] = useState('');
    const { getUserDetails } = useAuth();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [currentUser] = useGlobalState('currentUser');

    function submit() {
        if(!code) {
            setError('Coupon code can not be blank');

            return;
        }

        const body = {
            coupon_code: code
        }

        podApi.post('/user/addcoupon', body).then(res => {
            if(res && res.data && res.data.description && res.data.description === 'Successful') {
                // refresh user Details
                getUserDetails();
                setSuccess(true);
            } else {
                setError('Something went wrong when adding your coupon, please check your spelling. Otherwise contact support bo1@printondemand.co.za');
            }
        });
    }

    return (
        <>
            {!success ? <>
                <div className="w-full text-center">
                    <Heading3>Welcome to Print on Demand</Heading3>
                    <Paragraph>
                        Congratulations, you've successfully registered!
                    </Paragraph>
                </div>
                <div className="h-4" />
                <Link to="/new-book">
                    <Button
                        color="red"
                        className="hover:bg-red hover:text-white w-full hover:shadow-xl hover:pr-2 mb-5"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                    >
                        Get started
                        <Icon color="black" name="arrow_forward" size="sm" />
                    </Button>
                </Link>
                <div className="h-2" />
            </> : 
            <div className="w-full h-full flex flex-col align-center justify-center">
                <h3 className="text-4xl font-bold font-bold">Your Balance{" "}<span className="font-light">R{currentUser && currentUser.balance}</span></h3>
                <div className="h-5" />
                <Paragraph>
                    R100 was successfully added to your balance. You now have enough credits to get started on your first book! Click "Get started" to create your first book.
                </Paragraph>
                <div className="h-5" />
                <Link to="/new-book">
                    <Button
                        color="red"
                        className="hover:bg-red hover:text-white w-full hover:shadow-xl hover:pr-2 mb-5"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                    >
                        Get started
                        <Icon color="black" name="arrow_forward" size="sm" />
                    </Button>
                </Link>
            </div>}
        </>
    );
}

