import {useState} from 'react';

import Card from 'components/cards/Card';
import Icon from '@material-tailwind/react/Icon';

import Faq from './Faq';
import Legal from './Legal';
import Fees from './Fees';
import ContactUs from './ContactUs';

export default function Main() {
    const [selected, setSelected] = useState('legal');

    return (
        <>
            <div className="h-5" />
            {/* <div className="flex">
                <div className="w-1-3 md:w-full" onClick={() => setSelected('faq')}>
                    <Card className={selected === "faq" ? "bg-white shadow-2xl cursor-pointer" : "bg-white shadow-2xl cursor-pointer"}>
                        <div className="flex items-center justify-center flex-col h-full">
                            <Icon name="live_help" color={selected === "faq" ? "red" : "gray"} size="6xl" />
                            <h3 className={selected === "faq" ? "text-red" : "text-gray-500"}>FAQ</h3>
                        </div>
                    </Card>
                </div>
                <div className="w-1-3 md:w-full ml-3-custom md:ml-5" onClick={() => setSelected('fees')}>
                    <Card className={selected === "fees" ? "bg-white shadow-2xl cursor-pointer" : "bg-white shadow-2xl cursor-pointer"}>
                        <div className="flex items-center justify-center flex-col h-full">
                            <Icon name="paid" color={selected === "fees" ? "red" : "gray"} size="6xl" />
                            <h3 className={selected === "fees" ? "text-red" : "text-gray-500"}>Fees</h3>
                        </div>
                    </Card>
                </div>
                <div className="w-1-3 md:w-full ml-3-custom md:ml-5" onClick={() => setSelected('legal')}>
                    <Card className={selected === "legal" ? "bg-white shadow-2xl cursor-pointer" : "bg-white shadow-2xl cursor-pointer"}>
                        <div className="flex items-center justify-center flex-col h-full">
                            <Icon name="language" color={selected === "legal" ? "red" : "gray"} size="6xl" />
                            <h3 className={selected === "legal" ? "text-red" : "text-gray-500"}>Legal</h3>
                        </div>
                    </Card>
                </div>
            </div> */}
            <div className="h-10" />
            <div className="grid grid-cols-1 xl:grid-cols-6">
                <div className="xl:col-start-1 xl:col-end-5 px-4 mb-5">
                    {selected === 'faq' && <Faq />}
                    {selected === 'fees' && <Fees />}
                    {selected === 'legal' && <Legal />}
                </div>
                <div className="xl:col-start-5 xl:col-end-7 px-4 mb-5">
                    <ContactUs />
                </div>
            </div>
        </>
    );
}
