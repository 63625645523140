const documents = [
    {
        name: 'book_1.pdf',
        link: 'https://edit.org/images/cat/book-covers-big-2019101610.jpg',
        type: 'book'
    },
    {
        name: 'book_1_contract.pdf',
        link: 'https://www.allbusinesstemplates.com/thumbs/40fe228e-9702-40c7-a6ab-1026532ec05d_1.png',
        type: 'contract'
    },
    {
        name: 'book_2.pdf',
        link: 'https://static-cse.canva.com/blob/142565/Blue-Orange-and-Yellow-Cool-Memoir_Inspirational-Book-Cover.jpg',
        type: 'contract'
    },
    {
        name: 'book_2_contract.pdf',
        link: 'https://www.allbusinesstemplates.com/thumbs/40fe228e-9702-40c7-a6ab-1026532ec05d_1.png',
        type: 'contract'
    },
];



export default documents;