import Button from "@material-tailwind/react/Button";
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import Checkbox from "@material-tailwind/react/Checkbox";
import Icon from "@material-tailwind/react/Icon";
import Input from "@material-tailwind/react/Input";
import Label from "@material-tailwind/react/Label";
import Paragraph from "@material-tailwind/react/Paragraph";
import Ebook from "components/books/ebook/Ebook";
import Calculator from "components/books/print/Calculator";
import ConfirmOptions from "components/books/print/ConfirmOptions";
import HelpText from "components/general/HelpText";
import HelpTextWrapper from "components/general/HelpTextWrapper";
import Modal from "components/general/Modal";
import DropdownInputSearch from "components/inputs/DropdownInputSearch";
import DropdownInputSearchAddNew from "components/inputs/DropdownInputSearchAddNew";
import { is } from "date-fns/locale";
import { set } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { checkIsbn } from "routes/userRoutes";
import user from "sample-data/user";
import helpText from "static-data/help-text";
import { useEventListener, useLocalStorage } from "usehooks-ts";
import { capitalize, resolveBookFormatName } from "utils";

const HELP_TEXT = {
  print:
    "Your book will be sold as physical copies, purchasable by customers on ecommerce platforms",
  audio:
    "Your book will be read by a voice over artist and recorded. It will be published in audio format",
  ebook: "Your book will be available in virtual format for download as a pdf",
  local: "Your book will be sold within South Africa",
  international: "Your book will be sold internationally",
};
const ROLE_OPTIONS = [
  {
    label: "Co-Author",
    helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
    value: "Co-Author",
  },
  {
    label: "Editor",
    helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
    value: "Editor",
  },
  {
    label: "Proofreader",
    helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
    value: "Proofreader",
  },
  {
    label: "Foreword by",
    helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
    value: "Foreword by",
  },
  {
    label: "Afterword by",
    helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
    value: "Afterword by",
  },
];

export default function TitleRegionFormat({
  stage,
  title,
  setTitle,
  setCompleted,
  completed,
  localInternational,
  setBookFormat,
  bookFormat,
  pagePathname,
  isLocal,
  setIsLocal,
  isInternational,
  setIsInternational,
  isbnNumber,
  setIsbnNumber,
  isbnNumberNeeded,
  setIsbnNumberNeeded,
  options,
  genre,
  setGenre,
  bisacCode,
  setBisacCode,
  spotUvNeeded,
  setSpotUvNeeded,
  duplexNeeded,
  setDuplexNeeded,
  softTouchMattLaminationNeeded,
  setSoftTouchMattLaminationNeeded,
  spiralBoundNeeded,
  setSpiralBoundNeeded,
  duplexNeededInt,
  setDuplexNeededInt,
  perforationNeededInt,
  setPerforationNeededInt,
  author,
  setAuthor,
  contributors,
  setContributors,
  //Page Two
  numPages,
  setNumPages,
  paperTypesFiltered,
  setPaperTypesFiltered,
  internationalPaperTypesFiltered,
  setInternationalPaperTypesFiltered,
  size,
  setSize,
  bookPrice,
  setBookPrice,
  paperSide,
  royalties,
  setRoyalties,
  isRoyaltiesLoading,
  setIsRoyaltiesLoading,
  color,
  setColor,
  pageFinish,
  localPaperType,
  setLocalPaperType,
  setLocalInternational,
  setPaperSide,
  setPageFinish,
  setIntCoverOption,
  setIntCoverType,
  spineOption,
  setSpineOption,
  canSupportIntCoverTypes,
  canSupportPaperType,
  heavyGraphics,
  setHeavyGraphics,
  localCoverPaperType,
  setLocalCoverPaperType,
  internationalPaperType,
  setInternationalPaperType,
  internationalCoverPaperType,
  setInternationalCoverPaperType,
  intCoverOption,
  intCoverType,
  bookWidth,
  setBookWidth,
  bookHeight,
  setBookHeight,
  canSupportCoverTypes, // array of types that are supported (hardcover, paperback) based on min pages of page types selected -  also show user if text is supported on spine
  orientation,
  setOrientation,
  coverFinish,
  setCoverFinish,
  internationalBookPrice,
  setInternationalBookPrice,
  binding,
  setBinding,
  numPagesLimitExceeded,
  //
  //Confirmation page
  submit,
  book,
}) {
  const [error, setError] = useState({});
  const [focusedField, setFocusedField] = useState(null);
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [validationLevel, setValidationLevel] = useState(0);
  const [ebookValidationLevel, setEbookValidationLevel] = useState(0);
  const [sizeTemp, setSizeTemp] = useLocalStorage("calc_sizeTemp", null);

  const [hoverKey, setHoverKey] = useState(null);
  const [showFormatHelp, setShowFormatHelp] = useState(false);
  const [showRegionHelp, setShowRegionHelp] = useState(false);

  const isComplete = completed.includes(!stage);
  const [showIntro, setShowIntro] = useState(!isComplete);
  const [showNegativeRoyaltyError, setShowNegativeRoyaltyError] =
    useState(false);
  const [showBackModal, setShowBackModal] = useState(false);

  const [contributorName, setContributorName] = useState("");
  const [contributorRole, setContributorRole] = useState("");

  const [showGenres, setShowGenres] = useState(false);
  const [page, setPage] = useState(1);

  const history = useHistory();

  const formRef = useRef();

  const [isFirstInteraction, setIsFirstInteraction] = useState(true);

  // const [searchTerm, setSearchTerm] = useState('');
  // bisacText added to value to allow for searching by bisac code 10/05/2023
  // bisacCategory added to value to allow for searching by bisac category in 'dropdown' card 12/05/2023
  // SubjectHeadingTrim added to value to display only the SubjectHeading in 'CardInputSearchGenre' card 12/05/2023

  useEffect(() => {
    if (localInternational) clearError("localInternational");

    if (bookFormat) clearError("bookFormat");

    if (genre) clearError("genre");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInternational, bookFormat, genre]);

  function onFormatOptionHover(key) {
    if (key && key !== "local" && key !== "international") {
      setShowFormatHelp(true);

      setHoverKey(key);

      return;
    } else if (key === "local" || key === "international") {
      setShowRegionHelp(true);

      setHoverKey(key);

      return;
    }

    setShowFormatHelp(false);
    setShowRegionHelp(false);

    setHoverKey(null);
  }

  const genreOptions = useMemo(() => {
    if (options && options.genres && Array.isArray(options.genres)) {
      let filteredGenres = options.genres;

      if (!isFirstInteraction) {
        filteredGenres = filteredGenres.filter(
          (g) => g.SubjectHeading.toLowerCase() !== "please select a genre"
        );
      }

      return filteredGenres.map((g) => ({
        value: {
          id: g.id,
          text: capitalize(g.SubjectHeading.toLowerCase()),
          bisacText: capitalize(g.bisac).toLowerCase(),
          bisacCategory: capitalize(g.bisac)
            .toLowerCase()
            .replace(/[0-9\s]/g, ""),
          subjectHeadingTrim: capitalize(g.SubjectHeading.toLowerCase())
            .split("/")[0]
            .trim(),
          subCategoryTrim: capitalize(g.SubjectHeading)
            .split("/")
            .slice(1)
            .join("/")
            .trim(),
        },
        label: capitalize(g.SubjectHeading.toLowerCase()),
        text: g.SubjectHeading.toLowerCase(),
      }));
    }
    return [];
  }, [options, isFirstInteraction]);

  const defaultGenre = useMemo(() => {
    if (genre) {
      // If there's a genre prop, find the matching genre option.
      return genreOptions.find((g) => g.value.text === genre.text) || null;
    }
    // If no genre prop, you might return a default genre from genreOptions or null.
    return genreOptions[0] || null;
  }, [genre, genreOptions]);

  const handleGenreChange = (selectedGenre) => {
    setIsFirstInteraction(false);
    // And then, call setGenre or any other logic you have on change
    setGenre(selectedGenre);
  };

  useEffect(() => {
    if (
      genre &&
      genre.text &&
      typeof genre.text === "string" &&
      options &&
      typeof options === "object" &&
      options.genres
    ) {
      const { genres } = options;

      if (Array.isArray(genres)) {
        const foundGenreObject = genres.find(
          (g) => g.SubjectHeading.toLowerCase() === genre.text.toLowerCase()
        );

        if (
          foundGenreObject &&
          typeof foundGenreObject === "object" &&
          foundGenreObject.bisac
        ) {
          const { bisac } = foundGenreObject;

          setBisacCode(bisac);
        }
      }
    }
  }, [genre, options]);

  function toggleGenres() {
    setShowGenres(!showGenres);
  }

  function addContributorToList() {
    // Checking if the contributor name and role are not empty
    if (contributorName.trim() === "" || contributorRole.trim() === "") {
      setError({
        contributors:
          "Please fill both the Contributor Name and Contributor Role fields",
      });
      return;
    }
    setContributors([
      ...contributors,
      { name: contributorName, role: contributorRole },
    ]);

    setContributorName("");
    setContributorRole("");
  }

  function removeContributor(cn) {
    if (cn && typeof cn === "string") {
      const removed = contributors.filter(({ name }) => name !== cn);

      setContributors([...removed]);
    }
  }
  /* ------------------------Navigation function ------------------------ */
  const onHandleBack = () => {
    if (page === 1) {
      setShowBackModal(true);
      return;
    }

    if (page === 2 || page === 3) {
      setCompleted(completed.slice(0, -1));
      setPage(page - 1);
    }
  };

  const onHandleContinue = async () => {
    const errorList = await validateField();
    //Check for validation of isbn
    if (isbnNumberNeeded === false && isbnNumber.length === 13) {
      const hasIsbnError = await isbnCheck(isbnNumber);
      if (hasIsbnError) {
        return;
      }
    }

    setTitle(title.trim());

    if (page === 1 && (validationLevel === 1 || validationLevel === 2)) {
      completeStage("general-info");
      return;
    }

    //Ebook validation still counts as ths calculator stage
    if (
      page === 2 &&
      bookFormat === "ebook" &&
      royalties &&
      royalties.localRoyalty === "$0"
    ) {
      setShowNegativeRoyaltyError(true);
      return;
    }
    if (
      page === 2 &&
      bookFormat === "ebook" &&
      royalties &&
      royalties.localRoyalty !== "$0" &&
      internationalBookPrice !== "" &&
      numPages !== ""
    ) {
      completeStage("calculator");
      return;
    }

    if (page === 2) {
      if (
        royalties &&
        ((royalties.internationalRoyalty &&
          royalties.internationalRoyalty.includes("-")) ||
          (royalties.localRoyalty && royalties.localRoyalty.includes("-")))
      ) {
        setShowNegativeRoyaltyError(true);
      }

      if (!royalties && isRoyaltiesLoading) {
        setShowAllErrors(true);
        handleErrors(errorList);
        return;
      }
    }

    if (page === 2 && errorList && Object.keys(errorList).length === 0) {
      completeStage("calculator");
      return;
    }

    if (page === 3 && errorList && Object.keys(errorList).length === 0) {
      setCompleted([...completed, "confirm-details"]);
      submit();
      return;
    }

    if (
      page === 3 &&
      bookFormat === "ebook" &&
      numPages &&
      internationalBookPrice
    ) {
      setCompleted([...completed, "confirm-details"]);
      submit();
      return;
    }

    handleErrors(errorList);
  };

  const completeStage = (stageName) => {
    setCompleted([...completed, stageName]);
    setPage(page + 1);
    setShowAllErrors(false);
    window.scrollTo(0, 0);
  };

  const handleErrors = (errorList) => {
    if (!errorList) return;

    setShowAllErrors(true);
    const firstElementKey = Object.keys(errorList)[0];
    const firstEl = document.querySelector(`#${firstElementKey}`);
    if (firstEl) {
      firstEl.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  /* ------------------------Navigation function ------------------------ */
  /* ------------------------Validation ------------------------ */
  // Validation function
  const validateField = async () => {
    let tempErrors = {};
    setValidationLevel(0);
    //Deletes the error of the field that is currently focused
    if (focusedField) {
      delete tempErrors[focusedField];
    }
    //-----------------Regex-----------------
    const numRegex = /^[0-9]+$/;
    const numRegexWithDecimal = /^[0-9.]+$/;
    const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    const nameRegex = /^(?=.{2,})[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    const nameRegexWithNumbers = /^[a-zA-Z0-9 ]+$/;
    const nameRegexWithNumbersAndSpecialCharacters =
      /^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/;

    // -----------------Page One-----------------
    if (!title.trim()) {
      tempErrors["title"] = "Please enter a title for your book";
    }
    if (title && title.length <= 2) {
      tempErrors["title"] = "Title must be at least 3 characters long";
    }
    if (title && title.length >= 50) {
      tempErrors["title"] = "Title must be less than 50 characters long";
    }

    if (!bookFormat) {
      tempErrors["bookFormat"] = "Please select a format for your book";
    }
    if (bookFormat === "ebook" && !author) {
      tempErrors["author"] = "Please enter an author for your book";
    }
    if (bookFormat === "ebook" && author && !nameRegex.test(author)) {
      tempErrors["author"] = "Please enter a valid author for your book";
    }

    if (!isLocal && !isInternational) {
      tempErrors["localInternational"] =
        "Please select whether our book will be sold to a local or an international market";
    }

    if (!genre) {
      tempErrors["genre"] = "Please select a genre for your book";
    }
    if (genre === "Please Select A Genre") {
      tempErrors["genre"] = "Please select a genre for your book";
    }

    if (!isbnNumberNeeded && !isbnNumber) {
      tempErrors["isbnNumber"] = "Please enter an ISBN number for your book";
    }
    if (!isbnNumberNeeded && isbnNumber) {
      if (!numRegex.test(isbnNumber) || isbnNumber.length !== 13) {
        tempErrors["isbnNumber"] =
          "Please enter a valid ISBN number for your book - 13 characters long and only numbers.";
      }
    }

    //checking if the isbn is valid

    if (
      tempErrors &&
      !tempErrors.title &&
      !tempErrors.bookFormat &&
      !tempErrors.localInternational &&
      !tempErrors.genre &&
      !tempErrors.isbnNumber &&
      !tempErrors.author
    ) {
      setValidationLevel(1);
    }

    //-----------------Page Two-----------------
    if (!numPages) {
      tempErrors["numPages"] = "Please enter the number of pages in book";
    }
    if (numPages && !numRegex.test(numPages)) {
      tempErrors["numPages"] =
        "Please enter a valid number of pages for your book";
    }
    if (numPages && Number(numPages) < 18) {
      tempErrors["numPages"] =
        "Local and International books require at least 18 pages";
    }
    if (numPages && numPages.includes(".")) {
      tempErrors["numPages"] =
        "Local and International books can not have half a page";
    }
    //if number of pages is a odd number we want to throw an error
    if (numPages && Number(numPages) % 2 !== 0) {
      tempErrors["numPages"] =
        "Please add an extra page for printed books with an uneven number of pages.";
    }
    //soft cap on amount of pages per book
    if (bookFormat !== "ebook" && numPages && Number(numPages) > 1200) {
      tempErrors["numPages"] =
        "Local and International books can not have more than 1200 pages";
    }
    if (numPagesLimitExceeded) {
      tempErrors["numPages"] = "The amount of pages exceed the paper type";
    }

    if (!sizeTemp) {
      tempErrors["sizeTemp"] = "Please enter the dimensions of your book";
    }

    if (!bookHeight) {
      tempErrors["bookHeight"] = "Please enter the height of your book";
    }

    if (!bookWidth) {
      tempErrors["bookWidth"] = "Please enter the width of your book";
    }

    if (!color) {
      tempErrors["color"] =
        "Please select if your book will be printed in colour";
    }

    if (!orientation) {
      tempErrors["orientation"] = "Please select the orientation of your book";
    }
    if (!coverFinish) {
      tempErrors["coverFinish"] = "Please select the cover finish of your book";
    }

    if (!paperSide) {
      tempErrors["paperSide"] = "Please select the paper side for your book";
    }

    if (isLocal && !localPaperType) {
      tempErrors["localPaperType"] =
        "Please select the local market paper type for your book";
    }

    if (isLocal && !localCoverPaperType) {
      tempErrors["localCoverPaperType"] =
        "Please select the local market cover paper type for your book";
    }

    if (isInternational && !internationalPaperType && bookFormat === "print") {
      tempErrors["internationalPaperType"] =
        "Please select the international market paper type for your book";
    }

    if (isLocal && !bookPrice) {
      tempErrors["bookPrice"] =
        "Please enter the desired selling price of your book";
    }
    if (isLocal && bookPrice && !numRegexWithDecimal.test(bookPrice)) {
      tempErrors["bookPrice"] =
        "Please enter a valid selling price for your book";
    }
    if (
      isLocal &&
      royalties &&
      royalties.localRoyalty &&
      royalties.localRoyalty.includes("-")
    ) {
      tempErrors["bookPrice"] = "Estimated royals cannot be a negative amount";
    }

    if (isInternational && !internationalBookPrice) {
      tempErrors["internationalBookPrice"] =
        "Please enter the desired selling price of your book";
    }
    if (
      isInternational &&
      internationalBookPrice &&
      !numRegexWithDecimal.test(internationalBookPrice)
    ) {
      tempErrors["internationalBookPrice"] =
        "Please enter a valid selling price for your book";
    }
    if (
      isInternational &&
      royalties &&
      royalties.internationalRoyalty &&
      royalties.internationalRoyalty.includes("-")
    ) {
      tempErrors["internationalBookPrice"] =
        "Estimated royals cannot be a negative amount";
    }

    //-----------------Page Two Ebook-----------------
    //---------------Ebook is messed we get royal from localRoyal it should come from internationalRoyalty
    if (bookFormat === "ebook") {
      if (!numPages) {
        tempErrors["numPages"] = "Please enter the number of pages in book";
      }
      if (numPages && !numRegex.test(numPages)) {
        tempErrors["numPages"] =
          "Please enter a valid number of pages for your book";
      }
      if (numPages && Number(numPages) < 18) {
        tempErrors["numPages"] =
          "Local and International books require at least 18 pages";
      }

      if (!internationalBookPrice) {
        tempErrors["bookPrice"] =
          "Please enter the desired selling price of your book";
      }
    }

    if (Object.keys(tempErrors).length === 0) {
      setValidationLevel(2);
    }
    //-----------------Page Three-----------------

    if (focusedField && tempErrors[focusedField]) {
      setError({ ...error, [focusedField]: tempErrors[focusedField] });
    } else {
      setError(tempErrors);
    }
    return tempErrors;
  };
  // ----------------- useEffect For Validation -----------------
  useEffect(() => {
    const runValidation = async () => {
      const tempErrors = await validateField();
      setError(tempErrors);
    };
    runValidation();
  }, [
    title,
    bookFormat,
    isLocal,
    isInternational,
    genre,
    isbnNumberNeeded,
    isbnNumber,
    numPages,
    bookHeight,
    bookWidth,
    color,
    orientation,
    coverFinish,
    paperSide,
    localPaperType,
    localCoverPaperType,
    internationalPaperType,
    bookPrice,
    internationalBookPrice,
    royalties,
    author,
    contributorName,
    contributorRole,
  ]);

  const onFocusField = (field) => {
    setFocusedField(field);
  };

  const resetPaperTypeDD = () => {
    if (isLocal) {
      setLocalPaperType(null);
    }

    if (isInternational) {
      setInternationalPaperType(null);
    }

    if (isLocal && isInternational) {
      setLocalPaperType(null);
      setInternationalPaperType(null);
    }
  };

  //-----------------Refresh Page-----------------
  // Run this effect once when the component mounts
  useEffect(() => {
    // Get the page number from local storage
    const savedPage = localStorage.getItem("currentPage");

    if (savedPage) {
      // Set the page number from local storage if it exists
      setPage(Number(savedPage));
    }
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    // Save the current page number to local storage
    localStorage.setItem("currentPage", page);
  }, [page]);

  //-----------------ISBN Check-----------------
  const isbnCheck = async (isbn) => {
    if (isbn && isbn.length === 13) {
      const res = await checkIsbn(isbn);
      if (res && res.statusCode === 200 && res.isValid === false) {
        setError({
          ...error,
          isbnNumber:
            "The ISBN you provided is in use by another book. Please enter a valid ISBN number for your book",
        });
        setValidationLevel(0);
        return true;
      }
    }
    return false;
  };

  const clearError = useCallback(
    (key) => {
      if (key && typeof key === "string" && error) {
        const tempErrors = error;

        tempErrors[key] = null;

        setError({ ...tempErrors });
      }

      return;
    },
    [error]
  );
  /* ------------------------Validation ------------------------ */
  /* ------------------------Route Control ------------------------ */
  // Not allowing enter to add contributor for now
  // useEventListener('keydown', (e) => {
  //     if(e && e.key && e.key === 'Enter' && e.target && e.target.id && e.target.id.includes('contributor')) {
  //         addContributorToList();
  //     }

  // }, formRef);
  const goHome = () => {
    history.push("/dashboard");
  };
  //Block the user from going back using router-dom
  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        // only block backward navigation
        setShowBackModal(true);
        return false; // prevent navigation
      }
    });

    return () => {
      unblock();
    };
  }, []);
  /* ------------------------Route Control ------------------------ */
  /* ------------------------Royalty ------------------------ */

  return (
    <div className="w-full text-left" ref={formRef}>
      {/* ------------Page One------------*/}
      {page && page === 1 && (
        <>
          {/* ------------Page One------------*/}
          <h2 className="ml-1 text-4xl font-bold mb-5">Your Book's Details</h2>
          {/* ------------Book Title ------------ */}
          <HelpText
            iconSize="xl"
            helpText={
              helpText &&
              helpText.newBook &&
              helpText.newBook.generalInfo &&
              helpText.newBook.generalInfo.bookTitleTooltip
            }
          >
            <p
              className="text-gray-600 ml-1 text-2xl leading-normal mb-2"
              id="title"
            >
              <span className="italic bold text-red mr-2">*</span>Book Title
            </p>
            <p className="italic text-xs text-red ml-1 pt-1">required</p>
          </HelpText>
          <div className="mb-4 flex items-center ml-1">
            <p className="text-gray-500 ml-5">
              Please note that the title you enter here will be exactly how it
              will appear on eCommerce platforms. Please double-check that your
              casing, punctuation, and spelling are correct before proceeding.
            </p>
          </div>
          <Card className={`max-w-3xl text-left bg-white mb-6`}>
            <CardBody>
              <div className="mb-4 text-left calc-input">
                <Input
                  id="bookTitle"
                  outline={false}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  color="red"
                  placeholder="Enter your book's title"
                  size="large"
                  type="text"
                  onFocus={() => onFocusField("title")}
                  error={
                    (showAllErrors && error && error.title) ||
                    (focusedField === "title" && error && error.title) ||
                    null
                  }
                  tabIndex={1}
                />
              </div>
            </CardBody>
          </Card>
          {/* ------------Book Title ------------ */}
          {/* ------------Book Format ------------ */}
          <HelpText
            iconSize="xl"
            helpText={
              helpText &&
              helpText.newBook &&
              helpText.newBook.generalInfo &&
              helpText.newBook.generalInfo.bookFormatTooltip
            }
          >
            <p
              className="text-gray-600 ml-1 text-2xl leading-normal mb-2"
              id="bookFormat"
            >
              <span className="italic bold text-red mr-2">*</span>Book Format
            </p>
            <p className="italic text-xs text-red ml-1 pt-1">required</p>
          </HelpText>
          <div className="h-5" />
          <div
            className="flex flex-row w-full shadow-md h-20 rounded-xl bg-white calc-input"
            tabIndex={2}
          >
            <div
              className={`w-full flex align-center justify-center rounded-xl cursor-pointer ${
                hoverKey === "print" ? "bg-gray-200" : ""
              }`}
              onMouseOver={() => onFormatOptionHover("print")}
              onMouseOut={() => onFormatOptionHover()}
              onClick={() => setBookFormat("print")}
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className="h-full align-center flex"
              >
                <Checkbox
                  color="red"
                  value={bookFormat === "print"}
                  checked={bookFormat === "print"}
                  className="flex-shrink-0"
                  onChange={() => setBookFormat("print")}
                  text="Print"
                  id="checkbox-print"
                />
              </div>
            </div>
            <div
              className={`w-full flex align-center justify-center rounded-xl cursor-pointer ${
                hoverKey === "ebook" ? "bg-gray-200" : ""
              }`}
              onMouseOver={() => onFormatOptionHover("ebook")}
              onMouseOut={() => onFormatOptionHover()}
              onClick={() => {
                setBookFormat("ebook");
                setIsLocal(false);
                setIsInternational(true);
              }}
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className="h-full align-center flex"
              >
                <Checkbox
                  color="red"
                  value={bookFormat === "ebook"}
                  checked={bookFormat === "ebook"}
                  className="flex-shrink-0"
                  onChange={() => {
                    setBookFormat("ebook");
                    setIsLocal(false);
                    setIsInternational(true);
                  }}
                  text="eBook"
                  id="checkbox-ebook"
                />
              </div>
            </div>
            {/* <div 
                    className={`w-full flex align-center justify-center rounded-xl cursor-pointer ${hoverKey === 'audio' ? 'bg-gray-200' : ''}`}
                    onMouseOver={() => onFormatOptionHover('audio')}
                    onMouseOut={() => onFormatOptionHover()}
                    onClick={() => {setBookFormat('audiobook'); setIsLocal(false); setIsInternational(true);}} >
                    <div onClick={e => e.stopPropagation()} className="h-full align-center flex">
                        <Checkbox
                            color="red"
                            value={bookFormat === 'audiobook'}
                            checked={bookFormat === 'audiobook'}
                            className="flex-shrink-0"
                            onChange={() => {setBookFormat('audiobook'); setIsLocal(false); setIsInternational(true);}}
                            text="Audio"
                            id="checkbox-audio"
                        />
                        </div>
                    </div> */}
          </div>
          <div className="h-6 mt-5">
            {showFormatHelp && (
              <p className="text-gray-500 ml-1 text-md">
                {HELP_TEXT[hoverKey]}
              </p>
            )}
          </div>
          {showAllErrors && error && error.bookFormat && (
            <p className="text-red text-left my-4">{error.bookFormat}</p>
          )}
          {/* ------------Book Format ------------ */}
          {/* ------------Sales Region ------------ */}
          <div className="h-5" />
          <HelpText
            iconSize="xl"
            helpText={
              helpText &&
              helpText.newBook &&
              helpText.newBook.generalInfo &&
              helpText.newBook.generalInfo.salesRegionTooltip
            }
          >
            <p
              className="text-gray-600 ml-1 text-2xl leading-normal mb-2"
              id="localInternational"
            >
              <span className="italic bold text-red mr-2">*</span>Sales Region
            </p>
            <p className="italic text-xs text-red ml-1 pt-1">required</p>
          </HelpText>
          <div className="h-5" />
          <HelpTextWrapper
            hideHelp={bookFormat === "print" || !bookFormat}
            iconSize="xl"
            placement={"top"}
            helpText={`${resolveBookFormatName(
              bookFormat
            )} format books will be sold to an international market by default`}
          >
            <div
              className="flex flex-row w-full shadow-md h-20 rounded-xl bg-white calc-input"
              tabIndex={3}
            >
              <div
                className={`w-full flex align-center justify-center rounded-xl cursor-pointer ${
                  hoverKey === "local" ? "bg-gray-200" : ""
                }`}
                onMouseOver={() => onFormatOptionHover("local")}
                onMouseOut={() => onFormatOptionHover()}
                onClick={() => bookFormat === "print" && setIsLocal(!isLocal)}
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="h-full align-center flex"
                >
                  <Checkbox
                    color="red"
                    value={isLocal}
                    checked={isLocal}
                    className="flex-shrink-0"
                    onChange={() =>
                      bookFormat === "print" && setIsLocal(!isLocal)
                    }
                    text="Local"
                    id="checkbox-local"
                  />
                </div>
              </div>
              <br />
              <div
                className={`w-full flex align-center justify-center rounded-xl cursor-pointer ${
                  hoverKey === "international" ? "bg-gray-200" : ""
                }`}
                onMouseOver={() => onFormatOptionHover("international")}
                onMouseOut={() => onFormatOptionHover()}
                onClick={() =>
                  bookFormat === "print" && setIsInternational(!isInternational)
                }
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="h-full align-center flex"
                >
                  <Checkbox
                    color="red"
                    value={isInternational}
                    checked={isInternational}
                    className="flex-shrink-0"
                    onChange={() =>
                      bookFormat === "print" &&
                      setIsInternational(!isInternational)
                    }
                    text="International"
                    id="checkbox-international"
                  />
                </div>
              </div>
            </div>
          </HelpTextWrapper>
          <div className="h-5 mt-5">
            {showRegionHelp && (
              <p className="text-gray-500 ml-1 text-md">
                {HELP_TEXT[hoverKey]}
              </p>
            )}
          </div>
          {showAllErrors && error && error.localInternational && (
            <p className="text-red text-left my-4">
              {error.localInternational}
            </p>
          )}
          {/* ------------Sales Region ------------ */}
          {/* ------------Genre ------------ */}
          <div className="h-5" />
          <HelpText
            iconSize="xl"
            helpText={
              helpText &&
              helpText.newBook &&
              helpText.newBook.generalInfo &&
              helpText.newBook.generalInfo.genreTooltip
            }
          >
            <p
              className="text-gray-600 ml-1 text-2xl leading-normal"
              id="genre"
            >
              <span className="italic bold text-red mr-2">*</span>Genre
            </p>
            <p className="italic text-xs text-red ml-1 pt-1">required</p>
          </HelpText>
          <p>
            Genres relate to specific BISAC codes. The BISAC Subject Headings
            List, also known as the BISAC Subject Codes List, is a standard used
            by many companies to categorize books based on topical content.
          </p>
          <a
            href="https://bisg.org/page/BISACEdition"
            target="_blank"
            rel="noopener noreferrer"
            className="text-red underline"
          >
            About BISAC codes
          </a>
          <div className="h-5" />

          <Card className={`max-w-3xl text-left`}>
            <CardBody>
              <div className="mb-4 text-left">
                <DropdownInputSearch
                  className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown calc-input"
                  value={defaultGenre}
                  setValue={handleGenreChange}
                  options={genreOptions}
                  placeholder="Start typing to select your book's genre"
                  capitalize={true}
                  tabIndex={4}
                  showGenres={showGenres}
                  setShowGenres={setShowGenres}
                  onToggleGenres={toggleGenres}
                />

                {showAllErrors && error && error.genre && (
                  <p className="text-red text-left my-4">{error.genre}</p>
                )}
                {bisacCode && genre && (
                  <p className={`${!(error && error.genre) ? "mt-4" : "mt-8"}`}>
                    Bisac Code - <span className="font-bold">{bisacCode}</span>
                  </p>
                )}
              </div>
            </CardBody>
            <div className="w-full flex justify-end">
              <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/3 mt-4"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={toggleGenres}
                type="button"
              >
                {showGenres ? (
                  <Icon name="visibility_off" size="sm" />
                ) : (
                  <Icon name="visibility" size="sm" />
                )}{" "}
                {showGenres ? "Hide Genres" : "Show Genres"}
              </Button>
              <div className="h-5" />
            </div>
          </Card>

          {/* ------------Genre ------------ */}
          {/* ------------eBook Author ------------ */}
          <div className="h-5" />
          {bookFormat && bookFormat !== "print" && (
            <>
              <HelpText
                iconSize="xl"
                helpText={
                  helpText &&
                  helpText.newBook &&
                  helpText.newBook.additionalDetails &&
                  helpText.newBook.additionalDetails.authorTooltip
                }
              >
                <p
                  className="text-gray-600 ml-1 text-2xl leading-normal mb-2"
                  id="author"
                >
                  <span className="italic bold text-red mr-2">*</span>Author
                </p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
              </HelpText>
              <div className="h-5" />
              <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                  <div className="mb-4 text-left">
                    <Input
                      outline={false}
                      value={author}
                      onChange={(e) => setAuthor(e.target.value)}
                      color="red"
                      placeholder="Author Name E.g. John Smith"
                      size="large"
                      type="text"
                      onFocus={() => onFocusField("author")}
                      error={
                        (showAllErrors && error && error.author) ||
                        (focusedField === "author" && error && error.author) ||
                        null
                      }
                    />
                  </div>
                  <div className="h-2" />
                </CardBody>
              </Card>
              <div className="h-5" />
              <HelpText
                iconSize="xl"
                helpText={
                  helpText &&
                  helpText.newBook &&
                  helpText.newBook.additionalDetails &&
                  helpText.newBook.additionalDetails.contributorsTooltip
                }
              >
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                  Contributors{" "}
                </p>
              </HelpText>
              <div className="h-5" />
              <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                  <div className="mb-4 text-left relative">
                    <Input
                      outline={false}
                      value={contributorName}
                      onChange={(e) => setContributorName(e.target.value)}
                      color="red"
                      placeholder="Contributor Full Name E.g. Jane Smith"
                      size="large"
                      type="text"
                      autoComplete="off"
                      className="contributor-input"
                      onFocus={() => clearError("contributors")}
                      id="contributor-1"
                    />
                  </div>
                  <div className="mb-4 text-left relative">
                    <DropdownInputSearchAddNew
                      color="red"
                      className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown contributor-input"
                      value={contributorRole}
                      setValue={setContributorRole}
                      placeholder="Contributor Role"
                      options={ROLE_OPTIONS}
                      onlyValue={true}
                      onFocus={() => clearError("contributorRole")}
                      inputId="contributor-2"
                    />
                    <div className="w-full flex items-center">
                      <Icon name="help" size="xs" color="gray" />
                      <p className="text-xs text-gray-500 ml-1">
                        Click for options or enter and add a custom role
                      </p>
                    </div>
                    {/* Displays error message if both fields are not filled */}
                    {error && error.contributors && (
                      <div className="h-4">
                        <span className="block mt-1 text-xs text-red-500">
                          {error.contributors}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center">
                    <Icon name="info" size="sm" color="gray" />
                    <p className="ml-2 text-gray-500">
                      Remember to click "Add Contributor" after entering their
                      full name and role
                    </p>
                  </div>
                  <div className="w-full flex justify-end">
                    <Button
                      color="red"
                      className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/3 mt-4"
                      buttonType="outline"
                      size="lg"
                      ripple="dark"
                      onClick={addContributorToList}
                      type="button"
                    >
                      <Icon name="add" size="sm" />
                      Add Contributor
                    </Button>
                  </div>
                  {contributors &&
                    Array.isArray(contributors) &&
                    contributors.length > 0 && <div className="h-5" />}
                  {contributors &&
                    Array.isArray(contributors) &&
                    contributors.length > 0 &&
                    contributors.map((c, i) => (
                      <Label color="lightBlue" className="mb-2">
                        <div className="h-full flex items-center">
                          <span className="font-bold capitalize">{c.name}</span>
                          <span className="italic lowercase ml-1">
                            {c.role}
                          </span>
                          <span
                            className="material-icons ml-1 cursor-pointer text-base leading-none"
                            onClick={() => removeContributor(c.name)}
                          >
                            cancel
                          </span>
                        </div>
                      </Label>
                    ))}
                  <div className="h-2" />
                </CardBody>
              </Card>
              <div className="h-5" />
            </>
          )}
          {/* ------------eBook Author ------------ */}
          {/* ------------Local ------------ */}
          {isLocal && (
            <>
              <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                Local Optional Extras
              </p>
              <div className="h-5" />
              <div
                className="flex flex-row w-full shadow-md h-auto p-8 rounded-xl bg-white calc-input"
                tabIndex={5}
              >
                <div
                  className={`w-full flex align-center justify-start rounded-xl cursor-pointer ${
                    hoverKey === "spotUv" ? "bg-gray-200" : ""
                  }`}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="h-full align-start flex flex-col justify-end pl-8 md:pl-0"
                  >
                    {/* <HelpText iconClassName="top-0" iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.generalInfo && helpText.newBook.generalInfo.spotUvUnder}>
                                <Checkbox
                                    color="red"
                                    value={spotUvNeeded}
                                    checked={spotUvNeeded}
                                    className="flex-shrink-0"
                                    onChange={() => setSpotUvNeeded(!spotUvNeeded)}
                                    text="I require Spot UV on covers made for this book."
                                    id="checkbox-spot-uv"
                                />
                            </HelpText>
                            <div className="h-2" /> */}
                    <HelpText
                      iconClassName="top-0"
                      iconSize="xl"
                      helpText={
                        helpText &&
                        helpText.newBook &&
                        helpText.newBook.generalInfo &&
                        helpText.newBook.generalInfo.duplexCoverUnder
                      }
                    >
                      <Checkbox
                        color="red"
                        value={duplexNeeded}
                        checked={duplexNeeded}
                        className="flex-shrink-0"
                        onChange={() => {
                          setDuplexNeeded(!duplexNeeded);
                          resetPaperTypeDD();
                        }} // TODO: Add ResetPaperTypeDD
                        text="I require Duplex covers for each book printed."
                        id="checkbox-duplex"
                      />
                    </HelpText>
                    <div className="h-2" />
                    <HelpText
                      iconClassName="top-0"
                      iconSize="xl"
                      helpText={
                        helpText &&
                        helpText.newBook &&
                        helpText.newBook.generalInfo &&
                        helpText.newBook.generalInfo.softTouchLaminationUnder
                      }
                    >
                      <Checkbox
                        color="red"
                        value={softTouchMattLaminationNeeded}
                        checked={softTouchMattLaminationNeeded}
                        className="flex-shrink-0"
                        onChange={() => {
                          setSoftTouchMattLaminationNeeded(
                            !softTouchMattLaminationNeeded
                          );
                          resetPaperTypeDD();
                        }}
                        text="I require Soft Touch Matt Lamination on each printed book."
                        id="checkbox-soft-touch"
                      />
                    </HelpText>
                    <div className="h-2" />
                    <HelpText
                      iconClassName="top-0"
                      iconSize="xl"
                      helpText={
                        helpText &&
                        helpText.newBook &&
                        helpText.newBook.generalInfo &&
                        helpText.newBook.generalInfo.spiralBoundUnder
                      }
                    >
                      <Checkbox
                        color="red"
                        value={spiralBoundNeeded}
                        checked={spiralBoundNeeded}
                        className="flex-shrink-0"
                        onChange={() => {
                          setSpiralBoundNeeded(!spiralBoundNeeded);
                          resetPaperTypeDD();
                        }}
                        text="I require a Spiral Binding for each book printed."
                        id="checkbox-spiral"
                      />
                    </HelpText>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* ------------Local ------------ */}
          {/* ------------International ------------ */}
          {isInternational &&
            bookFormat !== "audiobook" &&
            bookFormat !== "ebook" && (
              <>
                <div className="h-5" />
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                  International Optional Extras
                </p>
                <div className="h-5" />
                <div
                  className="flex flex-row w-full shadow-md h-auto p-8 rounded-xl bg-white calc-input"
                  tabIndex={6}
                >
                  <div
                    className={`w-full flex align-center justify-start rounded-xl cursor-pointer`}
                  >
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="h-full align-start flex flex-col justify-end pl-8 md:pl-0"
                    >
                      <HelpText
                        iconClassName="top-0"
                        iconSize="xl"
                        helpText={
                          helpText &&
                          helpText.newBook &&
                          helpText.newBook.generalInfo &&
                          helpText.newBook.generalInfo.duplexCoverIntUnder
                        }
                      >
                        <Checkbox
                          color="red"
                          value={duplexNeededInt}
                          checked={duplexNeededInt}
                          className="flex-shrink-0"
                          onChange={() => {
                            setDuplexNeededInt(!duplexNeededInt);
                            resetPaperTypeDD();
                          }}
                          text={
                            isLocal && isInternational
                              ? "I require Duplex covers for each international book printed."
                              : "I require Duplex covers for each book printed."
                          }
                          id="checkbox-duplex-int"
                        />
                      </HelpText>
                      <div className="h-2" />
                      <HelpText
                        iconClassName="top-0"
                        iconSize="xl"
                        helpText={
                          helpText &&
                          helpText.newBook &&
                          helpText.newBook.generalInfo &&
                          helpText.newBook.generalInfo.perforationUnder
                        }
                      >
                        <Checkbox
                          color="red"
                          value={perforationNeededInt}
                          checked={perforationNeededInt}
                          className="flex-shrink-0"
                          onChange={() => {
                            setPerforationNeededInt(!perforationNeededInt);
                            resetPaperTypeDD();
                          }} // TODO: Add ResetPaperTypeDD
                          text={
                            isLocal && isInternational
                              ? "I require Perforation on international versions of my book."
                              : "I require perforation on printed copies of my book."
                          }
                          id="checkbox-perf-int"
                        />
                      </HelpText>
                    </div>
                  </div>
                </div>
              </>
            )}
          {/* ------------International ------------ */}
          {/* ------------ISBN ------------ */}
          <div className="h-5" />
          <HelpText
            iconSize="xl"
            helpText={
              helpText &&
              helpText.newBook &&
              helpText.newBook.generalInfo &&
              helpText.newBook.generalInfo.isbnTooltip
            }
          >
            <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
              ISBN
            </p>
          </HelpText>
          <div className="h-5" />
          <div
            className="flex flex-row w-full shadow-md h-20 rounded-xl bg-white calc-input"
            tabIndex={7}
          >
            <div
              className={`w-full flex align-center justify-center rounded-xl cursor-pointer ${
                hoverKey === "isbnNeeded" ? "bg-gray-200" : ""
              }`}
              onMouseOver={() => onFormatOptionHover("isbnNeeded")}
              onMouseOut={() => onFormatOptionHover()}
              onClick={() => {
                setIsbnNumberNeeded(!isbnNumberNeeded);
                setIsbnNumber("");
              }}
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className="h-full align-center flex justify-end pl-8 md:pl-0"
              >
                <Checkbox
                  color="red"
                  value={isbnNumberNeeded}
                  checked={isbnNumberNeeded}
                  className="flex-shrink-0"
                  onChange={() => {
                    setIsbnNumberNeeded(!isbnNumberNeeded);
                    setIsbnNumber("");
                  }}
                  text="I require Print on Demand to register an ISBN number for my book"
                  id="checkbox-isbn"
                />
              </div>
            </div>
          </div>
          <p className="text-gray-500 text-xs mt-2">
            {helpText &&
              helpText.newBook &&
              helpText.newBook.generalInfo &&
              helpText.newBook.generalInfo.isbnUnder}
          </p>
          {!isbnNumberNeeded && (
            <>
              <p className="text-gray-600 ml-1 text-xl leading-normal mt-5 mb-0">
                <span className="italic bold text-red mr-2">*</span>Enter your
                ISBN below
              </p>
              <p className="text-gray-500 mt-1 mb-4 ml-1">
                {helpText &&
                  helpText.newBook &&
                  helpText.newBook.generalInfo &&
                  helpText.newBook.generalInfo.isbnEnterUnder}
              </p>
              <Card className={`max-w-3xl text-left`}>
                <CardBody>
                  <div className="mb-4 text-left">
                    <Input
                      outline={false}
                      value={isbnNumber}
                      onChange={(e) => {
                        // Limit the input to only numbers
                        if (e.target.value.match(/^\d*$/)) {
                          setIsbnNumber(e.target.value);
                        }
                      }}
                      onBlur={async () => {
                        const hasIsbnError = await isbnCheck(isbnNumber);
                      }}
                      color="red"
                      placeholder="ISBN Number"
                      size="large"
                      type="text"
                      onFocus={() => onFocusField("isbnNumber")}
                      error={
                        (showAllErrors && error && error.isbnNumber) ||
                        (focusedField === "isbnNumber" &&
                          error &&
                          error.isbnNumber) ||
                        null
                      }
                      tabIndex={8}
                      //Limits this inputs
                      pattern="\d*" // allows only digits
                      maxLength="13" // limits the input to 13 characters
                    />
                    <p className="text-gray-500 mt-6">
                      Enter your ISBN code without any dashes or spaces e.g.,
                      9783161484100.
                    </p>
                    <p className="text-gray-500 mb-4">
                      Please ensure that the ISBN you provide is not already
                      linked to any previous uploads.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </>
          )}
          {/* ------------ISBN ------------ */}
          <div className="h-16" />
        </>
      )}
      {/* ------------Page Two------------*/}
      {page && page === 2 && bookFormat === "print" && (
        <>
          <Calculator
            error={error}
            setError={setError}
            showAllErrors={showAllErrors}
            setShowAllErrors={setShowAllErrors}
            focusedField={focusedField}
            onFocusField={onFocusField}
            options={options}
            setCompleted={setCompleted}
            completed={completed}
            title={title}
            setTitle={setTitle}
            localInternational={localInternational}
            setLocalInternational={setLocalInternational}
            setBookFormat={setBookFormat}
            bookFormat={bookFormat}
            bookPrice={bookPrice}
            setBookPrice={setBookPrice}
            numPages={numPages}
            setNumPages={setNumPages}
            //
            paperTypesFiltered={paperTypesFiltered}
            setPaperTypesFiltered={setPaperTypesFiltered}
            internationalPaperTypesFiltered={internationalPaperTypesFiltered}
            setInternationalPaperTypesFiltered={
              setInternationalPaperTypesFiltered
            }
            size={size}
            setSize={setSize}
            paperSide={paperSide}
            setPaperSide={setPaperSide}
            localPaperType={localPaperType}
            setLocalPaperType={setLocalPaperType}
            localCoverPaperType={localCoverPaperType}
            setLocalCoverPaperType={setLocalCoverPaperType}
            internationalPaperType={internationalPaperType}
            setInternationalPaperType={setInternationalPaperType}
            internationalCoverPaperType={internationalCoverPaperType}
            setInternationalCoverPaperType={setInternationalCoverPaperType}
            royalties={royalties}
            pagePathname={pagePathname}
            stage={stage}
            color={color}
            setColor={setColor}
            pageFinish={pageFinish}
            setPageFinish={setPageFinish}
            isLocal={isLocal}
            isInternational={isInternational}
            intCoverOption={intCoverOption}
            setIntCoverOption={setIntCoverOption}
            intCoverType={intCoverType}
            setIntCoverType={setIntCoverType}
            spineOption={spineOption}
            setSpineOption={setSpineOption}
            bookWidth={bookWidth}
            setBookWidth={setBookWidth}
            bookHeight={bookHeight}
            setBookHeight={setBookHeight}
            canSupportCoverTypes={canSupportCoverTypes}
            canSupportPaperType={canSupportPaperType}
            canSupportIntCoverTypes={canSupportIntCoverTypes}
            orientation={orientation}
            setOrientation={setOrientation}
            coverFinish={coverFinish}
            setCoverFinish={setCoverFinish}
            internationalBookPrice={internationalBookPrice}
            setInternationalBookPrice={setInternationalBookPrice}
            heavyGraphics={heavyGraphics}
            setHeavyGraphics={setHeavyGraphics}
            binding={binding}
            setBinding={setBinding}
            spiralBoundNeeded={spiralBoundNeeded}
            numPagesLimitExceeded={numPagesLimitExceeded}
          />
        </>
      )}
      {/* ------------Ebook Calculator----------- */}
      {page && page === 2 && bookFormat === "ebook" && (
        <>
          <Ebook
            numPages={numPages}
            setNumPages={setNumPages}
            bookPrice={bookPrice}
            setBookPrice={setBookPrice}
            royalties={royalties}
            completed={completed}
            setCompleted={setCompleted}
            stage={stage}
            internationalBookPrice={internationalBookPrice}
            setInternationalBookPrice={setInternationalBookPrice}
            error={error}
            setError={setError}
            showAllErrors={showAllErrors}
            setShowAllErrors={setShowAllErrors}
            focusedField={focusedField}
            onFocusField={onFocusField}
          />
        </>
      )}
      {/* ------------Page Two------------*/}
      {/* ------------Page Three------------*/}
      {page && page === 3 && (
        <>
          <ConfirmOptions
            book={book}
            stage={stage}
            completed={completed}
            setCompleted={setCompleted}
            submit={submit}
            isLocal={isLocal}
            isInternational={isInternational}
          />
        </>
      )}
      <Button
        color="gray"
        className="w-full float-left mb-3 md:mb-auto md:w-1/4 hover:bg-transparent hover:opacity-20"
        buttonType="outline"
        size="lg"
        ripple="dark"
        onClick={() => onHandleBack()}
      >
        <Icon name="arrow_back" size="sm" />
        Back
      </Button>
      <Button
        color="red"
        className="hover:bg-red hover:text-white hover:pr-2 w-full float-right md:w-1/4"
        buttonType="outline"
        size="lg"
        ripple="dark"
        onClick={() => onHandleContinue()}
      >
        Continue
        <Icon name="arrow_forward" size="sm" />
      </Button>
      {/* Spacing for royalty display */}
      <div
        className={
          isInternational && isLocal ? "conditional-height" : "default-height"
        }
      />
      {/* {isInternational && isLocal ? <div className="h-96 md:h-32 lg:h-24" /> : <div className="h-50 md:h-24" />} */}

      {/* -------------------Modal---------------------- */}
      <Modal showModal={showIntro} setShowModal={setShowIntro} size="lg">
        <div className="p-5">
          <h3 className="font-light text-xl mb-5">Uploading your book</h3>
          <p className="mb-5">
            This process usually takes <span className="font-bold">1 hour</span>{" "}
            to complete.
          </p>

          <div className="p-5 rounded-xl bg-gray-200">
            <p className="flex items-center">
              <span className="font-bold text-red text-lg mr-2">1</span>Enter
              your book's details.
            </p>
            <p className="flex items-center">
              <span className="font-bold text-red text-lg mr-2">2</span>Get your
              estimated royalty calculation.
            </p>
            <p className="flex items-center">
              <span className="font-bold text-red text-lg mr-2">3</span>Enter
              the publisher's details.
            </p>
            <p className="flex items-center">
              <span className="font-bold text-red text-lg mr-2">4</span>Enter
              your bank details for royalty payouts.
            </p>
            <p className="flex items-center">
              <span className="font-bold text-red text-lg mr-2">5</span>Accept
              T's & C's and sign the contract.
            </p>
            <p className="flex items-center">
              <span className="font-bold text-red text-lg mr-2">6</span>Enter
              additional book details.
            </p>
            <p className="flex items-center">
              <span className="font-bold text-red text-lg mr-2">7</span>Upload
              your book's files.
            </p>
          </div>
          <div className="h-10" />
          <Button
            color="red"
            className="w-full"
            buttonType="filled"
            size="lg"
            ripple="dark"
            onClick={() => setShowIntro(false)}
          >
            Get started
          </Button>
        </div>
      </Modal>
      {/* NegativeRoyalModal */}
      <Modal
        showModal={showNegativeRoyaltyError}
        setShowModal={setShowNegativeRoyaltyError}
        size="lg"
      >
        <div className="p-5">
          <h3 className="font-light text-xl mb-5">
            Your royalty fees, based on your selected options is a negative
            value.
          </h3>
          <Paragraph>
            Try editing book price, paper type and cover type until you get a
            positive royalty fee before moving on.
          </Paragraph>
          <div className="h-5" />
          <Button
            color="red"
            className="w-full"
            buttonType="filled"
            size="lg"
            ripple="dark"
            onClick={() => setShowNegativeRoyaltyError(false)}
          >
            Okay
          </Button>
        </div>
      </Modal>
      {/* Back navigation */}
      <Modal
        showModal={showBackModal}
        setShowModal={setShowBackModal}
        size="lg"
      >
        <div className="p-5">
          <h3 className="font-light text-xl mb-5">
            Do you wish to go back to the dashboard?
          </h3>
          <Paragraph>
            Please note that you may return to finalize this at your
            convenience. Simply navigate to 'Your Books' and select 'Continue
            Upload Process'.
          </Paragraph>
          <div className="h-5" />
          <Button
            color="red"
            className="w-full"
            buttonType="filled"
            size="lg"
            ripple="dark"
            onClick={() => setShowBackModal(false)}
          >
            No, stay here
          </Button>
          <div className="h-5" />
          <Button
            color="gray"
            className="w-full"
            buttonType="outline"
            size="lg"
            ripple="dark"
            onClick={() => goHome()}
          >
            Yes, go back
          </Button>
        </div>
      </Modal>
      {/* -------------------Modal---------------------- */}
    </div>
  );
}
