const sizesLocal = [
    {
        template: 'A5',
        height: 152,
        width: 102,
    },
    {
        template: 'A5',
        height: 178,
        width: 102,
    },
    {
        template: 'A5',
        height: 178,
        width: 108,
    },
    {
        template: 'A5',
        height: 190,
        width: 120,
    },
    {
        template: 'A5',
        height: 178,
        width: 127,
    },
    {
        template: 'A5',
        height: 203,
        width: 127,
    },
    {
        template: 'A5',
        height: 198,
        width: 129,
    },
    {
        template: 'A5',
        height: 203,
        width: 133,
    },
    {
        template: 'A5',
        height: 210,
        width: 140,
    },
    {
        template: 'A5',
        height: 216,
        width: 140,
    },
    {
        template: 'A5',
        height: 210,
        width: 148,
    },
    {
        template: 'NC',
        height: 234,
        width: 156,
    },
    {
        template: 'NC',
        height: 229,
        width: 152,
    },
    {
        template: 'NC',
        height: 165,
        width: 165,
    },
    {
        template: 'NC',
        height: 244,
        width: 170,
    },
    {
        template: 'A4',
        height: 260,
        width: 168,
    },
    {
        template: 'A4',
        height: 254,
        width: 178,
    },
    {
        template: 'A4',
        height: 246,
        width: 189,
    },
    {
        template: 'A4',
        height: 235,
        width: 191,
    },
    {
        template: 'A4',
        height: 203,
        width: 203,
    },
    {
        template: 'A4',
        height: 254,
        width: 203,
    },
    {
        template: 'A4',
        height: 276,
        width: 203,
    },
    {
        template: 'A4',
        height: 280,
        width: 210,
    },
    {
        template: 'A4',
        height: 297,
        width: 210,
    },
    {
        template: 'A4',
        height: 280,
        width: 216,
    },
    {
        template: 'A4',
        height: 216,
        width: 216,
    },
    {
        template: 'A4',
        height: 229,
        width: 216,
    },
    {
        template: 'A5',
        height: 216,
        width: 280,
    },
    {
        template: 'A5',
        height: 210,
        width: 297,
    },
    {
        template: 'A5',
        height: 170,
        width: 244,
    },
];

const sizesInt = [
    {
        height: 210,
        width: 148,
    },
    {
        height: 210,
        width: 140,
    },
    {
        height: 216,
        width: 140,
    },
    {
        height: 152,
        width: 102,
    },
    {
        height: 203,
        width: 133,
    },
    {
        height: 178,
        width: 102,
    },
    {
        height: 178,
        width: 108,
    },
    {
        height: 190,
        width: 120,
    },
    {
        height: 178,
        width: 127,
    },
    {
        height: 234,
        width: 156,
    },
    {
        height: 203,
        width: 127,
    },
    {
        height: 198,
        width: 129,
    },
    {
        height: 178,
        width: 111,
    },
    {
        height: 260,
        width: 168,
    },
    {
        height: 276,
        width: 203,
    },
    {
        height: 165,
        width: 165,
    },
    {
        height: 273,
        width: 210,
    },
    {
        height: 244,
        width: 170,
    },
    {
        height: 280,
        width: 210,
    },
    {
        height: 254,
        width: 178,
    },
    {
        height: 246,
        width: 189,
    },
    {
        height: 280,
        width: 216,
    },
    {
        height: 235,
        width: 191,
    },
    {
        height: 216,
        width: 216,
    },
    {
        height: 203,
        width: 203,
    },
    {
        height: 229,
        width: 216,
    },
    {
        height: 254,
        width: 203,
    },
    {
        height: 229,
        width: 152,
    },
    {
        height: 297,
        width: 210,
    },
    {
        height: 216,
        width: 280,
    },
    {
        height: 100,
        width: 50,
    },
    {
        height: 150,
        width: 100,
    },
    {
        height: 210,
        width: 150,
    }
]

const combined = [
    {
        "isLocal": true,
        "template": "A5",
        "height": 152,
        "width": 102
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 178,
        "width": 102
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 178,
        "width": 108
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 190,
        "width": 120
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 178,
        "width": 127
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 203,
        "width": 127
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 198,
        "width": 129
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 203,
        "width": 133
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 210,
        "width": 140
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 216,
        "width": 140
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 210,
        "width": 148
    },
    {
        "isLocal": true,
        "template": "NC",
        "height": 234,
        "width": 156
    },
    {
        "isLocal": true,
        "template": "NC",
        "height": 229,
        "width": 152
    },
    {
        "isLocal": true,
        "template": "NC",
        "height": 165,
        "width": 165
    },
    {
        "isLocal": true,
        "template": "NC",
        "height": 244,
        "width": 170
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 260,
        "width": 168
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 254,
        "width": 178
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 246,
        "width": 189
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 235,
        "width": 191
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 203,
        "width": 203
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 254,
        "width": 203
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 276,
        "width": 203
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 280,
        "width": 210
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 297,
        "width": 210
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 280,
        "width": 216
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 216,
        "width": 216
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 229,
        "width": 216
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 216,
        "width": 280
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 210,
        "width": 297
    },
    {
        "isLocal": true,
        "template": "A5",
        "height": 170,
        "width": 244
    },
    {
        "height": 210,
        "width": 148
    },
    {
        "height": 210,
        "width": 140
    },
    {
        "height": 216,
        "width": 140
    },
    {
        "height": 152,
        "width": 102
    },
    {
        "height": 203,
        "width": 133
    },
    {
        "height": 178,
        "width": 102
    },
    {
        "height": 178,
        "width": 108
    },
    {
        "height": 190,
        "width": 120
    },
    {
        "height": 178,
        "width": 127
    },
    {
        "height": 234,
        "width": 156
    },
    {
        "height": 203,
        "width": 127
    },
    {
        "height": 198,
        "width": 129
    },
    {
        "height": 178,
        "width": 111
    },
    {
        "height": 260,
        "width": 168
    },
    {
        "height": 276,
        "width": 203
    },
    {
        "height": 165,
        "width": 165
    },
    {
        "height": 273,
        "width": 210
    },
    {
        "height": 244,
        "width": 170
    },
    {
        "height": 280,
        "width": 210
    },
    {
        "height": 254,
        "width": 178
    },
    {
        "height": 246,
        "width": 189
    },
    {
        "height": 280,
        "width": 216
    },
    {
        "height": 235,
        "width": 191
    },
    {
        "height": 216,
        "width": 216
    },
    {
        "height": 203,
        "width": 203
    },
    {
        "height": 229,
        "width": 216
    },
    {
        "height": 254,
        "width": 203
    },
    {
        "height": 229,
        "width": 152
    },
    {
        "height": 297,
        "width": 210
    },
    {
        "height": 216,
        "width": 280
    },
    {
        "height": 100,
        "width": 50
    },
    {
        "height": 150,
        "width": 100
    },
    {
        "height": 210,
        "width": 150
    }
];

const final = [
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 152,
        "width": 102,
        "label": "152x102mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 178,
        "width": 102,
        "label": "178x102mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 178,
        "width": 108,
        "label": "178x108mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 190,
        "width": 120,
        "label": "190x120mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 178,
        "width": 127,
        "label": "178x127mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 203,
        "width": 127,
        "label": "203x127mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 198,
        "width": 129,
        "label": "198x129mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 203,
        "width": 133,
        "label": "203x133mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 210,
        "width": 140,
        "label": "210x140mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 216,
        "width": 140,
        "label": "216x140mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A5",
        "height": 210,
        "width": 148,
        "label": "210x148mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "NC",
        "height": 234,
        "width": 156,
        "label": "234x156mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "NC",
        "height": 229,
        "width": 152,
        "label": "229x152mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "NC",
        "height": 165,
        "width": 165,
        "label": "165x165mm",
        "orientation": "square"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "NC",
        "height": 244,
        "width": 170,
        "label": "244x170mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 260,
        "width": 168,
        "label": "260x168mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 254,
        "width": 178,
        "label": "254x178mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 246,
        "width": 189,
        "label": "246x189mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 235,
        "width": 191,
        "label": "235x191mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 203,
        "width": 203,
        "label": "203x203mm",
        "orientation": "square"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 254,
        "width": 203,
        "label": "254x203mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 276,
        "width": 203,
        "label": "276x203mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 280,
        "width": 210,
        "label": "280x210mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 297,
        "width": 210,
        "label": "297x210mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 280,
        "width": 216,
        "label": "280x216mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 216,
        "width": 216,
        "label": "216x216mm",
        "orientation": "square"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 229,
        "width": 216,
        "label": "229x216mm",
        "orientation": "portrait"
    },
    {
        "isLocal": true,
        "isInternational": true,
        "template": "A4",
        "height": 216,
        "width": 280,
        "label": "216x280mm",
        "orientation": "landscape"
    },
    {
        "isLocal": true,
        "isInternational": false,
        "template": "A4",
        "height": 210,
        "width": 297,
        "label": "210x297mm",
        "orientation": "landscape"
    },
    {
        "isLocal": true,
        "template": "A4",
        "height": 170,
        "width": 244,
        "label": "170x244mm",
        "orientation": "landscape"
    },
    {
        "isInternational": true,
        "height": 178,
        "width": 111,
        "label": "178x111mm",
        "orientation": "portrait"
    },
    {
        "isInternational": true,
        "height": 273,
        "width": 210,
        "label": "273x210mm",
        "orientation": "portrait"
    },
    // {
    //     "isInternational": true,
    //     "height": 100,
    //     "width": 50,
    //     "label": "100x50mm",
    //     "orientation": "portrait"
    // },
    // {
    //     "isInternational": true,
    //     "height": 150,
    //     "width": 100,
    //     "label": "150x100mm",
    //     "orientation": "portrait"
    // },
    // {
    //     "isInternational": true,
    //     "height": 210,
    //     "width": 150,
    //     "label": "210x150mm",
    //     "orientation": "portrait"
    // }
]
export default final;