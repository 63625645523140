import { useState, useEffect, useRef } from 'react'

import { useGlobalState } from 'state-pool'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { useHistory, useLocation } from 'react-router-dom'

import Button from '@material-tailwind/react/Button'
import Icon from '@material-tailwind/react/Icon'
import Card from '@material-tailwind/react/Card'
import CardBody from '@material-tailwind/react/CardBody'
import Checkbox from '@material-tailwind/react/Checkbox'

import { podApi } from 'api/pod'

import HelpTextWrapper from 'components/general/HelpTextWrapper'
import FillAndSign from 'components/fill-and-sign/FillAndSign'
import PdfModal from 'components/document-handling/PdfModal'
import HelpText from 'components/general/HelpText'
import PlacesAutocomplete from 'components/inputs/PlacesAutocomplete'
import SignatureModal from 'components/sign/SignatureModal'

export default function RenewalsComponent() {
    const [showModal, setShowModal] = useState(false)
    const [contract, setContract] = useState('')
    const [showPdf, setShowPdf] = useState(false)
    const [showContract, setShowContract] = useState(false)
    const [showInitialsModal, setShowInitialsModal] = useState(false)

    const [acceptTerms, setAcceptTerms] = useState(false);
    const [signature, setSignature] = useState('');
    const [initials, setInitials] = useState('');
    const [signedPlace, setSignedPlace] = useState('');

    const [error, setError] = useState({})

    const [currentUser] = useGlobalState('currentUser')
    const viewerRef = useRef()

    useEffect(() => {
        if (contract) {
            renderContract(contract)
            clickPosition()
        }
    }, [contract])

    const onContinueClick = () => {

    }

    async function renderContract(url) {
        const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true })
        // document.getElementById('pdf').src = pdfDataUri;
    }

    async function modifyPdf(x, y) {
        const existingPdfBytes = await fetch(contract).then((res) =>
            res.arrayBuffer(),
        )

        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const { width, height } = firstPage.getSize()
        firstPage.drawText(currentUser && currentUser.email, {
            x: x,
            y: height - y,
            size: 10,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })

        const pdfBytes = await pdfDoc.save()

        const pdfDocChanged = await PDFDocument.load(pdfBytes)

        const pdfDataUri = await pdfDocChanged.saveAsBase64({ dataUri: true })

        setContract(pdfDataUri)
    }

    function clickPosition() {
        if (viewerRef && viewerRef.current) {
            viewerRef.current.onclick = function (e) {
                let rect = e.target.getBoundingClientRect()
                let x = e.clientX - rect.left //x position within the element.
                let y = e.clientY - rect.top //y position within the element.

                console.log({
                    x,
                    y,
                })

                modifyPdf(x, y)
            }
        }
    }

    function openPdfModal() {
        setShowPdf(true)
    }

    return (
        <div className="w-full text-left">
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>
                <CardBody>
                    <h3 className="text-4xl font-bold text-black">Contract Renewal</h3>
                    <div className="h-5" />
                    <p className="text-xl">
                        Please review and accept our{' '}
                        <span className="text-red font-bold text-2xl cursor-pointer bg-white" style={{ background: "white" }} onClick={() => openPdfModal()}>
                            &nbsp;Terms & Conditions
                        </span>
                    </p>
                    <div className="h-10" />
                    {contract && <FillAndSign file={contract} setFile={setContract} />}
                    <div className="mb-4 text-left">
                        <Checkbox
                            color="red"
                            value={acceptTerms}
                            className="flex-shrink-0"
                            onChange={() => setAcceptTerms(!acceptTerms)}
                            text="I have read through and accept Print on Demand's Terms & Conditions"
                            id="checkbox"
                            checked={acceptTerms}
                        />
                    </div>
                    <HelpText iconSize="xl" helpText="This is the name of your book and will be used as the product title when displayed on ecommerce sites such as Takealot">
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">Signed Place</p>
                    </HelpText>
                    <div className="h-5" />
                    <div className="mb-4 text-left">
                        <PlacesAutocomplete
                            value={signedPlace}
                            setValue={setSignedPlace}
                            placeholder="Select the signed place"
                            error={error && error.signedPlace}
                            id="pacg-sign-1"
                        />
                    </div>
                    <div className="h-2" />
                    <div className="h-8" />
                    <div className="w-full grid grid-cols-1 gap-5 md:grid-cols-2">
                        <HelpTextWrapper
                            placement="top"
                            width="100%"
                            helpText={!signature ? "Click to draw your signature" : "Click to change your signature"}
                        >
                            <div className="rounded-xl border-gray-300 border-2  cursor-pointer p-5" onClick={() => setShowModal(true)}>
                                {signature ? (
                                    <>
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                                            Your Signature
                                        </p>
                                        <img
                                            className="h-32 w-auto"
                                            alt="user_signature"
                                            src={signature}
                                        />
                                        <div className="h-5" />
                                    </>
                                ) : (
                                    <div className="w-full flex flex-col items-center justify-center h-48" >
                                        <Icon name="create" size="6xl" color="gray" />
                                        <div className="h-2" />
                                        <p>Click to sign</p>
                                    </div>
                                )}
                            </div>
                        </HelpTextWrapper>
                        <HelpTextWrapper
                            placement="top"
                            width="100%"
                            helpText={!signature ? "Click to draw your initials" : "Click to change your initials"}
                        >
                            <div className="rounded-xl border-gray-300 border-2  cursor-pointer p-5" onClick={() => setShowInitialsModal(true)}>
                                {initials ? (
                                    <>
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                                            Your Initials
                                        </p>
                                        <img
                                            className="h-32 w-auto"
                                            alt="user_initials"
                                            src={initials}
                                        />
                                        <div className="h-5" />
                                    </>
                                ) : (
                                    <div className="w-full  flex flex-col items-center justify-center h-48" >
                                        <Icon name="create" size="6xl" color="gray" />
                                        <div className="h-2" />
                                        <p>Click to sign initials</p>
                                    </div>
                                )}
                            </div>
                        </HelpTextWrapper>
                    </div>
                    <div className="h-8" />
                    <HelpTextWrapper
                        helpText="Accept terms and sign to continue"
                        hideHelp={acceptTerms && !!signature}
                    >
                        <Button
                            color="red"
                            className={` ${!acceptTerms || !signature
                                ? 'cursor-not-allowed'
                                : 'hover:bg-red hover:text-white hover:pr-2'
                                }  w-full md:w-1/4 float-right`}
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            disabled={!acceptTerms}
                            onClick={
                                onContinueClick
                            }
                        >
                            submit
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                    </HelpTextWrapper>
                    <div className="h-12" />
                </CardBody>
            </Card>

            <div className="h-16" />
            <SignatureModal
                show={showModal}
                setShow={setShowModal}
                save={(sigBase64) => setSignature(sigBase64)}
                title="Your Signature"
                subText="Draw your signature and click save"
            />
            <SignatureModal
                show={showInitialsModal}
                setShow={setShowInitialsModal}
                save={(sigBase64) => setInitials(sigBase64)}
                title="Your Initials"
                subText="Draw your initials and click save"
            />
            <PdfModal
                file={contract}
                title={
                    currentUser && currentUser.firstname + ' ' + currentUser.lastname
                }
                showModal={showContract}
                setShowModal={setShowContract}
            />
            <PdfModal
                file={`/assets/pdf/Terms_and_Conditions_Print_On_Demand.pdf`}
                title={`Terms_and_Conditions_Print_On_Demand.pdf`}
                showModal={showPdf}
                setShowModal={setShowPdf}
            />
        </div>
    )
}
