import {useEffect} from 'react';
import {setPageTitle, isValidJson} from 'utils';

import Icon from '@material-tailwind/react/Icon';

export default function ActivateAccountInfo({setShowSidebar}) {
    useEffect(() => {
        setPageTitle('Activate Your Account');

        sessionStorage.clear();

        setShowSidebar(false);
    }, []);

    return (
        <div className="bg-image h-screen px-3 md:px-8 flex items-center justify-center flex-col relative overflow-hidden">
            <div className="max-w-3xl text-center w-full bg-white p-10 z-30 rounded-xl shadow-md-grey">
                <div className="h-8" />
                <Icon name="report_problem" size="9xl" color="red" />
                <h2 className="font-bold uppercase">Please activate your account</h2>
                <div className="h-2" />
                <div className="w-full flex justify-center">
                    <p className="w-2/3">You have successfully registered on our system but have not activated your account. To activate your account please follow the link sent to <strong>{localStorage.getItem('currentUser') && isValidJson(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser')).email}</strong>. You will then be able to log in successfully.</p>
                </div>
                <div className="h-8" />
            </div>
        </div>
    );
}
