import { useState, useEffect, useRef } from 'react';

import { Link } from 'react-router-dom';

import Card from 'components/cards/Card';
import Modal from 'components/general/Modal'

import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { isValidJson, isValidDate } from 'utils';

import { viewUsersBookContractV2 } from 'routes/userRoutes';

import { format } from 'date-fns';

export default function BookDetails({ book, isInternational, noCoverImage, isLoading, files, setViewContract, setShowViewContract, setViewBook, setShowViewBook, audioFiles, hasAudioFiles, onArchiveClick, onDeleteClick }) {

    const downloadRefCover = useRef();
    const [showLoading, setShowLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const [bookFileName, setBookFileName] = useState('');
    const [bookFileIcon, setBookFileIcon] = useState('');
    const [bookExtension, setBookExtension] = useState('');

    useEffect(() => {
        if (files && Array.isArray(files) && (files.length > 0)) {
            const name = getBookName('book', files);

            //console.log('UE', name)

            setBookFileName(name);

            const bookFileIconTemp = resolveBookDocTypeImage(name);

            setBookFileIcon(bookFileIconTemp);

            const bookExtensionTemp = getFileExtension(name);

            setBookExtension(bookExtensionTemp);
        }
    }, [files]);

    function getTextStatusColor(book) {
        if (
            book.misc &&
            isValidJson(book.misc) &&
            JSON.parse(book.misc).status &&
            JSON.parse(book.misc).status
        ) {
            switch (JSON.parse(book.misc).status) {
                case 'pending':
                    return 'text-orange-500'
                case 'quote':
                    return 'text-gray-500'
                case 'denied':
                    return 'text-red-500'
                default:
                    return 'text-green-500'
            }
        }
    }

    function onViewContractClick(book) {
        if (book && book.id) {
            setShowLoading(true);

            if (isInternational) {
                viewUsersBookContractV2(null, book.id).then(res => {
                    setShowLoading(false);
                    if (res) {
                        openContract(res);
                    }

                    if (!res) {
                        setShowError(true);
                    }
                })
                    .catch(err => {
                        setShowError(true);
                    });
            } else {
                viewUsersBookContractV2(book.id, null).then(res => {
                    setShowLoading(false);

                    if (res) {
                        openContract(res);
                    }

                    if (!res) {
                        setShowError(true);
                    }
                })
                    .catch(err => {
                        setShowError(true);
                    });
            }

        }
    }

    function openContract(contract, title) {

        setViewContract({ contract, title });

        setShowViewContract(true);
    }

    function onViewBookClick(book, type) {
        if (book && type === 'pdf' && files && Array.isArray(files) && files[0] && files[0].fileBuffer) {
            openBook(getBookData('book'), book.book_title);
        }
    }

    function openBook(bookBase64, title) {

        setViewBook({ book: bookBase64, title });

        setShowViewBook(true);
    }

    // 'cover' 'book' 'thumbnail'
    function getBookData(type) {
        if (files && Array.isArray(files)) {
            const found = files.find(({ filename }) => filename.includes(type));

            const jwt = localStorage.getItem('access-token');

            if (found && found.fileBuffer && !found.fileBuffer.includes('.pdf')) return found.fileBuffer;
            if (found && found.fileBuffer && found.fileBuffer.includes('.pdf')) return process.env.REACT_APP_API_PUBLIC + found.fileBuffer.slice(1) + `?jwt=${jwt}`;

            return '';
        }

        return '';
    }

    function getBookName(type, filesArr) {
        if (filesArr && Array.isArray(filesArr)) {
            const found = filesArr.find(({ filename }) => filename.includes(type));

            // const jwt = localStorage.getItem('access-token');

            if (found && found.filename) return found.filename;
            // if(found && found.fileBuffer && found.fileBuffer.includes('.pdf')) return process.env.REACT_APP_API_PUBLIC + found.fileBuffer.slice(1) + `?jwt=${jwt}`;

            return '';
        }

        return '';
    }

    const resolveContinueLink = () => {
        const parsedMisc = isValidJson(book.misc) && JSON.parse(book.misc);

        if (parsedMisc && typeof parsedMisc === 'object') {
            // below for internatinoal & local concurrent uploads
            const localBookIdMatch = parsedMisc.localBookMatch;
            const internationalBookIdMatch = parsedMisc.internationalBookMatch;

            const bookIds = `${isInternational ?
                    'ibid=' + book.id + (localBookIdMatch ? ('&lbid=' + localBookIdMatch) : '') :
                    'lbid=' + book.id + (internationalBookIdMatch ? ('&ibid=' + internationalBookIdMatch) : '')
                }`;

            const region = isInternational ?
                localBookIdMatch ? 'li' : 'i'
                : internationalBookIdMatch ? 'li' : 'l';

            return `/new-book${parsedMisc.bookStage === "getquote" ? "/publisher-info" : "/upload-type"}?continue=true&${bookIds}&region=${region}`;
        }

    }

    function resolveBookDocTypeImage(fileName) {
        // if(rawOrPdf === 'raw') return '/assets/icons/raw.png';

        // console.log('IMAGE', fileName);

        if (fileName && typeof fileName === 'string') {
            switch (true) {
                case fileName.includes('.indd'): return '/assets/icons/indd.png';
                case fileName.includes('.docx'): return '/assets/icons/raw.png';
                case fileName.includes('.epub'): return '/assets/icons/epub.png';

                default: return '/assets/icons/pdf.png';
            }
        }

        return '/assets/icons/pdf.png';
    }

    function getFileExtension(fileName) {
        // if(rawOrPdf === 'raw') return '/assets/icons/raw.png';

        // console.log('FE', fileName);

        if (fileName && typeof fileName === 'string') {
            switch (true) {
                case fileName.includes('.indd'): return '.indd';
                case fileName.includes('.docx'): return '.docx';
                case fileName.includes('.epub'): return '.epub';

                default: return '.pdf';
            }
        }

        return '.pdf';
    }

    return (
        <>
            <div className="h-5" />
            <div className="grid grid-cols-1 lg:gap-5 xl:grid-cols-6">
                <div className="xl:col-start-1 xl:col-end-3">
                    {!isLoading && !noCoverImage &&
                        <>
                            <img
                                alt={`${book.book_title} Cover`}
                                className="w-full h-auto rounded-xl shadow-xl"
                                src={`${getBookData('thumbnail')}`}
                            />
                        </>
                    }
                    {isLoading && (
                        <div className="h-96 w-full flex items-center justify-center">
                            <img className="w-1/3 h-auto" alt="no-image" src="/images/loading.gif" />
                        </div>
                    )}
                    {noCoverImage && (
                        <div className="h-96 w-full flex items-center justify-center p-8">
                            <h3 className="text-xl">
                                No thumbnail image was uploaded for this book
                            </h3>
                        </div>
                    )}
                    {hasAudioFiles && (
                        <>
                            {audioFiles && Array.isArray(audioFiles) && (audioFiles.length > 0) && audioFiles.map((af, i) => (
                                <>
                                    <div className="h-5" />
                                    <a href={af && af.fileBuffer} download={af && af.filename}>
                                        <Button
                                            color="red"
                                            ripple="light"
                                            className="w-full"
                                        >
                                            <Icon name="music_note" />
                                            Download Chapter {i + 1}
                                        </Button>
                                    </a>
                                </>
                            ))}
                        </>
                    )}
                    {getBookData('cover') && <>
                        <div className="h-5" />
                        <Button
                            color="red"
                            onClick={() => downloadRefCover && downloadRefCover.current && downloadRefCover.current.click()}
                            ripple="light"
                            className="w-full"
                        >
                            <Icon name="wallpaper" />
                            Download Cover
                        </Button>
                        <a href={getBookData('cover')} className="invisible h-0" ref={downloadRefCover} download={getBookName('cover')}>Download File</a>
                    </>}
                </div>
                <div className="xl:col-start-3 xl:col-end-7">
                    <div className="flex mb-5 flex-col md:flex-row">
                        {book && files && getBookData('book') && (getBookData('book').includes('application/pdf') || getBookData('book').includes('.pdf')) ? (
                            <div onClick={() => onViewBookClick(book, 'pdf')} className="w-full mt-5 md:mt-auto md:w-1/2">
                                <Card className="bg-gray-500 shadow-xl cursor-pointer">
                                    <div className="flex items-center justify-center flex-col h-full py-1">
                                        <img alt="pdf icon" className="h-20 w-auto" src={"/assets/icons/pdf.png"} />
                                        <h3 className="text-white text-xs mt-2">View Book</h3>
                                    </div>
                                </Card>
                            </div>
                        ) : files && getBookData('book') && (
                            <a href={getBookData('book')} target="_blank" rel="noopener noreferrer" download={`${book.book_title && typeof book.book_title === 'string' && book.book_title.toLowerCase().replace(/ /g, '-') + bookExtension}`} className="w-full mt-5 md:mt-auto md:w-1/2">
                                <Card className="bg-gray-500 shadow-xl cursor-pointer">
                                    <div className="flex items-center justify-center flex-col h-full py-1">
                                        <img alt="docx icon" className="h-20 w-auto" src={bookFileIcon} />
                                        <h3 className="text-white text-xs mt-2">Download Book</h3>
                                    </div>
                                </Card>
                            </a>
                        )}
                        {book && files && book.misc && isValidJson(book.misc) && JSON.parse(book.misc).status && (JSON.parse(book.misc).status !== "quote") && <div onClick={() => onViewContractClick(book)} className={`${book && files && getBookData('book') ? 'md:ml-5 ml-auto' : ''} w-full mt-5 md:mt-auto md:w-1/2`}>
                            <Card className="bg-gray-500 shadow-xl cursor-pointer">
                                <div className="flex items-center justify-center flex-col h-full py-1" >
                                    <img alt="pdf icon" className="h-20 w-auto" src="/assets/icons/pdf.png" />
                                    <h3 className="text-white text-xs mt-2">Contract</h3>
                                </div>
                            </Card>
                        </div>}
                    </div>
                    <div className="w-full rounded-xl overflow-hidden p-8 shadow-2xl my-7 cursor-pointer">
                        {(book && book.misc && isValidJson(book.misc) && JSON.parse(book.misc).archived) && (
                            <div className="flex items-center text-red-500 mt-5 mb-12 p-5 w-full rounded-xl bg-red-100">
                                <Icon name="error" size="2xl" />
                                <p className="ml-1 text-red-500 text-xl">This book has been cancelled</p>
                            </div>
                        )}
                        <div className="gs-12">
                            {(book && book.misc && isValidJson(book.misc) && !JSON.parse(book.misc).archived) && (
                                <>
                                    <div className="xs gs-1-2">
                                        <p className="font-light">Status</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p
                                            className={`${getTextStatusColor(
                                                book,
                                            )} uppercase font-bold`}
                                        >
                                            {book.misc &&
                                                isValidJson(book.misc) &&
                                                JSON.parse(book.misc).status &&
                                                JSON.parse(book.misc).status}
                                        </p>
                                    </div>
                                </>
                            )}
                            <div className="xs gs-1-2">
                                <p className="font-light">Book title</p>
                            </div>
                            <div className="xs gs-2-2 text-right">
                                <p className="font-bold">{book.book_title}</p>
                            </div>
                            <div className="xs gs-1-2">
                                <p className="font-light">Book Price</p>
                            </div>
                            <div className="xs gs-2-2 text-right">
                                <p className="font-bold">
                                    {isInternational ? '$' : 'R'}{' '}
                                    {book.price_sale && typeof book.price_sale === 'number' && book.price_sale.toFixed(2)}
                                    {/* {book.internationtotalcost && !isNaN(Number(book.internationtotalcost)) && Number(book.internationtotalcost).toFixed(2)} */}
                                </p>
                            </div>
                            <div className="xs gs-1-2">
                                <p className="font-light">ISBN</p>
                            </div>
                            <div className="xs gs-2-2 text-right">
                                <p className="font-bold">{book.isbn ? book.isbn : book.book_code}</p>
                            </div>
                            <div className="xs gs-1-2">
                                <p className="font-light">Format</p>
                            </div>
                            <div className="xs gs-2-2 text-right">
                                <p className="font-bold capitalize">{book.format_type}</p>
                            </div>
                            <div className="xs gs-1-2">
                                <p className="font-light">Number of Pages</p>
                            </div>
                            <div className="xs gs-2-2 text-right">
                                <p className="font-bold">{book.pages}</p>
                            </div>
                            {book.misc && isValidJson(book.misc) && JSON.parse(book.misc) && JSON.parse(book.misc).metaDataInfo && (
                                <>
                                    <div className="xs gs-1-2">
                                        <p className="font-light">Author</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p className="font-bold">{JSON.parse(book.misc).metaDataInfo.author}</p>
                                    </div>
                                    <div className="xs gs-1-2">
                                        <p className="font-light">BISAC Code</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p className="font-bold">{JSON.parse(book.misc).metaDataInfo.bisacCode}</p>
                                    </div>
                                    {Array.isArray(JSON.parse(book.misc).metaDataInfo.contributors) && JSON.parse(book.misc).metaDataInfo.contributors.length > 0 && <>
                                        <div className="xs gs-1-2">
                                            <p className="font-light">Contributors</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold">{Array.isArray(JSON.parse(book.misc).metaDataInfo.contributors) && JSON.parse(book.misc).metaDataInfo.contributors.map(c => c.name).join(', ')}</p>
                                        </div>
                                    </>}
                                    <div className="xs gs-1-2">
                                        <p className="font-light">Copyright Holder</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p className="font-bold">{JSON.parse(book.misc).metaDataInfo.copyrightHolder}</p>
                                    </div>
                                    <div className="xs gs-1-2">
                                        <p className="font-light">Copyright Date</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p className="font-bold">{isValidDate(new Date(JSON.parse(book.misc).metaDataInfo.copyrightDate)) ? format(new Date(JSON.parse(book.misc).metaDataInfo.copyrightDate), 'yyyy-MM-dd') : ''}</p>
                                    </div>
                                    <div className="xs gs-1-2">
                                        <p className="font-light">Genre</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p className="font-bold">{JSON.parse(book.misc).metaDataInfo.genre}</p>
                                    </div>
                                    <div className="xs gs-1-2">
                                        <p className="font-light">Indicated Audience Warning</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p className="font-bold">{JSON.parse(book.misc).metaDataInfo.indicatedAudienceWarning}</p>
                                    </div>
                                    <div className="xs gs-1-2">
                                        <p className="font-light">Keywords</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p className="font-bold">{Array.isArray(JSON.parse(book.misc).metaDataInfo.keywords) && JSON.parse(book.misc).metaDataInfo.keywords.join(', ')}</p>
                                    </div>
                                    <div className="xs gs-1-2">
                                        <p className="font-light">Language</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right capitalize">
                                        <p className="font-bold">{JSON.parse(book.misc).metaDataInfo.language}</p>
                                    </div>
                                    {/* {JSON.parse(book.misc).metaDataInfo.longDescription && <>
                                        <div className="xs gs-1-2">
                                            <p className="font-light">Long Description</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold">{JSON.parse(book.misc).metaDataInfo.longDescription}</p>
                                        </div>
                                    </>} */}
                                    {JSON.parse(book.misc).metaDataInfo.shortDescription && <>
                                        <div className="xs gs-1-2">
                                            <p className="font-light">Short Description</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold">{JSON.parse(book.misc).metaDataInfo.shortDescription}</p>
                                        </div>
                                    </>}
                                    <div className="xs gs-1-2">
                                        <p className="font-light">Main Description</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p className="font-bold">{JSON.parse(book.misc).metaDataInfo.mainDescription}</p>
                                    </div>
                                    <div className="xs gs-1-2">
                                        <p className="font-light">On Sale Date</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p className="font-bold">{isValidDate(new Date(JSON.parse(book.misc).metaDataInfo.onSaleDate)) ? format(new Date(JSON.parse(book.misc).metaDataInfo.onSaleDate), 'yyyy-MM-dd') : ''}</p>
                                    </div>
                                    <div className="xs gs-1-2">
                                        <p className="font-light">Publication Date</p>
                                    </div>
                                    <div className="xs gs-2-2 text-right">
                                        <p className="font-bold">{isValidDate(new Date(JSON.parse(book.misc).metaDataInfo.publicationDate)) ? format(new Date(JSON.parse(book.misc).metaDataInfo.publicationDate), 'yyyy-MM-dd') : ''}</p>
                                    </div>
                                    {JSON.parse(book.misc).metaDataInfo.subtitle && <>
                                        <div className="xs gs-1-2">
                                            <p className="font-light">Subtitle</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold">{JSON.parse(book.misc).metaDataInfo.subtitle}</p>
                                        </div>
                                    </>}
                                </>
                            )}

                        </div>
                        {book.misc && isValidJson(book.misc) && JSON.parse(book.misc).bookStage && JSON.parse(book.misc).archived !== true && ((JSON.parse(book.misc).bookStage === "getquote") || (JSON.parse(book.misc).bookStage === "signedcontract")) && (
                            <>
                                <div className="h-5" />
                                <Link to={resolveContinueLink()}>
                                    <Button
                                        color="green"
                                        // onClick={() => downloadRefCover && downloadRefCover.current && downloadRefCover.current.click()}
                                        ripple="light"
                                        className="w-full hover:pr-2"
                                    >
                                        Continue Upload Process
                                        <Icon name="arrow_forward" size="xl" />

                                    </Button>
                                </Link>
                            </>
                        )}

                        {book.misc && isValidJson(book.misc) && JSON.parse(book.misc).archived === true && (
                            <>
                                <div className="h-5" />
                                {/* <p>This book has not been paid for, you may cancel unpaid books.</p> */}
                                <Button
                                    color="red"
                                    onClick={onDeleteClick}
                                    ripple="light"
                                    className="w-full hover:pr-2"
                                >
                                    Delete book
                                    <Icon name="close" size="xl" />
                                </Button>
                            </>
                        )}
                        {/* TODO: some misc check for payment status below */}
                        {/* if payment status is unpaid, user is allowed to remove book */}
                        {book && book.misc && isValidJson(book.misc) && !JSON.parse(book.misc).archived && !JSON.parse(book.misc).isPaid ? (
                            <>
                                <div className="h-5" />
                                {/* <p>This book has not been paid for, you may cancel unpaid books.</p> */}
                                <Button
                                    color="red"
                                    onClick={onArchiveClick}
                                    ripple="light"
                                    className="w-full hover:pr-2"
                                >
                                    Cancel book
                                    <Icon name="close" size="xl" />
                                </Button>
                            </>
                        ) : book.misc && isValidJson(book.misc) && JSON.parse(book.misc).isPaid ? (
                            <></>
                        ) : (
                            <div className="w-full flex items-center text-red-500 mt-5">
                                <Icon name="error" />
                                <p className="ml-1 text-red-500">This book has been cancelled</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                showModal={showLoading}
                setShowModal={setShowLoading}
                size="sm"
            >
                <img
                    style={{ top: '12px' }}
                    className="h-20 w-auto relative"
                    alt="loading"
                    src="/images/loading.gif"
                />
            </Modal>
            <Modal
                showModal={showError}
                setShowModal={setShowError}
                size="sm"
                title=" "
            >
                <div className="w-full flex h-full items-center justify-center">
                    <Icon name="error" size="5xl" />
                    <h2 className="ml-2">
                        Something went wrong while fetching this books contract.
                    </h2>
                </div>
            </Modal>
        </>
    )
}
