import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Checkbox from '@material-tailwind/react/Checkbox';

import { useHistory } from 'react-router-dom';

import { useState } from 'react';
import CustomModal from 'components/general/Modal';

export default function ConfirmBleedAndCover({
    stage,
    setCompleted,
    completed,
    pagePathname,

    coverNeeded,
    thumbnailNeeded,
    formattingNeeded,
    bleedingNeeded,

    setCoverNeeded,
    setThumbnailNeeded,
    setFormattingNeeded,
    setBleedingNeeded,

    rawOrPdf,

    spotUvNeeded,
    setSpotUvNeeded,

    revisionsNeeded,
    setRevisionsNeeded,

    bookFormat,

    isLocal,

    duplexNeededInt,
    setDuplexNeededInt,
    perforationNeededInt,
    setPerforationNeededInt,

    heavyGraphics,
    setHeavyGraphics,

    conversionNeeded,
    setConversionNeeded
}) {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [unselectedOptions, setUnselectedOptions] = useState([]);
    const history = useHistory();

    const onContinueClick = () => {
        let unselected = [];

        // Common checks
        if (!thumbnailNeeded) unselected.push("Thumbnail Design");

        // Checks for 'print' format
        if (bookFormat === 'print') {
            if (!coverNeeded) unselected.push("Cover Design");
            // Add other print-specific checks as needed
        }

        // Checks for 'ebook' format
        if (bookFormat === 'ebook') {
            if (!conversionNeeded) unselected.push("Conversion to .epub format");
            if (conversionNeeded && !heavyGraphics) unselected.push("Handling of heavy graphics in the ebook");

            // Additional ebook-specific checks
            // if (!spotUvNeeded) unselected.push("Spot UV for the ebook");
            // if (!revisionsNeeded) unselected.push("Revisions for the ebook");
            // Note: Duplex printing and perforation services are typically not applicable to ebooks
        }

        if (unselected.length > 0) {
            setUnselectedOptions(unselected);
            setShowConfirmModal(true);
        } else {
            history.push(`/${pagePathname}/upload-assets`);
            setCompleted([...completed, stage]);
        }
    };

    return (
        <div className="w-full text-left">
            <h3 className="text-4xl font-bold">Cover Requirements</h3>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>
                <CardBody>
                    <p className="text-xl">Please confirm if any additional services are required. If any are required, a related item will be added to your cart.</p>
                    <div className="h-5" />
                    {rawOrPdf === 'raw' && <div className="mb-4 text-left">
                        <Checkbox
                            color="red"
                            value={bleedingNeeded}
                            checked={bleedingNeeded}
                            onChange={() => setBleedingNeeded(!bleedingNeeded)}
                            text="I require bleeding done for my book"
                            id="checkbox-bleed"
                        />
                    </div>}
                    {bookFormat === 'print' && <div className="mb-4 text-left">
                        <Checkbox
                            color="red"
                            value={coverNeeded}
                            checked={coverNeeded}
                            onChange={() => setCoverNeeded(!coverNeeded)}
                            text="I require a cover designed for my book"
                            id="checkbox-cover"
                        />
                    </div>}
                    {rawOrPdf === 'raw' && <div className="mb-4 text-left">
                        <Checkbox
                            color="red"
                            value={formattingNeeded}
                            checked={formattingNeeded}
                            onChange={() => setFormattingNeeded(!formattingNeeded)}
                            text="I require formatting done for my book"
                            id="checkbox-formatting"
                        />
                    </div>}
                    <div className="mb-4 text-left">
                        <Checkbox
                            color="red"
                            value={thumbnailNeeded}
                            checked={thumbnailNeeded}
                            onChange={() => setThumbnailNeeded(!thumbnailNeeded)}
                            text="I require a thumbnail to be designed for my book"
                            id="checkbox-thumbnail"
                        />
                    </div>

                    {bookFormat === 'ebook' && <div className="mb-4 text-left">
                        <Checkbox
                            color="red"
                            value={conversionNeeded}
                            checked={conversionNeeded}
                            onChange={() => setConversionNeeded(!conversionNeeded)}
                            text="I require the conversion from a Word, PDF or inDesign file I provide to .epub format."
                            id="checkbox-cn"
                        />
                    </div>}

                    {bookFormat === 'ebook' && conversionNeeded && <div className="mb-4 text-left">
                        <Checkbox
                            color="red"
                            value={heavyGraphics}
                            checked={heavyGraphics}
                            onChange={() => setHeavyGraphics(!heavyGraphics)}
                            text="Does your book contain many images or illustrations?"
                            id="checkbox-hg"
                        />
                    </div>}

                    {/* {!isLocal && <div className="mb-4 text-left">
                        <Checkbox
                            color="red"
                            value={duplexNeededInt}
                            checked={duplexNeededInt}
                            onChange={() => setDuplexNeededInt(!duplexNeededInt)}
                            text="I require duplex"
                            id="checkbox-thumbnail"
                        />
                    </div>} */}
                    {/* {isLocal && <>
                        <HelpTextWrapper placement="left" helpText="">
                            <div className="mb-4 text-left">
                                <Checkbox
                                    color="red"
                                    value={revisionsNeeded}
                                    checked={revisionsNeeded}
                                    onChange={() => setRevisionsNeeded(!revisionsNeeded)}
                                    text="I require revisions for my book"
                                    id="checkbox-revisions"
                                />
                            </div>
                        </HelpTextWrapper>
                    </>} */}

                    <div className="h-5" />
                </CardBody>
            </Card>
            <div className="h-5" />
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-left mb-3 md:mb-auto md:w-1/4 hover:pl-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={() => history.goBack()}
            >
                <Icon name="arrow_back" size="sm" />
                Back
            </Button>
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/4 hover:pr-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={onContinueClick}
            >
                {completed.includes(stage) ? "Next" : "Continue"}
                <Icon name="arrow_forward" size="sm" />
            </Button>
            <div className="h-10" />
            <CustomModal
                showModal={showConfirmModal}
                setShowModal={setShowConfirmModal}
                size="lg"
            >
                <h3 className="mb-2">Are you sure you don't need the following services?</h3>
                <ul className="flex justify-center flex-col items-center">
                    {unselectedOptions.map((option, index) => (
                        <li key={index} className="p-1"><p>{option}</p></li>
                    ))}
                </ul>
                <Button
                    color="red"
                    className="w-full mt-2"
                    buttonType="filled"
                    size="md"
                    ripple="dark"
                    onClick={() => {
                        setShowConfirmModal(false);
                        history.push(`/${pagePathname}/upload-assets`);
                        setCompleted([...completed, stage]);
                    }}
                >
                    Proceed
                </Button>
            </CustomModal>
        </div >
    );
}
