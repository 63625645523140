import { useState } from 'react';

import Button from '@material-tailwind/react/Button';
import Paragraph from '@material-tailwind/react/Paragraph';

import { saveSignature } from './index';

import Modal from 'components/general/Modal';
import Sign from 'components/sign/Sign';

export default function SignatureModal({ show, setShow, save, title, subText }) {
    const [sigCanvas, setSigCanvas] = useState();
    const [hasDrawn, setHasDrawn] = useState(false);

    function onSaveClick() {
        const sigBase64 = sigCanvas.toDataURL('image/png', {});

        saveSignature(sigBase64);

        save(sigBase64);

        setShow(false);
    }

    return (
        <Modal
            showModal={show}
            setShowModal={setShow}
            title={title ? title : "Your Signature"}
            size={window.innerWidth < 767 ? "sm" : "regular"}
        >
            <Paragraph>
                {subText ? subText : "Draw your signature and click save"}
            </Paragraph>
            <Sign setSigCanvas={setSigCanvas} />
            <div className="h-10" />
            <Button
                onClick={sigCanvas && sigCanvas.clear && sigCanvas.clear}
                color="blue"
                className="inline float-left"
                buttonType="fill"
            >
                Clear
            </Button>
            <Button
                onClick={onSaveClick}
                color="green"
                className="inline float-right"
                buttonType="fill"
            >
                Save
            </Button>
            <Button
                onClick={() => setShow(false)}
                color="red"
                className="inline mr-2 float-right border-primary"
                buttonType="outline"
            >
                Cancel
            </Button>
        </Modal>
    )
}
