// import {useEffect} from 'react';

// import {setPageTitle} from 'utils';

import BookNewMain from 'components/books/new/BookNewMain';

const BookNew = () => {

    // useEffect(() => {
    //     setPageTitle('New Book');
    // }, []);

    return (
        <BookNewMain />
    );
}

export default BookNew;