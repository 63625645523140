import Button from '@material-tailwind/react/Button';
import { Paragraph } from '@material-tailwind/react';

import Modal from 'components/general/Modal';

export default function AlertModal({ showModal, setShowModal, title, message }) {
    return (
        <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            size="sm"
        >
            <div className="p-5">
                <h3 className="font-light text-xl mb-5">
                    {title}
                </h3>
                {message ? <Paragraph>{message}</Paragraph> : null}
                <div className="h-5" />
                <Button
                    color="red"
                    className="w-full"
                    buttonType="filled"
                    size="lg"
                    ripple="dark"
                    onClick={() => setShowModal(false)}
                >
                    Okay
                </Button>
            </div>
        </Modal>
    )
}
