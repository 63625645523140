import { useEffect, useRef } from 'react';
import { store, useGlobalState } from 'state-pool';
import useSessionStorage from 'hooks/useSessionStorage';
import _ from 'lodash';

import { Order } from '../models/order';
import { Transaction } from '../models/transaction';
import { Book } from '../models/book';
import { Quote } from '../models/quote';
import { DocumentModel } from '../models/document';
import { podApi } from 'api/pod';
import { useOffline } from './useOffline';
import { useAlert } from './useAlert';
import { initializeStore } from 'state';

import { isEmptyArray } from 'utils';

// import { getUserBooks } from 'routes/userRoutes';

type TimerReference = { timerReference: NodeJS.Timeout, key: string, interval: number, path: string }[];

initializeStore();

export const useGlobal = () => {
    const [orders, setOrders] = useGlobalState(`orders`);
    const [order, setOrder] = useGlobalState(`order`);
    const [cachedOrder, setCachedOrder] = useSessionStorage('order', null);
    const [cachedOrders, setCachedOrders] = useSessionStorage<Order[] | null>('orders', null);

    const [transactions, setTransactions] = useGlobalState(`transactions`);
    const [transaction, setTransaction] = useGlobalState(`transaction`);
    const [cachedTransaction, setCachedTransaction] = useSessionStorage('transaction', null);
    const [cachedTransactions, setCachedTransactions] = useSessionStorage<Transaction[] | null>('transactions', null);

    const [books, setBooks] = useGlobalState(`books`);
    const [book, setBook] = useGlobalState(`book`);
    const [cachedBook, setCachedBook] = useSessionStorage('book', null);
    const [cachedBooks, setCachedBooks] = useSessionStorage<Book[] | null>('books', null);

    const [quotes, setQuotes] = useGlobalState(`quotes`);
    const [quote, setQuote] = useGlobalState(`quote`);
    const [cachedQuote, setCachedQuote] = useSessionStorage('quote', null);
    const [cachedQuotes, setCachedQuotes] = useSessionStorage<Quote[] | null>('quotes', null);

    const [documents, setDocuments] = useGlobalState(`documents`);
    const [document, setDocument] = useGlobalState(`document`);
    const [cachedDocument, setCachedDocument] = useSessionStorage('document', null);
    const [cachedDocuments, setCachedDocuments] = useSessionStorage<DocumentModel[] | null>('documents', null);

    const [resources, setResources] = useGlobalState(`resources`);
    const [cachedResources, setCachedResources] = useSessionStorage<any | null>('resources', null);

    const [sales, setSales] = useGlobalState(`sales`);
    const [cachedSales, setCachedSales] = useSessionStorage<any[] | null>('sales', null);

    const [voiceArtists, setVoiceArtists] = useGlobalState(`voiceArtists`);
    const [cachedVoiceArtists, setCachedVoiceArtists] = useSessionStorage<any[] | null>('voiceArtists', null);

    const [prices, setPrices] = useGlobalState(`prices`);
    const [cachedPrices, setCachedPrices] = useSessionStorage<any[] | null>('prices', null);

    const { addToQueue, isOnline } = useOffline();
    const timer = useRef<TimerReference>([]);
    const { newAlert } = useAlert();
    //Creating a array that will be use to store the book and check for validation of each part of the wizard

    useEffect(() => {
        return cleanUp;
    }, []);

    useEffect(() => {
        // alert('kbkjb')

        // console.log(cachedOrders);

        syncOrders();
        syncSales();
        // syncTransactions();
        syncBooks();
        syncResources();
        syncVoiceArtists();
        syncPrices();
        // syncQuotes();
        // syncDocuments();

        return () => {
            setCachedOrder(null);
            setCachedTransaction(null);
            setCachedBook(null);
            setCachedQuote(null);
            setCachedDocument(null);
        };


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders, transactions, documents, books, quotes, resources, voiceArtists, prices]);

    function syncBooks() {

        // if(book && (cachedBook && typeof cachedBook === 'object' && !_.isEqual(book, cachedBook))) {
        //     commit(book, book.id, '/books', 'book');
        // }

        // if(book && !cachedBook) setCachedBook(book);

        if ((!books || isEmptyArray(books)) && cachedBooks) {
            setBooks(cachedBooks);
        }
    }

    function syncResources() {

        // if(book && (cachedBook && typeof cachedBook === 'object' && !_.isEqual(book, cachedBook))) {
        //     commit(book, book.id, '/books', 'book');
        // }

        if ((!resources || isEmptyArray(resources)) && cachedResources) {
            setResources(cachedResources);
        }
    }

    function syncOrders() {
        if ((!orders || isEmptyArray(orders)) && cachedOrders && !isEmptyArray(cachedOrders)) {
            setOrders(cachedOrders);
        }
    }

    function syncSales() {
        if (!sales && cachedSales) {
            setSales(cachedSales);
        }
    }

    function syncVoiceArtists() {
        if ((!voiceArtists || isEmptyArray(voiceArtists)) && cachedVoiceArtists) {
            setVoiceArtists(cachedVoiceArtists);
        }
    }

    function syncPrices() {
        if ((!prices || (typeof prices === 'object' && !prices.audioBook)) && cachedPrices) {
            setPrices(cachedPrices);
        }
    }

    async function load(path: string, key: string) {
        const online = isOnline();

        if (!online) {
            addToQueue('GET', path);

            loadCache();

            return;
        }
        // get items
        // set global state items with response
        try {
            const res = await podApi.get(path);

            if (res && res.data && !res.data.results && !res.data.result) {
                setKey(key, res.data, 'load');
            }

            if (res && res.data && res.data.results) {
                setKey(key, res.data.results, 'load');
            }

            if (res && res.data && res.data.result) {
                setKey(key, res.data.result, 'load');
            }

        } catch (err: any) {

            //adds to network queue
            addToQueue('GET', path);
        }
    }

    function loadCache() {
        setOrders(cachedOrders);
        setOrder(cachedOrder);
        setTransactions(cachedTransactions);
        setTransaction(cachedTransaction);
        setBooks(cachedBooks);
        setBook(cachedBook);
        setQuotes(cachedQuotes);
        setQuote(cachedQuote);
        setDocuments(cachedDocuments);
        setDocument(cachedDocument);
        setResources(cachedResources);
        setSales(cachedSales);
        setVoiceArtists(cachedVoiceArtists);
        setPrices(cachedPrices);
    }

    function setKey(key: string, data: any, reference: string) {
        switch (key) {
            case 'orders': {
                if (cachedOrders && _.isEqual(cachedOrders, data)) return;

                setCachedOrders(data);
                setOrders(data);
                return;
            }
            case 'order': {
                setCachedOrder(data);
                setOrder(data);
                return;
            }
            case 'quotes': {
                if (cachedQuotes && _.isEqual(cachedQuotes, data)) return;

                setCachedQuotes(data);
                setQuotes(data);
                return;
            }
            case 'quote': {
                setCachedQuote(data);
                setQuote(data);
                return;
            }
            case 'transactions': {
                if (cachedTransactions && _.isEqual(cachedTransactions, data)) return;

                setCachedTransactions(data);
                setTransactions(data);
                return;
            }
            case 'transaction': {
                setCachedTransaction(data);
                setTransaction(data);
                return;
            }
            case 'books': {
                if (cachedBooks && _.isEqual(cachedBooks, data)) return;

                setCachedBooks(data);
                setBooks(data);
                return;
            }
            case 'book': {
                // setCachedBook(data);
                // setBook(data);
                return;
            }
            case 'documents': {
                if (cachedDocuments && _.isEqual(cachedDocuments, data)) return;

                setCachedDocuments(data);
                setDocuments(data);
                return;
            }
            case 'document': {
                setCachedDocument(data);
                setDocument(data);
                return;
            }
            case 'resources': {
                if (cachedResources && _.isEqual(cachedResources, data)) return;

                setCachedResources(data);
                setResources(data);
                return;
            }
            case 'sales': {
                if (cachedSales && _.isEqual(cachedSales, data)) return;

                setCachedSales(data);
                setSales(data);
                return;
            }
            case 'voiceArtists': {
                if (cachedVoiceArtists && _.isEqual(cachedVoiceArtists, data)) return;

                setCachedVoiceArtists(data);
                setVoiceArtists(data);
                return;
            }
            case 'prices': {
                if (cachedPrices && _.isEqual(cachedPrices, data)) return;

                setCachedPrices(data);
                setPrices(data);
                return;
            }
        }
    }

    async function refresh(path: string, key: string, reference: string) {
        const online = isOnline();

        if (!online) {
            return;
        }
        // gets items
        // reset items with response
        try {
            const res = await podApi.get(path);

            if (res && res.data) {
                setKey(key, res.data, 'refresh');
            }
        } catch {
            //adds to network queue
            addToQueue('GET', path);
        }
    }

    async function add(data: any, path: string, key?: string) {
        const online = isOnline();

        if (!online) {
            addToQueue('POST', path, data);
            //TODO
            newAlert('danger', 'No network connection', 'Data will be submitted when connection is restored');
            //show alert for no network
            return;
        }

        try {
            // adds new data
            await podApi.post(path, data);

            // refreshes once complete
            if (key) refresh(path, key, 'add');
        } catch {
            addToQueue('POST', path, data);
            return;
        }
    }

    function autoRefresh(interval: number, path: string, key: string) {
        const online = isOnline();

        if (!online) return;
        // setInterval with a refresh rate
        // gets and sets user at interval with refresh function

        let timerReference = setInterval(() => {
            refresh(path, key, 'auto refresh');
        }, interval * 1000);

        timer.current = [...timer.current, {
            timerReference,
            key,
            interval,
            path
        }];
    }

    async function remove(id: string, path: string, key: string) {
        const online = isOnline();

        if (!online) {
            addToQueue('DELETE', `${path}/${id}`);
            //TODO
            newAlert('danger', 'No network connection', 'Item will be deleted when connection is restored');

            //show no network
            return;
        }

        try {
            // updates user with updated user object
            await podApi.delete(`${path}/${id}`);

            // refreshes once complete
            refresh(path, key, 'remove');
        } catch (err) {
            addToQueue('DELETE', `${path}/${id}`);
            return;
        }
    }

    async function commit(data: any, id: string, path: string, key: string) {
        const online = isOnline();

        if (!online) {
            addToQueue('PUT', `${path}/${id}`, data);
            // TODO
            newAlert('danger', 'No network connection', 'Item will be updated when connection is restored');

            // show no network
            return;
        }

        try {
            // updates data with updated data object
            await podApi.put(`${path}/${id}`, data);

            // refreshes once complete
            refresh(path, key + 's', 'commit');
        } catch (err) {
            addToQueue('PUT', `${path}/${id}`, data);
            return;
        }
    }

    function cleanUp() {
        if (timer && timer.current) {
            timer.current.forEach(({ timerReference }: { timerReference: NodeJS.Timeout }) => {
                clearInterval(timerReference);
            });
        };
    }

    return {
        load,
        add,
        commit,
        refresh,
        autoRefresh,
        remove,
        store,
    }
}

