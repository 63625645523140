import { useState, useRef, useEffect } from 'react';

import { Icon, Progress, Button } from '@material-tailwind/react';

import DocumentUpload from 'components/document-handling/DocumentUpload';

import { isValidJson, csvJSON, toUtf8, isValidDate } from 'utils';

import { filter, isEqual } from 'lodash';

import { getQuote, uploadBook } from 'routes/userRoutes';

import { Link } from 'react-router-dom';

import { useLocalStorage } from 'usehooks-ts';
import { podApi } from 'api/pod';

const INITIAL_KEYS = [
    "barcode_is_required",
    "bind",
    "book_title",
    "duplex_needed",
    "finish",
    "format_type",
    "gender",
    "genre",
    "heavyGraphics",
    "heavy_graphics",
    "height",
    "i_cover_type_id",
    "i_duplex_covered",
    "i_paper_type_id",
    "international_price",
    "is_international",
    "is_local",
    "isbn",
    "isbn_is_required",
    "l_cover_type_id",
    "l_paper_type_id",
    "local_price",
    "nationality",
    "no_of_pages",
    "perforation",
    "recording_needed",
    "soft_touch_matt_lamination",
    "spine_id",
    "spiral_bound",
    "spot_uv",
    "voiceover_id",
    "width"
];

export default function BulkUpload() {
    const [file, setFile] = useState();
    const [progress, setProgress] = useState(0)
    const [isFileUploadComplete, setIsFileUploadComplete] = useState(false)
    const [isCsvImportedSuccessfully, setIsCsvImportedSuccessfully] = useState(false)

    const [validBooks, setValidBooks] = useState([])
    const [invalidBooks, setInvalidBooks] = useState([])

    const [bookWithQuoteDetails, setBookWithQuoteDetails] = useState([])

    const [isUploading, setIsUploading] = useState(false)

    const progressIntervalRef = useRef();
    const progressRef = useRef(0);

    const fileUploadProgress = () => {
        if (progressRef.current == 100) {

            setIsFileUploadComplete((uploaded) => !uploaded);
            clearInterval(progressIntervalRef.current);
        } else if (progressRef.current >= 0 && progressRef.current < 100) {
            progressRef.current = progressRef.current + 1;
        }
        setProgress(progressRef.current)
    }

    useEffect(() => {
        if (file && typeof file === 'object' && file.file_data) {
            setIsUploading((uploading) => !uploading);
            progressIntervalRef.current = setInterval(fileUploadProgress, 100);
        }
    }, [file]);


    useEffect(() => {

        if (isFileUploadComplete) {
            setIsFileUploadComplete(false);
        }


    }, [isFileUploadComplete]);

    function onCloseClick() {

        setIsUploading(false);

        setProgress(0);

        setFile(null);

        setValidBooks([]);

        setBookWithQuoteDetails([])
    }

    function onSubmitClick() {

    }

    function validateBooks(arr) {
        let allowed = [];
        let disallowed = [];

        if (!arr || (!Array.isArray(arr))) return false;

        arr.forEach(item => {
            const itemKeys = Object.keys(item);

            if (!isEqual(itemKeys, INITIAL_KEYS)) {
                disallowed.push(item);
            } else {
                allowed.push(item);
            }
        });

        return { allowed, disallowed };
    }

    useEffect(() => {
        if (file && file.file_data) {
            const utf8Data = toUtf8(file.file_data.split(',')[1]);
            const json = csvJSON(utf8Data);

            // console.log(typeof json);

            const parsed = isValidJson(json) && JSON.parse(json);

            if (!parsed || !Array.isArray(parsed)) return;

            const formatted = parsed.map(item => {
                let updatedItem = {};

                let keys = Object.keys(item);

                keys.forEach(key => {

                    if ((item[key] === 'true') || (item[key] === 'false') || (item[key] === 'null')) {
                        updatedItem[key] = JSON.parse(item[key]);
                    } else if (item[key].includes('\r')) {
                        updatedItem[key] = item[key].replace(/\r/g, '');
                        if (!isNaN(Number(item[key]))) {
                            updatedItem[key] = Number(item[key]);
                        }
                    } else if (!isNaN(Number(item[key]))) {
                        updatedItem[key] = Number(item[key]);
                    } else if (key.includes('/')) {
                        const splitKey = key.split('/');

                        const mainKey = splitKey[0];
                        const subKey = splitKey[1];

                        if (updatedItem[mainKey]) updatedItem[mainKey][subKey] = item[key];

                        if (!updatedItem[mainKey]) {
                            updatedItem[mainKey] = {};

                            updatedItem[mainKey][subKey] = item[key];
                        }

                    }
                    else {
                        updatedItem[key] = item[key];
                    }
                });

                return updatedItem;
            });

            // console.log('formatted CSV', formatted);

            // validateBooks

            const validated = validateBooks(formatted);

            // console.log(validated);

            // TODO: error message below
            if (!validated) return;

            if (validated && typeof validated === 'object' && validated.allowed && validated.disallowed) {
                const { allowed, disallowed } = validated;

                if (allowed && Array.isArray(allowed)) {
                    setValidBooks([...allowed]);
                }

                if (disallowed && Array.isArray(disallowed)) {
                    setInvalidBooks([...disallowed]);
                }
            }

            setIsCsvImportedSuccessfully(true);

            // setEmployeeList([...formatted, ...employeeList]);
        }
    }, [file]);

    useEffect(async () => {
        const books = (await podApi.get(`/user/getuserbooks`)).data

        if (validBooks) {
            const filteredValidBooks = validBooks.filter((book) => {
                let truthy = false;

                books.localBooks.every(element => {
                    if (element.book_title == book.book_title && book.is_local) {
                        truthy = true;
                        return false;
                    } else {
                        truthy = false;
                        return true
                    }
                });

                if (!truthy) {
                    books.internationalBooks.every(element => {
                        if (element.book_title == book.book_title && book.is_international) {
                            truthy = true;
                            return false;
                        } else {
                            truthy = false;
                            return true
                        }
                    });
                }

                return !truthy
            })

            console.log(filteredValidBooks)


            if (filteredValidBooks && Array.isArray(filteredValidBooks) && filteredValidBooks.length > 0 && filteredValidBooks?.length > bookWithQuoteDetails?.length) {
                for (let i = 0; i < filteredValidBooks.length; i++) {
                    getQuote({ ...filteredValidBooks[i], is_bulk: true }).then(quoteDetails => {
                        console.log(quoteDetails)
                        if (quoteDetails && typeof quoteDetails === 'object') {
                            setBookWithQuoteDetails(books => [...books, { ...filteredValidBooks[i], ...quoteDetails }]);
                        }
                    })

                }
            }


        }


    }, [validBooks]);


    function getTextStatusColor(book) {
        if (
            book.misc &&
            isValidJson(book.misc) &&
            JSON.parse(book.misc).status &&
            JSON.parse(book.misc).status
        ) {
            switch (JSON.parse(book.misc).status) {
                case 'pending':
                    return 'text-orange-500'
                case 'quote':
                    return 'text-gray-500'
                case 'denied':
                    return 'text-red-500'
                default:
                    return 'text-green-500'
            }
        }
    }

    return (
        <div className="w-full px-5 md:px-8">
            <h2 className="text-3xl mb-2 mt-8">Bulk upload from CSV document</h2>
            <p className="mb-1">
                Bulk uploading is useful when you have multiple books that you would like to upload. Prepare a CSV document with the book’s details and easily have all books processed in one go. Please note you will have to upload each book’s document individually.
                Please download and use our <a href="/sample-files/template.csv" download="csv-template.csv" className="text-red underline mb-5">CSV template</a>.
                Or download an <a href="/sample-files/bulk-upload-example.csv" download="csv-template.csv" className="text-red underline mb-5">example file</a>.
            </p>
            {/* <p className="text-red underline mb-1">How do I bulk upload?</p> */}
            {/* <p className="text-red underline mb-5">Download the CSV template</p> */}

            <div className="w-full mt-5 mb-5 rounded-xl">
                <div className="h-2" />
                {/* <div className="w-full flex items-center text-gray-500">
                    <Icon name="info" /><p className="ml-1">View, search, filter and download all documents related to <span className="capitalize">{site.replace('-', ' ')}</span></p>
                </div>
                <div className="h-5" /> */}

                {!isUploading ? (
                    <DocumentUpload setFile={setFile} fileTypes=".csv">
                        <div className="w-full p-5 rounded-xl bg-white shadow-xl flex flex-col items-center flex-center cursor-pointer hover:shadow-xl">
                            <Icon name="upload" size="3xl" />
                            <h3 className="text-gray-500" style={{ color: 'inherit' }}>Upload CSV</h3>
                        </div>
                    </DocumentUpload>
                ) : (
                    <div className="w-full bg-white shadow-xl p-5 rounded-xl mt-5">
                        {progress !== 100 ? (
                            <>
                                <Progress value={progress} color="green" />
                                <p className="mt-2">Uploading {file && file.file_name} <span className="ml-2 text-lg bold">{progress}%</span></p>
                            </>
                        ) : (
                            <div className="w-full flex items-center text-themegreen">
                                <Icon name="check_circle" size="md" />
                                <h3 className="text-themegreen ml-2"><span className="font-bold">{file && file.file_name}</span> uploaded <span className="text-green-500">successfully</span></h3>

                                <div className="justify-self-end ml-auto cursor-pointer">

                                    <Button
                                        color="red"
                                        buttonType="link"
                                        ripple="dark"
                                        rounded
                                        iconOnly
                                        size="sm"
                                        onClick={() => onCloseClick()}
                                    >
                                        <Icon name="close" size="md" />
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="h-5" />
                {((validBooks && Array.isArray(validBooks) && (validBooks.length > 0)) || (invalidBooks && Array.isArray(invalidBooks) && (invalidBooks.length > 0))) && (progress === 100) ? (
                    <>
                        <h2 className="text-xl mb-2 mt-8">Please confirm your book details below</h2>

                        {validBooks?.length > bookWithQuoteDetails?.length ? <h1 className="text-3xl mb-2 mt-8" style={{ color: 'red' }}>Duplicate upload detected, some books won't display below.</h1> : ""}

                        <h2 className="text-md text-green-500 mb-2 mt-8">Valid Books</h2>

                        {
                            bookWithQuoteDetails && Array.isArray(bookWithQuoteDetails) && (bookWithQuoteDetails.length > 0) && bookWithQuoteDetails.map(book => (
                                <div className="w-full bg-white shadow-xl p-5 rounded-xl mt-5">
                                    <h3 className="mb-5 text-2xl">{book.book_title}</h3>
                                    <div className="gs-12 p-8 bg-gray-200 rounded-xl">
                                        {(book && book.misc && isValidJson(book.misc) && !JSON.parse(book.misc).archived) && (
                                            <>
                                                <div className="xs gs-1-2">
                                                    <p className="font-light">Status</p>
                                                </div>
                                                <div className="xs gs-2-2 text-right">
                                                    <p
                                                        className={`${getTextStatusColor(
                                                            book,
                                                        )} uppercase font-bold`}
                                                    >
                                                        {book.misc &&
                                                            isValidJson(book.misc) &&
                                                            JSON.parse(book.misc).status &&
                                                            JSON.parse(book.misc).status}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        <div className="xs gs-1-2">
                                            <p className="font-light">Local Book Price</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold">R{book.local_price}</p>
                                        </div>
                                        <div className="xs gs-1-2">
                                            <p className="font-light">Local Royalty</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold">{book.localRoyalty}</p>
                                        </div>
                                        <div className="xs gs-1-2">
                                            <p className="font-light">International Book Price</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold">R{book.international_price}</p>
                                        </div>
                                        <div className="xs gs-1-2">
                                            <p className="font-light">International Royalty</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold">{book.internationRoyalty}</p>
                                        </div>
                                        <div className="xs gs-1-2">
                                            <p className="font-light">ISBN</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold text-gray-500">{book.isbn ? book.isbn : 'to be provided'}</p>
                                        </div>
                                        <div className="xs gs-1-2">
                                            <p className="font-light">Format</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold capitalize">{book.format_type}</p>
                                        </div>
                                        <div className="xs gs-1-2">
                                            <p className="font-light">Number of Pages</p>
                                        </div>
                                        <div className="xs gs-2-2 text-right">
                                            <p className="font-bold">{book.no_of_pages}</p>
                                        </div>
                                    </div>
                                    <div className="h-8" />
                                    <Link to={'/books'}>
                                        <Button
                                            color="red"
                                            className="hover:text-white hover:bg-red hover:shadow-xl justify-self-end ml-auto w-full md:w-1/4"
                                            buttonType="outline"
                                            size="lg"
                                            ripple="dark"
                                        // onClick={() => resolveContinueLink(book.localBookId, book.internationalBookId)}
                                        >
                                            Complete Process
                                            <Icon name="arrow_forward" size="sm" />
                                        </Button>
                                    </Link>
                                    <div className="h-5" />
                                </div>
                            ))
                            || <div className="w-full bg-white shadow-xl p-5 rounded-xl mt-5">
                                <h3>No valid books found</h3>
                            </div>

                        }

                        <h2 className="text-md text-red-500 mb-2 mt-8">Invalid Books</h2>

                        {
                            invalidBooks && Array.isArray(invalidBooks) && (invalidBooks.length > 0) && invalidBooks.map(book => (
                                <div className="w-full bg-white shadow-xl p-5 rounded-xl mt-5">
                                    <h3>{book?.book_title || "Title not found"}</h3>
                                </div>
                            ))
                            ||
                            <div className="w-full bg-white shadow-xl p-5 rounded-xl mt-5">
                                <h3>No invalid books found</h3>
                            </div>

                        }
                    </>
                ) : (progress === 100) ? (
                    <h2 className="text-red-500">No books detected in your CSV document.</h2>
                ) : ''}
            </div>

        </div>
    )
}
