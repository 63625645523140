import { useEffect } from 'react';

import { setPageTitle } from 'utils';

import ResignContractComponent from 'components/resign-contract';

export default function Renewals({ setShowSidebar }) {

    useEffect(() => {
        setPageTitle('Renewal');
        setShowSidebar(false);
    }, []);

    return (
        <div className="min-h-screen h-full w-full relative bg-image">
            <div className="text-center px-3 md:px-8 w-full">
                <div>
                    <div className="h-10" />
                    <div className="max-w-3xl w-full m-auto">
                        <ResignContractComponent />
                        <div className="h-10" />
                    </div>
                    <div className="h-10" />
                </div>
            </div>
        </div>
    );
}
