import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
    setShowSidebar: (val: boolean) => void;
};

const ShowSideBarController: React.FC<Props> = ({ setShowSidebar }) => {
    let location = useLocation();

    useEffect(() => {
        const tempShowSideBar = 
        location.pathname !== "/login"
        && !location.pathname.includes("/verify")
        && !location.pathname.includes("/register")
        && location.pathname !== "/forgot-password"
        && location.pathname !== "/reset-password"
        && location.pathname !== "/renewal"
        && !location.pathname.includes("/my-first-book");
    
        setShowSidebar(tempShowSideBar);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (null);
}

export default ShowSideBarController;