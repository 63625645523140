import React from 'react';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import CouponDropdown from 'components/cart/CouponDropdown';

import { isValidJson } from 'utils';

import { useGlobalState } from 'state-pool';

import HelpTextWrapper from 'components/general/HelpTextWrapper';
import Cart from 'pages/Cart';

import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';

// international_book_name: "Test Mobile View"
// local_book_name: "Test Mobile View"

export default function CartDetails({ cartItems, total, onProceedClick, onRemoveClick, setCouponId, couponDetails, setCouponDetails }) {
    const [currentUser] = useGlobalState('currentUser');

    cartItems = cartItems && Array.isArray(cartItems) && cartItems.filter(ci => ci ? true : false);

    const {
        refreshUserDetails
    } = useAuth();

    useEffect(() => {
        refreshUserDetails();
    }, []);

    function getDiscount(item) {
        if (item.misc && isValidJson(item.misc) && JSON.parse(item.misc)[0]) {
            const miscParsed = JSON.parse(item.misc)[0];
            if (typeof miscParsed.discount === 'number' && typeof miscParsed.discount_type === 'number') {
                const { discount, discount_type } = miscParsed;

                switch (discount_type) {
                    case (1): return `${discount}%`;
                    default: return `R ${discount}`
                }
            }
        }


        return `0%`;
    }

    function getFinalPrice(item) {
        const cost = item.misc && isValidJson(item.misc) && JSON.parse(item.misc)[0] ? JSON.parse(item.misc)[0].cost.toFixed(2) : 0;

        if (item.misc && isValidJson(item.misc) && JSON.parse(item.misc)[0]) {
            const miscParsed = JSON.parse(item.misc)[0];
            if (typeof miscParsed.discount === 'number' && typeof miscParsed.discount_type === 'number') {
                const { discount, discount_type } = miscParsed;

                switch (discount_type) {
                    case (1): return ((Number(cost) - (Number(cost) / 100 * Number(discount))) + Number(getVatValue(Number(cost)))).toFixed(2);
                    default: return ((Number(cost) - Number(discount)) + Number(getVatValue(Number(cost)))).toFixed(2);
                }
            }

        }
        return (Number(cost) + Number(getVatValue(Number(cost)))).toFixed(2);
    }

    function getCouponDetails() {
        if (couponDetails && couponDetails.type && couponDetails.amount) {
            const { type, amount } = couponDetails;

            switch (type) {
                case 'value': return `R ${amount}`;
                default: return `${amount} %`
            }
        }
    }

    function removeCoupon() {
        setCouponId(null)
        setCouponDetails(null);
    }

    function getTotal() {
        let tempTotal = 0;

        if (cartItems && Array.isArray(cartItems)) {
            cartItems.forEach(item => {
                const discountedItemPrice = getFinalPrice(item);

                tempTotal += Number(discountedItemPrice);
            });

            if (tempTotal && !couponDetails) {
                return tempTotal.toFixed(2);
            }

            if (tempTotal < 0) {
                return Number(0).toFixed(2)
            }

            if (tempTotal && couponDetails && couponDetails.type && couponDetails.amount) {
                const { type, amount } = couponDetails;

                if ((tempTotal - amount) < 0) {
                    return Number(0).toFixed(2);
                }

                switch (type) {
                    case 'value': return (Number(tempTotal) - Number(amount)).toFixed(2);
                    default: return (Number(tempTotal) - (Number(tempTotal) / 100 * amount)).toFixed(2);
                }



            }

        }

        return 0;
    }

    const getUserBalance = () => {
        if (currentUser && typeof currentUser === 'object' && typeof currentUser.balance === 'number' && !isNaN(Number(currentUser.balance))) {
            return Number(currentUser.balance).toFixed(2);
        }

        return 0;
    }

    const getPayable = () => {
        const total = getTotal();
        const bal = getUserBalance();

        const totalNum = Number(total).toFixed(2);
        const balNum = Number(bal).toFixed(2);

        if (isNaN(totalNum) || isNaN(balNum)) return 0;

        if (total < 0) return Number(0).toFixed(2);

        return (totalNum - balNum).toFixed(2);
    }

    const isNegativeBalance = () => currentUser && currentUser.balance && String(currentUser.balance).includes('-');

    const getVatValue = (val) => {
        if (!val || isNaN(Number(val))) return 0;

        return (val * 0.15).toFixed(2);
    };

    const getPriceExclVat = (val) => {
        if (!val || isNaN(Number(val))) return 0;

        const vatVal = getVatValue(val);

        return (val - vatVal).toFixed(2);
    }

    return (
        <div className="max-w-3xl w-full p-8 z-30 rounded-xl shadow-md-grey">
            <div className="h-6" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full justify-items-stretch mt-4">
                <h2 className="text-2xl mb-4 px-2">
                    Cart Items ({cartItems && cartItems.length})
                </h2>
                <div />
            </div>
            <div className="grid grid-cols-4 md:grid-cols-7-max gap-4 w-full justify-items-stretch">
                <p className="px-2 text-left font-bold pt-2">Product Name</p>{" "}
                <p className="px-2 text-left font-bold pt-2">Book Name</p>{" "}
                <p className="px-2 hidden md:block text-center font-bold pt-2">Price</p>{" "}
                <p className="px-2 hidden md:block text-center font-bold pt-2">VAT<span className="text-sm font-normal">{' '}(15%)</span></p>
                <p className="px-2 hidden md:block text-center font-bold pt-2">Discount</p>{" "}
                <p className="px-2 text-center font-bold pt-2">Final Price</p>{" "}
                <div />
                {cartItems && Array.isArray(cartItems) && cartItems.map((item, i) => (
                    <React.Fragment key={i}>
                        <HelpTextWrapper hideHelp={!(item.misc && isValidJson(item.misc) && JSON.parse(item.misc)[0] && JSON.parse(item.misc)[0].description)} helpText={item.misc && isValidJson(item.misc) && JSON.parse(item.misc)[0] && JSON.parse(item.misc)[0].description}>
                            <p className="px-2 text-left underline">{item.misc && isValidJson(item.misc) && JSON.parse(item.misc)[0] && JSON.parse(item.misc)[0].name}</p>{" "}
                        </HelpTextWrapper>
                        <p className="px-2 text-left">{item.international_book_name ? item.international_book_name : item.local_book_name ? item.local_book_name : 'N/A'}</p>{" "}
                        {/* <p className="px-2 text-center">{item.misc && isValidJson(item.misc) && JSON.parse(item.misc)[0] && JSON.parse(item.misc)[0].description}</p>{" "} */}
                        <p className="px-2 hidden md:block text-center align-self-center">R{(item.misc && isValidJson(item.misc) && JSON.parse(item.misc)[0] && JSON.parse(item.misc)[0].cost.toFixed(2))}</p>
                        <p className="px-2 hidden md:block text-center align-self-center">R{getVatValue(isValidJson(item.misc) && JSON.parse(item.misc)[0] && JSON.parse(item.misc)[0].cost.toFixed(2))}</p>
                        <p className="px-2 hidden md:block text-center align-self-center">{getDiscount(item)}</p>
                        <p className="px-2 text-center align-self-center">R {getFinalPrice(item)}</p>
                        <div className="w-full flex justify-end cursor-pointer" onClick={() => onRemoveClick(item.misc && isValidJson(item.misc) && JSON.parse(item.misc)[0] && JSON.parse(item.misc)[0].name, item.book_id)}>
                            <Icon name="highlight_off" color="red" size="xl" />
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className="h-8" />
            {couponDetails && couponDetails.type && couponDetails.amount && <div className="flex gap-4 w-full justify-items-stretch mt-4">
                {/* <div/> */}
                {/* <div/> */}
                <h2 className="text-2xl mt-4 text-left">
                    Coupon{" "}
                </h2>
                <h2 className="text-2xl mt-4 text-right">
                    <span className="font-light">{'-' + getCouponDetails()}</span>
                </h2>
            </div>}
            <div className="flex gap-4 w-full justify-items-stretch">
                {/* <div/> */}
                {/* <div/> */}
                <h2 className="text-xl mb-2 mt-4 text-left">
                    Total <span className="text-lg font-light">(excl. VAT)</span>{" "}
                </h2>
                <h2 className="justify-self-end ml-auto text-xl mb-2 mt-4 text-right">
                    <span className="font-light"> R{" "}{getPriceExclVat(getTotal())}</span>
                </h2>
            </div>
            <div className="flex gap-4 w-full justify-items-stretch">
                {/* <div/> */}
                {/* <div/> */}
                <h2 className="text-xl mb-2 mt-4 text-left">
                    VAT{" "}
                </h2>
                <h2 className="justify-self-end ml-auto text-xl mb-2 mt-4 text-right">
                    <span className="font-light"> R{" "}{getVatValue(getTotal())}</span>
                </h2>
            </div>
            <div className="flex gap-4 w-full justify-items-stretch">
                {/* <div/> */}
                <h2 className="text-xl mb-2 mt-4 text-left">
                    Cart Total <span className="text-lg font-light">(incl. VAT)</span>{" "}
                </h2>
                <h2 className="justify-self-end ml-auto text-xl mb-2 mt-4 text-right">
                    <span className="font-light">R{" "}{getTotal()}</span>
                </h2>
            </div>
            <div className="flex gap-4 w-full justify-items-stretch">
                {/* <div/> */}
                {/* <div/> */}
                <h2 className="text-xl mb-2 mt-4 text-left">
                    Your Balance{" "}
                </h2>
                <h2 className="justify-self-end ml-auto text-xl mb-2 mt-4 text-right">
                    <span className="font-light"> R{" "}{getUserBalance()}</span>
                </h2>
            </div>
            <div className="flex gap-4 w-full justify-items-stretch">
                {/* <div/> */}
                {/* <div/> */}
                <h2 className="text-xl mb-2 mt-4 text-left">
                    Payable{" "}
                </h2>
                <h2 className="justify-self-end ml-auto text-xl mb-2 mt-4 text-right">
                    <span className="font-light">R{" "}{getPayable()}</span>
                </h2>
            </div>
            <div className="h-8" />
            {total > 0 && <CouponDropdown setCouponDetails={setCouponDetails} setCouponId={setCouponId} removeCoupon={removeCoupon} />}
            <div className="h-10" />
            <>
                <Button
                    color="red"
                    className="w-full"
                    buttonType="filled"
                    size="lg"
                    ripple="dark"
                    onClick={() => onProceedClick()}

                >
                    Proceed
                </Button>
                <div className="h-5" />
                {getPayable() > 0 ? (
                    <p className="text-black text-lg">You need an additional {' '}<b>R {getPayable()}</b>{' '}. Clicking proceed will take you to our payments page where you will be able to pay the balance.</p>
                ) : (
                    <p className="text-black text-lg">Clicking proceed will deduct {' '}<b>R {getTotal()}</b>{' '} from your balance and submit your book to our proofing team.</p>
                )}
            </>
        </div>
    )
}
