import {useState, useRef} from 'react';

// import Paragraph from '@material-tailwind/react/Paragraph';
// import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import Modal from 'components/general/Modal';

import Payfast from 'components/payments/Payfast';


import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {useGlobalState} from 'state-pool';
import {useFlutterwavePayment} from 'hooks/useFlutterwave';

export default function SelectPaymentMethod({stage, pagePathname, paymentMethod, setPaymentMethod, completed, setCompleted, costPerBook, title}) {
    const helpRef = useRef();
    const [currentUser] = useGlobalState('currentUser');
    const [showPaypal, setShowPaypal] = useState(false);

    const OPTIONS = [
        {
            text: "Credit card",
            helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
            type: 'credit-card',
            logo: '/images/logos/flutterwave.png',
            onClick: (type) => {
                setPaymentMethod(type);
                executeFlutterwavePayment();
            },
            brand: 'Flutterwave',
        },
        {
            text: "EFT",
            helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
            type: 'eft',
            logo: '/images/logos/payfast.png',
            onClick: (type) => {
                setPaymentMethod(type);
            },
            brand: 'Payfast',
        },
        {
            text: "Paypal",
            helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
            type: 'papal',
            logo: '/images/logos/paypal.png',
            onClick: (type) => {
                setPaymentMethod(type);
                setShowPaypal(true);
            },
            brand: 'Paypal',
        },
    ];

    const executeFlutterwavePayment = useFlutterwavePayment(Number(costPerBook.replace('R', '').replace('$', '').trim()), currentUser && currentUser.email, '0813225224', currentUser && currentUser.name_first, title);

    // const onContinueClick = () => {
    //     history.push(`/${pagePathname}/submission-complete`);
    //     setCompleted([...completed, stage]);
    // }

    return (
        <>
            <div className="w-full text-left">
                <h3 className="text-4xl font-bold ">Select Payment Method</h3>
                <div className="h-2" />
                    <p className="text-gray-600 ml-1 text-xl leading-normal">Choose a payment method to proceed</p>
                    <div className="h-16" />
                    <div className="flex flex-col md:flex-row mb-5 w-full">
                        {currentUser && OPTIONS.map(({text, helpText, type, logo, brand, onClick}, i) => (
                            type === "eft" ? <Payfast i={i} name={currentUser.name_first} email={currentUser.email} userId={currentUser.id} amount={costPerBook} itemName={title} passPhrase="passphrase" key={i} type={type} helpText={helpText} helpRef={helpRef} brand={brand} logo={logo} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} text={text} /> :
                            <div key={i} onClick={() => onClick(type)} className={`w-full ${i !== 0 ? "md:ml-5" : ""}`}>
                                <div className={`w-full rounded-xl mb-5 md:mb-auto overflow-hidden p-4 shadow-2xl cursor-pointer bg-white relative`}>
                                    <div className="absolute top-5 right-5">
                                        <span ref={helpRef} className="ml-2 text-gray-500">
                                            <Icon className="ml-2" name="help" size="3xl" color="gray" />
                                        </span>
                                        <Tooltips placement="bottom" ref={helpRef}>
                                            <TooltipsContent>{helpText}</TooltipsContent>
                                        </Tooltips>
                                    </div>
                                    <div className="flex items-center justify-center flex-col py-1 h-auto md:h-40">
                                        <img alt={brand} src={logo} className="h-36 w-auto" />
                                    </div>
                                </div>
                                <p className="font-bold text-xl text-black my-5">{text}{" "}<span className="font-light text-gray-500 text-sm">with {brand}</span></p>
                            </div>
                        ))}
                    </div>
                <div className="h-5" />
                {/* <Button
                    color="red"
                    className="hover:bg-red hover:text-white hover:bg-red w-full hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onContinueClick}
                >
                    Continue
                    <Icon name="arrow_forward" size="sm" />
                </Button> */}
            </div>
            <Modal 
                showModal={showPaypal}
                setShowModal={setShowPaypal}
                title=" "
                size="sm"
            > 
                <PayPalScriptProvider options={{ "client-id": "test" }}>
                    <PayPalButtons style={{ layout: "horizontal" }} />
                </PayPalScriptProvider>
            </Modal>
        </>
    );
}
