import {useRef} from 'react';
// import { createSignature, generateSignature } from 'utils/payfast';

import Icon from '@material-tailwind/react/Icon';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";

import md5 from 'md5';

export default function Payfast({name, email, userId, amount, itemName, description, type, helpText, brand, logo, paymentMethod, setPaymentMethod, helpRef, i, text}) {
    const submitRef = useRef();
    const at = localStorage.getItem('access-token');

    const tref = localStorage.getItem('last_transaction_ref'); 
    const paymentsUrlSuccess = window.location.origin + `/checkout/success?token=${md5(tref + String(userId) + md5(at))}&id=${userId}&tref=${tref}`;
    const paymentsUrlCancel = window.location.origin + `/checkout/cancelled?token=${md5(tref + String(userId) + md5(at))}&id=${userId}&tref=${tref}`;

    // /posttransaction/:txref/:token/:status
    // success | failed | cancelled
    // returns tokens

    return (
        <div className={`w-full ${i !== 0 ? "md:ml-5" : ""}`} onClick={() => {submitRef && submitRef.current && submitRef.current.click(); setPaymentMethod(type)}}>
                <div className={`w-full rounded-xl mb-5 md:mb-auto p-4 shadow-2xl cursor-pointer bg-white relative ${paymentMethod === type ? "border-2 border-red-500" : ""}`}>
                    <div className="absolute top-5 right-5 w-40 flex justify-end">
                        <span ref={helpRef} className="ml-2 text-gray-500">
                            <Icon className="ml-2" name="help" size="3xl" color="gray" />
                        </span>
                        <Tooltips placement="top" ref={helpRef}>
                            <TooltipsContent>{helpText}</TooltipsContent>
                        </Tooltips>
                    </div>
                    <div className="flex items-center justify-center flex-col py-1 h-auto md:h-40">
                        <img alt={brand} src={logo} className="h-36 w-auto" />
                    </div>
                </div>
                <p className="font-bold text-xl text-black my-5">{text}{" "}<span className="font-light text-gray-500 text-sm">with {brand}</span></p>
                <form action={`${process.env.REACT_APP_PAYFAST_URL}/eng/process`} method="POST" target="_blank">
                    <input type="hidden" name="merchant_id" value={process.env.REACT_APP_PAYFAST_MERCHANT_ID} />
                    <input type="hidden" name="merchant_key" value={process.env.REACT_APP_PAYFAST_MERCHANT_KEY} />
                    <input type="hidden" name="return_url" value={paymentsUrlSuccess} />
                    <input type="hidden" name="cancel_url" value={paymentsUrlCancel} />
                    <input type="hidden" name="notify_url" value={paymentsUrlSuccess} />
                    <input type="hidden" name="name_first" value={name} />
                    <input type="hidden" name="email_address" value={email} />
                    <input type="hidden" name="m_payment_id" value={userId} />
                    <input type="hidden" name="amount" value={amount && amount.replace('R', '').replace('$', '').trim()} />
                    <input type="hidden" name="item_name" value={itemName} />
                    <input type="hidden" name="item_description" value={description} />
                    <input type="hidden" name="passphrase" value={process.env.REACT_APP_PAYFAST_PASSPHRASE} />
                    <input style={{visibility: 'hidden'}} name="disable" type="submit" disabled={false} alt="Submit Payfast Payment" value="Purchase" ref={submitRef} />
                </form>
        </div>
    )
}
