/*In the page we are going to have two columns Current Details which will come from currentUser.misc which is a global state called currentUser and another column called New Changes 
and that will come from currentUser.pending_changes. We will compare the two and see what changes there are. We want to display the difference between each field then. We need two buttons one for approved and one for declined*/
import React, { useState, useEffect } from 'react';
import { Input, Button, Icon, Card, CardBody, CardFooter, Paragraph } from '@material-tailwind/react';
import { setPageTitle } from 'utils';
import Chat from 'components/chat/Chat';
import { podApi } from 'api/pod';
import { useHistory } from 'react-router-dom';
import Modal from 'components/general/Modal'



export default function ProfileConfirmation({ setShowSidebar }) {

    const [loading, setLoading] = useState(false);
    const [completeModal, setCompleteModal] = useState(false);
    const [failedModal, setFailedModal] = useState(false);

    const userDetails = JSON.parse(localStorage.getItem('currentUser'))
    let pendingChanges = JSON.parse(userDetails.pending_changes);
    let pendingUserDetails = pendingChanges.pendingUserChanges;
    let pendingMisc =  pendingChanges;//JSON.parse(userDetails.misc);

    function onApprovedCLick() {
        setLoading(true);
        //Log out the user and redirect to login page 
        //Also make a call to the backend to update the user details 
        podApi.put('/user/userapproved', userDetails).then(res => {
            if (res.status == 200) {
                setLoading(false);
                setCompleteModal(true);
            } else {
                setLoading(false);
                setFailedModal(true);
            }
        }
        )
    }
    //Logging out the user and redirecting to login page
    function handleCloseModal() {
        sessionStorage.removeItem('currentUser');
        window.location.href = '/login';
    }

    useEffect(() => {
        setPageTitle('Profile Confirmation');
        setShowSidebar(false);
    }, []);

    return (
        <div className="bg-image  px-3 md:px-8 flex items-center justify-center flex-col h-full">
            <div className="max-w-3xl text-center w-full bg-white p-8 z-30 rounded-xl shadow-md-grey mt-20 mb-20">
                <div className="w-full flex justify-center py-2">
                    <img alt="logo" src="/logo.png" className="h-20 w-auto" />
                </div>
                <div className="h-2" />
                <Paragraph>
                    Please review the recent updates made to your account with careful consideration. Kindly note that upon approving these modifications, you will be required to re-sign any existing contracts.<br></br>Thank you for your cooperation.</Paragraph>
                <div className="h-2" />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center">
                    <div className="border border-black rounded-lg p-4">
                        <h2 className="text-lg font-bold mb-2">User Details</h2>
                        <p className="mb-1">
                            <span className="font-bold">First Name:</span> {pendingUserDetails && pendingUserDetails.name_first ? pendingUserDetails.name_first : "No Changes Made"}
                        </p>
                        <p className="mb-1">
                            <span className="font-bold">Last Name:</span> {pendingUserDetails && pendingUserDetails.name_last ? pendingUserDetails.name_last : "No Changes Made"}
                        </p>
                        <p className="mb-1">
                            <span className="font-bold">Username:</span> {pendingUserDetails && pendingUserDetails.username ? pendingUserDetails.username : "No Changes Made"}
                        </p>
                        <p className="mb-1">
                            <span className="font-bold">Email:</span> {pendingUserDetails && pendingUserDetails.email ? pendingUserDetails.email : "No Changes Made"}
                        </p>
                        <p className="mb-1">
                            <span className="font-bold">Phone Number:</span> {pendingUserDetails && pendingUserDetails.phone_main ? pendingUserDetails.phone_main : "No Changes Made"}
                        </p>
                    </div>
                    
                    <div className="border border-black rounded-lg p-4">
                        <h2 className="text-lg font-bold mb-2">Signing Details</h2>
                        <p className="mb-1">
                            <span className="font-bold">Publisher Name:</span> {pendingMisc && pendingMisc.bankInfo.publish_name ? pendingMisc.bankInfo.publish_name : "No Changes Made"}
                        </p>
                        <p className="mb-1">
                            <span className="font-bold">Publisher's Email:</span> {pendingMisc && pendingMisc.bankInfo.email ? pendingMisc.bankInfo.email : "No Changes Made"}
                        </p>
                        <p className="mb-1">
                            <span className="font-bold">Publisher's Number:</span> {pendingMisc && pendingMisc.bankInfo.contact_person ? pendingMisc.bankInfo.contract_person : "No Changes Made"}
                        </p>
                        <p className="mb-1">
                            <span className="font-bold">Contact Name:</span> {pendingMisc && pendingMisc.bankInfo.contact_name ? pendingMisc.bankInfo.contact_name : "No Changes Made"}
                        </p>
                        <p className="mb-1">
                            <span className="font-bold">Business Registration Number:</span> {pendingMisc && pendingMisc.bankInfo.reg_id_no ? pendingMisc.bankInfo.reg_id_no : "No Changes Made"}
                        </p>
                        <p className="mb-1">
                            <span className="font-bold">Postal Address:</span> {pendingMisc && pendingMisc.bankInfo.postal_address ? pendingMisc.bankInfo.postal_address : "No Changes Made"}
                        </p>
                        <p className="mb-1">
                            <span className="font-bold">Physical Address:</span> {pendingMisc && pendingMisc.bankInfo.physical_address ? pendingMisc.bankInfo.physical_address : "No Changes Made"}
                        </p>
                    </div>
                    <div className="border border-black rounded-lg p-4">
                        <h2 className="text-lg font-bold mb-2">Banking Details</h2>
                        {pendingMisc.bankInfo.bank_name && pendingMisc.bankInfo.bank_name && (
                            <p className="mb-1">
                                <span className="font-bold">Bank Name:</span> {pendingMisc && pendingMisc.bankInfo.bank_name ? pendingMisc.bankInfo.bank_name : "No Changes Made"}
                            </p>
                        )}
                        {pendingMisc && pendingMisc.bankInfo.brance_code && (
                            <p className="mb-1">
                                <span className="font-bold">Branch Code:</span> {pendingMisc && pendingMisc.bankInfo.brance_code ? pendingMisc.bankInfo.brance_code : "No Changes Made"}
                            </p>
                        )}
                        {pendingMisc && pendingMisc.bankInfo.account_name && (
                            <p className="mb-1">
                                <span className="font-bold">Account Holder Name:</span> {pendingMisc && pendingMisc.bankInfo.account_name ? pendingMisc.bankInfo.account_name : "No Changes Made"}
                            </p>
                        )}
                        {pendingMisc && pendingMisc.bankInfo.account_number && (
                            <p className="mb-1">
                                <span className="font-bold">Account Number:</span> {pendingMisc && pendingMisc.bankInfo.account_number ? pendingMisc.bankInfo.account_number : "No Changes Made"}
                            </p>
                        )}
                        {pendingMisc && pendingMisc.bankInfo.account_type && (
                            <p className="mb-1">
                                <span className="font-bold">Account Type:</span> {pendingMisc && pendingMisc.bankInfo.account_type ? pendingMisc.bankInfo.account_type : "No Changes Made"}
                            </p>
                        )}
                        {pendingMisc && pendingMisc.bankInfo.international_iban_number && (
                            <p className="mb-1">
                                <span className="font-bold">IBAN Number:</span> {pendingMisc && pendingMisc.bankInfo.international_iban_number ? pendingMisc.bankInfo.international_iban_number : "No Changes Made"}
                            </p>
                        )}
                        {pendingMisc && pendingMisc.bankInfo.international_swift_code && (
                            <p className="mb-1">
                                <span className="font-bold">Swift Code:</span> {pendingMisc && pendingMisc.bankInfo.international_swift_code ? pendingMisc.bankInfo.international_swift_code : "No Changes Made"}
                            </p>
                        )}
                        {pendingMisc && pendingMisc.international_bank_address && (
                            <p className="mb-1">
                                <span className="font-bold">Bank Address:</span> {pendingMisc && pendingMisc.bankInfo.international_bank_address ? pendingMisc.bankInfo.international_bank_address : "No Changes Made"}
                            </p>
                        )}
                        {pendingMisc && pendingMisc.paypal && (
                            <p className="mb-1">
                                <span className="font-bold">Paypal:</span> {pendingMisc && pendingMisc.bankInfo.paypal ? pendingMisc.bankInfo.paypal : "No Changes Made"}
                            </p>
                        )}
                    </div>
                </div>
                <div className="h-10" />
                <div className="flex flex-row justify-between gap-4">
                    <Button
                        color="red"
                        className="border-black-50 w-full hover:text-white hover:bg-red hover:shadow-xl"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onApprovedCLick}
                    >
                        Approve
                    </Button>
                </div>
                <Chat />
                <div className="h-5" />
                <Paragraph>
                    You will be logged out after you have approved the changes.
                </Paragraph>
            </div>
            {/* Loading Modal , loading gif*/}
            <Modal
                showModal={loading}
                setShowModal={setLoading}
                onClose={() => setLoading(false)}
                title="Please wait..."
                size="lg"
            >
                <div className="flex justify-center">
                    <img alt="loading" className="h-10 w-auto" src="/images/loading.gif" />
                </div>
            </Modal>
            {/* Completion modal, if this user exits or close this tab they will be redirect to profile */}
            <Modal
                showModal={completeModal}
                setShowModal={setCompleteModal}
                onClose={() => handleCloseModal()}
                size="sm"
            >
                <div className="flex items-center justify-between mb-2">
                    <h3 className="text-2xl font-bold mb-2 mr-2">Your request has been successfully submitted.</h3>
                    <button
                        onClick={() => handleCloseModal()}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                        <Icon name="close" size="md" />
                    </button>
                </div>
                <div className="flex justify-center">
                    <img alt="loading" className="h-64 w-auto" src="/images/complete_tick.gif" />
                </div>
            </Modal>

            {/* Failed to update modal this can open and close */}
            <Modal
                showModal={failedModal}
                setShowModal={setFailedModal}
                onClose={() => setFailedModal(false)}
                size="sm"
            >
                <div className="flex items-center w-full justify-between">
                    <h3 className="w-full whitespace-wrap text-2xl font-bold mb-2 mr-2">Request failed </h3>
                    <button
                        onClick={() => setFailedModal(false)}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                        <Icon name="close" size="md" />
                    </button>
                </div>
                <p>We apologize for the inconvenience, but it seems that there was an issue with submitting your request. Please kindly try again later.</p>
                <div className="h-4" />
                <div className="flex justify-center">
                    <img alt="loading" className="h-64 w-auto" src="/images/exit.gif" />
                </div>
            </Modal>
        </div >
    )
}