import md5 from 'md5'

export const requestNotificationPermission = () => {
  if (Notification.permission === 'granted') {
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        new Notification('Notification enabled')
      }
    })
  }
}

export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

export function isValidJson(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function isValidDate(d: Date) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    // it is a date
    if (isNaN(d.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return false
    } else {
      // date is valid
      return true
    }
  } else {
    // not a date
    return false
  }
}

export const isValidEmail = (email: string) => {
  if (
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  )
    return true

  return false
}

export function capitalize(str: string) {
  if (!str) return ''

  return str[0].toUpperCase() + str.slice(1)
}

export function capitalizeSentence(str: string) {
  if (str && typeof str === 'string' && str.includes(' ')) {
    const strArr = str.split(' ')

    const capitalizedArr = strArr.map((w) => w[0].toUpperCase() + w.slice(1))

    return capitalizedArr.join(' ')
  }

  return str[0].toUpperCase() + str.slice(1)
}

export function parseQueryString() {
  let str = window.location.search.replaceAll('%20', ' ')
  let objURL = {}

  str.replace(
    new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
    // @ts-ignore
    function ($0, $1, $2, $3) {
      // @ts-ignore
      objURL[$1] = $3
    },
  )
  return objURL
}

export function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function numberWithCommas(x: number) {
  return x
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function scrollToBottomOfChat() {
  const messagesEl = document.querySelector('#messagesScroll')

  if (messagesEl) {
    messagesEl.scrollTop = messagesEl.scrollHeight
  }
}

export function openChat() {
  const chatEl: HTMLDivElement | null = document.querySelector('.chat button')

  if (chatEl) {
    chatEl.click()

    scrollToBottomOfChat()
  }
}

export function getWordCount(str: string) {
  if (!str || typeof str !== 'string') return 0

  return str.trim().split(/\s+/).length
}

export async function getImageDimensions(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    let dimensions = {
      height: 0,
      width: 0,
    }

    img.onload = () => {
      const height = img.height
      const width = img.width

      dimensions.height = height
      dimensions.width = width

      resolve(dimensions)
    }

    img.src = url
  })
}

export const isEmptyArray = (arr: any) => Array.isArray(arr) && arr.length === 0

export function setPageTitle(sectionTitle: string, pageTitle: string) {
  if (
    sectionTitle &&
    typeof sectionTitle === 'string' &&
    (!pageTitle || (typeof pageTitle === 'string' && pageTitle === ''))
  ) {
    const pageTitleEl = document.querySelector('title')

    if (pageTitleEl && typeof pageTitleEl.innerText === 'string') {
      pageTitleEl.innerText = 'Print on Demand | ' + sectionTitle

      return
    }
  }

  if (
    sectionTitle &&
    typeof sectionTitle === 'string' &&
    pageTitle &&
    typeof pageTitle === 'string'
  ) {
    const pageTitleEl = document.querySelector('title')

    if (pageTitleEl && typeof pageTitleEl.innerText === 'string') {
      pageTitleEl.innerText =
        'Print on Demand | ' + sectionTitle + ' - ' + pageTitle

      return
    }
  }

  return
}

export const getMd5 = (str: string) => md5(str)

export function wordLimiter(
  value: string,
  oldValue: string,
  limit: number,
  setValue: (val: string) => void,
) {
  const wordCount = getWordCount(value)

  switch (true) {
    case wordCount > limit && value.length <= oldValue.length: {
      setValue(value)

      break
    }
    case wordCount > limit && value.length > oldValue.length: {
      break
    }
    default: {
      setValue(value)

      break
    }
  }
}

export function lengthLimiter(
  value: string,
  oldValue: string,
  limit: number,
  setValue: (val: string) => void,
) {
  const charCount = value.length

  switch (true) {
    case charCount > limit && value.length <= oldValue.length: {
      setValue(value)

      break
    }
    case charCount > limit && value.length > oldValue.length: {
      break
    }
    default: {
      setValue(value)

      break
    }
  }
}

export function resolveBookFormatName(str: string) {
  switch (true) {
    case str === 'audiobook':
      return 'Audiobook'
    case str === 'ebook':
      return 'eBook'
    default:
      return str
  }
}

export const removeDashes = (str: string) => {
  if (str && typeof str === 'string') {
    return str.replace(/-/g, '')
  }

  return ''
}

export const removeDashesAndSpaces = (str: string) => {
  if (str && typeof str === 'string') {
    const removedDashes = str.replace(/-/g, '')

    const removedSpaces = removedDashes.replace(/ /g, '').trim()

    return removedSpaces
  }

  return ''
}

export function csvJSON(csv: any) {
  var lines = csv.split('\n')

  var result = []

  // NOTE: If your columns contain commas in their values, you'll need
  // to deal with those before doing the next step
  // (you might convert them to &&& or something, then covert them back later)
  // jsfiddle showing the issue https://jsfiddle.net/
  var headers = lines[0].split(',')

  for (var i = 1; i < lines.length; i++) {
    var obj = {}
    var currentline = lines[i].split(',')

    for (var j = 0; j < headers.length; j++) {
        // @ts-ignore
      obj[headers[j].trim()] = currentline[j]
    }

    result.push(obj)
  }

  //return result; //JavaScript object
  return JSON.stringify(result) //JSON
}

export function toUtf8(str: string) {
  return decodeURIComponent(escape(window.atob(str)))
}
