import { useState, useEffect } from 'react';

import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import Modal from 'components/general/Modal';

import { Link, useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

import { setPageTitle, parseQueryString } from 'utils';
import Success from 'components/cart/Success';
import { clearSiteData } from 'utils/site-cleanup';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);

    const [error, setError] = useState({});
    const [incorrectDetailsShow, setIncorrectDetailsShow] = useState(false);
    const { login } = useAuth();
    const history = useHistory();

    //<--S.O--> Function to clear complete cache data//
    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };
    //<--S.O--> Function to clear complete cache data

    //<--S.O--> Function add data to the cache for testing//
    const addCacheData = async (cacheList) => {
        for (var i = 0; i < cacheList.length; i++) {
            // Converting our response into Actual Response form
            const data = new Response(JSON.stringify(cacheList[i].cacheData));
            if ('caches' in window) {
                // Opening given cache and putting our data into it
                var cache = await caches.open(cacheList[i].cacheName)
                cache.put(cacheList[i].url, data);
            }
        }
    };
    const CacheToBeStored = [
        {
            //Testing data for cache
            cacheName: 'CacheOne', cacheData: '1 CacheData',
            url: 'https://localhost:300'
        },

    ]
    // This is what clear the data on login 
    localStorage.setItem('currentUser', '');

    //<--S.O--> Function add data to the cache for testing//

    useEffect(() => {
        setPageTitle('Login');
        clearSiteData();
    }, []);

    // useEffect(() => {
    //     const emailInput = document.querySelector('#login-email');

    //     if(emailInput) {
    //         emailInput.focus();
    //         emailInput.click();
    //     }
    // }, [email, password]);


    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                onLoginClick();
            }
        };
        document.removeEventListener("keydown", listener);
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [email, password, error]);

    async function onLoginClick() {
        let tempErrors = {};

        if (!email) {
            tempErrors['email'] = 'Please enter your email address';
        }

        if (!password) {
            tempErrors['password'] = 'Please enter your password';
        }

        if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }

        const isSuccessful = await login(email, password);
        if (isSuccessful) {
            setTimeout(() => {
                // if(isSuccessful && isSuccessful === 1) history.push('/renewal');
                const pageQueries = parseQueryString();

                if (pageQueries && pageQueries.bookID && pageQueries.secret && pageQueries.islocal) {
                    history.push('/resign-contract' + window.location.search);
                    return;
                }
                //window.location.href = '/resign-contract';
                clearCacheData();
            }, 500);
            history.push('/dashboard');
        }

        if (!isSuccessful) {
            setIncorrectDetailsShow(true);
            addCacheData(CacheToBeStored);
        }

        return;
    }

    function clearError(key) {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError(tempErrors);
        }

        return;
    }

    return (
        <div className="bg-image h-screen px-3 md:px-8 flex items-center justify-center flex-col relative overflow-hidden">
            <div className="h-2" />
            <div className="max-w-3xl text-center w-full bg-white p-8 z-30 rounded-xl shadow-md-grey">
                <div className="w-full flex justify-center py-2">
                    <img alt="logo" src="/logo.png" className="h-20 w-auto" />
                </div>
                <div className="h-2" />
                <Paragraph>
                    Welcome to Print on Demand. <br></br> Enter your email and password to continue.
                </Paragraph>
                <div className="h-2" />

                <form>
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            autoComplete="username"
                            type="text"
                            color="red"
                            error={error && error.email}
                            placeholder="Email"
                            size="regular"
                            onFocus={() => clearError('email')}
                            id="login-email"
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <div className='flex'>
                            <Input
                                outline={false}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type={passwordShown ? "text" : "password"}
                                autoComplete="new-password"
                                color="red"
                                error={error && error.password}
                                placeholder="Password"
                                size="regular"
                                onFocus={() => clearError('password')}
                                id="login-pw"
                            />
                            <div className='ml-5'>
                                <Button
                                    color="red"
                                    buttonType="filled"
                                    type="button"
                                    size="regular"
                                    rounded={false}
                                    block={false}
                                    icononly="true"
                                    ripple="light"
                                    onClick={togglePassword}
                                >
                                    {!passwordShown ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                        </svg>
                                    )
                                    }

                                </Button>
                            </div>
                        </div>
                        <Link className="text-red float-right mt-2 underline" to="/forgot-password">Forgot your password?</Link>
                    </div>
                </form>
                <div className="h-10" />
                <Button
                    color="red"
                    className="border-black-50 w-full hover:text-white hover:bg-red hover:shadow-xl hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onLoginClick}
                >
                    Login
                    <Icon name="arrow_forward" size="sm" />
                </Button>
                <div className="h-5" />
                <Paragraph>
                    Need an account? <Link className="text-red" to="/register">Register</Link>
                </Paragraph>
            </div>
            <Modal
                showModal={incorrectDetailsShow}
                setShowModal={setIncorrectDetailsShow}
                size="sm"
            >
                <div className="p-5">
                    <h3 className="font-light text-xl mb-5">
                        Login unsuccessful
                    </h3>
                    <Paragraph>
                        The email or password you entered is incorrect or you haven't registered yet. Either{" "}
                        <Link className="text-red cursor-pointer text-base font-normal" to="/register">register</Link>{" "}or{" "}
                        <span className="text-red cursor-pointer font-normal" to="/register" onClick={() => setIncorrectDetailsShow(false)}>try again</span>.
                    </Paragraph>
                    <div className="h-5" />
                    <Button
                        color="red"
                        className="w-full"
                        buttonType="filled"
                        size="lg"
                        ripple="dark"
                        onClick={() => setIncorrectDetailsShow(false)}
                    >
                        Okay
                    </Button>
                </div>
            </Modal>
        </div>
    );
}
