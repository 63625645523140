import { useState, useEffect } from 'react';
import Alert from "@material-tailwind/react/ClosingAlert";

const OfflineAlert = () => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(true);
    setTimeout(() => {
        setShowToast(false);
    }, [1]);

    window.addEventListener('online', () => setShowToast(false));
    window.addEventListener('offline', () => setShowToast(true));

  }, []);

  return (
        <>
            {showToast && (
                <Alert style={style} color={'red'} onClick={() => setShowToast(false)}>
                    <span>
                        <b>No Internet Connection</b> You are currently in offline mode. Some features may not work as expected.
                    </span>
                </Alert>
            )}
        </>
  );
};

const style = {
    position: "fixed", 
    bottom: "-1em", 
    width: "100%", 
    zIndex: 9999
}

export default OfflineAlert;