import {useEffect} from 'react';
import {setPageTitle} from 'utils';

import Main from 'components/support/Main';

export default function Support() {
    useEffect(() => {
        setPageTitle('Support');
    }, []);

    return (
        <>
            <div className="bg-transparent px-3 md:px-8 h-24" />

            <div className="px-3 md:px-8 -mt-20">
                <div className="container mx-auto max-w-full">
                    <Main />
                </div>
            </div>
        </>
    );
}
