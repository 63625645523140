import {useRef} from 'react';

import Tooltips from '@material-tailwind/react/Tooltips';
import TooltipsContent from '@material-tailwind/react/TooltipsContent';
import Icon from '@material-tailwind/react/Icon';

const HelpText = ({children, helpText, iconSize, iconColor, className, placement, iconClassName}) => {
    const helpRef = useRef();

    return (
        <div className={`flex ${className}`}>
            {children}
            <span ref={helpRef} className={`ml-2 text-gray-500 h-5 cursor-pointer relative ${iconClassName ? iconClassName : "top-1"}`}>
                <Icon className="ml-2" name="info" size={iconSize ? iconSize : "sm"} color={iconColor ? iconColor : "gray"} />
            </span>
            
            <Tooltips placement={placement ? placement : "right"} ref={helpRef}>
                <div style={{width: 200}}>
                    <TooltipsContent>{helpText}</TooltipsContent>
                </div>
            </Tooltips>
        </div>
        )
};

export default HelpText;