import {useState, useEffect} from 'react';

import Dropdown from "@material-tailwind/react/Dropdown";
import Input from "@material-tailwind/react/Input";

import { DayPicker } from 'react-day-picker';
import {format} from 'date-fns';

import {isValidDate} from 'utils';

import 'react-day-picker/dist/style.css';

export default function DayPickerComponent({day, placeholder, setDay, color, error, onFocus, size, id, ...rest}) {
    const [rerender, setRerender] = useState(true);

	const today = new Date();
	let year = today.getFullYear();

    const _onDayClick = (day) => {
        setDay(day);

        resetDropdown();
    }

    function resetDropdown() {
        setRerender(!rerender);
    }

    useEffect(() => {
        if(!rerender) setRerender(true);
    }, [rerender]);

    function getDisplayText() {
        switch(true) {
            case !day && placeholder: return placeholder;
            case !!day: return format(new Date(day), 'yyyy-MM-dd');
            default: return 'Pick a day';
        }
    }

    const displayText = getDisplayText();

    function showDayPicker() {
        const dayPickerEl = document.querySelector(`#day-picker-${id}`);

        if(dayPickerEl) dayPickerEl.click();
    }

    return (
        <>
            <Input
                outline={false}
                value={day && isValidDate(new Date(day)) && format(new Date(day), 'yyyy-MM-dd')}
                type="text"
                color={color}
                size={size}
                placeholder={placeholder}
                onClick={showDayPicker}
                onFocus={onFocus}
                error={error}
                readOnly={true}
                {...rest}
            />
            <div className="h-4">
                {rerender && (
                    <>
                        <Dropdown
                            color={color ? color : 'green'}
                            placement="bottom-start"
                            buttonText={displayText}
                            id={`${`day-picker-${id}`}`}
                            buttonType="link"
                            size="regular"
                            rounded={false}
                            block={false}
                            ripple="light"
                            className="hover:bg-green hover:text-white invisible h-0 p-0 m-0"
                        >
                            <DayPicker  
                                onDayClick={_onDayClick}
								captionLayout="dropdown-buttons" 
								fromYear={1970} 
								toYear={year}
								fixedWeeks
								showOutsideDays
                            />
                        </Dropdown>
                    </>
                )}
            </div>
        </>
    )
}
