import { useState, useCallback } from 'react';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import { useHistory } from 'react-router-dom';

import { updatePassword } from 'routes/userRoutes';
import { useAuth } from 'hooks/useAuth';
import { useAlert } from 'hooks/useAlert';

export default function ChangePassword() {
    // const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState({});
    const { logout } = useAuth();
    const { newAlert } = useAlert();

    const history = useHistory();

    const clearError = useCallback((key) => {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError({ ...tempErrors });
        }

        return;
    }, [error]);

    function onDoneClick() {
        let tempErrors = {};

        // if(!oldPassword) {
        //     tempErrors.oldPassword = 'Please enter your existing/old password';
        // }

        if (!newPassword) {
            tempErrors.newPassword = 'Please enter your new password';
        }

        if (!confirmNewPassword) {
            tempErrors.confirmNewPassword = 'Please confirm your new password';
        }

        if (newPassword !== confirmNewPassword) {
            if (confirmNewPassword) {
                tempErrors.confirmNewPassword = 'Passwords must match';
            }
        }

        if (newPassword === confirmNewPassword) {
            let passwordCheck = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
            if (!passwordCheck.test(newPassword)) {
                tempErrors.confirmNewPassword = 'Your new password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and must be a minimum of eight characters long.';
            }
        }

        if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }

        updatePassword(newPassword, confirmNewPassword).then(res => {
            if (res && res.description === 'Password changed') {
                logout();

                newAlert('success', 'Your password was changed successfully', 'Login with your new details');
            } else if (res && res.description) {
                newAlert('danger', 'An error occurred', res.description);
            }
        });
    }

    return (
        <div className="h-screen px-3 md:px-8 flex relative justify-center">
            <div className=" absolute left-1 top-5 md:top-3">
                <Button
                    color="white"
                    rounded
                    className="hover:pl-3 text-red"
                    buttonType="link"
                    size="lg"
                    ripple="dark"
                    onClick={() => history.goBack()}
                >
                    <Icon name="arrow_back" size="sm" />
                    Back
                </Button>
            </div>
            <div className="mt-20">
                <h3 className="text-4xl font-bold text-black">Change password</h3>
                <div className="h-2" />
                <Paragraph>
                    Please enter your new password
                </Paragraph>
                <Card className="max-w-3xl text-left">
                    <div className="h-2" />
                    <CardBody>

                        {/* <div className={`${error && error.oldPassword ? "mb-8" : "mb-4"} text-left`}>
                            <Input
                                outline={false}
                                value={oldPassword}
                                onChange={e => setOldPassword(e.target.value)}
                                type="password"
                                color="red"
                                placeholder="Old password"
                                size="regular"
                                error={error && error.oldPassword}
                                onFocus={() => clearError('oldPassword')}

                            />
                        </div> */}
                        <form>
                            <div className={`${error && error.newPassword ? "mb-8" : "mb-4"} text-left`}>
                                <Input
                                    outline={false}
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                    type="password"
                                    color="red"
                                    placeholder="New password"
                                    size="regular"
                                    error={error && error.newPassword}
                                    onFocus={() => clearError('newPassword')}

                                />
                            </div>

                            <div className="mb-4 text-left">
                                <Input
                                    outline={false}
                                    value={confirmNewPassword}
                                    onChange={e => setConfirmNewPassword(e.target.value)}
                                    type="password"
                                    color="red"
                                    placeholder="Confirm new password"
                                    size="regular"
                                    error={error && error.confirmNewPassword}
                                    onFocus={() => clearError('confirmNewPassword')}
                                />
                            </div>
                        </form>
                    </CardBody>
                    <CardFooter>
                        <Button
                            color="red"

                            className="hover:bg-red hover:text-white w-full hover:shadow-xl hover:pr-2"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={onDoneClick}
                        >
                            Done
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                        <div className="h-5" />
                        <Paragraph>
                            Password must be at least 8 characters long. The more characters, the better.
                            A mixture of both uppercase and lowercase letters.
                            A mixture of letters and numbers.
                            Inclusion of at least one special character, e.g., ! @ # ?
                        </Paragraph>
                    </CardFooter>
                </Card>
                <div className="h-10" />
            </div>
        </div>
    );
}
