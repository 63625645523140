import { useEffect, useState } from "react";

import DayPickerInput from "components/inputs/DayPickerInput";
import Input from "@material-tailwind/react/Input";

import { useGlobalState } from "state-pool";

import { format } from "date-fns";

import { setPageTitle } from "utils";

export default function Orders() {
    const [orders] = useGlobalState("orders");
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [query, setQuery] = useState("");
    const [showTo, setShowTo] = useState(12);
    const [firstLoad, setFirstLoad] = useState(true);
    //added ~Z
    const [isCleared, setIsCleared] = useState(false);

    useEffect(() => {
        if (orders && Array.isArray(orders) && orders.length > 0) {
            setFilteredOrders(orders);

            setFirstLoad(false);
        }
    }, [orders]);

    // use effect changes ~Z
    useEffect(() => {
        if (
            query &&
            typeof query === "string" &&
            orders &&
            Array.isArray(orders) &&
            orders.length > 0
        ) {
            const filtered = orders.filter((order) => {
                const lowerCaseQuery = query.toLowerCase();
                const productTitle = order.product_title.toLowerCase();
                const customer = order.customer.toLowerCase();
                const saleStatus = order.sale_status.toLowerCase();
                const orderDate = format(new Date(order.order_date), "dd-MM-yyyy HH:mm");
                const sellingPrice = order.selling_price.toString();
                const quantity = order.quantity.toString();

                return (
                    productTitle.includes(lowerCaseQuery) ||
                    customer.includes(lowerCaseQuery) ||
                    saleStatus.includes(lowerCaseQuery) ||
                    orderDate.includes(lowerCaseQuery) ||
                    sellingPrice.includes(lowerCaseQuery) ||
                    quantity.includes(lowerCaseQuery)
                );
            });
            setFilteredOrders(filtered);
        } else {
            setFilteredOrders(orders);
        }
        if (isCleared) {
            // Reset the filteredOrders state to the original orders
            setFilteredOrders(orders);
            setIsCleared(false); // Reset the isCleared state to false
        }
    }, [query, orders, isCleared]);
    //end of changes

    useEffect(() => {
        setPageTitle("Your Orders");
    }, []);

    useEffect(() => {
        let from, to;
        try {
            if (fromDate) {
                from = new Date(fromDate);
            } else {
                from = new Date();
            }
            if (toDate) {
                to = new Date(toDate);
            } else {
                to = new Date();
            }
        } catch (err) {
            if (err) return;
        }

        if (from && to && !firstLoad) {
            const mapArr = query ? filteredOrders : orders;

            let filtered = mapArr.filter((order) => {
                let orderDate;
                try {
                    orderDate = new Date(order.order_date);
                } catch (err) {
                    return;
                }

                if (orderDate >= from && orderDate <= to) return true;
            });
            setFilteredOrders(filtered);
        }
    }, [fromDate, toDate]);

    // unique orders updated ~Z
    // unique orders updated with search and search by date filters
    let uniqueOrders = [];
    if (filteredOrders && Array.isArray(filteredOrders) && filteredOrders.length > 0) {
        uniqueOrders = Array.from(
            new Set(filteredOrders.map((order) => order.order_id))
        ).map((orderId) => {
            return filteredOrders.find((order) => order.order_id === orderId);
        }).filter((order) => {
            let includeOrder = true;

            // Apply search query filter
            if (query) {
                const lowerCaseQuery = query.toLowerCase();
                const productTitle = order.product_title.toLowerCase();
                const customer = order.customer.toLowerCase();
                const saleStatus = order.sale_status.toLowerCase();
                const orderDate = format(new Date(order.order_date), "dd-MM-yyyy HH:mm");
                const sellingPrice = order.selling_price.toString();
                const quantity = order.quantity.toString();

                includeOrder = (
                    productTitle.includes(lowerCaseQuery) ||
                    customer.includes(lowerCaseQuery) ||
                    saleStatus.includes(lowerCaseQuery) ||
                    orderDate.includes(lowerCaseQuery) ||
                    sellingPrice.includes(lowerCaseQuery) ||
                    quantity.includes(lowerCaseQuery)
                );
            }

            // Apply date filter
            if (fromDate && toDate) {
                const orderDate = new Date(order.order_date);
                const from = new Date(fromDate);
                const to = new Date(toDate);

                includeOrder = includeOrder && (orderDate >= from && orderDate <= to);
            }

            return includeOrder;
        });
    }


    return (
        <>
            <div className='bg-gray-200 px-3 md:px-8 h-40' />

            <div className='px-3 md:px-8 -mt-24'>
                <div className='container mx-auto max-w-full'>
                    <div className='grid grid-cols-1 lg:gap-5 xl:grid-cols-6'>
                        {orders && Array.isArray(orders) && orders.length > 0 && (
                            <div className='xl:col-start-1 xl:col-end-3'>
                                <div className='bg-white rounded-xl shadow-xl p-4 mb-5'>
                                    <p className='font-bold mt-2 mb-4'>
                                        Filter By Search Query
                                    </p>
                                    <Input
                                        outline={true}
                                        value={query}
                                        onChange={(e) =>
                                            setQuery(e.target.value)
                                        }
                                        color='red'
                                        placeholder='Search Orders...'
                                        size='large'
                                        type='text'
                                    // onFocus={() => clearError('author')}
                                    // error={error && error.subtitle}
                                    />
                                    <p className='font-bold mt-8 mb-4'>
                                        Filter By Date
                                    </p>
                                    <DayPickerInput
                                        outline={true}
                                        day={fromDate}
                                        setDay={setFromDate}
                                        color='red'
                                        placeholder='Filter from (Date)'
                                        size='large'
                                        // onFocus={() => clearError('onSaleDate')}
                                        // error={error && error.onSaleDate}
                                        id='from-date-orders'
                                    />

                                    <DayPickerInput
                                        outline={true}
                                        day={toDate}
                                        setDay={setToDate}
                                        color='red'
                                        placeholder='Filter to (Date)'
                                        size='large'
                                        // onFocus={() => clearError('onSaleDate')}
                                        // error={error && error.onSaleDate}
                                        id='to-date-orders'
                                    />
                                    {/* clear filters */}
                                    <button onClick={() => {
                                        setFromDate(""); // Clear the fromDate state
                                        setToDate(""); // Clear the toDate state
                                        setQuery(""); // Clear the query state
                                        setIsCleared(true); // Set the isCleared state to true
                                    }}
                                        className='flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-3 px-7 text-sm leading-relaxed text-white bg-red-500 hover:bg-red-700 focus:bg-red-400 active:bg-red-800 shadow-md-red hover:shadow-lg-red w-full my-5'
                                    >
                                        Clear Filters
                                    </button>
                                </div>
                            </div>
                        )}
                        {uniqueOrders && Array.isArray(uniqueOrders) && uniqueOrders.length > 0 ? (
                            <div className="xl:col-start-3 xl:col-end-7">
                                {uniqueOrders.slice(0, showTo).map((order) => (
                                    <div className='w-full bg-white rounded-xl shadow-xl p-4 mb-2'
                                        key={order.order_id}>
                                        <div className='w-full flex'>
                                            <p className='font-bold'>
                                                {order.product_title}
                                            </p>
                                            <p className='text-gray-500 ml-auto justify-self-end'>
                                                {format(
                                                    new Date(
                                                        order.order_date
                                                    ),
                                                    "dd-MM-yyyy HH:mm"
                                                )}
                                            </p>
                                        </div>

                                        <div className='w-full flex mt-5'>
                                            <p className=''>Customer</p>
                                            <p className=' ml-auto justify-self-end'>
                                                {" "}
                                                {order.customer}
                                            </p>
                                        </div>

                                        <div className='w-full flex'>
                                            <p className=''>Sale Status</p>
                                            <p
                                                className={` ml-auto justify-self-end ${order.sale_status.toLowerCase() ===
                                                    "shipped to customer"
                                                    ? "text-green-500"
                                                    : "text-red-500"
                                                    }`}
                                            >
                                                {" "}
                                                {order.sale_status}
                                            </p>
                                        </div>

                                        <div className='w-full flex'>
                                            <p className=''>
                                                Selling Price
                                            </p>
                                            <p className=' ml-auto justify-self-end'>
                                                {" "}
                                                {order.selling_price}
                                            </p>
                                        </div>

                                        <div className='w-full flex'>
                                            <p className=''>
                                                Quantity Ordered
                                            </p>
                                            <p className=' ml-auto justify-self-end'>
                                                {" "}
                                                {order.quantity}
                                            </p>
                                        </div>

                                        <div className='w-full flex justify-end my-5'>
                                            <a
                                                target='_blank'
                                                href={order.takealot_url}
                                                className='false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-3 px-7 text-sm leading-relaxed text-white bg-red-500 hover:bg-red-700 focus:bg-red-400 active:bg-red-800 shadow-md-red hover:shadow-lg-red w-full md:w-1/3'
                                            >
                                                View Product
                                            </a>
                                        </div>
                                    </div>
                                ))}
                                {uniqueOrders.length > showTo && (
                                    <button
                                        onClick={() => setShowTo(showTo + 12)}
                                        className='false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-3 px-7 text-sm leading-relaxed text-white bg-red-500 hover:bg-red-700 focus:bg-red-400 active:bg-red-800 shadow-md-red hover:shadow-lg-red w-full my-5'
                                    >
                                        Load More
                                    </button>
                                )}
                            </div>
                        ) : null}
                    </div>
                    {(orders && Array.isArray(orders) && orders.length === 0) ||
                        !orders ||
                        (orders && orders.statusCode === 11) ? (
                        <div className='w-full h-96 flex flex-col items-center justify-center'>
                            <h2 className='text-2xl font-bold'>
                                You don't have any orders yet
                            </h2>
                            <p className='text-gray-500'>
                                When customers place an order on one of your
                                books, you'll see the order information on this
                                page
                            </p>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
}
