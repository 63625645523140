import { useLocation } from 'react-router-dom';

import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { numberWithSpaces } from 'utils';

import { useGlobalState } from 'state-pool';

import CartPopdown from 'components/cart/CartPopdown';

export default function AdminNavbar({ showSidebar, setShowSidebar }) {
    const location = useLocation();

    const [currentUser] = useGlobalState('currentUser');

    const getBalance = () => {
        if (currentUser && currentUser.balance) {
            const balance = numberWithSpaces(currentUser.balance);

            return `R ${balance}`;
        }

        return 'R 0.00';
    }

    return (
        <nav className="md:ml-64 py-6 px-3 bg-red">
            <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
                <div className="flex justify-between items-center w-full">
                    <div className="flex items-center">
                        {location && location.pathname && !location.pathname.includes('dashboard') ?
                            <h4 className="uppercase text-sm tracking-wider text-white">
                                <span className="text-white uppercase">Balance</span> {getBalance()}
                            </h4> :
                            <h4 className="uppercase text-md tracking-wider text-white">
                                <span className="text-white capitalize">Welcome, {currentUser && currentUser.name_first}</span>
                            </h4>
                        }
                    </div>

                    <div className="flex">
                        <div className="md:-mr-4 ml-6 md:block">
                            <CartPopdown />
                        </div>
                    </div>
                </div>
                <div className="md:hidden">
                    <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        iconOnly
                        rounded
                        ripple="light"
                        onClick={() => setShowSidebar('right-0')}
                    >
                        <Icon name="menu" size="2xl" color="white" />
                    </Button>
                    <div
                        className={`absolute top-2 md:hidden ${showSidebar === 'right-0' ? 'right-59' : 'hidden'
                            } z-50 transition-all duration-300`}
                    >
                        <Button
                            color="transparent"
                            buttonType="link"
                            size="lg"
                            iconOnly
                            rounded
                            ripple="light"
                            onClick={() => setShowSidebar('-right-64')}
                        >
                            <Icon name="close" size="2xl" color="black" />
                        </Button>
                    </div>
                </div>
            </div>
        </nav>
    );
}
