import { useState } from "react";

import Navbar from "@material-tailwind/react/Navbar";
import NavbarContainer from "@material-tailwind/react/NavbarContainer";
import NavbarWrapper from "@material-tailwind/react/NavbarWrapper";
import NavbarToggler from "@material-tailwind/react/NavbarToggler";
import NavbarCollapse from "@material-tailwind/react/NavbarCollapse";
import Nav from "@material-tailwind/react/Nav";
import NavItem from "@material-tailwind/react/NavItem";
import Icon from "@material-tailwind/react/Icon";

export default function ViewBookMenu({selectedSection, setSelectedSection, hasSales, hasOrders}) {
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <Navbar color="red">
            <NavbarContainer>
                <NavbarWrapper>
                    <NavbarToggler
                        color="white"
                        onClick={() => setOpenMenu(!openMenu)}
                        ripple="light"
                    />
                </NavbarWrapper>

                <NavbarCollapse open={openMenu}>
                    <Nav leftSide>
                        <NavItem active={selectedSection === 'details' ? "light" : ""} style={{cursor: 'pointer'}} ripple="light" onClick={() => setSelectedSection('details')}>
                            <Icon name="toc" size="xl" />
                            Details
                        </NavItem>
                        {hasOrders && <NavItem active={selectedSection === 'orders' ? "light" : ""} style={{cursor: 'pointer'}} className="cursor-pointer" ripple="light" onClick={() => setSelectedSection('orders')}>
                            <Icon name="local_offer" size="xl" />
                            Orders
                        </NavItem>}
                        {hasSales && <NavItem active={selectedSection === 'sales' ? "light" : ""} style={{cursor: 'pointer'}} className="cursor-pointer" ripple="light" onClick={() => setSelectedSection('sales')}>
                            <Icon name="monetization_on" size="xl" />
                            Sales
                        </NavItem>}
                    </Nav>
                </NavbarCollapse>
            </NavbarContainer>
        </Navbar>
    );
}