import {useRef} from 'react';

// import Paragraph from '@material-tailwind/react/Paragraph';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import { useHistory } from 'react-router';

const OPTIONS = [
    {
        text: "PDF",
        helpText: "Selecting this option allows you to upload your file in .pdf format in the following step",
        type: 'pdf',
        icon: 'pdf.png'
    },
    // {
    //     text: "DOCX",
    //     helpText: "Selecting this option allows you to upload your file in .docx format in the following step",
    //     type: 'raw',
    //     icon: 'docx.png'
    // },
];

export default function RawOrPdf({
    stage,
    pagePathname,
    rawOrPdf,
    setRawOrPdf,
    completed,
    setCompleted,
    bookFormat,
    setLocalBookId,
    setInternationalBookId
}) {
    const helpRef = useRef();
    const history = useHistory();

    const onContinueClick = () => {
        const next = `/${pagePathname}/metadata`;
        //  : `/${pagePathname}/upload-assets`;

        history.push(next);
        setCompleted([...completed, stage]);
    }

    return (
        <div className="w-full text-left">
            <h3 className="text-4xl font-bold ">Select Upload Type</h3>
            <div className="h-5" />
            {/* <Card className={`max-w-3xl text-left`}>  
                <CardBody> */}
                    <p className="text-gray-600 ml-1 text-xl leading-normal mb-5">Select the file type of the book you intend to upload</p>
                    <div className="flex mb-5 w-full">
                            {OPTIONS.map(({text, helpText, type, icon}, i) => ((bookFormat === 'print') && type === 'raw') ? '' : (
                                <div key={i} onClick={_ => setRawOrPdf(type)} className={`w-full ${i !== 0 ? "ml-5" : ""}`}>
                                    <div className={`w-full rounded-xl p-4 shadow-2xl cursor-pointer bg-white relative ${rawOrPdf === type ? "border-2 border-red-500" : ""}`}>
                                        <div className="absolute top-5 right-5 w-full flex justify-end">
                                            <span ref={helpRef} className="ml-2 text-gray-500">
                                                <Icon className="ml-2" name="help" size="3xl" color="gray" />
                                            </span>
                                            <Tooltips placement="right" ref={helpRef}>
                                                <TooltipsContent>{helpText}</TooltipsContent>
                                            </Tooltips>
                                        </div>
                                        <div className="flex items-center justify-center flex-col py-1 h-32">
                                            <img alt={`type ${type}`} className="w-16 h-auto" src={`/assets/icons/${icon}`} />
                                            <p className="mt-2 text-xl text-black">{text}</p>
                                        </div>
                                    </div>
                                </div>
                                )
                            )}
                    </div>
                {/* </CardBody>
            </Card> */}
            <div className="h-5" />
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-left mb-3 md:mb-auto md:w-1/4 hover:pl-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={() => history.goBack()}
            >
                <Icon name="arrow_back" size="sm" />
                Back
            </Button> 
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/4 hover:pr-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={onContinueClick}
            >
                {completed.includes(stage) ? "Next" : "Continue"}
                <Icon name="arrow_forward" size="sm" />
            </Button>
        </div>
    );
}
