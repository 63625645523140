import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';

const FEES = [
    {
        title: 'Title',
        fee: 'Fee',
        description: 'Description'
    },
    {
        title: 'Title',
        fee: 'Fee',
        description: 'Description'
    },
    {
        title: 'Title',
        fee: 'Fee',
        description: 'Description'
    },
    {
        title: 'Title',
        fee: 'Fee',
        description: 'Description'
    },
];

export default function Fees() {

    return (
        <div className="mt-5">
            {FEES.map(({title, fee, description}, i) => (
                <div key={i} className="w-full">
                    <Card className="mb-2 transition cursor-pointer group">
                        <CardBody>
                            <div className="relative flex flex-col md:flex-row w-auto md:h-24">
                                <div className="w-1/2 md:w-1/3">
                                    <span className="text-sm text-gray-900 font-light">{title}</span>
                                </div>
                                <div className="w-1/2 md:w-1/3">
                                    <span className="text-sm text-gray-900 font-light">{fee}</span>
                                </div>
                                <div className="w-full md:w-1/3">
                                    <span className="text-sm text-gray-900 font-light">{description}</span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            ))}
        </div>
    );
}
