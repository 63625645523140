import {useState} from 'react';

import Icon from '@material-tailwind/react/Icon';

import DOCUMENTS from 'sample-data/documents';

export default function Documents() {
    const [docTypeFilter, setDocTypeFilter] = useState('all');

    return (
        <div className="bg-transparent pt-14 pb-28 px-3 md:px-8 h-auto">
            <div className="container mx-auto max-w-full">
            <div className="flex mb-10">
                <div onClick={() => setDocTypeFilter("all")} className={`${docTypeFilter === "all" ? "bg-white" : "bg-gray-500 text-white"} shadow-2xl cursor-pointer w-full rounded-xl overflow-hidden p-8 ml-5`}>
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="stars" color={`${docTypeFilter === "all" ? "red" : "white"}`} size="6xl" />
                        <h3 className={docTypeFilter === "all" ? "text-red" : "text-white"}>All</h3>
                    </div>
                </div>

                <div onClick={() => setDocTypeFilter("books")} className={`${docTypeFilter === "books" ? "bg-white" : "bg-gray-500 text-white"} shadow-2xl cursor-pointer w-full rounded-xl overflow-hidden p-8 ml-5`}>
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="menu_book" color={`${docTypeFilter === "books" ? "red" : "white"}`} size="6xl" />
                        <h3 className={docTypeFilter === "books" ? "text-red" : "text-white"}>Books</h3>
                    </div>
                </div>

                <div onClick={() => setDocTypeFilter("contracts")} className={`${docTypeFilter === "contracts" ? "bg-white" : "bg-gray-500 text-white"} shadow-2xl cursor-pointer w-full rounded-xl overflow-hidden p-8 ml-5`}>
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="description" color={`${docTypeFilter === "contracts" ? "red" : "white"}`} size="6xl" />
                        <h3 className={docTypeFilter === "contracts" ? "text-red" : "text-white"}>Contracts</h3>
                    </div>
                </div>
            </div>
                <div className="grid grid-cols-1 lg:gap-5 xl:grid-cols-4">
                    {DOCUMENTS.map((d, i) => (
                        <div key={i}>
                            <div style={{ background: `url(${d.link}), rgb(255, 255, 255)`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: '28rem' }} className="w-full cursor-pointer rounded-xl hover:shadow-2xl transition shadow-xl" />
                            <p className="text-lg font-extrabold ml-1 mt-2">{d.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
