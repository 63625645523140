import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";

import Modal from "components/general/Modal";
import PdfViewer from 'components/document-handling/PdfViewer';

export default function PdfModal({ file, title, showModal, setShowModal }) {

    return (
        <>
            {showModal && <Modal 
                showModal={showModal}
                setShowModal={setShowModal}
                size="lg"   
                title={<p className="text-2xl font-bold"></p>}
            >
                <div>
                    <PdfViewer 
                        file={file}
                    />
                    <div className="h-5" />
                    <ModalFooter>
                        {file && <a 
                            href={file}
                            download={(title ? title : '') + 'download.pdf'}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-2.5 px-6 text-xs leading-normal text-white bg-green-500 hover:bg-green-700 focus:bg-green-400 active:bg-green-800 shadow-md-green hover:shadow-lg-green undefined"
                        >
                            Download
                            <Icon name="download" />
                        </a>}
                        <Button 
                            color="red"
                            buttonType="filled"
                            onClick={() => setShowModal(false)}
                            ripple="dark"
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>}
        </>
    )
}
