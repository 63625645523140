const TabError = () => {
    
    return (
        <>
            <div className="h-screen flex items-center justify-center flex-col">
                <h1>Important Notice: Data Integrity and Security</h1>
                <p>We have detected an issue with your current session, which has now expired.</p>

                <p>As the integrity of your data is vitally important to us, the system requires that each book be uploaded in a separate, dedicated session.</p>
                <br />
                <h1>Required Action: </h1>
                <p>Please close this Tab and continue your process in the newly opened Tab.</p>
            </div>
        </>
    );
};

export default TabError;
