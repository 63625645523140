import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";

export default function CustomModal({ showModal, setShowModal, title, children, size, onClose, closeOnOutsideClick = true}) {
    const toggler = closeOnOutsideClick ? (onClose ? onClose : () => setShowModal(false)) : undefined;
    return (
        <Modal className="bg-white" size={size} active={showModal }toggler={toggler}>
            {title && <ModalHeader className={'m-10'} toggler={toggler}>
                {title}
            </ModalHeader>}
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    );
}