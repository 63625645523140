import {useState, useEffect, useRef} from "react";
import axios from "axios";

import { getMd5 } from "utils";

export function useCoverChunkUpload(
    localBookId,
    internationalBookId,
    files,
    chunkSize,
    nextPage
) {
    const [coverIsDone, setCoverIsDone] = useState(false);
    const [currentCoverIndex, setCurrentCoverIndex] = useState(null);
    const [lastUploadedCoverIndex, setLastUploadedCoverIndex] = useState(null);
    const [currentCoverChunkIndex, setCurrentCoverChunkIndex] = useState(null);
	const [isCoverUploaded, setIsCoverUploaded] = useState(false);

    function readAndUploadCurrentChunk() {
        const reader = new FileReader();
        const file = files[currentCoverIndex];
        if (!file) {
            return;
        }
        const from = currentCoverChunkIndex * chunkSize;
        const to = from + chunkSize;
        const blob = file.slice(from, to);
        reader.onload = e => uploadChunk(e);
        reader.readAsDataURL(blob);
    }

    async function uploadChunk(readerEvent) {
        const file = files[currentCoverIndex];
        const data = readerEvent.target.result;
        
        const params = new URLSearchParams();
        params.set('name', file.name);
        params.set('size', file.size);
        params.set('local_bookId', localBookId ? localBookId : null);
        params.set('international_bookId', internationalBookId ? internationalBookId : null);
        params.set('size', file.size);
        params.set('md5_chunk', getMd5(data));

        params.set('currentCoverChunkIndex', currentCoverChunkIndex);
        params.set('totalChunks', Math.ceil(file.size / chunkSize));
    
        const headers = {
            'Content-Type': 'application/octet-stream',
            'Authorization': `Bearer ${localStorage.getItem('access-token')}`
        };
    
        const url = process.env.REACT_APP_API_URL + '/session/v2/packet-cover?' + params.toString();
    
        try {
            const response = await axios.post(url, data, { headers });
            const file = files[currentCoverIndex];
            const filesize = files[currentCoverIndex].size;
            const chunks = Math.ceil(filesize / chunkSize) - 1;
            const isLastChunk = currentCoverChunkIndex === chunks;
            
            if (isLastChunk) {
                file.finalFilename = response.data.finalFilename;
                setLastUploadedCoverIndex(currentCoverIndex);
                setCurrentCoverChunkIndex(null);
                setIsCoverUploaded(true);
            } else {
                setCurrentCoverChunkIndex(response.data.next_index);
            }
        } catch (error) {
            console.error("Error while uploading chunk:", error);
        }
    }

    useEffect(() => {
        if (lastUploadedCoverIndex === null) {
        return;
        }
        const isLastFile = lastUploadedCoverIndex === files.length - 1;
        const nextFileIndex = isLastFile ? null : currentCoverIndex + 1;
        setCurrentCoverIndex(nextFileIndex);
    }, [lastUploadedCoverIndex]);

    useEffect(() => {
        if (files.length > 0) {
			if (currentCoverIndex === null) {
				setCurrentCoverIndex(
				lastUploadedCoverIndex === null ? 0 : lastUploadedCoverIndex + 1
				);
			}
        }
    }, [coverIsDone]);

    useEffect(() => {
        if (currentCoverIndex !== null) {
        setCurrentCoverChunkIndex(0);
        }
    }, [currentCoverIndex]);

    useEffect(() => {
        if (currentCoverChunkIndex !== null) {
        readAndUploadCurrentChunk();
        }
    }, [currentCoverChunkIndex]);

    return {
        coverIsDone,
        setCoverIsDone,
        currentCoverIndex,
        setCurrentCoverIndex,
        lastUploadedCoverIndex,
        setLastUploadedCoverIndex,
        currentCoverChunkIndex,
        setCurrentCoverChunkIndex,
		isCoverUploaded
    };
}

export function useChunkUpload(
    localBookId,
    internationalBookId,
    files,
    chunkSize,
    nextPage
) {
    const [isDone, setIsDone] = useState(false);
    const [currentFileIndex, setCurrentFileIndex] = useState(null);
    const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
	const [isBookUploaded, setIsBookUploaded] = useState(false);

    function readAndUploadCurrentChunk() {
        const reader = new FileReader();
        const file = files[currentFileIndex];
        if (!file) {
            return;
        }
        const from = currentChunkIndex * chunkSize;
        const to = from + chunkSize;
        const blob = file.slice(from, to);
        reader.onload = e => uploadChunk(e);
        reader.readAsDataURL(blob);
    }

    async function uploadChunk(readerEvent) {
        const file = files[currentFileIndex];
        const data = readerEvent.target.result;
    
        const params = new URLSearchParams();
        params.set('name', file.name);
        params.set('size', file.size);
        params.set('local_bookId', localBookId ? localBookId : null);
        params.set('international_bookId', internationalBookId ? internationalBookId : null);
        params.set('size', file.size);
        params.set('md5_chunk', getMd5(data));
        params.set('currentChunkIndex', currentChunkIndex);
        params.set('totalChunks', Math.ceil(file.size / chunkSize));
    
        const headers = {
            'Content-Type': 'application/octet-stream',
            'Authorization': `Bearer ${localStorage.getItem('access-token')}`
        };
    
        const url = process.env.REACT_APP_API_URL + '/session/v2/packet?' + params.toString();
    
        try {
            const response = await axios.post(url, data, { headers });
            const file = files[currentFileIndex];
            const filesize = files[currentFileIndex].size;
            const chunks = Math.ceil(filesize / chunkSize) - 1;
            const isLastChunk = currentChunkIndex === chunks;
    
            if (isLastChunk) {
                file.finalFilename = response.data.finalFilename;
                setLastUploadedFileIndex(currentFileIndex);
                setCurrentChunkIndex(null);
                setIsBookUploaded(true);
            } else {
                setCurrentChunkIndex(response.data.next_index);
            }
        } catch (error) {
            console.error("Error while uploading chunk:", error);
        }
    }

    useEffect(() => {
        if (lastUploadedFileIndex === null) {
        return;
        }
        const isLastFile = lastUploadedFileIndex === files.length - 1;
        const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
        setCurrentFileIndex(nextFileIndex);
    }, [lastUploadedFileIndex]);

    useEffect(() => {
        if (files.length > 0) {
			if (currentFileIndex === null) {
				setCurrentFileIndex(
				lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
				);
			}
        }
    }, [isDone]);

    useEffect(() => {
        if (currentFileIndex !== null) {
        setCurrentChunkIndex(0);
        }
    }, [currentFileIndex]);

    useEffect(() => {
        if (currentChunkIndex !== null) {
        readAndUploadCurrentChunk();
        }
    }, [currentChunkIndex]);

    return {
        isDone,
        setIsDone,
        currentFileIndex,
        setCurrentFileIndex,
        lastUploadedFileIndex,
        setLastUploadedFileIndex,
        currentChunkIndex,
        setCurrentChunkIndex,
		isBookUploaded
    };
}

// export default useChunkUpload;