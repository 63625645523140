import { useState, useEffect } from 'react';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import { useHistory, Link } from 'react-router-dom';
import { isValidEmail, parseQueryString } from 'utils';

export default function Stage1({ email, password, confirmPassword, setEmail, setPassword, setConfirmPassword }) {
    const [error, setError] = useState({});
    const history = useHistory();
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordValidationMessages, setPasswordValidationMessages] = useState([]);
    const [confirmNoMatch, setConfirmNoMatch] = useState(false);

    const validations = [
        {
            regexp: ".{8,}",
            message: "*Your password must be a minimum of eight characters long"
        },
        {
            regexp: "[a-z]",
            message: "*Your password must contain at least one lowercase letter"
        },
        {
            regexp: "[A-Z]",
            message: "*Your password must contain at least one uppercase letter"
        },
        {
            regexp: "[0-9]",
            message: "*Your password must contain at least one one number"
        },
        {
            regexp: ".*[!@#$%?=*&]",
            message: "*Your password must contain at least one one special character"
        }
    ];

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    useEffect(() => {
        const pageQueries = parseQueryString();

        if (pageQueries && pageQueries.e) {
            let tempErrors = {};

            tempErrors.email = 'This email alreay exists on our system, please use another email or login to continue';

            setError(tempErrors);
        }
    }, []);

    function clearError(key) {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError(tempErrors);
        }
    }

    function onContinueClick() {
        let tempErrors = {};

        if (!email) {
            tempErrors.email = 'Please enter your email to continue';
        }

        if (email && !isValidEmail(email)) {
            tempErrors.email = 'Please enter a valid email address';
        }

        if (!password) {
            tempErrors.password = 'Please enter your password to continue';
        }

        if (!confirmPassword) {
            tempErrors.confirmPassword = 'Please confirm your password to continue';
        }

        if (password !== confirmPassword) {
            if (confirmPassword) {
                tempErrors.confirmPassword = 'passwords must match';
            }
        }

        if (password === confirmPassword) {
            let passwordCheck = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
            if (!passwordCheck.test(password)) {
                tempErrors.password = 'Your password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and must be a minimum of eight characters long.';
            }
        }

        if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }

        history.push('/register/your-details');

        return;
    }

    useEffect(() => {
        if (typeof password === 'string' && validations && Array.isArray(validations)) {
            let messagesTemp = [];
            validations.forEach(({ regexp, message }) => {
                let messageExists = messagesTemp.find(m => m === message);

                if (!password.match(regexp)) {
                    if (!messageExists) messagesTemp.push(message);
                } else if (password.match(regexp)) {

                    if (messageExists) {
                        let removedMessage = messagesTemp.filter(m => m !== message);

                        messagesTemp = removedMessage;
                    }
                }
            });

            setPasswordValidationMessages([...messagesTemp]);
        }
    }, [password]);

    useEffect(() => {
        if (typeof password === 'string' && typeof confirmPassword === 'string') {
            if (!(password === confirmPassword)) setConfirmNoMatch(true);

            if (password === confirmPassword) setConfirmNoMatch(false);
        }
    }, [password, confirmPassword]);

    const passwordValidations = () => {
        if (passwordValidationMessages && Array.isArray(passwordValidationMessages) && (passwordValidationMessages.length > 0)) {
            return (
                <div className="mt-5 p-5 bg-gray-200 rounded-xl">
                    {passwordValidationMessages.map(m => (
                        <p className="text-red text-xs">{m}</p>
                    ))}
                </div>
            );
        }

        return '';
    };

    return (
        <>
            <div className={`mb-${error && error.email ? "8" : "4"} text-left`}>
                <Input
                    outline={false}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    type="text"
                    color="red"
                    placeholder="Email"
                    size="regular"
                    error={error && error.email}
                    onFocus={() => clearError('email')}
                />
            </div>
            <div className={error && error.email && window.innerWidth < 767 && 'h-8'} />
            <div className={`mb-${error && error.password ? "12" : "4"} text-left`}>
                <div className='flex'>
                    <Input
                        outline={false}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type={passwordShown ? "text" : "password"}
                        color="red"
                        error={error && error.password}
                        placeholder="Password"
                        size="regular"
                        onFocus={() => clearError('password')}
                    />
                    <div className='ml-5'>
                        <Button
                            color="red"
                            buttonType="filled"
                            size="regular"
                            rounded={false}
                            block={false}
                            icononly="true"
                            ripple="light"
                            onClick={togglePassword}
                        >
                            {!passwordShown ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                </svg>
                            )
                            }

                        </Button>
                    </div>
                </div>
                {/* {passwordValidations()} */}
            </div>
            <div className={`mb-${error && error.confirmPassword ? "8" : "4"} text-left`}>
                <Input
                    outline={false}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    type={passwordShown ? "text" : "password"}
                    color="red"
                    placeholder="Confirm Password"
                    size="regular"
                    error={error && error.confirmPassword}
                    onFocus={() => clearError('confirmPassword')}
                />
                {confirmNoMatch && <div className="mt-5 p-5 bg-gray-200 rounded-xl"><p className="text-red text-xs">*Passwords don't match</p></div>}
            </div>
            <div className="w-full flex justify-center mt-6">
                <div className="g-recaptcha" data-sitekey="your_site_key"></div>
            </div>
            <div className="h-10" />
            <div className="flex justify-center flex-col">
                <Button
                    color="red"
                    className="hover:bg-red hover:text-white w-full hover:shadow-xl hover:pr-2 mb-5"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onContinueClick}
                >
                    Continue
                    <Icon color="black" name="arrow_forward" size="sm" />
                </Button>
                <Paragraph>
                    Already have an account? <Link className="text-red" to="/login">Login</Link>
                </Paragraph>
            </div>
        </>
    );
}
