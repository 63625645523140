import {useEffect} from 'react';
import {setPageTitle} from 'utils';

// TODO: Needs styling

export default function NotFound() {

    useEffect(() => {
        setPageTitle('404 - Page Not Found');
    }, []);
    
    return (
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
            <div className="bg-landing-background bg-cover bg-center absolute top-0 w-full h-full" />
            <div className="container max-w-8xl relative mx-auto">
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                        404
                    </div>
                </div>
            </div>
        </div>
    );
}