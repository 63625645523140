import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';

// import {useGlobalState} from 'state-pool';

export default function TransactionsTable() {
    // const [transactions] = useGlobalState('transactions');

    return (
        <Card>
            <CardHeader color="red" contentPosition="left">
                <h2 className="text-white text-2xl">Transactions</h2>
            </CardHeader>
            <CardBody>
                <div className="overflow-x-auto">
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-2 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    Date and time
                                </th>
                                <th className="px-2 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    Type
                                </th>
                                <th className="px-2 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    Amount before fees
                                </th>
                                <th className="px-2 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    Fee
                                </th>
                                <th className="px-2 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    Amount after fees
                                </th>
                                <th className="px-2 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    Status
                                </th>
                                <th className="px-2 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                    Balance
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
}


// TODO component for no transactions
// may need icon from Dan for above

                            // {/* {transactions && Array.isArray(transactions) && transactions.length > 0 && transactions.map((transaction, i) => (
                            //     <tr>
                            //         <th className="border-b border-gray-200 align-middle text-white font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            //             {/* TODO format below */}
                            //             {transaction.created_at && format(new Date(transaction.created_at), 'dd-MM-yyyy HH:mm')}
                            //         </th>
                            //         <th className="border-b border-gray-200 align-middle text-white font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            //             {/* TODO needs logic on destination to determine display string */}
                            //             {transaction.destination === "in:p" && "Payout"}
                            //             {transaction.destination === "in:d" && "Deposit"}

                            //         </th>
                            //         <th className="border-b border-gray-200 align-middle text-white font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            //             {/* TODO convert to decimal w/ 2 points & add currency symbol */}
                            //             R{" "}{transaction.amount && transaction.amount}
                            //         </th>
                            //         <th className="border-b border-gray-200 align-middle text-white font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            //             {/* TODO convert to decimal w/ 2 points & add currency symbol */}
                            //             R{" "}{transaction.fee_total && transaction.fee_total}
                            //         </th>
                            //         <th className="border-b border-gray-200 align-middle text-white font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                            //             {/* TODO convert to decimal w/ 2 points & add currency symbol */}
                            //             {/* TODO double check below logic once clarified on fee variables */}
                            //             R{" "}{transaction.fee_total && transaction.amount && (transaction.amount + transaction.fee_total)}
                            //         </th>
                            //         <th className={`border-b border-gray-200 align-middle ${transaction.is_confirmed === 1 ? "text-success" : "text-red"} font-light text-sm whitespace-nowrap px-2 py-4 text-left`}>
                            //             {/* TODO logic based on is_confirmed number to display string */}
                            //             {transaction.is_confirmed === 1 ? 'Successful' : 'Declined'}
                            //         </th>
                            //         <th className={`border-b border-gray-200 align-middle ${transaction.destination.includes("in") ? "text-success" : "text-red"} font-light text-sm whitespace-nowrap px-2 py-4 text-left`}>
                            //             {/* TODO logic based on is_confirmed number to display string */}
                            //             {"R"}{" "}{transaction.your_balance}{" "}<Icon color="green" name="arrow_upward" size="sm" />
                            //         </th>
                            //     </tr>
                            // ))} */}