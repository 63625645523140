import SignatureCanvas from 'react-signature-canvas';

export default function Sign({ setSigCanvas }) {
    const width = window.innerWidth < 767 ? 350 : 500;

    return (
        <div className="flex justify-center flex-col items-center">
            <div>
                <SignatureCanvas
                    ref={(ref) => { setSigCanvas(ref) }}
                    penColor='black'
                    canvasProps={{ width, height: 200, className: 'sigCanvas' }}
                />
            </div>
        </div>
    )
}
