import { Paragraph } from "@material-tailwind/react";
import Icon from "@material-tailwind/react/Icon";

export default function SuccessfulSubmissiion() {

    return (
        <div className="w-full text-center">

            <Icon color="red" name="email" size="9xl" />
            <div className="h-5" />
            <h3 className="text-4xl font-bold mb-3">Check your email</h3>
            <Paragraph>
                We've sent a link that'll redirect you to our site to reset your password.
            </Paragraph>
            <Paragraph>
                Can't find the email? Check your spam folder.
            </Paragraph>
        </div>
    );
}
