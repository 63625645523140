import {useState, useEffect} from 'react';

import Icon from '@material-tailwind/react/Icon';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";

import Payfast from 'components/payments/Payfast';

import {setPageTitle, numberWithSpaces} from 'utils';

export default function Payment({currentUser, total, helpRef, paymentDetails, totalDiscount, cartItems}) {
    const [paymentMethod, setPaymentMethod] = useState('');

    const options = [
        // {
        //     text: "Credit card",
        //     helpText: "Use your Mastercard or Visa credit card to make a payment using Flutterwaves' secure payment portal.",
        //     type: 'credit-card',
        //     logo: '/images/logos/flutterwave.png',
        //     onClick: (type) => {
        //         setPaymentMethod(type);
        //         executeFlutterwavePayment();
        //     },
        //     brand: 'with Flutterwave',
        // },
        {
            text: "EFT",
            helpText: "Pay from any South African bank via EFT with PayFast",
            type: 'eft',
            logo: '/images/logos/payfast.png',
            onClick: (type) => {
                setPaymentMethod(type);
            },
            brand: 'Payfast',
        },
        // {
        //     text: "Coupon",
        //     helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        //     type: 'coupon',
        //     icon: 'loyalty',
        //     onClick: (type) => {
        //         setPaymentMethod(type);
        //         setShowAddCoupon(true);
        //     },
        //     brand: 'Enter a coupon code',
        // },
    ];

    useEffect(() => {
        setPageTitle('Checkout', 'Payment');
    }, []);

    return (
        <div className="max-w-3xl w-full p-8 z-30 rounded-xl shadow-md-grey">
            <div className="h-5" />
            <h2 className="text-2xl font-bold">
                Payment
            </h2>   
            <div className="h-5" />

            <h2 className="text-xl mb-2 mt-4">
                Total{" "}<span className="font-light">R{" "}{paymentDetails && numberWithSpaces(String(paymentDetails.amountToPay.toFixed(2)))}</span>
            </h2>
            {/* <h2 className="text-xl mb-2 mt-4">
                Discount{" "}<span className="font-light">R{" "}{paymentDetails && paymentDetails.toFixed(2)}</span>
            </h2> */}
            {/* <h2 className="text-xl mb-2 mt-4">
                Coupon{" "}<span className="font-light">R{" "}{paymentDetails && paymentDetails.couponDeduction.toFixed(2)}</span>
            </h2> */}
            <div className="h-5" />
            <h3 className="text-xl">You currently do not have sufficient balance to complete this transaction</h3>
            <div className="h-5" />
            <p className="text-red">You require an additional R {paymentDetails && paymentDetails.amountToPay.toFixed(2)} to proceed. Select a payment method below to add the required amount to your balance.</p>

            <div className="h-5" />
            <div className="flex flex-col md:flex-row mb-5 w-full">
                {currentUser && options.map(({text, helpText, type, logo, icon, brand, onClick}, i) => (
                    type === "eft" ? <Payfast i={i} name={currentUser.name_first} email={currentUser.email} userId={currentUser.id} amount={paymentDetails && paymentDetails.amountToPay.toFixed(2)} itemName={'POD Checkout'} passPhrase="passphrase" key={i} type={type} helpText={helpText} helpRef={helpRef} brand={brand} logo={logo} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} text={text} /> :
                    <div key={i} onClick={() => onClick(type)} className={`w-full ${i !== 0 ? "md:ml-5" : ""}`}>
                        <div className={`w-full rounded-xl mb-5 md:mb-auto p-4 shadow-2xl cursor-pointer bg-white relative`}>
                            <div className="absolute top-5 right-5 w-40 flex justify-end">
                                <span ref={helpRef} className="ml-2 text-gray-500">
                                    <Icon className="ml-2" name="help" size="3xl" color="gray" />
                                </span>
                                <Tooltips placement="top" ref={helpRef}>
                                    <TooltipsContent>{helpText}</TooltipsContent>
                                </Tooltips>
                            </div>
                            <div className="flex items-center justify-center flex-col py-1 h-auto md:h-40">
                                {!icon ? <img alt={brand} src={logo} className="h-36 w-auto" /> : <Icon name="loyalty" size="9xl" />}
                            </div>
                        </div>
                        <p className="font-bold text-xl text-black my-5">{text}{" "}<span className="font-light text-gray-500 text-sm">{brand}</span></p>
                    </div>
                ))}
            </div>
        </div>
    )
}

// SELECTED OPTION IF MORE THAN 1 ${paymentMethod === type ? "border-black border-2" : ""}