import { useRef, useState } from 'react';
import Modal from 'components/general/Modal';


export interface FileType {
    file_data: string,
    file_name: string
};

interface Props {
    setFile: ({ file_name, file_data }: FileType) => void,
    setFiles: (file: File) => void,

    children: React.ReactElement | React.ReactElement[];
    fileTypes: string,
    useBlob: boolean
};

const DocumentUpload: React.FC<Props> = ({ children, setFile, fileTypes, useBlob, setFiles }) => {
    const filesRef = useRef<HTMLInputElement>(null);
    const [showWarning, setShowWarning] = useState(false);
    // console.log(fileTypes, useBlob, setFiles, setFile)
    function handleFilesClick() {
        if (filesRef && filesRef.current) {
            filesRef.current.click();
        }
    }

    function handleFilesUpload(e: any) {
        e.persist();
        if (e && e.target.files && e.target.files.length > 0) {
            [].forEach.call(e.target.files, (file: File) => {
                // Check file type against accepted types
                const fileExtension = `.${file.name.split('.').pop()}`;

                // Check file type against accepted types
                if (!fileTypes.split(',').includes(fileExtension)) {
                    setShowWarning(true);
                    return;
                }
                if (e && e.target.files && e.target.files.length > 0) {

                    [].forEach.call(e.target.files, (file: File) => {
                        // @ts-ignore
                        // console.log(file.slice(0, 20))

                        if (!useBlob) {
                            // console.log(file)
                            const reader = new FileReader();
                            reader.onload = function () {
                                if (typeof reader.result === 'string') {
                                    let tempFile: any = {
                                        // @ts-ignore
                                        file_name: file.name,
                                        file_data: reader.result,
                                    };

                                    // console.log(Object.keys(tempFile));

                                    setFile({ ...tempFile });

                                    tempFile = {};
                                    // uploadImage(tempFile);
                                }
                            };
                            reader.onerror = function () { };

                            reader.readAsDataURL(file);
                        } else if (!setFiles) {
                            // @ts-ignore
                            if (file && file.name) setFile({ file_name: file.name, file_data: file });
                        } else if (setFiles) {
                            // @ts-ignore
                            if (file && file.name) setFiles([file]);

                            let tempFile: any = {
                                // @ts-ignore
                                file_name: file.name,
                            };

                            setFile({ ...tempFile });
                        }

                    })
                }
            });
        }

    }

    return (
        <>
            <input style={{ visibility: 'hidden' }} type="file" ref={filesRef} onChange={e => handleFilesUpload(e)} accept={fileTypes} />
            <div onClick={handleFilesClick}>
                {children}
            </div>
            <Modal
                showModal={showWarning}
                setShowModal={setShowWarning}
                size="sm"
                title="Invalid File Type"
                onClose={() => { setShowWarning(false) }}
            >
                <div className="flex flex-col justify-center items-center h-full">
                    <img
                        className="h-14 w-auto mb-4"  // Add some margin bottom for spacing
                        alt="loading"
                        src="/images/exit.gif"
                    />
                    {fileTypes === ".epub" ? (
                        <>
                            <p className="text-gray-600">We kindly request you to upload files with the <b>{fileTypes}</b> extensions. Alternatively, you may return to the <b>"Cover requirements"</b> section and opt for <b>"Conversion needed"</b>.</p>
                        </>
                    ) : (
                        <>
                            <p className="text-gray-600">We kindly request you to upload files with the <b>{fileTypes}</b> extensions.</p>
                        </>
                    )}
                </div>
            </Modal>

        </>
    );
};

export default DocumentUpload;