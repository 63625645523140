import { useState, useRef, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';

import { useGlobalState } from 'state-pool';

import { useAuth } from 'hooks/useAuth';
import { isValidJson, parseQueryString, removeDashesAndSpaces, capitalize } from 'utils';
import { podApi } from 'api/pod';

import { useAlert } from 'hooks/useAlert';

import { useLocalStorage } from 'usehooks-ts';
//For limiting the amount of calls
import { debounce } from 'lodash';


const STAGES_NAMES = [
    "publishing-rights",
    "general-info",
    "calculator",
    "confirm-details",
    "publisher-info",
    "bank-details",
    "accept-terms",
    "contract",
    "upload-type",
    "metadata",
    "confirm-bleed-cover",
    "upload-assets",
    "accept-terms-additional",
    "payment-method",
    "submission-complete",
    "e-book",
    "audio-book",
    "upload-audio",
    "applicability"
];

const minimumPagesPerPaperType = [
    {
        keyWords: ['80gsm', 'bond'],
        three: 42,
        four: 60
    },
    {
        keyWords: ['70gsm', 'creamy', 'bulky'],
        three: 32,
        four: 46
    },
    {
        keyWords: ['90gsm', 'cartridge'],
        three: 38,
        four: 54
    },
    {
        keyWords: ['90gsm', 'lets', 'go', 'matt'],
        three: 46,
        four: 65
    },
    {
        keyWords: ['115gsm', 'lets', 'go', 'matt'],
        three: 36,
        four: 50
    },
    {
        keyWords: ['140gsm', 'lets', 'go', 'matt'],
        three: 30,
        four: 40
    },
    { //this option can only be landscpe
        keyWords: ['170gsm', 'matt'],
        three: 24,
        four: 38
    },
];

export default function BookNew() {
    const [resources] = useGlobalState('resources');
    const [currentUser] = useGlobalState('currentUser');
    const [bookFormat, setBookFormat] = useLocalStorage('calc_bookFormat', null);
    const [title, setTitle] = useLocalStorage('calc_title', '');
    const [numPages, setNumPages] = useLocalStorage('calc_numPages', '');
    //Filtering paper types
    const [paperTypesFiltered, setPaperTypesFiltered] = useState(0);
    const [internationalPaperTypesFiltered, setInternationalPaperTypesFiltered] = useState(0);
    const [size, setSize] = useLocalStorage('calc_size', '');
    const [paperSide, setPaperSide] = useLocalStorage('calc_paperSide', 42);
    const [completed, setCompleted] = useLocalStorage('calc_completed', []);
    const [acceptTerms, setAcceptTerms] = useLocalStorage('calc_acceptTerms', false);
    const [signature, setSignature] = useLocalStorage('calc_signature', '');
    const [rawOrPdf, setRawOrPdf] = useLocalStorage('calc_rawOrPdf', null);
    const [acceptBleed, setAcceptBleed] = useLocalStorage('calc_acceptBleed', false);
    const [acceptCover, setAcceptCover] = useLocalStorage('calc_acceptCover', false);
    const [cover, setCover] = useLocalStorage('calc_cover', '');
    const [thumbnail, setThumbnail] = useLocalStorage('calc_thumbnail', '');
    const [bookDocument, setBookDocument] = useLocalStorage('calc_bookDocument', '');
    const [paymentMethod, setPaymentMethod] = useLocalStorage('calc_paymentMethod', '');
    const [localInternational, setLocalInternational] = useLocalStorage('calc_localInternational', '');
    const [localPaperType, setLocalPaperType] = useLocalStorage('calc_localPaperType', '');
    const [localCoverPaperType, setLocalCoverPaperType] = useLocalStorage('calc_localCoverPaperType', '');
    const [internationalPaperType, setInternationalPaperType] = useLocalStorage('calc_internationalPaperType', '');
    const [internationalCoverPaperType, setInternationalCoverPaperType] = useLocalStorage('calc_internationalCoverPaperType', '');
    const [bookPrice, setBookPrice] = useLocalStorage('calc_bookPrice', '');
    const [royalties, setRoyalties] = useLocalStorage('calc_royalties', '');
    const [costPerBook, setCostPerBook] = useLocalStorage('calc_costPerBook', '');
    const [color, setColor] = useLocalStorage('calc_color', '');
    const [pageFinish, setPageFinish] = useLocalStorage('calc_pageFinish', '');
    const [localBookId, setLocalBookId] = useLocalStorage('calc_localBookId', '');
    const [internationalBookId, setInternationalBookId] = useLocalStorage('calc_internationalBookId', '');
    const [isLocal, setIsLocal] = useLocalStorage('calc_isLocal', false);
    const [isInternational, setIsInternational] = useLocalStorage('calc_isInternational', false);
    const [voiceAccent, setVoiceAccent] = useLocalStorage('calc_voiceAccent', '');
    const [voiceGender, setVoiceGender] = useLocalStorage('calc_voiceGender', '');
    const [coverNeeded, setCoverNeeded] = useLocalStorage('calc_coverNeeded', false);
    const [thumbnailNeeded, setThumbnailNeeded] = useLocalStorage('calc_thumbnailNeeded', false);
    const [formattingNeeded, setFormattingNeeded] = useLocalStorage('calc_formattingNeeded', false);
    const [bleedingNeeded, setBleedingNeeded] = useLocalStorage('calc_bleedingNeeded', false);
    const [intCoverOption, setIntCoverOption] = useLocalStorage('calc_intCoverOption', '');
    const [intCoverType, setIntCoverType] = useLocalStorage('calc_intCoverType', '');
    const [isbnNumber, setIsbnNumber] = useLocalStorage('calc_isbnNumber', '');
    const [isbnNumberNeeded, setIsbnNumberNeeded] = useLocalStorage('calc_isbnNumberNeeded', false);
    const [spineOption, setSpineOption] = useLocalStorage('calc_spineOption', 105);
    const [subtitle, setSubtitle] = useLocalStorage('calc_subtitle', '');
    const [author, setAuthor] = useLocalStorage('calc_author', '');
    const [contributors, setContributors] = useLocalStorage('calc_contributors', []);
    const [publicationDate, setPublicationDate] = useLocalStorage('calc_publicationDate', '');
    const [onSaleDate, setOnSaleDate] = useLocalStorage('calc_onSaleDate', '');
    const [language, setLanguage] = useLocalStorage('calc_language', '');
    const [genre, setGenre] = useLocalStorage('calc_genre', '');
    const [keywords, setKeywords] = useLocalStorage('calc_keywords', []);
    const [bisacCode, setBisacCode] = useLocalStorage('calc_bisacCode', '');
    const [shortDescription, setShortDescription] = useLocalStorage('calc_shortDescription', '');
    const [longDescription, setLongDescription] = useLocalStorage('calc_longDescription', '');
    const [mainDescription, setMainDescription] = useLocalStorage('calc_mainDescription', '');
    const [copyrightHolder, setCopyrightHolder] = useLocalStorage('calc_copyrightHolder', '');
    const [copyrightDate, setCopyrightDate] = useLocalStorage('calc_copyrightDate', '');
    const [recommendedAudience, setRecommendedAudience] = useLocalStorage('calc_recommendedAudience', '');
    const [indicatedAudienceWarning, setInicatedAudienceWarning] = useLocalStorage('calc_indicatedAudienceWarning', '');
    const [recordingRequired, setRecordingRequired] = useLocalStorage('calc_recordingRequired', false);
    const [numChapters, setNumChapters] = useLocalStorage('calc_numChapters', null);
    const [audioFiles, setAudioFiles] = useLocalStorage('calc_audioFiles', []);
    const [publisherName, setPublisherName] = useLocalStorage('calc_publisherName', '');
    const [publisherRegId, setPublisherRegId] = useLocalStorage('calc_publisherRegId', '');
    const [publisherPhysicalAddress, setPublisherPhysicalAddress] = useLocalStorage('calc_publisherPhysicalAddress', '');
    const [publisherTelephone, setPublisherTelephone] = useLocalStorage('calc_publisherTelephone', '');
    const [publisherPostalAddress, setPublisherPostalAddress] = useLocalStorage('calc_publisherPostalAddress', '');
    const [publisherEmail, setPublisherEmail] = useLocalStorage('calc_publisherEmail', '');
    const [publisherContactPerson, setPublisherContactPerson] = useLocalStorage('calc_pulisherContactPerson', '');
    const [signedPlace, setSignedPlace] = useLocalStorage('calc_signedPlace', '');
    const [initials, setInitials] = useLocalStorage('calc_initials', '');
    const [bankName, setBankName] = useLocalStorage('calc_bankName', '');
    const [branchCode, setBranchCode] = useLocalStorage('calc_branchCode', '');
    const [accountType, setAccountType] = useLocalStorage('calc_accountType', '');
    const [accountName, setAccountName] = useLocalStorage('calc_accountName', '');
    const [accountNumber, setAccountNumber] = useLocalStorage('calc_accountNumber', '');
    const [bankAddress, setBankAddress] = useLocalStorage('calc_bankAddress', '');
    const [ibanNumber, setIbanNumber] = useLocalStorage('calc_ibanNumber', '');
    const [swiftCode, setSwiftCode] = useLocalStorage('calc_swiftCode', '');
    const [paypal, setPaypal] = useLocalStorage('calc_paypal', '');
    const [isInternationalAccount, setIsInternationalAccount] = useLocalStorage('calc_isInternationalAccount', false);
    const [isPaypalAccount, setIsPaypalAccount] = useLocalStorage('calc_isPaypaAccount', false);
    const history = useHistory();
    const location = useLocation();
    const { stage } = useParams();
    const helpRef = useRef();
    const { getUserDetails } = useAuth();
    let numStages = rawOrPdf === 'raw' ? 10 : 9;
    const pagePathname = location.pathname.split('/')[1];

    const [bookHeight, setBookHeight] = useLocalStorage('calc_bookHeight', '');
    const [bookWidth, setBookWidth] = useLocalStorage('calc_bookWidth', '');

    const [canSupportCoverTypes, setCanSupportCoverTypes] = useLocalStorage('calc_canSupportCoverTypes', []);
    const [canSupportPaperTypes, setCanSupportPaperTypes] = useLocalStorage('calc_canSupportPaperTypes', []);

    const [canSupportIntCoverTypes, setCanSupportIntCoverTypes] = useLocalStorage('calc_canSupportIntCoverTypes', []);

    const [canSupportPaperType, setCanSupportPaperType] = useLocalStorage('calc_canSupportPaperType', false);

    const [orientation, setOrientation] = useLocalStorage('calc_orientation', '');

    const [coverFinish, setCoverFinish] = useLocalStorage('calc_coverFinish', '');

    const [internationalBookPrice, setInternationalBookPrice] = useLocalStorage('calc_internationalBookPrice', '');

    const [heavyGraphics, setHeavyGraphics] = useLocalStorage('calc_heavyGraphics', false);
    const [isSelfPublisher, setIsSelfPublisher] = useLocalStorage('calc_isSelfPublisher', false);
    const { newAlert } = useAlert();

    const [voiceArtistName, setVoiceArtistName] = useLocalStorage('calc_voiceArtistName', '');
    const [spotUvNeeded, setSpotUvNeeded] = useLocalStorage('calc_spotUvNeeded', false);

    const [duplexNeeded, setDuplexNeeded] = useLocalStorage('calc_duplexNeeded', false);
    const [softTouchMattLaminationNeeded, setSoftTouchMattLaminationNeeded] = useLocalStorage('calc_softTouchLaminationNeeded', false);
    const [spiralBoundNeeded, setSpiralBoundNeeded] = useLocalStorage('calc_spiralBoundNeeded', false);

    const [revisionsNeeded, setRevisionsNeeded] = useLocalStorage('calc_revisionNeeded', false);

    const [duplexNeededInt, setDuplexNeededInt] = useLocalStorage('calc_duplexNeededInt', false);
    const [perforationNeededInt, setPerforationNeededInt] = useLocalStorage('calc_perforationNeededInt', false);

    const [customRecordingRequired, setCustomRecordingRequired] = useLocalStorage('calc_customRecordingNeeded', false);

    const [books] = useGlobalState('books');

    const [binding, setBinding] = useLocalStorage('calc_binding', '');
    const [conversionNeeded, setConversionNeeded] = useLocalStorage('calc_conversionNeeded', false);

    const [branchName, setBranchName] = useLocalStorage('calc_branchName', '');

    const [numPagesLimitExceeded, setNumPagesLimitExceeded] = useLocalStorage('calc_numPageLimitExceeded', false);

    const [isRoyaltiesLoading, setIsRoyaltiesLoading] = useState(false);

    function submit() {
        const currentUser = localStorage.getItem('currentUser');

        if (isValidJson(currentUser)) {
            const parsedUser = JSON.parse(currentUser);

            if (currentUser) {
                const { firstname, lastname, email } = parsedUser;
                if (firstname && lastname && email) {
                    const body = {
                        book_title: title,
                        no_of_pages: numPages ? Number(numPages) : 0,
                        format_type: bookFormat,
                        is_local: isLocal,
                        l_paper_type_id: localPaperType ? localPaperType.id : null,
                        l_cover_type_id: localCoverPaperType ? localCoverPaperType.id : null,
                        local_price: bookPrice ? Number(bookPrice) : null,
                        international_price: internationalBookPrice ? Number(internationalBookPrice) : null,
                        is_international: isInternational,
                        i_paper_type_id: internationalPaperType ? internationalPaperType.id : null,
                        i_cover_type_id: localCoverPaperType && localCoverPaperType.id ? localCoverPaperType.id : 7,
                        // international_price: isInternational ? Number(bookPrice) : null,   
                        gender: voiceGender ? voiceGender : null,
                        nationality: voiceAccent ? voiceAccent : null,
                        isbn_is_required: isbnNumberNeeded,
                        barcode_is_required: false,
                        isbn: isbnNumber ? removeDashesAndSpaces(isbnNumber) : null,
                        spine_id: spineOption ? spineOption : null,
                        genre: genre && genre.id,
                        heavy_graphics: heavyGraphics ? heavyGraphics : null,
                        heavyGraphics: heavyGraphics ? heavyGraphics : null,
                        voiceover_id: voiceAccent ? voiceAccent : null,

                        spot_uv: spotUvNeeded ? spotUvNeeded : null,
                        duplex_needed: duplexNeeded ? duplexNeeded : null,
                        soft_touch_matt_lamination: softTouchMattLaminationNeeded ? softTouchMattLaminationNeeded : null,
                        spiral_bound: spiralBoundNeeded ? spiralBoundNeeded : null,
                        // duplex_covered: null,
                        // perforation: null
                        i_duplex_covered: duplexNeededInt ? duplexNeededInt : null,
                        perforation: perforationNeededInt ? perforationNeededInt : null,
                        recording_needed: customRecordingRequired ? customRecordingRequired : null,

                        finish: coverFinish ? coverFinish : null,
                        bind: binding ? binding : spiralBoundNeeded ? 'Spiral Bound' : null,
                        height: bookHeight ? bookHeight : null,
                        width: bookWidth ? bookWidth : null,
                    }
                    podApi.post('/user/getquote', body).then(res => {

                        if (res && res.data && res.data.statusCode === 0 && res.data.localRoyalty && (res.data.localBookId || res.data.internationalBookId || res.data.bookID)) {
                            const { localRoyalty, internationRoyalty } = res.data;

                            setRoyalties({
                                ...{
                                    internationalRoyalty: internationRoyalty, // BE spelling mistake
                                    localRoyalty
                                }
                            });

                            if (res.data.localBookId) setLocalBookId(res.data.localBookId);
                            if (res.data.internationalBookId) setInternationalBookId(res.data.internationalBookId);
                            if (res.data.bookID) setInternationalBookId(res.data.bookID);
                            if (res.data.totalCost) setCostPerBook(res.data.totalCost);

                            getUserDetails();

                            history.push(`/${pagePathname}/publisher-info`);
                        } else if (res && res.data && res.data.statusCode == "ISBN already exists") {
                            newAlert('danger', 'The provided ISBN is already in use. Please select a different ISBN', '');
                        } else {
                            newAlert('danger', 'Something went wrong', '');
                        }
                    });
                }
            }
        }
    }
    /* ---------------fetching the royalties----------------- */
    const fetchRoyalties = debounce(() => {
        if (
            (
                (isLocal && !isInternational && bookPrice && (typeof bookPrice === 'string')) ||
                (isInternational && !isLocal && internationalBookPrice && (typeof internationalBookPrice === 'string')) ||
                (isInternational && isLocal && internationalBookPrice && (typeof internationalBookPrice === 'string') && bookPrice && (typeof bookPrice === 'string'))
            ) &&
            numPages
        ) {
            setIsRoyaltiesLoading(true);
            const body = {
                no_of_pages: numPages ? Number(numPages) : 0,
                format: bookFormat ? bookFormat : 0,
                is_local: isLocal,
                l_paper_type_id: localPaperType && localPaperType.id ? localPaperType.id : 0,
                l_cover_type_id: localCoverPaperType && localCoverPaperType.id ? localCoverPaperType.id : 0,
                local_price: bookPrice ? Number(bookPrice) : 0,
                international_price: internationalBookPrice ? Number(internationalBookPrice) : 0,
                is_international: isInternational,
                i_paper_type_id: internationalPaperType && internationalPaperType.id ? internationalPaperType.id : 0,
                i_cover_type_id: localCoverPaperType && localCoverPaperType.id ? localCoverPaperType.id : 7,
                nationality: voiceAccent,
                gender: voiceGender,
                spine_id: spineOption,
                spot_uv: spotUvNeeded ? spotUvNeeded : null,
                duplex_needed: duplexNeeded ? duplexNeeded : null,
                soft_touch_matt_lamination: softTouchMattLaminationNeeded ? softTouchMattLaminationNeeded : null,
                spiral_bound: spiralBoundNeeded ? spiralBoundNeeded : null,
                recording_needed: customRecordingRequired ? customRecordingRequired : null,
                i_duplex_covered: duplexNeededInt ? duplexNeededInt : null,
                perforation: perforationNeededInt ? perforationNeededInt : null,
                recording_needed: customRecordingRequired ? customRecordingRequired : null,
            }
            podApi.put('/user/realtimeroyalty', body).then(res => {
                setNumPagesLimitExceeded(false);
                //if the responds is valid and the royalties are not null
                if (res && res.data && res.data.statusCode === 0 && (res.data.localRoyalty || res.data.internationRoyalty)) {
                    setRoyalties(() => {
                        return {
                            ...{
                                internationalRoyalty: res.data.internationRoyalty, // BE spelling mistake
                                localRoyalty: res.data.localRoyalty
                            }
                        }
                    });
                    setCostPerBook(() => res.data.totalCost);
                    setNumPagesLimitExceeded(false);
                    setIsRoyaltiesLoading(false);
                }
                //if the responds is valid but the number of pages are too much
                if (res && res.data && res.data.statusCode === "B12" && res.data.description) {
                    setNumPagesLimitExceeded(true);
                    setRoyalties('');
                }
                return;
            });
            return;
        } else {
            setIsRoyaltiesLoading(true);
        }
    }, 300);
    /* ---------------When these variables are changed we run fetchRoyalties----------------- */
    useEffect(() => {
        fetchRoyalties();
        return () => {
            fetchRoyalties.cancel();
        };
    }, [bookPrice, internationalBookPrice, bookFormat, numPages, localCoverPaperType?.id, localPaperType?.id, internationalPaperType?.id, internationalCoverPaperType?.id, isLocal, isInternational, paperSide, title]);
    /* ---------------fetching the royalties----------------- */
    const getStageNumber = () => {

        if (STAGES_NAMES && stage) {
            return STAGES_NAMES.indexOf(stage);
        }
    }

    useEffect(() => {
        const pageQueries = parseQueryString();

        if (pageQueries && typeof pageQueries === 'object' && pageQueries.continue && (pageQueries.lbid || pageQueries.ibid) && pageQueries.region) {
            const { region } = pageQueries;

            switch (true) {
                case region.includes('i'): {
                    setInternationalBookId(Number(pageQueries.ibid));

                    const internationalBooks = books && books.internationalBooks;

                    const relatedBook = internationalBooks && Array.isArray(internationalBooks) && internationalBooks.find(ib => ib.id == pageQueries.ibid);

                    if (relatedBook && relatedBook.genre_id) {
                        const genres = resources && resources.genres && Array.isArray(resources.genres) ? resources.genres : null;

                        if (genres && Array.isArray(genres)) {
                            const relatedGenre = genres.find(g => g.id === relatedBook.genre_id);

                            if (relatedGenre && typeof relatedGenre === 'object' && relatedGenre.bisac) {
                                const { bisac } = relatedGenre;

                                setGenre({
                                    id: relatedGenre.id,
                                    text: relatedGenre.SubjectHeading,
                                    bisac: relatedGenre.bisac,
                                    value: relatedGenre.id
                                });

                                setBisacCode(bisac);

                            }
                        }
                    }

                    if (relatedBook && relatedBook.format_type) {
                        setBookFormat(relatedBook.format_type);
                    }
                }

                case region.includes('l'): {
                    setLocalBookId(Number(pageQueries.lbid));

                    const localBooks = books && books.localBooks;

                    const relatedBook = localBooks && Array.isArray(localBooks) && localBooks.find(ib => ib.id == pageQueries.lbid);

                    if (relatedBook && relatedBook.genre_id) {
                        const genres = resources && resources.genres && Array.isArray(resources.genres) ? resources.genres : null;

                        if (genres && Array.isArray(genres)) {
                            const relatedGenre = genres.find(g => g.id === relatedBook.genre_id);

                            if (relatedGenre && typeof relatedGenre === 'object' && relatedGenre.bisac) {
                                const { bisac } = relatedGenre;

                                setGenre({
                                    id: relatedGenre.id,
                                    text: relatedGenre.SubjectHeading,
                                    bisac: relatedGenre.bisac,
                                    value: relatedGenre.id
                                });

                                setBisacCode(bisac);

                                // console.log(bisac, pageQueries.lbid)
                            }
                        }
                    }

                    if (relatedBook && relatedBook.format_type) {
                        setBookFormat(relatedBook.format_type);
                    }

                    break;
                }

            }

        }

    }, []);

    const stageNumber = getStageNumber();
    const percentageComplete = (stageNumber / numStages) * 100;
    const showRoyaltiesWidget = (stage !== "submission-complete" && stage !== "publishing-rights" && (stage === "general-info" && completed.length > 0));
    const isRoyaltiesFloating = (stage === "calculator" || stage === "audio-book" || stage === "e-book" || (stage === "general-info" && completed.length === 1));

    const filterGenres = useState(() => {
        if (resources && resources.genres && Array.isArray(resources.genres)) {
            return resources.genres.map(g => {
                return {
                    value: {
                        id: g.id,
                        text: capitalize(g.SubjectHeading.toLowerCase()),
                        bisacText: capitalize(g.bisac).toLowerCase(),
                        bisacCategory: capitalize(g.bisac).toLowerCase().replace(/[0-9\s]/g, ''),
                        subjectHeadingTrim: capitalize(g.SubjectHeading.toLowerCase()).split('/')[0].trim(),
                        subCategoryTrim: capitalize(g.SubjectHeading).split('/').slice(1).join('/').trim(),
                    },
                    label: capitalize(g.SubjectHeading.toLowerCase()),
                    text: g.SubjectHeading.toLowerCase(),
                }
            });
        }
        return [];
    }, [resources]);



    return {
        resources,
        filterGenres,
        currentUser,
        bookFormat,
        percentageComplete,
        showRoyaltiesWidget,
        isRoyaltiesFloating,
        stage,
        helpRef,
        numStages,
        pagePathname,
        getUserDetails,
        submit,
        stageNumber,
        setBookFormat,
        title,
        setTitle,
        numPages,
        setNumPages,
        size,
        setSize,
        paperSide,
        setPaperSide,
        completed,
        setCompleted,
        acceptTerms,
        setAcceptTerms,
        signature,
        setSignature,
        rawOrPdf,
        setRawOrPdf,
        acceptBleed,
        setAcceptBleed,
        acceptCover,
        setAcceptCover,
        cover,
        setCover,
        thumbnail,
        setThumbnail,
        bookDocument,
        setBookDocument,
        paymentMethod,
        setPaymentMethod,
        localInternational,
        setLocalInternational,
        localPaperType,
        setLocalPaperType,
        localCoverPaperType,
        setLocalCoverPaperType,
        internationalPaperType,
        setInternationalPaperType,
        internationalCoverPaperType,
        setInternationalCoverPaperType,
        bookPrice,
        setBookPrice,
        royalties,
        setRoyalties,
        costPerBook,
        setCostPerBook,
        color,
        setColor,
        pageFinish,
        setPageFinish,
        localBookId,
        setLocalBookId,
        internationalBookId,
        setInternationalBookId,
        isLocal,
        setIsLocal,
        isInternational,
        setIsInternational,
        voiceGender,
        setVoiceGender,
        voiceAccent,
        setVoiceAccent,
        coverNeeded,
        setCoverNeeded,
        thumbnailNeeded,
        setThumbnailNeeded,
        formattingNeeded,
        setFormattingNeeded,
        bleedingNeeded,
        setBleedingNeeded,
        intCoverOption,
        setIntCoverOption,
        intCoverType,
        setIntCoverType,
        isbnNumber,
        setIsbnNumber,
        isbnNumberNeeded,
        setIsbnNumberNeeded,
        spineOption,
        setSpineOption,
        subtitle,
        setSubtitle,
        author,
        setAuthor,
        contributors,
        setContributors,
        publicationDate,
        setPublicationDate,
        onSaleDate,
        setOnSaleDate,
        language,
        setLanguage,
        genre,
        setGenre,
        keywords,
        setKeywords,
        bisacCode,
        setBisacCode,
        shortDescription,
        setShortDescription,
        longDescription,
        setLongDescription,
        mainDescription,
        setMainDescription,
        copyrightHolder,
        setCopyrightHolder,
        copyrightDate,
        setCopyrightDate,
        recommendedAudience,
        setRecommendedAudience,
        indicatedAudienceWarning,
        setInicatedAudienceWarning,
        recordingRequired,
        setRecordingRequired,
        numChapters,
        setNumChapters,
        audioFiles,
        setAudioFiles,
        publisherName,
        setPublisherName,
        publisherRegId,
        setPublisherRegId,
        publisherPhysicalAddress,
        setPublisherPhysicalAddress,
        publisherPostalAddress,
        setPublisherPostalAddress,
        publisherTelephone,
        setPublisherTelephone,
        publisherEmail,
        setPublisherEmail,
        publisherContactPerson,
        setPublisherContactPerson,
        signedPlace,
        setSignedPlace,
        initials,
        setInitials,
        bankName,
        setBankName,
        branchCode,
        setBranchCode,
        accountType,
        setAccountType,
        accountName,
        setAccountName,
        accountNumber,
        setAccountNumber,
        bankAddress,
        setBankAddress,
        ibanNumber,
        setIbanNumber,
        swiftCode,
        setSwiftCode,
        paypal,
        setPaypal,
        isInternationalAccount,
        setIsInternationalAccount,
        isPaypalAccount,
        setIsPaypalAccount,
        bookHeight,
        setBookHeight,
        bookWidth,
        setBookWidth,
        canSupportCoverTypes,
        canSupportPaperTypes,
        canSupportIntCoverTypes,
        canSupportPaperType,
        orientation,
        setOrientation,
        coverFinish,
        setCoverFinish,
        internationalBookPrice,
        setInternationalBookPrice,
        heavyGraphics,
        setHeavyGraphics,
        isSelfPublisher,
        setIsSelfPublisher,
        voiceArtistName,
        setVoiceArtistName,
        spotUvNeeded,
        setSpotUvNeeded,
        duplexNeeded,
        setDuplexNeeded,
        softTouchMattLaminationNeeded,
        setSoftTouchMattLaminationNeeded,
        spiralBoundNeeded,
        setSpiralBoundNeeded,
        revisionsNeeded,
        setRevisionsNeeded,
        duplexNeededInt,
        setDuplexNeededInt,
        perforationNeededInt,
        setPerforationNeededInt,
        customRecordingRequired,
        setCustomRecordingRequired,
        binding,
        setBinding,
        conversionNeeded,
        setConversionNeeded,
        branchName,
        setBranchName,
        numPagesLimitExceeded,
        setNumPagesLimitExceeded,
        isRoyaltiesLoading,
        setIsRoyaltiesLoading,
        paperTypesFiltered,
        setPaperTypesFiltered,
        internationalPaperTypesFiltered,
        setInternationalPaperTypesFiltered,
    };
}
