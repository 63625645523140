import { useEffect, useState } from 'react';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';
import Modal from 'components/general/Modal';

import { useHistory } from 'react-router-dom';

export default function Fin({ getUserDetails }) {
    const history = useHistory();
    const [showBackModal, setShowBackModal] = useState(false);


    useEffect(() => {
        for (let i = localStorage.length - 1; i >= 0; i--) {
            const key = localStorage.key(i);
            if (!['access-token', 'refresh-token', 'currentUser', 'activeTabInfo'].includes(key)) {
                localStorage.removeItem(key);
            }
        }
    }, []);
    /* ---------------Navigation Control----------------- */

    /* -------On Continue Click------- */
    function next() {
        getUserDetails();
        history.push('/checkout/cart');
    }
    /* -------/On Continue Click/------- */

    /* -------Going back------- */
    const goHome = () => {
        history.push('/dashboard');
    };
    //Block the user from going back using router-dom 
    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (action === 'POP') { // only block backward navigation
                setShowBackModal(true);
                return false; // prevent navigation
            }
        });

        return () => {
            unblock();
        };
    }, []);
    /* -------/Going back/------- */

    /* ---------------/Navigation Control/----------------- */

    return (
        <>
            <div className="w-full text-left mt-10">
                {/* <h3 className="text-4xl font-bold ">Equiry Complete</h3>
                <div className="h-2" />
                <Paragraph>
                    Your book submission is complete
                </Paragraph> */}
                <Card className="max-w-3xl text-center">
                    <div className="h-2" />
                    <CardBody>
                        <Icon color="green" name="check_circle_outline" size="9xl" />
                        <div className="h-4" />
                        <Paragraph>
                            Your book has been successfully added to your cart. You can find your cart in the top right hand corner of your screen and proceed to payment when you're ready.
                        </Paragraph>
                        <div className="h-5" />
                        <div className="flex justify-center">
                            <Button
                                color="red"
                                className="hover:bg-red hover:text-white w-full md:w-1/3 hover:pr-2"
                                buttonType="outline"
                                size="lg"
                                ripple="dark"
                                onClick={next}
                            >
                                Take me to my cart
                                <Icon name="arrow_forward" size="sm" />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
                {/* ---------------Modal---------------- */}
                {/* ------Back navigation------ */}
                <Modal
                    showModal={showBackModal}
                    setShowModal={setShowBackModal}
                    size="lg"
                >
                    <div className="p-5">
                        <h3 className="font-light text-xl mb-5">
                            Do you wish to go back to the dashboard?
                        </h3>
                        <Paragraph>
                            Please note that you may return to finalize this at your convenience. Simply navigate to 'Your Books' and select 'Continue Upload Process'.
                        </Paragraph>
                        <div className="h-5" />
                        <Button
                            color="red"
                            className="w-full"
                            buttonType="filled"
                            size="lg"
                            ripple="dark"
                            onClick={() => setShowBackModal(false)}
                        >
                            No, stay here
                        </Button>
                        <div className="h-5" />
                        <Button
                            color="gray"
                            className="w-full"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={() => goHome()}
                        >
                            Yes, go back
                        </Button>
                    </div>
                </Modal>
                {/* -------------------Modal---------------------- */}
            </div>
        </>
    )
}
