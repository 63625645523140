import { useState, useEffect } from 'react';

import Progress from '@material-tailwind/react/Progress';

// stages
import Stage1 from 'components/register/Stage1';
import Stage2 from 'components/register/Stage2';
import Welcome from 'components/register/Welcome';

// general
import BackButton from 'components/buttons/BackButton';
import NextButton from 'components/buttons/NextButton';

import { useParams, useLocation, useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

import {useGlobalState} from 'state-pool';

import {setPageTitle} from 'utils';

const NUM_STAGES = 3;

export default function Register() {

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [optInNewsletter, setOptInNewsletter] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const {register} = useAuth(true);

    const [currentUser] = useGlobalState('currentUser');

    const { stage } = useParams();

    useEffect(() => {
        setPageTitle('Register');
    }, []);

    async function submit() {
        const body = {
            "username": email,
            "firstname": firstname,
            "lastname": lastname,
            "email": email,
            "password": password,
            "confirmPassword": confirmPassword,
        };

        register(body);

        return;
    }

    const STAGES = {
        "email-password": (
            <Stage1
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
            />
        ),
        "your-details": (
            <Stage2
                firstname={firstname}
                setFirstname={setFirstname}
                lastname={lastname}
                setLastname={setLastname}
                acceptTerms={acceptTerms}
                setAcceptTerms={setAcceptTerms}
                optInNewsletter={optInNewsletter}
                setOptInNewsletter={setOptInNewsletter}
                submit={submit}
            />
        ),
        "welcome": (
            <Welcome />
        )
    };

    const stagesArr = Object.keys(STAGES).map(s => `/register/${s}`);

    const locationArr = [...stagesArr, '/your-first-book/general-info'];

    const getStageNumber = () => {
        let returnNum = 0;

        if(locationArr && stage) {
            for(let i = 0; i < locationArr.length; i++) {
                if(locationArr[i].includes(stage)) returnNum = i;
            }

            return returnNum;
        }

        return;
    }

    const stageNumber = getStageNumber();
    const percentageComplete = (stageNumber / NUM_STAGES) * 100;

    return (
        <div className="bg-image h-screen px-3 md:px-8 relative flex items-center justify-center overflow-hidden">
            <div className="w-full absolute top-0 left-0">
                <Progress color="red" value={String(percentageComplete)} percentage={false} />
            </div>
            {!location.pathname.includes('email-password') && (stageNumber < 2) && (
                <div className="absolute top-5 left-5">
                    <BackButton />
                </div>
            )}
            {/* {(currentUser || ((stage === 'email-password') && email && password && confirmPassword)) && (stageNumber < 2) && (
                <div className="absolute top-5 right-5">
                    <NextButton onClick={() => history.push(locationArr[stageNumber + 1])} />
                </div>
            )} */}
            <div className="max-w-3xl text-center w-full bg-white p-8 z-30 rounded-xl shadow-md-grey">
                {stage !== "welcome" && <div className="w-full flex justify-center py-2">
                    <img alt="logo" src="/logo.png" className="h-20 w-auto" />
                </div>}
                {stage !== "welcome" ? <div className="h-12" /> : <div className="h-6" />}
                {STAGES[stage]}
            </div>
        </div>
    );
}
