import {capitalize, isValidJson} from 'utils';

const getBookFormatString = (str) => {

    if(typeof str !== 'string') return '';

    switch(true) {
        case str.toLowerCase().trim() === 'ebook': return 'eBook';
        default: return capitalize(str);
    }
}

const BookCard = ({book, selected, onClick}) => (
        <div onClick={onClick} className={`w-full flex rounded-xl overflow-hidden p-4 shadow-md mb-2 mt-2 cursor-pointer ${selected ? "bg-red" : "bg-white"}`}>
            <div className="relative h-auto text-left w-full flex items-center">
                <h3 className={`text-md font-light ${selected ? "text-white" : "text-black"}`}>{book.book_title}</h3>
                <span className={`text-xs italic ${selected ? "text-white" : "text-gray-500"} ml-1`}>{book && book.format_type && getBookFormatString(book.format_type)}</span>
            </div>
            {book && book.misc && isValidJson(book.misc) && JSON.parse(book.misc).archived ? (
                <p className={`justify-self-end font-light text-xs ml-auto rounded-md p-1 ${!selected ? 'text-red-500 bg-red-250' : 'text-white'}`}>
                    cancelled
                </p>
            ) : book.misc && isValidJson(book.misc) && JSON.parse(book.misc).isPaid && (
                <p className={`justify-self-end font-light text-xs ml-auto rounded-md p-1 ${!selected ? 'text-green-500 bg-green-200' : 'text-white'}`}>
                    paid
                </p>
            )}
        </div>
);

export default BookCard;