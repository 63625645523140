const languages = [
    {
        "label": "Afar",
        "value": "afar"
    },
    {
        "label": "Abkhazian",
        "value": "abkhazian"
    },
    {
        "label": "Afrikaans",
        "value": "afrikaans"
    },
    {
        "label": "Akan",
        "value": "akan"
    },
    {
        "label": "Amharic",
        "value": "amharic"
    },
    {
        "label": "Arabic",
        "value": "arabic"
    },
    {
        "label": "Assamese",
        "value": "assamese"
    },
    {
        "label": "Awadhi",
        "value": "awadhi"
    },
    {
        "label": "Aymara",
        "value": "aymara"
    },
    {
        "label": "Azerbaijani",
        "value": "azerbaijani"
    },
    {
        "label": "Bhojpuri",
        "value": "bhojpuri"
    },
    {
        "label": "Maithili",
        "value": "maithili"
    },
    {
        "label": "Bashkir",
        "value": "bashkir"
    },
    {
        "label": "Belarussian",
        "value": "belarussian"
    },
    {
        "label": "Bulgarian",
        "value": "bulgarian"
    },
    {
        "label": "Bihari",
        "value": "bihari"
    },
    {
        "label": "Bislama",
        "value": "bislama"
    },
    {
        "label": "Balochi",
        "value": "balochi"
    },
    {
        "label": "Bengali",
        "value": "bengali"
    },
    {
        "label": "Tibetan",
        "value": "tibetan"
    },
    {
        "label": "Breton",
        "value": "breton"
    },
    {
        "label": "Catalan",
        "value": "catalan"
    },
    {
        "label": "Cebuano",
        "value": "cebuano"
    },
    {
        "label": "Chechen",
        "value": "chechen"
    },
    {
        "label": "Corsican",
        "value": "corsican"
    },
    {
        "label": "Czech",
        "value": "czech"
    },
    {
        "label": "Welsh",
        "value": "welsh"
    },
    {
        "label": "Danish",
        "value": "danish"
    },
    {
        "label": "German",
        "value": "german"
    },
    {
        "label": "Dakhini",
        "value": "dakhini"
    },
    {
        "label": "Bhutani",
        "value": "bhutani"
    },
    {
        "label": "Greek",
        "value": "greek"
    },
    {
        "label": "English",
        "value": "english"
    },
    {
        "label": "Esperanto",
        "value": "esperanto"
    },
    {
        "label": "Spanish",
        "value": "spanish"
    },
    {
        "label": "Estonian",
        "value": "estonian"
    },
    {
        "label": "Basque",
        "value": "basque"
    },
    {
        "label": "Persian",
        "value": "persian"
    },
    {
        "label": "Finnish",
        "value": "finnish"
    },
    {
        "label": "Fiji",
        "value": "fiji"
    },
    {
        "label": "Faeroese",
        "value": "faeroese"
    },
    {
        "label": "French",
        "value": "french"
    },
    {
        "label": "Frisian",
        "value": "frisian"
    },
    {
        "label": "Irish",
        "value": "irish"
    },
    {
        "label": "Scottish Gaelic",
        "value": "scottish gaelic"
    },
    {
        "label": "Galician",
        "value": "galician"
    },
    {
        "label": "Guarani",
        "value": "guarani"
    },
    {
        "label": "Gujarati",
        "value": "gujarati"
    },
    {
        "label": "Hausa",
        "value": "hausa"
    },
    {
        "label": "Hindi",
        "value": "hindi"
    },
    {
        "label": "Croatian",
        "value": "croatian"
    },
    {
        "label": "Haitian Creole",
        "value": "haitian creole"
    },
    {
        "label": "Hungarian",
        "value": "hungarian"
    },
    {
        "label": "Armenian",
        "value": "armenian"
    },
    {
        "label": "Interlingua",
        "value": "interlingua"
    },
    {
        "label": "Interlingue",
        "value": "interlingue"
    },
    {
        "label": "Inupiak",
        "value": "inupiak"
    },
    {
        "label": "Indonesian",
        "value": "indonesian"
    },
    {
        "label": "Icelandic",
        "value": "icelandic"
    },
    {
        "label": "Italian",
        "value": "italian"
    },
    {
        "label": "Hebrew",
        "value": "hebrew"
    },
    {
        "label": "Japanese",
        "value": "japanese"
    },
    {
        "label": "Yiddish",
        "value": "yiddish"
    },
    {
        "label": "Javanese",
        "value": "javanese"
    },
    {
        "label": "Georgian",
        "value": "georgian"
    },
    {
        "label": "Kabyle",
        "value": "kabyle"
    },
    {
        "label": "Konkani",
        "value": "konkani"
    },
    {
        "label": "Kazakh",
        "value": "kazakh"
    },
    {
        "label": "Greenlandic",
        "value": "greenlandic"
    },
    {
        "label": "Khmer",
        "value": "khmer"
    },
    {
        "label": "Kannada",
        "value": "kannada"
    },
    {
        "label": "Korean",
        "value": "korean"
    },
    {
        "label": "Kashmiri",
        "value": "kashmiri"
    },
    {
        "label": "Kurdish",
        "value": "kurdish"
    },
    {
        "label": "Kirghiz",
        "value": "kirghiz"
    },
    {
        "label": "Latin",
        "value": "latin"
    },
    {
        "label": "Luxembourgish",
        "value": "luxembourgish"
    },
    {
        "label": "Lombard",
        "value": "lombard"
    },
    {
        "label": "Lingala",
        "value": "lingala"
    },
    {
        "label": "Laothian",
        "value": "laothian"
    },
    {
        "label": "Lithuanian",
        "value": "lithuanian"
    },
    {
        "label": "Latvian",
        "value": "latvian"
    },
    {
        "label": "Malagasy",
        "value": "malagasy"
    },
    {
        "label": "Maori",
        "value": "maori"
    },
    {
        "label": "Macedonian",
        "value": "macedonian"
    },
    {
        "label": "Malayalam",
        "value": "malayalam"
    },
    {
        "label": "Mongolian",
        "value": "mongolian"
    },
    {
        "label": "Moldavian",
        "value": "moldavian"
    },
    {
        "label": "Marathi",
        "value": "marathi"
    },
    {
        "label": "Malay",
        "value": "malay"
    },
    {
        "label": "Maltese",
        "value": "maltese"
    },
    {
        "label": "Makhuwa",
        "value": "makhuwa"
    },
    {
        "label": "Marwari",
        "value": "marwari"
    },
    {
        "label": "Burmese",
        "value": "burmese"
    },
    {
        "label": "Nauru",
        "value": "nauru"
    },
    {
        "label": "Nepali",
        "value": "nepali"
    },
    {
        "label": "Dutch",
        "value": "dutch"
    },
    {
        "label": "Norwegian",
        "value": "norwegian"
    },
    {
        "label": "Occitan",
        "value": "occitan"
    },
    {
        "label": "Oromo",
        "value": "oromo"
    },
    {
        "label": "Oriya",
        "value": "oriya"
    },
    {
        "label": "Punjabi",
        "value": "punjabi"
    },
    {
        "label": "Polish",
        "value": "polish"
    },
    {
        "label": "Pashto",
        "value": "pashto"
    },
    {
        "label": "Portuguese",
        "value": "portuguese"
    },
    {
        "label": "Quechua",
        "value": "quechua"
    },
    {
        "label": "Rifian",
        "value": "rifian"
    },
    {
        "label": "Rhaeto-Romance",
        "value": "rhaeto-romance"
    },
    {
        "label": "Kirundi",
        "value": "kirundi"
    },
    {
        "label": "Romanian",
        "value": "romanian"
    },
    {
        "label": "Russian",
        "value": "russian"
    },
    {
        "label": "Kinyarwanda",
        "value": "kinyarwanda"
    },
    {
        "label": "Sanskrit",
        "value": "sanskrit"
    },
    {
        "label": "Sindhi",
        "value": "sindhi"
    },
    {
        "label": "Sangro",
        "value": "sangro"
    },
    {
        "label": "Serbo-Croatian",
        "value": "serbo-croatian"
    },
    {
        "label": "Sinhalese",
        "value": "sinhalese"
    },
    {
        "label": "Slovak",
        "value": "slovak"
    },
    {
        "label": "Slovenian",
        "value": "slovenian"
    },
    {
        "label": "Samoan",
        "value": "samoan"
    },
    {
        "label": "Shona",
        "value": "shona"
    },
    {
        "label": "Somali",
        "value": "somali"
    },
    {
        "label": "Albanian",
        "value": "albanian"
    },
    {
        "label": "Serbian",
        "value": "serbian"
    },
    {
        "label": "Siswati",
        "value": "siswati"
    },
    {
        "label": "Sesotho",
        "value": "sesotho"
    },
    {
        "label": "Sundanese",
        "value": "sundanese"
    },
    {
        "label": "Swedish",
        "value": "swedish"
    },
    {
        "label": "Swahili",
        "value": "swahili"
    },
    {
        "label": "Tamil",
        "value": "tamil"
    },
    {
        "label": "Telugu",
        "value": "telugu"
    },
    {
        "label": "Tajik",
        "value": "tajik"
    },
    {
        "label": "Thai",
        "value": "thai"
    },
    {
        "label": "Tigrinya",
        "value": "tigrinya"
    },
    {
        "label": "Turkmen",
        "value": "turkmen"
    },
    {
        "label": "Tagalog",
        "value": "tagalog"
    },
    {
        "label": "Tuareg",
        "value": "tuareg"
    },
    {
        "label": "Setswana",
        "value": "setswana"
    },
    {
        "label": "Tonga",
        "value": "tonga"
    },
    {
        "label": "Turkish",
        "value": "turkish"
    },
    {
        "label": "Tsonga",
        "value": "tsonga"
    },
    {
        "label": "Tatar",
        "value": "tatar"
    },
    {
        "label": "Twi",
        "value": "twi"
    },
    {
        "label": "Tamazight",
        "value": "tamazight"
    },
    {
        "label": "Uyghur",
        "value": "uyghur"
    },
    {
        "label": "Ukrainian",
        "value": "ukrainian"
    },
    {
        "label": "Urdu",
        "value": "urdu"
    },
    {
        "label": "Uzbek",
        "value": "uzbek"
    },
    {
        "label": "Vietnamese",
        "value": "vietnamese"
    },
    {
        "label": "Volapuk",
        "value": "volapuk"
    },
    {
        "label": "Wolof",
        "value": "wolof"
    },
    {
        "label": "Xhosa",
        "value": "xhosa"
    },
    {
        "label": "Yoruba",
        "value": "yoruba"
    },
    {
        "label": "Chinese",
        "value": "chinese"
    },
    {
        "label": "Zulu",
        "value": "zulu"
    }
];

export default languages;