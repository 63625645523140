import {useState, useEffect} from 'react';

import Dropdown from "@material-tailwind/react/Dropdown";
import Icon from "@material-tailwind/react/Icon";
import Button from "@material-tailwind/react/Button";

import CartSimple from './CartSimple';

import {useGlobalState} from 'state-pool';

import {useHistory} from 'react-router-dom';

import {isValidJson} from 'utils';

export default function CartPopdown() {
    const [rerender, setRerender] = useState(true);
    const [currentUser] = useGlobalState('currentUser');
    const history = useHistory();
    const cartItems = currentUser && currentUser.cart && Array.isArray(currentUser.cart) && currentUser.cart.filter(({misc}) => misc && isValidJson(misc) && JSON.parse(misc)[0] && JSON.parse(misc)[0].cost);

    const onCartViewClick = (day) => {
        resetDropdown();

        history.push('/checkout');
    }

    function resetDropdown() {
        setRerender(!rerender);
    }

    useEffect(() => {
        if(!rerender) setRerender(true);
    }, [rerender]);

    const hasNoItems = !cartItems || (cartItems.length === 0);

    return (
        <>
            {rerender && (
                <Dropdown
                    placement="bottom-start"
                    color="transparent"
                    buttonText={
                        <div className="w-10 h-10 flex bg-gray" style={{borderRadius: '50%', alignItems: 'center', justifyContent: 'center'}}>
                            <Icon name="shopping_cart" size="2xl" color="black" />
                            ({cartItems && Array.isArray(cartItems) && cartItems.length > 0 ? cartItems.length : 0})
                        </div>
                    }
                    rounded
                    style={{
                        padding: 0,
                        color: 'transparent',
                    }}
                >
                    <div className="p-4">
                        <CartSimple cartItems={cartItems} hasNoItems={hasNoItems} />
                        {!hasNoItems && (
                            <>
                                <div className="h-5" />
                                <Button
                                    color="red"
                                    className="w-full"
                                    buttonType="filled"
                                    size="lg"
                                    ripple="dark"
                                    onClick={() => onCartViewClick(false)}
                                >
                                    Checkout
                                </Button>
                            </>
                        )}
                    </div>
                </Dropdown>
            )}
        </>
    )
}
