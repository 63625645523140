import { useState, useRef, useEffect } from 'react';

import { sendUserMessage, getChat } from 'routes/messageRoutes';
import { useGlobalState } from 'state-pool';

import _ from 'lodash';

let MESSAGES = [
    {
        source: 'system',
        message: 'Hey there, welcome to Print on Demand. This is our support chat.',
        created_at: new Date(),
    }
];

export default function Chat() {
    const [message, setMessage] = useState('');
    const [numUnread, setNumUnread] = useState(0);
    const messagesRef = useRef();
    const fetchChatsInterval = useRef();
    const [messages, setMessages] = useState(MESSAGES);
    const [prevMessages, setPrevMessages] = useState([]);
    const [chatPreload] = useGlobalState(`chatPreload`);
    const [showPickEmoji, setShowPickEmoji] = useState(false);
    const [showSendImage, setShowSendImage] = useState(false);
    const [sendImage, setSendImage] = useState('');
    const [loading, setLoading] = useState(true);

    const filesRef = useRef(null);

    const currentHours = new Date().getHours();
    const afterHours = (currentHours > 17) || (currentHours < 8);

    function onImageClick() {
        if (filesRef && filesRef.current) {
            filesRef.current.click();
        }
    }

    function handleFilesUpload(e) {
        e.persist();
        if (e && e.target.files && e.target.files.length > 0) {
            [].forEach.call(e.target.files, (file) => {
                const reader = new FileReader();
                reader.onload = function () {
                    if (typeof reader.result === 'string') {
                        setSendImage(reader.result);

                        setShowSendImage(true);
                    }
                };
                reader.onerror = function () { };

                reader.readAsDataURL(file);
            })
        }
    }

    function onOpenSendImageClick() {
        if (filesRef && filesRef.current) {
            filesRef.current.click();
        }
    }

    function onSendImageMessageClick() {
        setLoading(true);

        setShowSendImage(false);

        sendUserMessage(message, sendImage).then(_ => fetchChats());

        setMessage('');
    }

    function onEmojiClick() {
        setShowPickEmoji(!showPickEmoji);
    }

    function closeSendMessage() {
        setSendImage('');

        setShowSendImage(!showSendImage);
    }

    function onEmojiSelect(e, { emoji }) {

        if (message) setMessage(message + ' ' + emoji);

        if (!message) setMessage(emoji);

        setShowPickEmoji(false);
    }

    // function pollChats() {
    //     fetchChatsInterval.current = setInterval(() => {
    //         fetchChats(true);

    //         pollChats();
    //     }, 5000);

    // }

    function fetchChats() {
        getChat().then(res => {
            setLoading(false);
            if (res && Array.isArray(res)) {
                // {"id":5,"source":"user","user_id":1,"message":"test","created_at":"2022-01-17T15:33:05.000Z","misc":null}

                res.reverse();

                if (afterHours) {
                    const newMessage = {
                        source: 'admin',
                        message: 'Thank you for reaching out to us. Please note that our office is currently closed and we will not be able to respond to your message right away. However, we will get back to you as soon as we can. Thank you for your patience and understanding.',
                        created_at: new Date()
                    };

                    setMessages([...res, newMessage]);
                    return;
                };

                setMessages([...res]);
            }
        });
    }

    useEffect(() => {
        fetchChatsInterval.current = setInterval(fetchChats, 20000)

        // pollChats();

        return () => {
            clearTimeout(fetchChatsInterval.current);
        }

    }, []);

    function onSendMessageClick() {

        if (!message) return;

        setLoading(true);
        const newMessage = {
            source: 'user',
            message
        };

        setMessages([...messages, newMessage]);
        setPrevMessages([...messages, newMessage]);

        sendUserMessage(message).then(_ => fetchChats());

        setMessage('');
    }

    function scrollToBottomOfMessagesDiv() {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }

    function onChatClick() {
        setNumUnread(0);
        scrollToBottomOfMessagesDiv();
    }

    useEffect(() => {
        if (!_.isEqual(messages, prevMessages)) {
            const messagesIsOpen = document.querySelector('.chat div[data-tippy-root]');

            if (!messagesIsOpen) {
                const numNew = messages.length - prevMessages.length;

                setNumUnread(numNew);
            } else {
                scrollToBottomOfMessagesDiv();
            }
        }

        if (messagesRef && messagesRef.current && numUnread > 0) scrollToBottomOfMessagesDiv();

        setPrevMessages(messages);
    }, [messages]);

    useEffect(() => {
        const chatEl = document.querySelector('.chat button');

        if (chatPreload && chatEl) {
            chatEl.click();
            setMessage(chatPreload);
        }
    }, [chatPreload]);

    return {
        numUnread,
        setNumUnread,
        messagesRef,
        messages,
        handleFilesUpload,
        onChatClick,
        filesRef,
        message,
        setMessage,
        onImageClick,
        onSendMessageClick,
        onEmojiClick,
        showPickEmoji,
        onEmojiSelect,
        showSendImage,
        closeSendMessage,
        sendImage,
        onSendImageMessageClick,
        setShowSendImage,
        onOpenSendImageClick,
        loading
    };

}
