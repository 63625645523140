import { useRef } from 'react';

export interface FileType {
    file_data: string,
    file_name: string
};

interface Props {
    setImage: ({file_name, file_data}: FileType) => void,
    children: React.ReactElement | React.ReactElement[],
    fileTypes: string | undefined
};

const ImageUpload: React.FC<Props> = ({children, setImage, fileTypes}) => {
    const filesRef = useRef<HTMLInputElement>(null);

    function handleFilesClick() {
        if(filesRef && filesRef.current) {
            filesRef.current.click();
        }
    }

    function handleFilesUpload(e: any) {
        e.persist();
        if(e && e.target.files && e.target.files.length > 0) {
            [].forEach.call(e.target.files, (file) => {
                const reader = new FileReader();
                reader.onload = function() {
                    if(typeof reader.result === 'string') {
                        const tempFile = {
                            // @ts-ignore
                            file_name: file.name,
                            file_data: reader.result,
                        };

                        setImage({...tempFile});
                    }
                };
                reader.onerror = function() {};

                reader.readAsDataURL(file);
            })
        }
    }

    return (
        <>
            <input className="h-0 m-0 p-0" style={{visibility: 'hidden'}} type="file" ref={filesRef} onChange={e => handleFilesUpload(e)} accept={fileTypes ? fileTypes : ".svg,.png,.jpg,.jpeg,.jfif"} />
            <div className="m-0" onClick={handleFilesClick}>
                {children}
            </div>
        </>
    );
};

export default ImageUpload;