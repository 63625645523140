import {useEffect, useState} from 'react';

export const UpdateController = () => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            window.addEventListener('update-available', () => {
                setShow(true);
            });
        }
    }, []);

    function refresh() {
        if(navigator && navigator.serviceWorker && navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
                type: 'MESSAGE_IDENTIFIER',
            });
        }
        window.location.reload();
    }

    return {
        show,
        refresh
    }
}

