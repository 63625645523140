import { useState, useEffect, useRef } from 'react';

import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
// import Checkbox from '@material-tailwind/react/Checkbox';

import { podApi } from 'api/pod';

import {useGlobalState} from 'state-pool';

import { useHistory } from 'react-router-dom';

import PdfModal from 'components/document-handling/PdfModal';

// import HelpTextWrapper from 'components/general/HelpTextWrapper';
// import PdfViewer from 'components/document-handling/PdfViewer';

export default function Appliability({stage, setCompleted, completed, pagePathname, bookFormat}) {
    const [showModal, setShowModal] = useState(false);
    const [contract, setContract] = useState('');
    const [showContract, setShowContract] = useState(false);
    // const [error, setError] = useState({});
    const [currentUser] = useGlobalState('currentUser');
    const viewerRef = useRef();

    const history = useHistory();

    useEffect(() => {
        fetchContract();
    }, []);

    // useEffect(() => {
    //     if(contract) {
    //         renderContract(contract);
    //         clickPosition();
    //     }
    // }, [contract]);


    function openContract() {
        setShowContract(true);
    }

    const onContinueClick = () => {
        setCompleted([...completed, stage]);
        history.push(`/${pagePathname}/submission-complete`); 
    }

    const fetchContract = () => {
        podApi.get('/user/viewcontract').then(res => {
            const { data } = res; 
            setContract(data);
        });
    }

    return (
        <div className="w-full text-left">
            <h3 className="text-4xl font-bold">Applicability of Terms and Conditions</h3>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>  
                <CardBody>
                    <p className="text-xl font-bold">Certain circumstances are beyond our control.</p> 
                    {/* <p>Please note that we cannot be responsible for</p> */}
                    <div className="h-5" />
                    <p className="font-bold bg-gray-200 text-black px-8 py-6 rounded-xl">Please not we try our best to prevent any of the following and will communicate with you via our chat system if we detect any errors. After our initial review and communication, Print on Demand is not liable for errors in a final product caused by any of the following reasons</p>
                    <div className="h-5" />
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Spelling, punctuation, and grammatical errors</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Low resolution or low-quality graphics and images</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Damaged fonts</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Transparency issues</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Overprint issues</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Artwork files that are not created following our specifications</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Variances in colour from the conversion of Pantone or RGB colours to CMYK</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Errors in user-selected options such as size, quantity, paper, and finish</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Duplicate orders submitted by the User</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Incorrect files uploaded</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Incorrect file orientation</p>
                    <div className="h-5" />
                    {bookFormat === "print" && <>
                        <p className="font-bold">Material Submission and Production</p>
                        <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Print ready pdf’s of cover and text and/or Indesign files are required.</p>
                        {/* <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>File naming – where possible text files should be named (ISBN)-text.pdf</p>
                        <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>File naming – where possible cover files should be named (ISBN)-cover.pdf</p> */}
                        <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Your publications cover must be supplied with a barcode containing the ISBN with which the title has been added to our system.</p>
                        <div className="h-5" />
                    </>}
                    <p className="font-bold">General Information</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>No refunds for uploaded contents.</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>File revisions for national platform will be charged at R 250 excl vat</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Prices are subject to change. Royalty pay-outs may be affected.</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Royalties for international wholesale platforms will be calculated at 70% after all costs are deducted.</p>
                    <p className="pl-4"><span className="bg-gray-500 rounded w-2 h-2 inline-block mr-4"></span>Royalties for international platforms are subject to the exchange rate at the time of pay-out.</p>
                    <div className="h-5" />
                </CardBody>
            </Card>
            <div className="h-10" />
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-left mb-3 md:mb-auto md:w-1/4 hover:pl-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={() => history.goBack()}
            >
                <Icon name="arrow_back" size="sm" />
                Back
            </Button> 
            {/* <HelpTextWrapper helpText="Accept terms and sign to continue"> */}
                <Button
                    color="red"
                    className={` hover:bg-red hover:text-white hover:pr-2 w-full md:w-1/4 float-right`}
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    // disabled={!acceptTerms}
                    onClick={completed.includes(stage) ? () => history.goForward() :  onContinueClick}
                >
                    {completed.includes(stage) ? "Next" : "Continue"}
                    <Icon name="arrow_forward" size="sm" />
                </Button>
            {/* </HelpTextWrapper> */}
            <div className="h-16" />
            <PdfModal 
                file={contract}
                title={currentUser && (currentUser.firstname + " " + currentUser.lastname)}
                showModal={showContract}
                setShowModal={setShowContract}
            />
        </div>
    );
}