import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

export const useFlutterwavePayment = (amount: number, email: string, phonenumber: string, name: string, title: string, redirectUrl?: string) => {
    const txRef = localStorage.getItem('last_transaction_ref');

    const config: any = {
        public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
        tx_ref: txRef ? txRef : Date.now(),
        amount: 1,
        // country: 'ZA',
        currency: 'USD',
        payment_options: 'card,account,banktransfer,credit',
        redirect_url: !redirectUrl ? `${window.location.origin}/dashboard` : redirectUrl,
        customer: {
            email,
            phonenumber,
            name,
        },
        customizations: {
            title,
            description: 'Payment for Print on Demand Book of One',
            logo: 'https://pod-front.vercel.app/favicon.png',
        },
    };

    // status=successful&tx_ref=1641987715153&transaction_id=2816569

    const handleFlutterPayment = useFlutterwave(config);

    const executePayment = () => {
        handleFlutterPayment({
            callback: (response) => {
                // @ts-ignore
                // const pageQueries: { status: string, tx_ref: string, transaction_id: string } = parseQueryString();

                // createTransaction([1], 999, 1, pageQueries.tx_ref);
                closePaymentModal(); // this will close the modal programmatically
            },

            // transaction failed handling
            onClose: () => {},
        });


    }

    return executePayment;
}


