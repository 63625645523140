import {podApi} from 'api/pod';
import { isValidJson } from 'utils';

export async function sendAdminMessage(message: string) {
    // @ts-ignore
    const currentUser = localStorage.getItem('currentUser') && isValidJson(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'));
    
    if(currentUser && currentUser.id) {
        const body = {
            userId: currentUser.id,
            message
        };
    
        try {
            const res = await podApi.post(`/chat/admin/sendmsg`, body);
    
            if(res && res.data) return res.data;
    
            if(res && !res.data) return res;
    
            return;
    
        } catch(err) {
            console.log(err);
    
            return;
        }
    }
}

export async function sendUserMessage(message: string, imageData?: string) {
    const body = {
        message,
        imageData
    };

    try {
        const res = await podApi.post(`/chat/user/sendmsg`, body);

        if(res && res.data) return res.data;

        if(res && !res.data) return res;

        return;

    } catch(err) {
        console.log(err);

        return;
    }
}

export async function getUserChats() {

    try {
        const res = await podApi.get(`/chat/admin/getuserchats`);

        if(res && res.data) return res.data;

        if(res && !res.data) return res;

        return;

    } catch(err) {
        console.log(err);

        return;
    }
}

export async function getChat() {

    try {
        const res = await podApi.get(`/chat/user/getchat`);

        if(res && res.data) return res.data;

        if(res && !res.data) return res;

        return;

    } catch(err) {
        console.log(err);

        return;
    }
}