import {useState} from 'react';

import Icon from '@material-tailwind/react/Icon';
import Input from '@material-tailwind/react/Input';
import { Button } from '@material-tailwind/react';

import { podApi } from 'api/pod';
import { useAuth } from 'hooks/useAuth';

export default function WhatNext() {
    const [code, setCode] = useState('');
    const { getUserDetails } = useAuth();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    function submit() {
        if(!code) {
            setError('Coupon code can not be blank');

            return;
        }

        const body = {
            coupon_code: code
        }

        podApi.post('/user/addcoupon', body).then(res => {
            if(res && res.data && res.data.description && res.data.description === 'Successful') {
                // refresh user Details
                getUserDetails();
                setSuccess(true);
                // hide form???
            } else {
                setError('Something went wrong when adding your coupon, please check your spelling. Otherwise contact support bo1@printondemand.co.za');
            }
        });
    }

    return (
        <>
            <div className="w-full rounded-xl overflow-hidden p-10 bg-white shadow-2xl">
                <div className="relative h-auto text-left">
                    {!success ? <>
                        <h3 className="text-4xl font-bold font-bold">Enter coupon</h3>
                        <div className="h-4" />
                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                type="text"
                                color="red"
                                placeholder="Enter your coupon code here"
                                size="regular"
                                error={error}
                            />
                        </div>
                        <div className="h-8" />
                        <Button
                            color=""
                            className="border-black w-full hover:bg-primary hover:text-white hover:border-primary"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={submit}
                        >
                            Submit
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                        <div className="h-2" />
                    </> : 
                    <div className="w-full h-full flex flex-col align-center justify-center">
                        <h3 className="text-2xl font-bold">Coupon added successfully</h3>
                        <div className="h-5" />
                        <Button
                            color=""
                            className="border-black w-full hover:bg-primary hover:text-white hover:border-primary"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={() => setSuccess(false)}
                        >
                            Add another
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                    </div>}
                </div>
            </div>
        </>
    );
}
