import { Link } from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Modal from 'components/general/Modal';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export default function ProfileDetails() {
    //Confirmation Modal
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const history = useHistory();

    //handle onClick for confirmation modal and to check if the user has a current pending 

    useEffect(() => {

    }, [currentUser]);

    const handleConfirmClick = () => {
        if (currentUser && (typeof currentUser === 'object')) {
            const pendingChanges = currentUser.pending_changes;
            if (currentUser.user_confirmation === 1 && pendingChanges.length > 0) {
                setShowConfirmationModal(true);
            } else {
                history.push('/profile/update-users')
            }
        }
    }

    return (
        <>
            {/* <Link to="/profile/update-email">
                <Card className="mb-2 transition cursor-pointer group">
                    <CardBody>
                        <div className="relative h-10 justify-center">
                            <span className="text-sm text-gray-900 font-light text-black justify-center h-full">Update email address</span>
                            <Button
                                color=""
                                className="inline float-right -mt-1 group-hover:pl-4"
                                buttonType="link"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => { }}
                            >
                                <Icon name="arrow_forward" size="2xl" color="black" />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Link> */}
            {/* -------CM---------
            Disabled editing banking details until resigning contract has be implemented 
        Remember to uncomment the Link to*/}
            <Link to="#" onClick={handleConfirmClick}>
                <Card className="mb-2 transition cursor-pointer group">
                    <CardBody>
                        <div className="relative h-10">
                            <div className="flex h-full w-full justify-between">
                                <div>
                                    <h5 className="text-sm text-gray-900 font-light text-black h-full flex items-center">Update Request <span className="text-xs text-gray-500 font-light ml-2 items-start">User Details / Banking Details / Publishers Details </span></h5>

                                </div>
                                <Button
                                    color=""
                                    className="h-full items-center group-hover:pl-4"
                                    buttonType="link"
                                    size="sm"
                                    iconOnly
                                    rounded
                                    ripple="light"
                                    onClick={() => { }}
                                >
                                    <Icon name="arrow_forward" size="2xl" color="black" />
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Link>
            <Link to="/profile/change-password">
                <Card className="mb-2 transition cursor-pointer group">
                    <CardBody>
                        <div className="relative h-10 ">
                            <div className="flex h-full w-full justify-between">
                                <span className="text-sm text-gray-900 font-light text-black h-full flex items-center">Change password</span>
                                <Button
                                    color=""
                                    className="h-full items-center group-hover:pl-4"
                                    buttonType="link"
                                    size="sm"
                                    iconOnly
                                    rounded
                                    ripple="light"
                                    onClick={() => { }}
                                >
                                    <Icon name="arrow_forward" size="2xl" color="black" />
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Link>
            <Modal
                showModal={showConfirmationModal}
                setShowModal={setShowConfirmationModal}

            >
                <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        {/* Heroicon name: exclamation */}
                        <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.785-1.346
                                        2.785-3s-1.245-3-2.785-3H5.062c-1.54 0-2.785
                                        1.346-2.785 3s1.245 3 2.785 3z" />
                        </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                            Update Request
                        </h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                We have noticed that there are pending changes on your account. Before proceeding, we would like to confirm if you wish to overwrite these existing changes and submit a new request
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 mt-4 sm:px-6 flex justify-between">
                    <Button
                        color="red"
                        buttonType="outline"
                        onClick={() => setShowConfirmationModal(false)}
                        ripple="dark"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="green"
                        buttonType="outline"
                        onClick={() => {
                            setShowConfirmationModal(false);
                            history.push('/profile/update-users')
                        }}
                        ripple="light"
                    >
                        Confirm
                    </Button>
                </div>
            </Modal>
            <div className='h-10' />
        </>
    );
}
