import { useState, useEffect } from 'react';

import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import Input from "@material-tailwind/react/Input";

import md5 from 'md5';

export default function DropdownInputSearchAddNew({ value, setValue, placeholder, options, color, error, onFocus, noMatches, inputId }) {
    const [rerender, setRerender] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');

    const id = md5(placeholder);

    useEffect(() => {
        if (!value && query) setQuery('');
    }, [value]);

    const onOptionClick = (option) => {
        if (option && option.value) setValue(option.value);
        setQuery(option.label)

        resetDropdown();
    }

    function resetDropdown() {
        setRerender(!rerender);

        setIsOpen(false);
    }

    useEffect(() => {
        if (!rerender) setRerender(true);
    }, [rerender]);

    function showDropdown() {
        const dropdownEl = document.getElementById(id);

        if (dropdownEl) dropdownEl.click();
    }

    function _onChange(e) {
        if (e && e.target) {
            setQuery(e.target.value);

            if (!isOpen) {
                setIsOpen(true);
                showDropdown();
            }
        }
    }

    return (
        <>
            <Input
                outline={false}
                value={query}
                type="text"
                color="red"
                size="regular"
                placeholder={placeholder}
                onChange={e => _onChange(e)}
                onClick={() => { setQuery(''); setIsOpen(true); showDropdown(); }}
                onFocus={onFocus}
                error={error}
                id={inputId}
            />
            <div className="h-0 relative" style={{ top: '-1em' }}>
                {rerender && (
                    <>
                        <Dropdown
                            color={color ? color : 'red'}
                            placement="bottom-start"
                            id={id}
                            buttonType="link"
                            size="regular"
                            rounded={false}
                            block={false}
                            ripple="light"
                            buttonText=""
                            className="hover:bg-green hover:text-white invisible h-0 p-0 m-0"
                        >
                            {query && <DropdownItem color={color ? color : "red"} ripple="light" onClick={() => onOptionClick({ value: query, label: query })}>
                                add new "{query}"
                            </DropdownItem>}
                            {options && Array.isArray(options) && options.length > 0 && options.filter(opt => opt.label.toLowerCase().includes(query.toLowerCase())).length > 0 ? options.filter(opt => opt.label.toLowerCase().includes(query.toLowerCase())).map(({ label, value }, i) =>
                            (
                                <DropdownItem key={i} color={color ? color : "red"} ripple="light" onClick={() => onOptionClick({ value, label })}>
                                    {label}
                                </DropdownItem>
                            )
                            ) : (
                                <p className="p-4 text-gray" >
                                    {noMatches ? noMatches : "No matches for your search term"}
                                </p>
                            )}
                        </Dropdown>
                    </>
                )}
            </div>
        </>
    )
}
