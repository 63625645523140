import React, {
    useCallback,
    useEffect,
    useState
} from 'react'

import Button from '@material-tailwind/react/Button'
import Card from '@material-tailwind/react/Card'
import CardBody from '@material-tailwind/react/CardBody'
import Icon from '@material-tailwind/react/Icon'

import DocumentUpload from 'components/document-handling/DocumentUpload'
import ImageUpload from 'components/image-handling/ImageUpload'

import { handlePacketUpload2, handlePacketUploadCover, uploadBook } from 'routes/userRoutes'

import { useHistory } from 'react-router-dom'

import HelpText from 'components/general/HelpText'
import Modal from 'components/general/Modal'
import { useAlert } from 'hooks/useAlert'

import { getImageDimensions } from 'utils'

import { useChunkUpload, useCoverChunkUpload } from 'hooks/useChunkUpload'
import { findKey } from 'lodash'

export default function CoverBookThumbnail({
    stage,
    completed,
    setCompleted,
    cover,
    setCover,
    thumbnail,
    setThumbnail,
    bookDocument,
    setBookDocument,
    localBookId,
    internationalBookId,
    rawOrPdf,
    pagePathname,
    coverNeeded,
    thumbnailNeeded,
    formattingNeeded,
    bleedingNeeded,
    subtitle,
    author,
    contributors,
    publicationDate,
    onSaleDate,
    language,
    genre,
    keywords,
    bisacCode,
    shortDescription,
    longDescription,
    mainDescription,
    copyrightHolder,
    copyrightDate,
    recommendedAudience,
    indicatedAudienceWarning,
    audioFiles,
    bookFormat,
    spotUvNeeded,

    heavyGraphics,
    setHeavyGraphics,

    conversionNeeded,
    setConversionNeeded
}) {
    const [showLoading, setShowLoading] = useState(false)
    const [thumbnailError, setThumbnailError] = useState(null);
    const history = useHistory()
    const { newAlert } = useAlert()

    const [uploadStatus, setUploadStatus] = useState('');
    const [bookFiles, setBookFiles] = useState([]);
    const [coverFiles, setCoverFiles] = useState([]);

    const [showCanvaTutorial, setShowCanvaTutorial] = useState(false);

    const chunkSize = 1000 * 1024;

    let bookProgress = 0;
    let coverProgress = 0;

    const {
        isDone,
        setIsDone,
        currentFileIndex,
        currentChunkIndex,
        isBookUploaded,
    } = useChunkUpload(
        localBookId,
        internationalBookId,
        bookFiles,
        chunkSize,
        // coverIsDone,
        nextPage
    );

    const {
        coverIsDone,
        setCoverIsDone,
        currentCoverIndex,
        currentCoverChunkIndex,
        isCoverUploaded,
    } = useCoverChunkUpload(
        localBookId,
        internationalBookId,
        coverFiles,
        chunkSize,
        // isDone,
        nextPage
    );

    const onContinueClick = async () => {
        if (
            (coverNeeded || (cover) || (bookFormat === 'audiobook') || (bookFormat === 'ebook')) &&
            (thumbnailNeeded || (thumbnail && thumbnail.file_data) || (bookFormat === 'ebook')) && !thumbnailError
        ) {
            setShowLoading(true)
            setUploadStatus('Uploading cover and thumbnail');

            uploadBook(
                localBookId,
                internationalBookId,
                // cover.file_data,
                thumbnail.file_data,
                rawOrPdf ? rawOrPdf : 'pdf',
                coverNeeded,
                thumbnailNeeded,
                formattingNeeded,
                bleedingNeeded,
                subtitle,
                author,
                contributors,
                publicationDate,
                onSaleDate,
                language,
                genre.text,
                keywords,
                bisacCode,
                shortDescription,
                longDescription,
                mainDescription,
                copyrightHolder,
                copyrightDate,
                recommendedAudience,
                indicatedAudienceWarning,
                audioFiles,
                spotUvNeeded,

                heavyGraphics,
                conversionNeeded,
            ).then((res) => {

                if (res && typeof res === 'object' && res.statusCode === 0) {
                    if (bookFormat === 'ebook') {
                        setCoverIsDone(false);
                        setIsDone(true);
                        setUploadStatus('Uploading Book and Cover');

                    }

                    if (bookFormat === 'print') {
                        setCoverIsDone(true);
                        setIsDone(true);
                        setUploadStatus('Uploading Book and Cover');
                    }

                } else {
                    newAlert('danger', 'Something went wrong', 'This may be due to your internet connection.', onContinueClick, 'retry');
                };
            })
        }
    }

    // function to clear state on back click
    const clearDocumentState = () => {
        setCover('');
        setThumbnail('');
        setBookDocument('');
        setBookFiles([]);
        setCoverFiles([]);
        setUploadStatus('');
    }

    const onBackClick = () => {
        clearDocumentState();
    
        history.goBack()
    }

    useEffect(() => {
        history.block((location, action) => {
            if (action === 'POP') { // only block backward navigation
                clearDocumentState();
            }
        });

        return;
    }, []);

    useEffect(() => {
        if (bookFormat === 'ebook') {
            if (isBookUploaded) {
                nextPage();
            }
        }

        if (bookFormat === 'print') {
            if (isBookUploaded && isCoverUploaded) {
                nextPage();
            }
        }
        if (bookFormat === 'print' && coverNeeded) {
            if (isBookUploaded) {
                nextPage();
            }
        }

        if (bookFormat === 'print' && coverNeeded && thumbnailNeeded) {
            if (isBookUploaded) {
                nextPage();
            }
        }
    })

    function nextPage() {
        history.push(`/${pagePathname}/submission-complete`)
        setCompleted([...completed, stage]);
    }
    //Handling the thumbnail upload
    async function handleThumbnailUpload(image) {

        if (image && typeof image === 'object' && image.file_data) {
            const dimensions = await getImageDimensions(image.file_data);
            let filename = image.file_name;
            let fileExt = filename.slice(filename.lastIndexOf(".") + 1).toLowerCase();  // Get the file extension more robustly

            if (bookFormat === "print") {
                if (dimensions.height !== 1000 && dimensions.width !== 1000) {
                    setThumbnailError(`Thumbnail dimensions are incorrect - We require a thumbnail image that is 1000px x 1000px whereas the image you selected is ${dimensions.width}px x ${dimensions.height}px`);
                    return;
                }
            }
            if (bookFormat === 'ebook') {
                if (dimensions.height !== 2500 && dimensions.width !== 1600) {
                    setThumbnailError(`Thumbnail dimensions are incorrect - We require a thumbnail image that is 1600px x 2500px whereas the image you selected is ${dimensions.width}px x ${dimensions.height}px`);
                    return;
                }
            }
            //Not  needed
            if (fileExt === 'png') {
                setThumbnailError(`Cannot upload PNG files - Please upload a JPG or JPEG file`);
                return;
            }

            if (fileExt !== 'jpg' && fileExt !== 'jpeg') {
                setThumbnailError(`Cannot upload ${fileExt.toUpperCase()} files - Please upload a JPG or JPEG file`);
                return;
            }

            setThumbnail(image)
        } else {
            setThumbnailError(`Please upload a valid JPG or JPEG file`);
            return;
        }


    }

    useEffect(() => {
        if (!cover || !cover.file_data) return;
        const packetCount = (cover && cover.file_data ? Math.ceil(cover.file_data.size / 1000000) : null);

        handlePacketUploadCover(cover, packetCount).then(res => {
            setShowLoading(false);
        });
    }, [cover]);

    useEffect(() => {
        if (!bookDocument || !bookDocument.file_data) return;

        const packetCount = (bookDocument && bookDocument.file_data ? Math.ceil(bookDocument.file_data.size / 1000000) : null);

        handlePacketUpload2(bookDocument, packetCount).then(res => {
            setShowLoading(false);
        });
    }, [bookDocument]);

    function resolveBookDocTypeImage() {
        if (rawOrPdf === 'raw') return '/assets/icons/raw.png';

        if (bookDocument && bookDocument.file_name) {
            switch (true) {
                case bookDocument.file_name.includes('.indd'): return '/assets/icons/indd.png';
                case bookDocument.file_name.includes('.docx'): return '/assets/icons/raw.png';
                case bookDocument.file_name.includes('.epub'): return '/assets/icons/epub.png';

                default: return '/assets/icons/pdf.png';
            }
        }

        return '/assets/icons/pdf.png';
    }

    // console.log((bookFormat === 'ebook' && conversionNeeded), bookFormat, conversionNeeded)

    return (
        <>
            <div className="w-full text-left">
                <h3 className="text-4xl font-bold mb-4">
                    {
                        !coverNeeded && !thumbnailNeeded
                            ? 'Upload Cover, Book & Thumbnail'
                            : !thumbnailNeeded
                                ? 'Upload Book & Thumbnail'
                                : !coverNeeded
                                    ? 'Upload Book & Cover'
                                    : 'Upload Book'
                    }
                </h3>
                <div className="h-8" />
                {/* Senpai this is how the upload cover, book and thumbnail page looks like 
                {((!coverNeeded && (bookFormat !== 'audiobook') && (bookFormat !== 'ebook')) || (!coverNeeded && ((bookFormat === 'ebook') && thumbnailNeeded))) && (
                */}
                {(!coverNeeded && (bookFormat !== 'audiobook') && (bookFormat !== 'ebook')) && (
                    <Card className="max-w-3xl text-center">
                        <div className="h-2" />
                        <CardBody>
                            <HelpText
                                iconSize="2xl"
                                helpText="This will be the image displayed on the cover of your printed book."
                                className="justify-center"
                            >
                                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                                    <span className="italic bold text-red mr-2">*</span>Upload your book's cover
                                </p>
                                <p className="italic text-xs text-red ml-1 pt-1">required</p>
                            </HelpText>
                            <p className="text-gray-600 ml-1 text-md leading-normal mb-5">
                                Only .pdf format is accepted for cover uploads.
                            </p>

                            <div className="w-full flex justify-center">
                                {cover && (
                                    <img
                                        className="w-40 h-auto"
                                        alt={`book_cover`}
                                        src={'/assets/icons/pdf.png'}
                                    />
                                )}
                            </div>
                            {cover && (
                                <div className="w-full flex items-center p-5 rounded-xl bg-gray-200 mt-8">
                                    <Icon name="description" size="3xl" />
                                    <a className="ml-2 text-lg font-light">{cover.file_name}</a>
                                    <span className="justify-self-end ml-auto text-lg font-light">
                                        <Button
                                            color="red"
                                            buttonType={'link'}
                                            size="lg"
                                            ripple="dark"
                                            iconOnly
                                            rounded
                                            onClick={() => setCover(null)}
                                        >
                                            <Icon name="close" color="red" size="2xl" />
                                        </Button>
                                    </span>
                                </div>
                            )}
                            <DocumentUpload
                                setFile={(image) => setCover(image)}
                                setFiles={setCoverFiles}
                                fileTypes={'.pdf'}
                                useBlob={true}
                            >
                                <Button
                                    color="red"
                                    className="w-full hover:bg-red hover:text-white"
                                    buttonType={'link'}
                                    size="lg"
                                    ripple="dark"
                                    onClick={() => { }}
                                >
                                    {!cover ? 'Upload file' : 'Choose a different file'}
                                    {!cover && <Icon name="upload" size="sm" />}
                                </Button>
                            </DocumentUpload>
                        </CardBody>
                    </Card>
                )}
                <div className="h-5" />
                {!thumbnailNeeded && (
                    <Card className="max-w-3xl text-center">
                        <div className="h-2" />
                        <CardBody>
                            <HelpText
                                iconSize="2xl"
                                helpText="This will be the image displayed when your book is displayed on ecommerce platforms."
                                className="justify-center"
                            >
                                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                                    <span className="italic bold text-red mr-2">*</span>Upload your book's thumbnail
                                </p>
                                <p className="italic text-xs text-red ml-1 pt-1">required</p>
                            </HelpText>
                            <div className="w-full flex flex-col md:flex-row items-center p-8 rounded-xl bg-gray-200 mb-5">
                                <div className="text-left md:w-2/3">
                                    <p className="text-black">
                                        We recommend using{' '}
                                        <a className="font-bold underline text-red-500" href="https://www.canva.com/" target="_blank" rel="noopener noreferrer">Canva</a>{' '}
                                        to get the correct dimensions. Creating an account is completely free.
                                        Click {' '}
                                        <span className="underline font-bold text-red-500 cursor-pointer" onClick={() => setShowCanvaTutorial(true)}>here</span> {' '}
                                        for a video tutorial on how to get the correct dimensions using Canva.
                                    </p>
                                    {/* <p className="text-black mb-4">

                                    </p> */}
                                </div>
                                <a className="md:justify-self-end md:ml-auto mt-5 md:mt-2  w-full md:w-auto" href="https://www.canva.com/" target="_blank" rel="noopener noreferrer">
                                    <Button
                                        color="blue"
                                        className="hover:bg-blue-500 hover:text-white w-full md:w-auto"
                                        buttonType="outline"
                                        size="sm"
                                        ripple="dark"
                                    >
                                        Go to Canva
                                        <Icon name="arrow_forward" />
                                    </Button>
                                </a>
                            </div>

                            <p className="text-gray-600 ml-1 text-md leading-normal mb-5">
                                {bookFormat === 'audiobook' ? 'A .jpeg image is required that is 3000px x 3000px @300dpi' : bookFormat !== 'ebook' ? 'A .jpeg image is required that is 1000px x 1000px @300dpi' : 'A .jpeg image is required that is 1600px x 2500px @300dpi'}
                            </p>
                            <div className="w-full flex justify-center">
                                {thumbnail && (
                                    <img
                                        className="w-40 h-auto"
                                        alt={`book_cover`}
                                        src={thumbnail.file_data}
                                    />
                                )}
                            </div>
                            {thumbnail && (
                                <div className="w-full flex items-center p-5 rounded-xl bg-gray-200 mt-8">
                                    <Icon name="image" size="3xl" />
                                    <a className="ml-2 text-lg font-light">{thumbnail.file_name}</a>
                                    <span className="justify-self-end ml-auto text-lg font-light">
                                        <Button
                                            color="red"
                                            buttonType={'link'}
                                            size="lg"
                                            ripple="dark"
                                            iconOnly
                                            rounded
                                            onClick={() => { setThumbnail(null); setThumbnailError(''); }}
                                        >
                                            <Icon name="close" color="red" size="2xl" />
                                        </Button>
                                    </span>
                                </div>
                            )}
                            <ImageUpload setImage={(image) => handleThumbnailUpload(image)} fileTypes=".jpeg,.jpg">
                                <Button
                                    color="red"
                                    className="w-full hover:bg-red hover:text-white"
                                    buttonType={'link'}
                                    size="lg"
                                    ripple="dark"
                                    onClick={() => setThumbnailError(null)}
                                >
                                    {!thumbnail ? 'Upload image' : 'Choose a different file'}
                                    {!thumbnail && <Icon name="upload" size="sm" />}
                                </Button>
                            </ImageUpload>
                            {thumbnailError && <p className="text-left text-red-500 mt-2">{thumbnailError}</p>}
                        </CardBody>
                    </Card>
                )}
                <div className="h-5" />
                <Card className="max-w-3xl text-center">
                    <div className="h-2" />
                    <CardBody>
                        <HelpText
                            iconSize="2xl"
                            helpText={`This is where you upload the ${rawOrPdf ? rawOrPdf : '.pdf'} file of your book`}
                            className="justify-center"
                        >
                            <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                                <span className="italic bold text-red mr-2">*</span>Upload your book
                            </p>
                            <p className="italic text-xs text-red ml-1 pt-1">required</p>
                        </HelpText>
                        <p className="text-gray-600 ml-1 text-md leading-normal mb-2">
                            {
                                (rawOrPdf && rawOrPdf !== 'pdf')
                                    ? '.docx' :
                                    bookFormat === 'ebook' && !conversionNeeded
                                        ? '.epub' :
                                        (bookFormat === 'ebook' && conversionNeeded)
                                            ? '.pdf, .docx and .indd' :
                                            '.pdf'

                            } formats are accepted for this book upload
                        </p>
                        <div className="w-full flex justify-center">
                            {bookDocument && (
                                <img
                                    className="w-40 h-auto mt-5"
                                    alt={`book upload type`}
                                    src={
                                        resolveBookDocTypeImage()
                                    }
                                />
                            )}
                        </div>
                        {bookDocument && (
                            <div className="w-full flex items-center p-5 rounded-xl bg-gray-200 mt-8">
                                <Icon name="description" size="3xl" />
                                <a className="ml-2 text-lg font-light">{bookDocument.file_name}</a>
                                <span className="justify-self-end ml-auto text-lg font-light">
                                    <Button
                                        color="red"
                                        buttonType={'link'}
                                        size="lg"
                                        ripple="dark"
                                        iconOnly
                                        rounded
                                        onClick={() => setBookDocument(null)}
                                    >
                                        <Icon name="close" color="red" size="2xl" />
                                    </Button>
                                </span>
                            </div>
                        )}
                        <DocumentUpload
                            setFile={(image) => setBookDocument(image)}
                            setFiles={setBookFiles}
                            fileTypes={
                                rawOrPdf === 'raw' ?
                                    '.docx' :
                                    (bookFormat === 'ebook' && !conversionNeeded) ?
                                        '.epub' :
                                        (bookFormat === 'ebook' && conversionNeeded) ?
                                            '.pdf, .docx, .indd' :
                                            '.pdf'
                            }
                            useBlob={true}
                        >
                            <Button
                                color="red"
                                className="w-full hover:bg-red hover:text-white"
                                buttonType={'link'}
                                size="lg"
                                ripple="dark"
                                onClick={() => { }}
                            >
                                {!bookDocument ? 'Upload file' : 'Choose a different file'}
                                {!bookDocument && <Icon name="upload" size="sm" />}
                            </Button>
                        </DocumentUpload>
                    </CardBody>
                </Card>
                <div className="h-10" />
                <Button
                    color="red"
                    className="hover:bg-red hover:text-white hover:bg-red w-full float-left mb-3 md:mb-auto md:w-1/4 hover:pl-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onBackClick}
                >
                    <Icon name="arrow_back" size="sm" />
                    Back
                </Button>
                <Button
                    color="red"
                    className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/4 hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={
                        completed.includes(stage)
                            ? () => history.goForward()
                            : onContinueClick
                    }
                >
                    {completed.includes(stage) ? 'Next' : 'Continue'}
                    <Icon name="arrow_forward" size="sm" />
                </Button>
                <div className="h-10" />
            </div>
            <Modal
                showModal={showLoading}
                setShowModal={setShowLoading}
                size="sm"
                closeOnOutsideClick={false}
            >
                <div style={{ width: '15rem' }} className="w-36 flex flex-col items-center justify-center">
                    <img
                        // style={{ top: '16px' }}
                        className="h-20 w-auto relative mb-5"
                        alt="loading"
                        src="/images/loading.gif"
                    />
                    <p>{uploadStatus}</p>
                    <>
                        {isDone && (
                            <>
                                {bookFiles.map((file, fileIndex) => {
                                    bookProgress = 0;
                                    if (file.finalFilename) {
                                        bookProgress = 100;
                                    } else {
                                        const uploading = fileIndex === currentFileIndex;
                                        const chunks = Math.ceil(file.size / chunkSize);
                                        if (uploading) {
                                            bookProgress = Math.round(currentChunkIndex / chunks * 100);
                                        } else {
                                            bookProgress = 100;
                                        }
                                    }

                                    return (
                                        <React.Fragment key={fileIndex}>
                                            <div style={{ height: 25 }} className="w-full rounded-xl border-gray-500 border-2 flex items-center justify-center mt-4 relative">
                                                {/* <div className="text-white">{file.name}</div> */}
                                                {bookProgress !== 100 ? <div
                                                    className={"h-full bg-green-500 absolute left-0 top-0 bottom-0 flex items-center bg-green rounded-xl"}
                                                    style={{ width: bookProgress + '%', justifySelf: 'start' }}>
                                                    <p className="justify-self-end ml-auto pl-2 mr-2">{bookProgress}%</p>
                                                </div> : <div
                                                    className={"h-full bg-green-500 absolute left-0 top-0 bottom-0 flex items-center bg-green rounded-xl"}
                                                    style={{ width: '100%', justifySelf: 'start' }}>
                                                    <p className="justify-self-end ml-auto pl-2 mr-2">100%</p>
                                                </div>
                                                }
                                            </div>
                                        </React.Fragment>
                                    );

                                })}
                                {coverIsDone && (
                                    <>
                                        {coverFiles.map((file, fileIndex) => {
                                            coverProgress = 0;
                                            if (file.finalFilename) {
                                                coverProgress = 100;
                                            } else {
                                                const uploading = fileIndex === currentCoverIndex;
                                                const chunks = Math.ceil(file.size / chunkSize);
                                                if (uploading) {
                                                    coverProgress = Math.round(currentCoverChunkIndex / chunks * 100);
                                                } else {
                                                    coverProgress = 100;
                                                }
                                            }

                                            return (
                                                <React.Fragment key={fileIndex}>
                                                    <div style={{ height: 25 }} className="w-full rounded-xl border-gray-500 border-2 flex items-center justify-center mt-4 relative">
                                                        {coverProgress !== 100 ? <div
                                                            className={"h-full bg-green-500 absolute left-0 top-0 bottom-0 flex items-center bg-green rounded-xl"}
                                                            style={{ width: coverProgress + '%', justifySelf: 'start' }}>
                                                            <p className="justify-self-end ml-auto pl-2 mr-2">{coverProgress}%</p>
                                                        </div> : <div
                                                            className={"h-full bg-green-500 absolute left-0 top-0 bottom-0 flex items-center bg-green rounded-xl"}
                                                            style={{ width: '100%', justifySelf: 'start' }}>
                                                            <p className="justify-self-end ml-auto pl-2 mr-2">100%</p>
                                                        </div>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                )}
                            </>

                        )}
                    </>
                </div>
            </Modal>

            <Modal
                showModal={showCanvaTutorial}
                setShowModal={setShowCanvaTutorial}
                title=" "
            // size="sm"
            >
                <video className="rounded-xl mt-2" src="/tutorials/canva.mp4" controls>

                </video>
                <div className="w-full flex mt-5">
                    <Button
                        color="red"
                        buttonType="filled"
                        onClick={() => setShowCanvaTutorial(false)}
                        ripple="dark"
                        className="ml-auto justify-self-end"
                    >
                        Close
                    </Button>
                    <a className="justify-self-end ml-5" href="https://www.canva.com/" target="_blank" rel="noopener noreferrer">
                        <Button
                            color="blue"
                            className="hover:bg-blue-500 hover:text-white"
                            buttonType="outline"
                            ripple="dark"
                        >
                            Go to Canva
                            <Icon name="arrow_forward" />
                        </Button>
                    </a>
                </div>
            </Modal>
        </>
    )
}
// 3189707
// 2392262