import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';

import { isValidJson } from 'utils/index';

import { useHistory } from 'react-router-dom';

export default function EmailContract({stage, pagePathname, setCompleted, completed, recordingRequired, bookFormat}) {
    let currentUser = localStorage.getItem('currentUser');
    const history = useHistory();

    if(isValidJson(currentUser)) currentUser = JSON.parse(currentUser);

    const onContinueClick = () => {
        setCompleted([...completed, stage]);

        if(bookFormat === 'audiobook' && !recordingRequired) {
            history.push(`/${pagePathname}/upload-audio`);
        } else {
            history.push(`/${pagePathname}/metadata`);

        }
        // else {
        //     history.push(`/${pagePathname}/upload-type`);
        // }
    }

    return (
        <div className="w-full text-left">
            <h3 className="text-4xl font-bold ">Check Your Email</h3>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>  
                <CardBody>
                    <div className="h-full w-full flex items-center py-4 flex-col justify-center">
                        <Icon name="mail_outline" color="red" size="9xl" />
                        <p className="text-base text-center mt-6">
                            We have emailed your contract to 
                            <span className="text-red font-bold">{" "}{currentUser && currentUser.email}</span>
                            . 
                            <br />
                            If you have any additional questions, please contact us at
                            <span className="text-red font-bold">{" "}bo1@printondemand.co.za</span>
                        </p>
                    </div>
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-10" />
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-left mb-3 md:mb-auto md:w-1/4 hover:pl-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={() => history.goBack()}
            >
                <Icon name="arrow_back" size="sm" />
                Back
            </Button> 
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/4 hover:pr-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={completed.includes(stage) ? () => history.goForward() :  onContinueClick}
            >
                {completed.includes(stage) ? "Next" : "Continue"}
                <Icon name="arrow_forward" size="sm" />
            </Button>
        </div>
    );
}
