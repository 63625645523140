import {useEffect} from 'react';
import {setPageTitle} from 'utils';

export default function Sales() {

    useEffect(() => {
        setPageTitle('Sales');
    }, []);

    return (
        <>
            <div className="bg-gray-200 px-3 md:px-8 h-40" />

            <div className="px-3 md:px-8 -mt-24">
                <div className="container mx-auto max-w-full">
                    {/* <div className="grid grid-cols-1 lg:gap-5 xl:grid-cols-6">
                        <div className="xl:col-start-1 xl:col-end-4">
                            <BalanceInfo />
                            <div className="h-5" />
                            <DepositNow />
                            <div className="h-5" />
                        </div>
                        <div className="xl:col-start-4 xl:col-end-7">
                            <Options />
                            <Options2 />
                        </div>
                        <div className="xl:col-start-1 xl:col-end-4 mb-14">

                        </div>
                        <div className="xl:col-start-4 xl:col-end-7 mb-14">

                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}
