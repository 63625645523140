import {useState} from 'react';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Heading3, Textarea } from '@material-tailwind/react';
import { Link } from 'react-router-dom';

export default function ContactUs() {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    function onSendClick() {

    }

    return (
        <Card className="text-left mt-5">  
            <Heading3 className="text-4xl font-bold">Have a question?</Heading3>
            {/* <div className="h-2" />
            <CardBody>

                <div className="mb-4 text-left">
                    <Input
                        outline={false}
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                        type="text"
                        color="red"
                        placeholder="Subject"
                        size="regular"
                    />
                </div>

                <div className="mb-4 mt-10 text-left">
                    <Textarea
                        outline={false}
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        type="text"
                        color="red"
                        placeholder="Your message"
                        size="regular"
                    />
                </div>

            </CardBody> */}
            <CardBody>
                <p>
                    Navigate to the dashboard and open the chat to contact one of our operators
                </p>
                {/* <Link to={{pathname:'/dashboard',search:'?chat=true'}}>
                    <Button
                        color="red"
                        className="w-full hover:bg-red hover:text-white hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onSendClick}
                    >
                        Let's Chat
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                </Link> */}
            </CardBody>
        </Card>
    );
}