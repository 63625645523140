import {useState} from 'react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { Paragraph } from '@material-tailwind/react';

const QUESTIONS = [
    {
        q: "Question",
        a: "Answer"
    },
    {
        q: "Question",
        a: "Answer"
    },
    {
        q: "Question",
        a: "Answer"
    },
    {
        q: "Question",
        a: "Answer"
    },
    {
        q: "Question",
        a: "Answer"
    },
    {
        q: "Question",
        a: "Answer"
    },
];

export default function Faq() {
    const [selected, setSelected] = useState('');

    return (
        <div className="mt-5">
            {QUESTIONS.map(({q, a}, i) => (
                <div key={i} onClick={() => selected !== i ? setSelected(i) : setSelected(null)}>
                    <Card className="mb-2 transition cursor-pointer group">
                        <CardBody>
                            <div className={`relative h-20 md:h-5 ${selected === i ? "mb-5" : ""}`}>
                                <div className="inline left w-2/3">
                                    <span className="text-sm text-gray-900 font-light">{q}</span>
                                </div>
                                <Button
                                    color=""
                                    className="inline float-right -mt-1 w-1/3"
                                    buttonType="link"
                                    size="sm"
                                    iconOnly
                                    rounded
                                    ripple="light"
                                    onClick={() => {}}
                                >
                                        <Icon name={selected === i ? "remove" : "add"} size="2xl" color="black" />
                                </Button>
                            </div>
                            {selected === i && (
                                <div className="h-auto text-left">
                                    <Paragraph>
                                        {a}
                                    </Paragraph>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </div>
            ))}
        </div>
    );
}
