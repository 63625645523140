import Card from '@material-tailwind/react/Card';
import {useEffect, useState} from 'react';

import DayPickerInput from 'components/inputs/DayPickerInput';
import Button from '@material-tailwind/react/Button';
import Input from '@material-tailwind/react/Input';

import {format} from 'date-fns';

export default function BookOrders({ orders }) {
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [query, setQuery] = useState('');
    const [showTo, setShowTo] = useState(12);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if(orders && Array.isArray(orders) && orders.length > 0) {
            setFilteredOrders(orders);

            setFirstLoad(false);
        }
    }, [orders]);

    useEffect(() => {
        if(query && typeof query === 'string' && orders && Array.isArray(orders) && orders.length > 0) {
            let filtered = orders.filter(order => {
                if(order.product_title.toLowerCase().includes(query.toLowerCase())) return true;
                if(order.customer.toLowerCase().includes(query.toLowerCase())) return true;
                if(order.sale_status.toLowerCase().includes(query.toLowerCase())) return true;
            });
            setFilteredOrders(filtered);
        }
    }, [query]);

    useEffect(() => {
        let from, to;
        try {
            if(fromDate) {
                from = new Date(fromDate);
            } else {
                from = new Date();
            }
            if(toDate) {
                to = new Date(toDate);
            } else {
                to = new Date();
            }
        } catch(err) {
            if(err) return;
        }

        if(from && to && orders && Array.isArray(orders) && !firstLoad) {
            const mapArr = query ? filteredOrders : orders;

            let filtered = mapArr.filter(order => {
                let orderDate;
                try {
                    orderDate = new Date(order.createdAt);
                } catch (err) {
                    return;
                }

                if(orderDate >= from && orderDate <= to) return true;
            });
            setFilteredOrders(filtered);
        }
    }, [fromDate, toDate]);

    return (
        <>
            <div className="h-5" />
            <div className="grid grid-cols-1 lg:gap-5 xl:grid-cols-6">
                <div className="xl:col-start-1 xl:col-end-3">
                    <div className="bg-white rounded-xl shadow-xl p-4 mb-5">
                        <p className="font-bold mt-2 mb-4">Filter By Search Query</p>
                        <Input
                            outline={true}
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            color="red"
                            placeholder="Search Orders..."
                            size="large"
                            type="text"
                            // onFocus={() => clearError('author')}
                            // error={error && error.subtitle}
                        />
                        <p className="font-bold mt-8 mb-4">Filter By Date</p>
                        <DayPickerInput
                            outline={true}
                            day={fromDate}
                            setDay={setFromDate}
                            color="red"
                            placeholder="Filter from (Date)"
                            size="large"
                            // onFocus={() => clearError('onSaleDate')}
                            // error={error && error.onSaleDate}
                            id="from-date-orders"
                        />

                        <DayPickerInput
                            outline={true}
                            day={toDate}
                            setDay={setToDate}
                            color="red"
                            placeholder="Filter to (Date)"
                            size="large"
                            // onFocus={() => clearError('onSaleDate')}
                            // error={error && error.onSaleDate}
                            id="to-date-orders"
                        />
                    </div>
                </div>
                <div className="xl:col-start-3 xl:col-end-7">
                    {filteredOrders && Array.isArray(filteredOrders) && filteredOrders.length > 0 && filteredOrders.slice(0, showTo).map(order => (
                    <div className="w-full bg-white rounded-xl shadow-xl p-4 mb-2">
                        <div className="w-full flex">
                            {/* <p className="font-bold">{order.product_title}</p> */}
                            <p className="text-gray-500">{format(new Date(order.createdAt), 'dd-MM-yyyy HH:mm')}</p>
                        </div>

                        <div className="w-full flex mt-5">
                            <p className="">Customer</p>
                            <p className=" ml-auto justify-self-end"> {order.customer}</p>
                        </div>

                        <div className="w-full flex">
                            <p className="">Sale Status</p>
                            <p className={` ml-auto justify-self-end ${order.sale_status.toLowerCase() === "shipped to customer" ? "text-green-500" : "text-red-500"}`}> {order.sale_status}</p>
                        </div>
                        
                        <div className="w-full flex">
                            <p className="">Selling Price</p>
                            <p className=" ml-auto justify-self-end"> {order.selling_price}</p>
                        </div>

                        <div className="w-full flex">
                            <p className="">Quantity Ordered</p>
                            <p className=" ml-auto justify-self-end"> {order.quantity}</p>
                        </div>

                        <div className="w-full flex justify-end my-5">
                            <a target="_blank" href={order.takealot_url} className="false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-3 px-7 text-sm leading-relaxed text-white bg-red-500 hover:bg-red-700 focus:bg-red-400 active:bg-red-800 shadow-md-red hover:shadow-lg-red w-full md:w-1/2">
                                View Product
                            </a>
                        </div>
                    </div>   
                ))}
                {filteredOrders && Array.isArray(filteredOrders) && filteredOrders.length > 12 && <button onClick={() => setShowTo(showTo + 12)} className="false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-3 px-7 text-sm leading-relaxed text-white bg-red-500 hover:bg-red-700 focus:bg-red-400 active:bg-red-800 shadow-md-red hover:shadow-lg-red w-full mt-5">
                    Load More
                </button>}
                </div>
            </div>
        </>
    )
}
