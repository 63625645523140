import {useState, useEffect} from 'react';

import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import {Link} from 'react-router-dom';

import SuccessfulSubmission from 'components/forgot-password/SuccessfulSubmission';

import {isValidEmail, setPageTitle} from 'utils';

import {forgotPassword} from 'routes/userRoutes';

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setPageTitle('Forgot Password');
    }, []);

    function onButtonClick() {
        if(!email) {
            setError('Please enter your email address to continue');

            return;
        }

        if(email && !isValidEmail(email)) {
            setError('Please enter a valid email address');

            return;
        }

        forgotPassword(email).then(res => {
            if(res && typeof res === 'object' && res.statusCode === 0) {
                setSuccess(true);
            } else {
                setError('Something went wrong');
            }
        });
    }

    return (
        <div className="bg-image h-screen px-3 md:px-8 flex items-center justify-center flex-col relative overflow-hidden">
            <div className="max-w-3xl text-center w-full bg-white p-8 z-30 rounded-xl shadow-md-grey">
            {!success && (
                <>
                    <h3 className="text-4xl font-bold">Forgot Password</h3>
                    <div className="h-2" />
                    <Paragraph>Enter your email and we'll send you a link to reset you password.</Paragraph>
                </>
            )}

            <div className="h-2" />
            {!success && (
                <div className="max-w-3xl text-center w-full">  
                    <div className="h-2" />
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            type="text"
                            color="red"
                            error={error}
                            placeholder="Enter your email"
                            size="regular"
                            onFocus={() => setError('')}
                        />
                    </div>
                    <div className="h-5" />
                    <Button
                        color="red"
                        className="w-full hover:bg-blue hover:text-white hover:bg-red hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onButtonClick}
                    >
                        {!success ? "Next" : "Login"}
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                    <div className="h-5" />
                    <Paragraph>
                        Need an account? <Link className="text-red" to="/register">Register</Link>
                    </Paragraph>
                </div>
                
            )}
            {success && <SuccessfulSubmission />}
            </div>
        </div>
    );
}
