import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";

import md5 from 'md5';

export default function CustomDropdown({ options, value, setValue, placeholder, color, type, btnTxt, iconOnly, tabIndex, ...rest }) {
    value = (value && (typeof value === 'object' && value.name)) ? value.name : value;
    const [buttonText, setButtonText] = useState(btnTxt || value || placeholder || 'Default Text');
    const [rerender, setRerender] = useState(true);

    const id = md5(placeholder ? placeholder : new Date().toISOString());

    const onOptionClick = ({ value, label }) => {
        setValue(value);
        setButtonText(label);

        resetDropdown();
    }

    function resetDropdown() {
        setRerender(!rerender);
    }

    useEffect(() => {
        if (!rerender) setRerender(true);
    }, [rerender]);

    useEffect(() => {
        setButtonText(value || placeholder || 'Default Text');
    }, [value, placeholder]);

    function showDropdown() {
        const dropdownEl = document.getElementById(id);

        if (dropdownEl) dropdownEl.click();
    }

    return (
        <div style={{ minHeight: '5rem' }} tabIndex={tabIndex}>
            {rerender && (
                <Dropdown
                    buttonText={buttonText}
                    color={iconOnly ? null : color}
                    id={id}
                    {...rest}
                >
                    {options && Array.isArray(options) && options.length > 0 ? options.map(({ label, value, link }, i) =>
                        type === "link" ?
                            (
                                <Link to={link}>
                                    <DropdownItem key={i} color={"red"} ripple="light" onClick={resetDropdown}>
                                        {label}
                                    </DropdownItem>
                                </Link>
                            )
                            :
                            (
                                <DropdownItem key={md5(label)} color={"red"} ripple="light" onClick={() => onOptionClick({ value, label })}>
                                    {label}
                                </DropdownItem>
                            )
                    ) : <p className="p-4">No options available with your size, colour and finish selections</p>}
                </Dropdown>
            )}
        </div>
    )
}
