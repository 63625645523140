import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Input from '@material-tailwind/react/Input'
import Checkbox from '@material-tailwind/react/Checkbox'
import Button from '@material-tailwind/react/Button'
import Icon from '@material-tailwind/react/Icon'
import { Paragraph } from '@material-tailwind/react'
import PdfModal from 'components/document-handling/PdfModal'
import { set } from 'lodash'

export default function Stage2({
    firstname,
    setFirstname,
    lastname,
    setLastname,
    acceptTerms,
    setAcceptTerms,
    optInNewsletter,
    setOptInNewsletter,
    submit,
}) {
    const [error, setError] = useState({})
    const [disabled, setDisabled] = useState(true)
    const [showPdf, setShowPdf] = useState(false)
    const [pdfDoc, setPdfDoc] = useState('')

    useEffect(() => {
        if (acceptTerms === true) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [acceptTerms, disabled])

    function onContinueClick() {
        let tempErrors = {}

        if (!firstname) {
            tempErrors.firstname = 'Please enter your first name'
        }

        if (!lastname) {
            tempErrors.lastname = 'Please enter your last name'
        }

        if (
            tempErrors &&
            typeof tempErrors === 'object' &&
            Object.keys(tempErrors).length > 0
        ) {
            setError(tempErrors)

            return
        }

        submit();

        return;
    }

    function clearError(key) {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError(tempErrors);
        }

        return;
    }

    function openPdfModal(pdfType) {
        if (pdfType === "Terms") {
            setPdfDoc('Terms_and_Conditions_Print_On_Demand.pdf');
            setShowPdf(true);
        } else {
            setPdfDoc('Privacy_Policy_Print_On_Demand.pdf');
            setShowPdf(true);
        }
    }

    return (
        <>
            <div
                className={`${error && error.firstname ? 'mb-8' : 'mb-4'} text-left`}
            >
                <Input
                    outline={false}
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    onFocus={() => clearError('firstname')}
                    type="text"
                    color="red"
                    size="regular"
                    placeholder="First Name"
                    error={error && error.firstname}
                />
            </div>

            <div className={`${error && error.lastname ? 'mb-8' : 'mb-4'} text-left`}>
                <Input
                    outline={false}
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    onFocus={() => clearError('lastname')}
                    type="text"
                    color="red"
                    size="regular"
                    placeholder="Last Name"
                    error={error && error.lastname}
                />
            </div>

            <div className="w-full text-left">
                <div className="h-5" />
                <div className="flex items-center mb-4">
                    <Checkbox
                        color="red"
                        className="flex-shrink-0"
                        id="checkbox-terms"
                        value={acceptTerms}
                        text=""
                        onChange={() => setAcceptTerms(!acceptTerms)}
                        checked={acceptTerms}
                    />
                    <p>
                        I have read and accept Print on Demand's{' '}
                        <span className="text-red font-bold text-lg cursor-pointer" onClick={() => openPdfModal("Terms")}>
                            Terms & Conditions
                        </span>
                        &nbsp;and&nbsp;
                        <span className="text-red font-bold text-lg cursor-pointer" onClick={() => openPdfModal("Privacy")}>
                            Privacy Policy
                        </span>
                    </p>
                </div>
                <PdfModal
                    file={`/assets/pdf/${pdfDoc}`}
                    title={`${pdfDoc}`}
                    showModal={showPdf}
                    setShowModal={setShowPdf}
                />
                {/* <div className="flex items-center">
                    <Checkbox
                        color="red"
                        className="flex-shrink-0"
                        id="checkbox-news"
                        value={optInNewsletter}
                        onChange={() => setOptInNewsletter(!optInNewsletter)}
                        checked={optInNewsletter}
                        text=""
                    />
                    <p>
                        I would like to receive the latest deals and promotions via email
                    </p>
                </div> */}
            </div>

            <div className="h-10" />
            <div className="flex justify-center flex-col">
                <Button
                    color="red"
                    className={`${!disabled
                        ? 'hover:bg-red hover:text-white hover:shadow-xl hover:pr-2'
                        : 'hover:bg-transparent cursor-not-allowed'
                        } w-full`}
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onContinueClick}
                    disabled={disabled}
                >
                    Continue
                    <Icon color="black" name="arrow_forward" size="sm" />
                </Button>
                <div className="h-6" />
                <Paragraph>
                    Already have an account?{' '}
                    <Link className="text-red" to="/login">
                        Login
                    </Link>
                </Paragraph>
            </div>
        </>
    )
}
