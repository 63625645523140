import {useEffect} from 'react';

import {setPageTitle} from 'utils';

import RenewalsComponent from 'components/renewals';

export default function Renewals() {

    useEffect(() => {
        setPageTitle('Renewal');
    }, []);

    return (
        <div className="h-full w-full relative bg-image">
            <div className="text-center px-3 md:px-8 w-full">
                <div>
                    <div className="h-10" />
                    <div className="max-w-3xl w-full m-auto">
                        <RenewalsComponent />
                        <div className="h-10" />
                    </div>
                    <div className="h-10" />
                </div>
            </div>
        </div>
    );
}
