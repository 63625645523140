import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Icon from '@material-tailwind/react/Icon';

import BookCard from 'components/books/BookCard';
import ViewBook from 'components/books/ViewBook';

import { useGlobalState } from 'state-pool';

import HelpTextWrapper from 'components/general/HelpTextWrapper';

import { setPageTitle } from 'utils';
// import HelpText from 'components/general/HelpText';

import { useGlobal } from 'hooks/useGlobalState';

export default function Books() {
    const [selected, setSelected] = useState(0);
    const [books] = useGlobalState('books');
    const [view, setView] = useState('localBooks');
    const { load } = useGlobal();
    const hasBooks = (books && typeof books === 'object' && (books.localBooks || books.internationalBooks) && (Array.isArray(books.localBooks) || Array.isArray(books.internationalBooks)) && (books.localBooks.length > 0 || books.internationalBooks.length > 0));
    useEffect(() => {
        setSelected(0);
    }, [view]);

    useEffect(() => {
        setPageTitle('Your Books');

        load(`/user/getuserbooks`, 'books');
    }, []);

    return (
        <>
            {!hasBooks && (
                <div className="w-full h-96 flex flex-col items-center justify-center">
                    <h2 className="text-2xl font-bold">You haven't uploaded any books yet</h2>
                    <p className="text-gray-500 mb-8">Your books along with their files and info will show here. Upload your first title to get started.</p>
                    <HelpTextWrapper helpText="Create a new Book of One book here">
                        <Link to="/new-book">
                            <div className={`border-red border-2 rounded-xl text-red-500 overflow-hidden p-4 shadow-xl mb-5 cursor-pointer hover:bg-red hover:text-white`}>
                                <div className="flex items-center justify-center relative h-auto text-center">
                                    <Icon name="add" size="2xl" />
                                    <h3 className={`text-lg font-light ml-1`}>Get Started</h3>
                                </div>
                            </div>
                        </Link>
                    </HelpTextWrapper>
                </div>
            )}
            {hasBooks && <div className="bg-transparent pt-14 pb-28 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                    <div className="flex items-center">
                        <p onClick={() => setView('localBooks')} className={`m-0 p-0 ${view === 'localBooks' ? 'text-red font-bold text-2xl' : 'cursor-pointer text-xl'}`}>Local</p>
                        <p className='text-2xl text-gray-600 ml-2'>|</p>
                        <p onClick={() => setView('internationalBooks')} className={`ml-2 m-0 p-0 ${view === 'internationalBooks' ? 'text-red font-bold text-2xl' : 'cursor-pointer text-xl'}`}>International</p>
                    </div>
                    <div className="w-full flex items-center mb-5">
                        <Icon name="info" color="gray" size="sm" />
                        <p className="text-xs ml-2">Switch between your local and international books here</p>
                    </div>
                    <div className="grid grid-cols-1 lg:gap-5 xl:grid-cols-6">

                        <div className={books && books[view] && Array.isArray(books[view]) && books[view].length === 0 ? "xl:col-start-1 xl:col-end-6" : "xl:col-start-1 xl:col-end-3"}>
                            <h2 className="text-3xl font-bold mb-5">{books[view] && Array.isArray(books[view]) && books[view].length === 0 ? `You currently don't have any ${view === 'localBooks' ? 'local' : 'international'} books.` : "Your Books"}</h2>
                            {books[view] && Array.isArray(books[view]) && books[view].length === 0 && <p>Create a new book by clicking on the button below</p>}
                            <div className={`overflow-y-auto border-gray-500 ${books[view] && Array.isArray(books[view]) && books[view].length > 8 ? "shadow-from-bottom shadow-from-top" : ""} px-4`} style={{ maxHeight: '35rem' }}>
                                {books[view] && Array.isArray(books[view]) && books[view].map((b, i) => (
                                    <BookCard
                                        onClick={() => setSelected(i)}
                                        selected={selected === i}
                                        key={i}
                                        book={b}
                                        icon=""
                                        id={i}
                                    />
                                ))}
                            </div>
                            <div className="h-9" />
                            <HelpTextWrapper helpText="Create a new Book of One book here" style={books[view] && Array.isArray(books[view]) && books[view].length === 0 ? { width: '50%' } : { width: '100%' }}>
                                <Link to="/new-book">
                                    <div className={`border-red border-2 rounded-xl text-red-500 overflow-hidden p-4 shadow-xl mb-5 cursor-pointer hover:bg-red hover:text-white`}>
                                        <div className="flex items-center justify-center relative h-auto text-center">
                                            <Icon name="add" size="2xl" />
                                            <h3 className={`text-lg uppercase font-light ml-1`}>Upload Book</h3>
                                        </div>
                                    </div>
                                </Link>
                            </HelpTextWrapper>
                            <div className="h-2" />
                            <HelpTextWrapper helpText="Create a new Book of One book here" style={books[view] && Array.isArray(books[view]) && books[view].length === 0 ? { width: '50%' } : { width: '100%' }}>
                                <Link to="/bulk-upload">
                                    <div className={`border-red border-2 rounded-xl text-red-500 overflow-hidden p-4 shadow-xl mb-5 cursor-pointer hover:bg-red hover:text-white`}>
                                        <div className="flex items-center justify-center relative h-auto text-center">
                                            <Icon name="upload" size="2xl" />
                                            <h3 className={`text-lg uppercase font-light ml-1`}>Bulk CSV Upload</h3>
                                        </div>
                                    </div>
                                </Link>
                            </HelpTextWrapper>
                        </div>
                        <div className="xl:col-start-3 xl:col-end-7">
                            <ViewBook book={books[view] && books[view][selected] && books[view][selected]} isInternational={view === 'internationalBooks'} />
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}
