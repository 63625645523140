import { useState, useEffect } from 'react';

import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import { useGlobalState } from 'state-pool';

import { useAuth } from 'hooks/useAuth';
import { isEmpty, last } from 'lodash';

export default function UpdateEmail({ editUser, setShowModal }) {
    const [currentUser] = useGlobalState('currentUser');
    const [firstName, setFirstName] = useState((currentUser && currentUser.name_first) ? (currentUser && currentUser.name_first) : '');
    const [lastName, setLastName] = useState((currentUser && currentUser.name_last) ? (currentUser && currentUser.name_last) : '');
    const [phoneNumber, setPhoneNumber] = useState((currentUser && currentUser.phone_main) ? (currentUser && currentUser.phone_main) : '');
    //Place holder for number
    const [phNumber, setPhNumber] = useState("Phone Number");
    const [phFirstName, setPhFirstName] = useState("Name");
    const [phLastName, setPhLastName] = useState("Surname");
    const { refreshUserDetails } = useAuth();


    const phoneRegEx = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
    const validName = /^[a-zA-Z\s]+$/;
    const validSurname = /^[a-zA-Z\s]+$/;

    const validatePhoneNumber = (phoneNumber) => {
        const phoneRegEx = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
        return phoneRegEx.test(phoneNumber);
    };

    const validateName = (name) => {
        const validName = /^[a-zA-Z\s]+$/;
        return validName.test(name);
    };

    const validateSurname = (surname) => {
        const validSurname = /^[a-zA-Z\s]+$/;
        return validSurname.test(surname);
    };

    const validateInputs = () => {
        let isValid = true;
        let errors = {
            phoneNumber: "",
            firstName: "",
            lastName: "",
        };

        if (!phoneNumber || !validatePhoneNumber(phoneNumber)) {
            errors.phoneNumber = "Please enter a valid number";
            isValid = false;
        }

        if (!firstName || !validateName(firstName)) {
            errors.firstName = "Please enter a valid name";
            isValid = false;
        }

        if (!lastName || !validateSurname(lastName)) {
            errors.lastName = "Please enter a valid surname";
            isValid = false;
        }

        return { isValid, errors };
    };

    function onDoneClick() {
        const { isValid, errors } = validateInputs();

        if (isValid) {
            editUser(firstName, lastName, phoneNumber).then(res => {
                if (res && res.statusCode === 0) {
                    refreshUserDetails();
                    setShowModal(false);
                }
            });
        } else {
            setPhNumber(errors.phoneNumber);
            setPhFirstName(errors.firstName);
            setPhLastName(errors.lastName);
        }
    }

    return (
        <div className="px-3 md:pb-8 flex relative justify-center w-3xl">
            <div>
                <h3 className="text-4xl font-bold">Update your details</h3>
                <div className="h-2" />
                <Paragraph >
                    Edit your details below and click submit to update. Your details will update immediately.
                </Paragraph>
                <div className="h-2" />
                <div className="mb-4 text-left">
                    <Input
                        outline={false}
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        type="text"
                        color="red"
                        placeholder={"Name"}
                        size="regular"
                        required
                    />
                    {(!firstName || !validName.test(firstName)) && <p className="text-red-600 text-xs">Please enter a valid name</p>}
                </div>
                <div className="mb-4 text-left">
                    <Input
                        outline={false}
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        type="text"
                        color="red"
                        placeholder={"Surname"}
                        size="regular"
                        required
                    />
                    {(!lastName || !validSurname.test(lastName)) && <p className="text-red-600 text-xs">Please enter a valid surname</p>}
                </div>
                <div className="mb-4 text-left">
                    <Input
                        outline={false}
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                        type="text"
                        color="red"
                        placeholder={"Phone Number"}
                        size="regular"
                        required
                    />

                    {(!phoneNumber || !phoneRegEx.test(phoneNumber)) && <p className="text-red-600 text-xs">Please enter a valid phone number</p>}
                </div>

                <Button
                    color="red"
                    className="hover:bg-red hover:text-white w-full hover:shadow-xl hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onDoneClick}
                    disabled={!lastName || !firstName || !phoneNumber}
                >
                    Done
                    <Icon name="arrow_forward" size="sm" />
                </Button>
            </div>
        </div >
    );
}
