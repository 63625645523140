
import Progress from '@material-tailwind/react/Progress';

import BookNewController from './BookNewControllerTest';
import BookNewStages from './BookNewStages';
import RoyaltiesDisplay from './RoyaltiesDisplay';

export default function BookNew() {
    const componentState = BookNewController();

    const {
        bookFormat,
        percentageComplete,
        showRoyaltiesWidget,
        isRoyaltiesFloating,
        helpRef,
        royalties,
        isLocal,
        isInternational,
        isRoyaltiesLoading,
        setIsRoyaltiesLoading,
        //paperTypesFiltered for all available paper types
        paperTypesFiltered,
        setPaperTypesFiltered,
        internationalPaperTypesFiltered,
        setInternationalPaperTypesFiltered,
    } = componentState;

    return (
        <div className="h-screen w-full relative">
            <div className="w-full">
                <Progress color="red" value={String(percentageComplete)} percentage={false} />
            </div>

            <div className="text-center px-3 md:px-8 w-full">
                <div>
                    <div className="h-10" />
                    <div className="max-w-3xl w-full m-auto">
                        {showRoyaltiesWidget && (
                            <div className={isRoyaltiesFloating ? "" : "flex mb-8 w-full"}>
                                <div className="w-full">
                                    <RoyaltiesDisplay
                                        isRoyaltiesFloating={isRoyaltiesFloating}
                                        isLocal={isLocal}
                                        isInternational={isInternational}
                                        royalties={royalties}
                                        bookFormat={bookFormat}
                                        helpRef={helpRef}
                                        percentageComplete={percentageComplete}
                                        isRoyaltiesLoading={isRoyaltiesLoading}
                                        setIsRoyaltiesLoading={setIsRoyaltiesLoading}
                                        paperTypesFiltered={paperTypesFiltered}
                                        setPaperTypesFiltered={setPaperTypesFiltered}
                                        internationalPaperTypesFiltered={internationalPaperTypesFiltered}
                                        setInternationalPaperTypesFiltered={setInternationalPaperTypesFiltered}
                                    />
                                </div>
                            </div>
                        )}
                        <BookNewStages componentState={componentState} />
                        <div className="h-10" />
                    </div>
                    <div className="h-10" />
                </div>
            </div>
        </div>
    );
}
