import React, {useState, useEffect} from 'react';

import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import Input from "@material-tailwind/react/Input";

import md5 from 'md5';

export default function DropdownInputSearch({value, setValue, placeholder, capitalize, options, color, error, onFocus, noMatches, initialValueDisplay, showOnInitialClick, onlyValue, tabIndex, dropdownClassName, showRegion}) {
    const [rerender, setRerender] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState(value && value.text ? value.text : '');

    const id = md5(placeholder);

    const onOptionClick = (option) => {
        setQuery(option.label)

        if(onlyValue) {
            const {value} = option;

            setValue(value);

            resetDropdown();

            return;
        }
        
        setValue(option);

        resetDropdown();
    }

    function resetDropdown() {
        setRerender(!rerender);

        setIsOpen(false);
    }

    useEffect(() => {
        if(!rerender) setRerender(true);
    }, [rerender]);

    function showDropdown() {
        const dropdownEl = document.getElementById(id);

        if(dropdownEl) dropdownEl.click();
    }

    function _onChange(e) {
        if(e && e.target) {
            setQuery(e.target.value);

            if(!isOpen) {
                setIsOpen(true);
                showDropdown();
            }
        }
    }

    useEffect(() => {
        if(initialValueDisplay !== query && typeof initialValueDisplay === 'string') setQuery(initialValueDisplay);
    }, [initialValueDisplay]);

    const onInputClick = () => {

        setQuery('');  

        showDropdown(); 

        setIsOpen(true);
        
    }

    return (
        <>
            <Input
                outline={false}
                value={query}
                type="text"
                color="red"
                size="regular"
                placeholder={placeholder}
                onChange={e => _onChange(e)}
                onClick={onInputClick}
                onFocus={onFocus}
                error={error}
                style={{textTransform: capitalize ? "capitalize" : ""}}
                tabIndex={tabIndex}
            />
            <div className="h-0">
                {rerender && (
                    <>
                        <Dropdown
                            color={color ? color : 'red'}
                            placement="bottom-start"
                            id={id}
                            buttonType="link"
                            size="regular"
                            rounded={false}
                            block={false}
                            buttonText=""
                            ripple="light"
                            className={`hover:bg-green hover:text-white invisible h-0 p-0 m-0 ${dropdownClassName}`}
                        >
                            {options && Array.isArray(options) && options.length > 0 && options.filter(opt => opt.label.toLowerCase().includes(query.toLowerCase()) || opt.orientation.toLowerCase().includes(query.toLowerCase())).length > 0 ? options.filter(opt => opt.label.toLowerCase().includes(query.toLowerCase()) || opt.orientation.toLowerCase().includes(query.toLowerCase())).map(({label, value, orientation, isLocal, isInternational}, i) => 
                                (<React.Fragment key={i}>
                                    <div style={{height: 1, background: '#00000010'}} />
                                    <DropdownItem key={i} color={color ? color : "red"} ripple="light" onClick={() => onOptionClick({value, label})}>
                                        <span className="mr-1">{label}</span>
                                        {showRegion && <br />}
                                        {showRegion && <div className="h-2" />}
                                        <span className={`mr-1 py-1 px-2 capitalize text-xs rounded-xl bg-${orientation === 'portrait' ? 'blue' : orientation === 'landscape' ? 'orange' : 'amber'}-100 text-${orientation === 'portrait' ? 'blue' : orientation === 'landscape' ? 'orange' : 'amber'}-500`}>{orientation}</span>
                                        {isInternational && showRegion && <span className="mr-1 py-1 px-2 capitalize text-xs rounded-xl bg-green-100 text-green-500">international</span>}
                                        {isLocal && showRegion && <span className="mr-1 py-1 px-2 capitalize text-xs rounded-xl bg-green-100 text-green-500">local</span>}
                                    </DropdownItem>
                                    <div style={{height: 1, background: '#00000010'}} />

                                </React.Fragment>)
                            ) : (
                                <p className="p-4 text-gray" >
                                    {noMatches ? noMatches : "No matches for your search term"}
                                </p>
                            )}
                        </Dropdown>
                    </>
                )}
            </div>
        </>
    )
}
