//changes made by me
import { useState, useEffect, useRef } from 'react';

import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import Input from "@material-tailwind/react/Input";

import md5 from 'md5';

import countries from 'static-data/phone-codes';

import './PhoneNumber.css';

export default function CountrySelectInput({ value, setValue, dialValue, setDialValue, placeholder, options, color, error, onFocus, disabled }) {

    const getCountryFromPhoneNumber = (phoneNumber) => {
        for (const country of countries) {
            if (phoneNumber.startsWith(country.dial_code)) {
                return {
                    selected: country.dial_code,
                    radioSelected: country.code,
                    flag: `/assets/flags/${country.code}.svg`,
                    defaultCode: country.dial_code
                };
            }
        }
        return {
            selected: defaultCountry.dial_code,
            radioSelected: defaultCountry.code,
            flag: defaultCountry.flag,
            defaultCode: defaultCountry.dial_code
        };
    };

    const initialCountryDetails = getCountryFromPhoneNumber(value || '+27');
    
    const [rerender, setRerender] = useState(true);
    const countryInputRef = useRef(null);
    const [selected, setSelected] = useState('+27');
    const [radioSelected, setRadioSelected] = useState('ZA');
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const [defaultFlag, setDefaultFlag] = useState(`/assets/flags/ZA.svg`);
    const [isCountryCodeIdentified, setIsCountryCodeIdentified] = useState(false);
    const [defaultCode, setDefaultCode] = useState(initialCountryDetails.defaultCode);

    const defaultCountry = {
        code: 'ZA',
        dial_code: '+27',
        flag: `/assets/flags/ZA.svg`
    };
    // const defaultCode = '+27';
    const [searchQuery, setSearchQuery] = useState('');

    const id = md5(placeholder);

    // useEffect(() => {
    //     if (value) {
    //         let dialCode = value.trim().split(' ')[0];
    //         switch (dialCode) {
    //             case "0":
    //             case '+27':
    //                 setDefaultFlag(`/assets/flags/ZA.svg`);
    //                 break;
    //         }
    //     }
    // }, [value]);

    useEffect(() => {
        if (value) {
            const countryDetails = getCountryFromPhoneNumber(value);
            setSelected(countryDetails.selected);
            setRadioSelected(countryDetails.radioSelected);
            setDefaultFlag(countryDetails.flag);
            setDefaultCode(countryDetails.defaultCode); // setting the state
            setInputValue(value); // Set the provided phone number to the inputValue state
        }
    }, [value]);

    useEffect(() => {
        if (countryInputRef && countryInputRef.current && countryInputRef.current.parentElement) {
            countryInputRef.current.parentElement.style.zIndex = "999";
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            const dropdownEl = document.getElementById(id);

            if (dropdownEl) dropdownEl.click();
        }
    }, [isOpen]);

    

    function handleOptionClick(o) {
        const { name, dial_code, code } = o;
        //  const val = dial_code + inputValue.replace(selected, '');
        setInputValue(dial_code);
        setSelected(dial_code);
        setRadioSelected(code);
        setIsCountryCodeIdentified(true);
        setDefaultCode(dial_code);
        setDefaultFlag(`/assets/flags/${code}.svg`);
        setSearchQuery('');
        setIsOpen(false);
        //  resetDropdown();
    }

    function handleDropdownClick() {
        setIsOpen(!isOpen);
        setSearchQuery(''); // Reset the search query when opening/closing the dropdown 
    }

    function _onChange(e) {
        const inputValue = e.target.value ? e.target.value : value;
        let numericValue = inputValue.replace(/[^+\d]/g, '');

        setInputValue(numericValue);
        let matchingCountry = null;
        for (const country of countries) {
            if (numericValue.startsWith(country.dial_code)) {
                matchingCountry = country;
                break;
            }
        }

        if (matchingCountry) {
            setSelected(matchingCountry.dial_code);
            setRadioSelected(matchingCountry.code);
            setDefaultFlag(`/assets/flags/${matchingCountry.code}.svg`); // Update the flag
            setDefaultCode(matchingCountry.dial_code); // Update the default code
            setValue(numericValue); // Set input value to the combination of dialed code + phone number 
        } else {
            setSelected(defaultCountry.dial_code);
            setRadioSelected(defaultCountry.code);
            setDefaultFlag(defaultCountry.flag); // Reset to default flag
        }
        setSearchQuery('');
    }


    function addDefaultCountryCode() {
        const defaultCountry = countries.find(country => country.code === 'ZA'); // Replace 'ZA' with the desired default country code
        if (defaultCountry) {
            setSelected(defaultCountry.dial_code);
            setRadioSelected(defaultCountry.code);
            setInputValue(defaultCountry.dial_code);
        }
    }

    useEffect(() => {
        if (inputValue.trim() === '') {
            addDefaultCountryCode();
        }
    }, [inputValue]);

    return (
        <>
            <div className="relative">
                <input
                    className={`w-full h-full text-gray-800 leading-normal shadow-none outline-none focus:outline-none focus:ring-0 focus:text-gray-800 pr-0 false mt-input-red-500 mt-input bg-transparent border-none ${error ? 'mt-input-outline-error' : ''}`}
                    outline="false"
                    value={disabled === true ? value : inputValue}
                    type="text"
                    color="red"
                    size="regular"
                    placeholder="E.g. (+27)123456789"
                    onChange={e => _onChange(e)}
                    onFocus={onFocus}
                    error={error}
                    style={{ paddingLeft: 110, height: 44 }}
                    disabled={disabled}
                />

                <label className={`text-gray-400 absolute left-0 -top-0.5 w-full h-full border border-t-0 border-l-0 border-r-0 border-b-1 ${error ? 'border-red-500' : 'border-gray-300'} pointer-events-none`}>
                </label>

                <div className="country-phone-input-flag-code" onClick={disabled === true ? null : handleDropdownClick}>
                    <>
                        <img className="flag country-phone-code-flag" src={defaultFlag} alt="Country Flag" />
                        <p className="margin-none country-phone-code-selected">{defaultCode}</p>

                    </>
                    <img className="caret country-phone-code-arrow" src="/assets/icons/down-caret-small-black.svg" alt="Dropdown Arrow" />
                </div>

                <div className="h-0">
                    {isOpen && (
                        <>
                            <Dropdown
                                color={color ? color : 'red'}
                                placement="bottom-start"
                                id={id}
                                buttonType="link"
                                buttonText=""
                                size="regular"
                                rounded={false}
                                block={false}
                                ripple="light"
                                className="hover:bg-green hover:text-white invisible h-0 p-0 m-0"
                            >
                                {/* Search input */}
                                <div className="p-2">
                                    <Input
                                        type="text"
                                        placeholder="Search Countries..."
                                        value={searchQuery}
                                        color="red"
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        onFocus={() => setIsOpen(true)}
                                        autoFocus // Optional: Automatically focus on the search input when the dropdown is opened
                                    />
                                </div>

                                {/* Displays in a grid of 4 columns*/}
                                <div className="grid grid-cols-4 gap-4">
                                    {countries && Array.isArray(countries) && countries.length > 0 && countries
                                        .filter(country => country.name.toLowerCase().includes(searchQuery.toLowerCase())) // Filter countries based on the search query
                                        .map(({ code, name, dial_code }, i) => (
                                            <div className="flex flex-col items-center" key={i}>
                                                <DropdownItem color={color ? color : "red"} ripple="light" onClick={() => handleOptionClick({ code, name, dial_code })}>
                                                    {name} {'(' + dial_code + ')'}
                                                </DropdownItem>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Dropdown>
                        </>
                    )}
                </div>
            </div>
            {error && <span class="block mt-1 text-xs text-red-500">{error}</span>}
        </>
    )

} 