import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useGlobalState } from 'state-pool'
import { useAuth } from 'hooks/useAuth'

import Input from '@material-tailwind/react/Input'
import Button from '@material-tailwind/react/Button'
import Icon from '@material-tailwind/react/Icon'
import Card from '@material-tailwind/react/Card'
import CardBody from '@material-tailwind/react/CardBody'
import Checkbox from '@material-tailwind/react/Checkbox'
import { Paragraph } from '@material-tailwind/react';
import CardFooter from '@material-tailwind/react/CardFooter';
import PlacesAutocomplete from 'components/inputs/PlacesAutocomplete'
import Dropdown from 'components/inputs/Dropdown'

import Modal from 'components/general/Modal'

import HelpText from 'components/general/HelpText'

import helpText from 'static-data/help-text'
import DropdownInput from 'components/inputs/DropdownInput'
import CountrySelectInput from 'components/inputs/PhoneNumber';

import { isValidJson } from 'utils'

import { editUserBankDetails } from 'routes/userRoutes';
import { useAlert } from 'hooks/useAlert'
import user from 'sample-data/user'
import { first, set } from 'lodash'
import { phone } from 'faker'
import { isValid } from 'date-fns'
import { is } from 'date-fns/locale'


const HELP_TEXT = {
    'is-local': 'Your bank is within South Africa',
    'is-international': 'Your bank is outside of South Africa',
}

const ACCOUNT_TYPES = [
    {
        label: 'Cheque',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Cheque',
    },
    {
        label: 'Savings',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Savings',
    },
]

const BANK_OPTIONS = [
    {
        label: 'First National Bank',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'First National Bank',
    },
    {
        label: 'Capitec Bank Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Capitec Bank Limited',
    },
    {
        label: 'Standard Bank of South Africa',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Standard Bank of South Africa',
    },
    {
        label: 'Nedbank Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Nedbank Limited',
    },
    {
        label: 'Absa Group Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Absa Group Limited',
    },
    {
        label: 'African Bank Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'African Bank Limited',
    },
    {
        label: 'Discovery Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Discovery Limited',
    },
    {
        label: 'Investec Bank Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Investec Bank Limited',
    },
    {
        label: 'Bidvest Bank Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Bidvest Bank Limited',
    },
    {
        label: 'FirstRand Bank - A subsidiary of First Rand Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'FirstRand Bank - A subsidiary of First Rand Limited',
    },
    {
        label: 'Grindrod Bank Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Grindrod Bank Limited',
    },
    // {
    //     label: "Imperial Bank South Africa",
    //     helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
    //     value: 'Imperial Bank South Africa'
    // },
    {
        label: 'Sasfin Bank Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Sasfin Bank Limited',
    },
    {
        label: 'Ubank Limited',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'Ubank Limited',
    },
    {
        label: 'TymeBank',
        helpText: 'lorem ipsum dolor sit amet, consectetur adipiscing',
        value: 'TymeBank',
    },
]

const BRANCH_DETAILS = {
    'Absa Group Limited': '632 005',
    'African Bank Limited': '430 000',
    'Bidvest Bank Limited': '462 005',
    'Capitec Bank Limited': '470 010',
    'Discovery Limited': '679 000',
    'First National Bank': '250 655',
    'FirstRand Bank - A subsidiary of First Rand Limited': '250 655',
    'Grindrod Bank Limited': '223 626',
    'Investec Bank Limited': '580 105',
    'Nedbank Limited': '198 765',
    'Sasfin Bank Limited': '683 000',
    'Standard Bank of South Africa': '051 011',
    'Ubank Limited': '431010',
    'TymeBank': '678 910',
}

export default function UpdatingProfileInformation({ editUser }) {
    //const [currentUser] = useGlobalState('currentUser');
    let currentUser;
    let defaultDetails;
    try {
        currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            defaultDetails = currentUser;
        }
    } catch (e) {
        console.error("Error parsing currentUser from sessionStorage:", e);
    }
    // let userMisc = JSON.parse(currentUser && currentUser.misc);

    const history = useHistory()
    const { newAlert } = useAlert();
    const { refreshUserDetails } = useAuth();
    const [isInternationalAccount, setIsInternationalAccount] = useState(false)

    //Users information
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    // const [username, setUsername] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    //Banking details 
    const [bankName, setBankName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchCode, setBranchCode] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountType, setAccountType] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [bankAddress, setBankAddress] = useState('');
    const [ibanNumber, setIbanNumber] = useState('');
    const [swiftCode, setSwiftCode] = useState('');
    const [paypal, setPaypal] = useState('');
    const [isPaypalAccount, setIsPaypalAccount] = useState(false);

    //Signing Contract details 
    const [publisherName, setPublisherName] = useState('');
    const [publisherEmail, setPublisherEmail] = useState('');
    const [publisherPhoneNumber, setPublisherPhoneNumber] = useState('');
    const [contactName, setContactName] = useState('');
    const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState('');
    const [postalAddress, setPostalAddress] = useState('');
    const [physicalAddress, setPhysicalAddress] = useState('');

    //Show modals or sections
    const [showFormatHelp, setShowFormatHelp] = useState(false);
    const [showRegionHelp, setShowRegionHelp] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showBankingDetails, setShowBankingDetails] = useState(false);
    const [showSigningDetails, setShowSigningDetails] = useState(false);
    const [hoverKey, setHoverKey] = useState(null)

    //Error handling
    const [termsChecked, setTermsChecked] = useState(false)
    const [error, setError] = useState({})
    const [inputError, setInputError] = useState({});
    const [loading, setLoading] = useState(false);
    const [completeModal, setCompleteModal] = useState(false);
    const [failedModal, setFailedModal] = useState(false);

    //Redirect 
    function handleCloseModal() {
        setCompleteModal(false);
        const redirect = setTimeout(() => {
            history.push('/profile');
        }, 2000);
    }

    const validate = () => {
        let tempErrors = {}
        //Regex 
        const emailRegex = /(?:[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-z0-9A-Z]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        const nameRegex = /^(?=.{2,})[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        const numRegex = /^[0-9 ]{4,30}$/;
        const bothRegex = /^[0-9a-zA-Z]+$/;
        const phoneRegex = /^\+?[0-9]{8,15}$/;
        const ibanNumberRegex = /^[a-zA-Z0-9 ]{4,34}$/;
        const nameAndNumberRegex = /^[a-zA-Z0-9 ]{4,30}$/;
        const idRegex = /^[0-9 a-zA-Z/-]{2,30}$/


        //------------- Users Details -----------------//
        if (!firstName.trim() || !nameRegex.test(firstName.trim()) || firstName.trim().length < 2) {
            tempErrors['name_first'] = `Please enter your first name`
        }
        if (!lastName.trim() || !nameRegex.test(lastName.trim()) || lastName.trim().length < 2) {
            tempErrors['name_last'] = `Please enter your last name`
        }
        // if (!username) {
        //     tempErrors['username'] = `Please enter your username`
        // }
        if (!emailAddress.trim() || !emailRegex.test(emailAddress.trim())) {
            tempErrors['email'] = `Please enter your email address`
        }
        if (!phoneNumber.trim() || !phoneRegex.test(phoneNumber.trim())) {
            tempErrors['phone_main'] = `Please enter your phone number`
        }

        //------------- Local ACCOUNT -----------------//
        if (!isInternationalAccount) {
            //BankName
            if (!bankName.trim()) {
                tempErrors['bankName'] = `Please enter your banks name`
            }
            //BranchCode
            if (!branchCode.trim()) {
                tempErrors['branchCode'] = `Please enter your banks branch code`
            } else if (!numRegex.test(branchCode.trim())) {
                tempErrors['branchCode'] = `Please enter a valid branch code within the range of 4-30 numbers`;
            }
            //BranchName
            if (!branchName.trim()) {
                tempErrors['branchName'] = `Please enter your bank's branch name`;
            } else if (!nameRegex.test(branchName.trim())) {
                tempErrors['branchName'] = `Please enter a valid branch name`;
            }
            //AccountName
            if (!accountName.trim()) {
                tempErrors['accountName'] = `Please enter your bank account name`
            } else if (!nameRegex.test(accountName.trim())) {
                tempErrors['accountName'] = `Please enter a valid account name`
            }
            //AccountNumber
            if (!accountNumber.trim()) {
                tempErrors['accountNumber'] = `Please enter your account number`;
            } else if (!numRegex.test(accountNumber.trim())) {
                tempErrors['accountNumber'] = `Please enter a valid account number `;
            }
            //AccountType
            if (!accountType.trim()) {
                tempErrors['accountType'] = `Please enter your account type`
            }
        }
        //------------- INTERNATIONAL ACCOUNT -----------------//
        if (isInternationalAccount) {
            //BankAddress
            if (!bankAddress.trim()) {
                tempErrors['bankAddress'] = `Please enter your bank address`
            } else if (bankAddress.trim().length < 5) {
                tempErrors['bankAddress'] = `Please enter a valid bank address`
            }
            //IBANNumber
            if (!ibanNumber.trim()) {
                tempErrors['ibanNumber'] = `Please enter your banks IBAN number`
            } else if (!ibanNumberRegex.test(ibanNumber.trim())) {
                tempErrors['ibanNumber'] = `Please enter a valid IBAN number`
            }
            //SwiftCode
            if (!swiftCode.trim()) {
                tempErrors['swiftCode'] = `Please enter your banks swift code`
            } else if (!bothRegex.test(swiftCode.trim())) {
                tempErrors['swiftCode'] = `Please enter a valid swift code`
            }
            //These are optional fields
            if (accountName.trim()) {
                if (!nameRegex.test(accountName.trim())) {
                    tempErrors['accountName'] = `Please enter a valid account name`
                }
            }
            if (accountNumber.trim()) {
                if (!numRegex.test(accountNumber.trim())) {
                    tempErrors['accountNumber'] = `Please enter a valid account number`;
                }
            }
            if (paypal.trim()) {
                if (!emailRegex.test(paypal.trim())) {
                    tempErrors['paypal'] = `Please enter a valid paypal`
                }
            }
        }
        //Paypal
        if (isPaypalAccount && !paypal.trim() && !emailRegex.test(paypal.trim())) {
            tempErrors['paypal'] = `Please enter your paypal`
        }

        //------------- Publishers details -----------------//
        //PublisherName
        if (!publisherName.trim()) {
            tempErrors['publisherName'] = `Please enter your publishers name`
        }
        //PublisherEmail
        if (!publisherEmail.trim()) {
            tempErrors['publisherEmail'] = `Please enter your publishers email`
        } else if (!emailRegex.test(publisherEmail.trim())) {
            tempErrors['publisherEmail'] = `Please enter a valid email`
        }
        //PublisherPhoneNumber
        if (!publisherPhoneNumber.trim()) {
            tempErrors['publisherPhoneNumber'] = `Please enter your publishers phone number`
        } else if (!phoneRegex.test(publisherPhoneNumber.trim())) {
            tempErrors['publisherPhoneNumber'] = `Please enter a valid phone number`
        }
        //ContactName
        if (!contactName.trim()) {
            tempErrors['contactName'] = `Please enter your contact name`
        } else if (!nameRegex.test(contactName.trim())) {
            tempErrors['contactName'] = `Please enter a valid contact name`
        }
        //BusinessRegistrationNumber
        if (!businessRegistrationNumber.trim()) {
            tempErrors['businessRegistrationNumber'] = `Please enter your business registration number`
        } else if (!idRegex.test(businessRegistrationNumber.trim())) {
            tempErrors['businessRegistrationNumber'] = `Please enter a valid business registration number`
        }
        //PostalAddress
        if (!postalAddress.trim()) {
            tempErrors['postalAddress'] = `Please enter your postal address`
        } else if (postalAddress.trim().length < 5) {
            tempErrors['postalAddress'] = `Please enter a valid postal address`
        }
        //PhysicalAddress
        if (!physicalAddress.trim()) {
            tempErrors['physicalAddress'] = `Please enter your physical address`
        } else if (physicalAddress.trim().length < 5) {
            tempErrors['physicalAddress'] = `Please enter a valid physical address`
        }

        //------------- Movement to error -----------------//
        if (
            tempErrors &&
            typeof tempErrors === 'object' &&
            Object.keys(tempErrors).length > 0
        ) {
            let firstElementKey = Object.keys(tempErrors)[0]
            //If any error come from the user details section it should take the user to the user details section
            if (firstElementKey === 'name_first' || firstElementKey === 'name_last' || firstElementKey === 'username' || firstElementKey === 'email' || firstElementKey === 'phone_main') {
                firstElementKey = "user_details"
            }
            const firstEl = document.querySelector(`#${firstElementKey}`);

            if (firstEl) {
                firstEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            setError(tempErrors)

            return false
        }

        return true
    }

    const clearError = useCallback(
        (key) => {
            if (key && typeof key === 'string') {
                const tempErrors = error
                tempErrors[key] = null
                setError({ ...tempErrors })
            }
            return
        },
        [error],
    )

    function onContinueClick() {
        //Create a function that will check for any changes from currentUser and DefaultDetails if there are no changes nothing should run 
        setLoading(true)
        editUserBankDetails(
            firstName.trim(),
            lastName.trim(),
            // username,
            emailAddress.trim(),
            phoneNumber.trim(),
            bankName.trim(),
            branchCode.trim(),
            branchName.trim(),
            accountName.trim(),
            accountType.trim(),
            accountNumber.trim(),
            swiftCode.trim(),
            ibanNumber.trim(),
            bankAddress.trim(),
            paypal.trim(),
            publisherName.trim(),
            publisherEmail.trim(),
            publisherPhoneNumber.trim(),
            contactName.trim(),
            businessRegistrationNumber.trim(),
            postalAddress.trim(),
            physicalAddress.trim(),
        ).then(res => {
            if (res && res.statusCode === 200) {
                setLoading(false)
                refreshUserDetails();
                setShowConfirmModal(false);
                // newAlert('success', 'Bank details request has been successfully sent.', '');
                setCompleteModal(true);
                return;
            } else {
                setLoading(false)
                newAlert('error', 'Error sending request to admin.', '');
                return;
            }
        });
    }

    function onClickValidate() {
        setShowBankingDetails(true)
        setShowSigningDetails(true)
        setTimeout(() => {
            const isValid = validate()
            if (!isValid) return
            setShowSigningDetails(false)
            setShowBankingDetails(false)
            setShowConfirmModal(true)
        }, 200);
    }

    function onFormatOptionHover(key) {
        if (key) {
            setShowFormatHelp(true)

            setHoverKey(key)

            return
        }

        setShowFormatHelp(false)
        setShowRegionHelp(false)

        setHoverKey(null)
    }
    //-------------Data-----------------//
    //This is where we will get the users details from the backend and set if its exists
    useEffect(() => {
        if (currentUser && (typeof currentUser === 'object') && currentUser.misc && (typeof currentUser.misc === 'string') && isValidJson(currentUser.misc)) {
            const parsedMisc = JSON.parse(currentUser.misc);
            //User details
            if (currentUser.name_first) setFirstName(currentUser.name_first);
            if (currentUser.name_last) setLastName(currentUser.name_last);
            // if (currentUser.username) setUsername(currentUser.username);
            if (currentUser.email) setEmailAddress(currentUser.email);
            if (currentUser.phone_main) setPhoneNumber(currentUser.phone_main);

            //Banking details
            const { bankInfo } = parsedMisc;
            if (bankInfo && typeof bankInfo === 'object') {
                if (bankInfo.bank_name) setBankName(bankInfo.bank_name);
                // Branch spelling mistake
                if (bankInfo.brance_code) setBranchCode(bankInfo.brance_code);
                if (bankInfo.branch_name) setBranchName(bankInfo.branch_name);
                if (bankInfo.account_name) setAccountName(bankInfo.account_name);
                if (bankInfo.account_type) setAccountType(bankInfo.account_type);
                if (bankInfo.account_number) setAccountNumber(bankInfo.account_number);

                if (bankInfo.international_swift_code && bankInfo.international_swift_code !== ' ') {
                    setSwiftCode(bankInfo.international_swift_code);
                    setIsInternationalAccount(true);
                }

                if (bankInfo.international_iban_number && bankInfo.international_iban_number !== ' ') {
                    setIbanNumber(bankInfo.international_iban_number);
                    setIsInternationalAccount(true);
                }

                if (bankInfo.international_bank_address && bankInfo.international_bank_address !== ' ') {
                    setBankAddress(bankInfo.international_bank_address);
                    setIsInternationalAccount(true);
                }
                if (bankInfo.paypal) {
                    setPaypal(bankInfo.paypal);
                }

                //Sign contract details
                if (bankInfo.publish_name) setPublisherName(bankInfo.publish_name)
                if (bankInfo.email) setPublisherEmail(bankInfo.email);
                if (bankInfo.telephone) setPublisherPhoneNumber(bankInfo.telephone);
                if (bankInfo.contact_person) setContactName(bankInfo.contact_person);
                if (bankInfo.reg_id_no) setBusinessRegistrationNumber(bankInfo.reg_id_no);
                if (bankInfo.postal_address) setPostalAddress(bankInfo.postal_address);
                if (bankInfo.physical_address) setPhysicalAddress(bankInfo.physical_address);
            }
        }
    }, []);

    useEffect(() => {
        if (bankName && typeof bankName === 'string') {
            const associatedBranchCode = BRANCH_DETAILS[bankName]

            if (associatedBranchCode) {
                setBranchCode(associatedBranchCode)
            }
        }
    }, [bankName]);

    return (
        <div className="h-screen px-3 md:px-8 flex relative justify-center">
            <div className="w-full max-w-3xl">
                <div className="mb-10 w-full mt-20">
                    <div className=" absolute left-1 top-5 md:top-3">
                        <Button
                            color="white"
                            rounded
                            className="hover:pl-3 text-red"
                            buttonType="link"
                            size="lg"
                            ripple="dark"
                            onClick={() => history.goBack()}
                        >
                            <Icon name="arrow_back" size="sm" />
                            Back
                        </Button>
                    </div>
                    <div className="mt-2">
                        <h3 className="text-4xl font-bold mt-2 mb-2" id='user_details'>Update Request</h3>
                        <HelpText
                            iconSize="xl"
                            helpText="Please note this is a request to change your details. After submission the request will be reviewed by administrators before any changes are implemented."
                            className={"mb-2"}
                        >
                            <p className="text-red-600 ml-1 text-l  leading-normal">
                                Please be advised that any request to modify the following details will be subject to administrative review before any changes are enacted. If these changes are approved and implemented, you will need to resign all existing contracts.
                            </p>
                        </HelpText>
                        <Card className="max-w-3xl text-left">
                            <div className="h-2" />
                            <div className="flex flex-wrap justify-between" >
                                <div className="w-full md:w-5/12">
                                    <p className="text-gray-600 ml-1 text-2xl leading-normal" >
                                        First Name</p>
                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                type="text"
                                                name="name_first"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                color="red"
                                                placeholder="John"
                                                iconname="account_circle"
                                                onFocus={() => clearError('name_first')}
                                                error={error && error.name_first}
                                            />
                                        </div>
                                    </CardBody>
                                </div>
                                <div className="w-full md:w-5/12">
                                    <p className="text-gray-600 ml-1 text-2xl leading-normal" >
                                        Last Name</p>
                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                type="text"
                                                name="name_last"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                color="red"
                                                placeholder="Doe"
                                                iconname="account_circle"
                                                onFocus={() => clearError('name_last')}
                                                error={error && error.name_last}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>
                                </div>
                            </div>
                            <p className="text-gray-600 ml-1 text-2xl leading-normal">
                                Username</p>
                            <CardBody>
                                <div className="text-left">
                                    <Input
                                        type="text"
                                        name="email"
                                        value={emailAddress}
                                        onChange={(e) => setEmailAddress(e.target.value)}
                                        color="red"
                                        placeholder="johndoe@gmail.com"
                                        iconname="account_circle"
                                        onFocus={() => clearError('email')}
                                        error={error && error.email}
                                    />
                                </div>
                                <div className="h-2" />
                            </CardBody>
                            <p className="text-gray-600 ml-1 text-2xl leading-normal">
                                Phone Number</p>
                            <CardBody>
                                <div className="text-left">
                                    <Input
                                        type="text"
                                        name="phone_main"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        color="red"
                                        placeholder="E.g. (+27)123456789"
                                        iconname="account_circle"
                                        onFocus={() => clearError('phone_main')}
                                        error={error && error.phone_main}
                                    />
                                </div>
                                <div className="h-2" />
                            </CardBody>
                            {/* <CountrySelectInput
                                outline={false}
                                value={phoneNumber}
                                setValue={setPhoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                color="red"
                                placeholder="E.g. (+27)123456789"
                                size="large"
                                type="text"
                                onFocus={() => clearError('phone_main')}
                                error={error && error.phoneNumber}
                                disabled={false}
                            /> */}
                        </Card>
                        <div className="h-10" />
                    </div>
                    <h2 className="ml-1 text-4xl font-bold mb-2">Bank Details</h2>
                    <HelpText
                        iconSize="xl"
                        helpText={
                            helpText &&
                            helpText.newBook &&
                            helpText.newBook.bankDetails &&
                            helpText.newBook.bankDetails.localVsInternationalTooltip
                        }
                    >
                        <p className="text-gray-600 ml-1 text-xl leading-normal">
                            Is your bank account local or international?
                        </p>
                    </HelpText>
                    <div className="flex flex-row mt-2 w-full shadow-md h-20 rounded-xl bg-white">
                        <div
                            className={`w-full flex align-center justify-center rounded-xl cursor-pointer ${hoverKey === 'is-local' ? 'bg-gray-200' : ''
                                }`}
                            onMouseOver={() => onFormatOptionHover('is-local')}
                            onMouseOut={() => onFormatOptionHover()}
                            onClick={() => {
                                setIsInternationalAccount(false);
                                setShowBankingDetails(true);
                            }
                            }
                        >
                            <div
                                onClick={(e) => e.stopPropagation()}
                                className="h-full align-center flex"
                            >
                                <Checkbox
                                    color="red"
                                    value={!isInternationalAccount}
                                    checked={!isInternationalAccount}
                                    className="flex-shrink-0"
                                    onChange={() => {
                                        setIsInternationalAccount(false);
                                        setShowBankingDetails(true);
                                    }}
                                    text="Local"
                                    id="checkbox-is-local"
                                />
                            </div>
                        </div>
                        <div
                            className={`w-full flex align-center justify-center rounded-xl cursor-pointer ${hoverKey === 'is-international' ? 'bg-gray-200' : ''
                                }`}
                            onMouseOver={() => onFormatOptionHover('is-international')}
                            onMouseOut={() => onFormatOptionHover()}
                            onClick={() => {
                                setIsInternationalAccount(true);
                                setShowBankingDetails(true);
                                clearError('isInternationalAccount');
                            }}>
                            <div
                                onClick={(e) => e.stopPropagation()}
                                className="h-full align-center flex">
                                <Checkbox
                                    color="red"
                                    value={isInternationalAccount}
                                    checked={isInternationalAccount}
                                    className="flex-shrink-0"
                                    onChange={() => {
                                        setIsInternationalAccount(true);
                                        setShowBankingDetails(true);
                                        clearError('isInternationalAccount');
                                    }}
                                    text="International"
                                    id="checkbox-is-international" />
                            </div>
                        </div>
                        <Button
                            color="white"
                            className="hover:bg-gray-200 hover:text-white w-full "
                            size="md"
                            onClick={() => setShowBankingDetails(!showBankingDetails)}
                            style={{
                                borderRadius: "0.5rem",
                                width: "20%"
                            }}>
                            {showBankingDetails ? <Icon name="arrow_drop_up" size="md" color="red" className="ml-1" /> : <Icon name="arrow_drop_down" size="md" color="red" className="ml-1" />}
                        </Button>
                    </div>

                    <div className="h-0 mt-4">
                        {showFormatHelp && (
                            <p className="text-gray-500 ml-1 text-md">
                                {HELP_TEXT[hoverKey]}
                            </p>
                        )}
                    </div>
                    {error && error.isInternationalAccount && (
                        <p className="text-red text-left my-4">
                            {error.isInternationalAccount}
                        </p>
                    )}
                    <div className="h-8" />
                    {showBankingDetails == true ? (
                        !isInternationalAccount ? (
                            <>
                                <Card className={`max-w-3xl text-left bg-white`}>
                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.bankNameTooltip
                                        }
                                    >
                                        <p
                                            className="text-gray-600 ml-1 text-2xl leading-normal"
                                            id="bankName">
                                            Bank Name</p>
                                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                                    </HelpText>

                                    <CardBody>
                                        <div
                                            className="text-left"
                                            onClick={() => clearError('bankName')}
                                        >
                                            <DropdownInput
                                                color="red"
                                                className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                                                error={error && error.bankName}
                                                value={bankName}
                                                onChange={(e) => setBankName(e.target.value)}
                                                setValue={setBankName}
                                                placeholder="Your bank name"
                                                options={BANK_OPTIONS}
                                                onlyValue={true}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.branchCodeTooltip
                                        }>
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                            id="branchCode" >
                                            Branch Code</p>
                                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                                    </HelpText>

                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                outline={false}
                                                value={branchCode}
                                                onChange={(e) => setBranchCode(e.target.value)}
                                                color="red"
                                                placeholder="E.g. 123456"
                                                size="large"
                                                type="text"
                                                onFocus={() => clearError('branchCode')}
                                                error={error && error.branchCode}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.branchNameTooltip
                                        }>
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                            id="branchName" >
                                            Branch Name </p>
                                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                                    </HelpText>

                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                outline={false}
                                                value={branchName}
                                                onChange={(e) => setBranchName(e.target.value)}
                                                color="red"
                                                placeholder="E.g. ABSA, Sandton"
                                                size="large"
                                                type="text"
                                                onFocus={() => clearError('branchName')}
                                                error={error && error.branchName}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.accountNameTooltip
                                        }
                                    >
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                            id="accountName">
                                            Account Holder Name </p>
                                        <p className="italic text-xs text-red ml-1 pt-1">required</p>

                                    </HelpText>

                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                outline={false}
                                                value={accountName}
                                                onChange={(e) => setAccountName(e.target.value)}
                                                color="red"
                                                placeholder="E.g. John Smith"
                                                size="large"
                                                type="text"
                                                onFocus={() => clearError('accountName')}
                                                error={error && error.accountName}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.accountTypeTooltip
                                        }
                                    >
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                            id="accountType">
                                            Account Type </p>
                                        <p className="italic text-xs text-red ml-1 pt-1">required</p>

                                    </HelpText>

                                    <CardBody>
                                        <div
                                            className="text-left"
                                            onClick={() => clearError('accountType')}
                                        >
                                            <DropdownInput
                                                color="red"
                                                className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                                                error={error && error.accountType}
                                                value={accountType}
                                                onChange={(e) => setAccountType(e.target.value)}
                                                setValue={setAccountType}
                                                placeholder="Your account type"
                                                options={ACCOUNT_TYPES}
                                                onlyValue={true}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.accountNumberTooltip
                                        }
                                    >
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                            id="accountNumber" >
                                            Account Number</p>
                                        <p className="italic text-xs text-red ml-1 pt-1">required</p>

                                    </HelpText>

                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                outline={false}
                                                value={accountNumber}
                                                onChange={(e) => setAccountNumber(e.target.value)}
                                                color="red"
                                                placeholder="E.g. 1234567890"
                                                size="large"
                                                type="text"
                                                onFocus={() => clearError('accountNumber')}
                                                error={error && error.accountNumber}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                </Card>
                                <div className="h-4" />
                            </>
                        ) : (
                            <>
                                <Card className={`max-w-3xl text-left bg-white`}>
                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.bankAddressTooltip
                                        }
                                    >
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal" id="bankAddress">
                                            Bank Address
                                        </p>
                                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                                    </HelpText>

                                    <CardBody>
                                        <div className="text-left">
                                            <PlacesAutocomplete
                                                value={bankAddress}
                                                setValue={setBankAddress}
                                                placeholder="Start typing and select the address"
                                                error={error && error.bankAddress}
                                                onFocus={() => clearError('bankAddress')}
                                                id="pacg3"
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.ibanTooltip
                                        }
                                    >
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal" id='ibanNumber'>
                                            IBAN Number
                                        </p>
                                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                                    </HelpText>

                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                outline={false}
                                                value={ibanNumber}
                                                onChange={(e) => setIbanNumber(e.target.value)}
                                                color="red"
                                                placeholder="E.g. AL35202111090000000001234567"
                                                size="large"
                                                type="text"
                                                onFocus={() => clearError('ibanNumber')}
                                                error={error && error.ibanNumber}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.accountNameTooltip
                                        }
                                    >
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                            id="accountName">
                                            Account Holder Name</p>
                                    </HelpText>

                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                outline={false}
                                                value={accountName}
                                                onChange={(e) => setAccountName(e.target.value)}
                                                color="red"
                                                placeholder="E.g. John Smith"
                                                size="large"
                                                type="text"
                                                onFocus={() => clearError('accountName')}
                                                error={error && error.accountName}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.accountTypeTooltip
                                        }
                                    >
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                            id="accountType" >
                                            Account Type</p>
                                    </HelpText>

                                    <CardBody>
                                        <div
                                            className="text-left"
                                            onClick={() => clearError('accountType')}
                                        >
                                            <DropdownInput
                                                color="red"
                                                className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                                                error={error && error.accountType}
                                                value={accountType}
                                                onChange={(e) => setAccountType(e.target.value)}
                                                setValue={setAccountType}
                                                placeholder="Your account type"
                                                options={ACCOUNT_TYPES}
                                                onlyValue={true}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.accountNumberTooltip
                                        }
                                    >
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                            id="accountNumber" >
                                            Account Number</p>
                                    </HelpText>

                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                outline={false}
                                                value={accountNumber}
                                                onChange={(e) => setAccountNumber(e.target.value)}
                                                color="red"
                                                placeholder="E.g. 1234567890"
                                                size="large"
                                                type="text"
                                                onFocus={() => clearError('accountNumber')}
                                                error={error && error.accountNumber}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.swiftCodeTooltip
                                        }
                                    >
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal" id="swiftCode">
                                            Swift Code
                                        </p>
                                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                                    </HelpText>

                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                outline={false}
                                                value={swiftCode}
                                                onChange={(e) => setSwiftCode(e.target.value)}
                                                color="red"
                                                placeholder="E.g. SBZAZAJJ"
                                                size="large"
                                                type="text"
                                                onFocus={() => clearError('swiftCode')}
                                                error={error && error.swiftCode}
                                            />
                                        </div>
                                        <div className="h-2" />
                                    </CardBody>

                                    <HelpText
                                        iconSize="xl"
                                        helpText={
                                            helpText &&
                                            helpText.newBook &&
                                            helpText.newBook.bankDetails &&
                                            helpText.newBook.bankDetails.paypalTooltip
                                        }
                                    >
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal">
                                            Paypal Address{' '}
                                            <span className="text-base">
                                                (email address associated with your paypal account)
                                            </span>
                                        </p>
                                    </HelpText>

                                    <CardBody>
                                        <div className="text-left">
                                            <Input
                                                outline={false}
                                                value={paypal}
                                                onChange={(e) => setPaypal(e.target.value)}
                                                color="red"
                                                placeholder="E.g. someone@domain.com"
                                                size="large"
                                                type="text"
                                                onFocus={() => clearError('paypal')}
                                                error={error && error.paypal}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="h-4" />
                            </>
                        )
                    ) : (
                        <></>
                    )}
                    <h2 className="ml-1 text-4xl font-bold mb-2">Publisher Details</h2>
                    <HelpText
                        iconSize="xl"
                        helpText="Kindly take note that the information mentioned below is relevant to the signing of your contract. Should any modifications occur to these fields, it will be necessary for you to complete the process of re-signing your contract."
                    >
                        <p className="text-gray-600 ml-1 text-xl leading-normal">
                            Revise and update your publisher details for contracts. Please note that this applies to all existing publisher details.
                        </p>
                    </HelpText>
                    <div className="h-5" />
                    <Button
                        color="red"
                        className="hover:bg-red hover:text-white w-full hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="md"
                        onClick={() => setShowSigningDetails(!showSigningDetails)}
                    >{showSigningDetails ? "Hide Signing Details" : "Show Signing Details"}</Button>
                    <div className="h-8" />
                    {showSigningDetails ? (
                        <>
                            <Card className={`max-w-3xl text-left bg-white mt-2`}>
                                <HelpText
                                    iconSize="xl"
                                    helpText={
                                        helpText &&
                                        helpText.newBook &&
                                        helpText.newBook.publisherInfo &&
                                        helpText.newBook.publisherInfo.publisherNameTooltip + " If you are a self-publisher, please enter your name here."
                                    }>
                                    <p className="text-gray-600 ml-1 text-2xl leading-normal" id="publisherName" >
                                        Publisher Name
                                    </p>
                                    <p className="italic text-xs text-red ml-1 pt-1">required</p>
                                </HelpText>

                                <CardBody>
                                    <div
                                        className="text-left"
                                        onClick={() => clearError('publisherName')}
                                    >
                                        <Input
                                            outline={false}
                                            value={publisherName}
                                            onChange={(e) => setPublisherName(e.target.value)}
                                            color="red"
                                            placeholder="E.g. Shane's Publishing"
                                            size="large"
                                            type="text"
                                            onFocus={() => clearError('publisherName')}
                                            error={error && error.publisherName}
                                        />
                                    </div>
                                    <div className="h-2" />
                                </CardBody>

                                <HelpText
                                    iconSize="xl"
                                    helpText={
                                        helpText &&
                                        helpText.newBook &&
                                        helpText.newBook.publisherInfo &&
                                        helpText.newBook.publisherInfo.publisherEmailTooltip
                                    }
                                >
                                    <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                        id="publisherEmail" >
                                        Publishers Email </p>
                                    <p className="italic text-xs text-red ml-1 pt-1">required</p>

                                </HelpText>

                                <CardBody>
                                    <div className="text-left">
                                        <Input
                                            outline={false}
                                            value={publisherEmail}
                                            onChange={(e) => setPublisherEmail(e.target.value)}
                                            color="red"
                                            placeholder="E.g. shanespublishing@gmail.com"
                                            size="large"
                                            type="text"
                                            onFocus={() => clearError('publisherEmail')}
                                            error={error && error.publisherEmail}
                                        />
                                    </div>
                                    <div className="h-2" />
                                </CardBody>

                                <HelpText
                                    iconSize="xl"
                                    helpText={
                                        helpText &&
                                        helpText.newBook &&
                                        helpText.newBook.publisherInfo &&
                                        helpText.newBook.publisherInfo.publisherTelephoneTooltip
                                    }
                                >
                                    <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                        id="publisherPhoneNumber">
                                        Publishers Phone Number
                                    </p>
                                    <p className="italic text-xs text-red ml-1 pt-1">required</p>
                                </HelpText>

                                <CardBody>
                                    <div className="h-2" />
                                    <div className="text-left">
                                        <CountrySelectInput
                                            outline={false}
                                            value={publisherPhoneNumber}
                                            setValue={setPublisherPhoneNumber}
                                            onChange={(e) => setPublisherPhoneNumber(e.target.value)}
                                            color="red"
                                            placeholder="E.g. (+27)123456789"
                                            size="large"
                                            type="text"
                                            onFocus={() => clearError('publisherPhoneNumber')}
                                            error={error && error.publisherPhoneNumber}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="h-2" />
                                </CardBody>

                                <HelpText
                                    iconSize="xl"
                                    helpText={
                                        helpText &&
                                        helpText.newBook &&
                                        helpText.newBook.publisherInfo &&
                                        helpText.newBook.publisherInfo.publisherContactPersonTooltip
                                    }
                                >
                                    <p className="text-gray-600 ml-1 text-2xl leading-normal"
                                        id="contactName" >
                                        Contact Name
                                    </p>
                                    <p className="italic text-xs text-red ml-1 pt-1">required</p>

                                </HelpText>

                                <CardBody>
                                    <div className="text-left">
                                        <Input
                                            outline={false}
                                            value={contactName}
                                            onChange={(e) => setContactName(e.target.value)}
                                            color="red"
                                            placeholder="E.g. Shane Smith"
                                            size="large"
                                            type="text"
                                            onFocus={() => clearError('contactName')}
                                            error={error && error.contactName}
                                        />
                                    </div>
                                    <div className="h-2" />
                                </CardBody>

                                <HelpText
                                    iconSize="xl"
                                    helpText={
                                        helpText &&
                                        helpText.newBook &&
                                        helpText.newBook.publisherInfo &&
                                        helpText.newBook.publisherInfo.publisherRegistrationIdTooltip
                                    }
                                >
                                    <p
                                        className="text-gray-600 ml-1 text-2xl leading-normal"
                                        id="businessRegistrationNumber">
                                        Company Registration or ID Number
                                    </p>
                                    <p className="italic text-xs text-red ml-1 pt-1">required</p>
                                </HelpText>

                                <CardBody>
                                    <div
                                        className="text-left"
                                        onClick={() => clearError('businessRegistrationNumber')}
                                    >
                                        <Input
                                            outline={false}
                                            value={businessRegistrationNumber}
                                            onChange={(e) => setBusinessRegistrationNumber(e.target.value)}
                                            color="red"
                                            placeholder="E.g. 1234567890"
                                            size="large"
                                            type="text"
                                            onFocus={() => clearError('businessRegistrationNumber')}
                                            error={error && error.businessRegistrationNumber}
                                        />
                                    </div>
                                    <div className="h-2" />
                                </CardBody>

                                <HelpText
                                    iconSize="xl"
                                    helpText={
                                        helpText &&
                                        helpText.newBook &&
                                        helpText.newBook.publisherInfo &&
                                        helpText.newBook.publisherInfo.publisherPostalAddressTooltip
                                    }
                                >
                                    <p
                                        className="text-gray-600 ml-1 text-2xl leading-normal"
                                        id="postalAddress">
                                        Postal Address
                                    </p>
                                    <p className="italic text-xs text-red ml-1 pt-1">required</p>

                                </HelpText>

                                <CardBody>
                                    <div className="text-left">
                                        <Input
                                            outline={false}
                                            value={postalAddress}
                                            onChange={(e) => setPostalAddress(e.target.value)}
                                            color="red"
                                            placeholder="E.g. 11 Apple Street, Johannesburg, 2000"
                                            size="large"
                                            type="text"
                                            onFocus={() => clearError('postalAddress')}
                                            error={error && error.postalAddress}
                                        />
                                    </div>
                                    <div className="h-2" />
                                </CardBody>

                                <HelpText
                                    iconSize="xl"
                                    helpText={
                                        helpText &&
                                        helpText.newBook &&
                                        helpText.newBook.publisherInfo &&
                                        helpText.newBook.publisherInfo.publisherPhysicalAddressUnder
                                    }
                                >
                                    <p
                                        className="text-gray-600 ml-1 text-2xl leading-normal"
                                        id="physicalAddress" >
                                        Physical Address
                                    </p>
                                    <p className="italic text-xs text-red ml-1 pt-1">required</p>
                                </HelpText>

                                <CardBody>
                                    <div className="text-left">
                                        <Input
                                            outline={false}
                                            value={physicalAddress}
                                            onChange={(e) => setPhysicalAddress(e.target.value)}
                                            color="red"
                                            placeholder="E.g. John Smith"
                                            size="large"
                                            type="text"
                                            onFocus={() => clearError('physicalAddress')}
                                            error={error && error.physicalAddress}
                                        />
                                    </div>
                                    <div className="h-2" />
                                </CardBody>
                            </Card>
                            <div className="h-5" />
                        </>
                    ) : (
                        <></>
                    )}
                    <Button
                        color="red"
                        className="hover:bg-red hover:text-white hover:bg-red w-full float-right mb-6"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onClickValidate}
                    >
                        Update
                    </Button>
                    <div className="h-10" />
                    <Modal
                        showModal={showConfirmModal}
                        setShowModal={setShowConfirmModal}
                        size="lg"
                    >
                        <div className="p-5">
                            <div className="flex items-center justify-between mb-2">
                                <h3 className="text-2xl font-bold">
                                    Please make sure the following details you entered are correct
                                </h3>
                                <button
                                    onClick={() => setShowConfirmModal(false)}
                                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                >
                                    <Icon name="close" size="md" />
                                </button>
                            </div>
                            <p className="text-gray-500 ml-1 mb-5 text-md">
                                We will utilize the precise information provided to edit and update your account details. Please ensure that the banking details entered are accurate, as they will be used for royalty payouts. We cannot be held responsible for any inaccuracies in the details entered on this page.
                            </p>
                            <div className="border border-black rounded-lg p-4 mb-4">
                                <h2 className="text-lg font-bold mb-2">User Details</h2>
                                <p className="mb-1">
                                    <span className="font-bold">First Name:</span> {firstName}
                                </p>
                                <p className="mb-1">
                                    <span className="font-bold">Last Name:</span> {lastName}
                                </p>
                                {/* <p className="mb-1">
                                        <span className="font-bold">Username:</span> {username}
                                    </p> */}
                                <p className="mb-1">
                                    <span className="font-bold">Email:</span> {emailAddress}
                                </p>
                                <p className="mb-1">
                                    <span className="font-bold">Phone Number:</span> {phoneNumber}
                                </p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center">
                                <div className="border border-black rounded-lg p-4">
                                    <h2 className="text-lg font-bold mb-2">Signing Details</h2>
                                    <p className="mb-1">
                                        <span className="font-bold">Publisher Name:</span> {publisherName}
                                    </p>
                                    <p className="mb-1">
                                        <span className="font-bold">Publisher's Email:</span> {publisherEmail}
                                    </p>
                                    <p className="mb-1">
                                        <span className="font-bold">Publisher's Number:</span> {publisherPhoneNumber}
                                    </p>
                                    <p className="mb-1">
                                        <span className="font-bold">Contact Name:</span> {contactName}
                                    </p>
                                    <p className="mb-1">
                                        <span className="font-bold">Business Registration Number:</span> {businessRegistrationNumber}
                                    </p>
                                    <p className="mb-1">
                                        <span className="font-bold">Postal Address:</span> {postalAddress}
                                    </p>
                                    <p className="mb-1">
                                        <span className="font-bold">Physical Address:</span> {physicalAddress}
                                    </p>
                                </div>
                                <div className="border border-black rounded-lg p-4">
                                    <h2 className="text-lg font-bold mb-2">Banking Details</h2>
                                    {!isInternationalAccount ? (
                                        <>
                                            <p className="mb-1">
                                                <span className="font-bold">Account Number:</span> {accountNumber}
                                            </p>
                                            <p className="mb-1">
                                                <span className="font-bold">Bank Name:</span> {bankName}
                                            </p>
                                            <p className="mb-1">
                                                <span className="font-bold">Branch Code:</span> {branchCode}
                                            </p>
                                            <p className="mb-1">
                                                <span className="font-bold">Account Holder Name:</span> {accountName}
                                            </p>
                                            <p className="mb-1">
                                                <span className="font-bold">Account Type:</span> {accountType}
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            {ibanNumber && (
                                                <p className="mb-1">
                                                    <span className="font-bold">IBAN Number:</span> {ibanNumber}
                                                </p>
                                            )}
                                            {accountName && (
                                                <p className="mb-1">
                                                    <span className="font-bold">Account Holder Name:</span> {accountName}
                                                </p>
                                            )}
                                            {accountNumber && (
                                                <p className="mb-1">
                                                    <span className="font-bold">Account Number:</span> {accountNumber}
                                                </p>
                                            )}
                                            {swiftCode && (
                                                <p className="mb-1">
                                                    <span className="font-bold">Swift Code:</span> {swiftCode}
                                                </p>
                                            )}
                                            <p className="mb-1">
                                                <span className="font-bold">Bank Address:</span> {bankName}
                                            </p>
                                            <p className="mb-1">
                                                <span className="font-bold">Account Type:</span> {accountType}
                                            </p>
                                            {paypal && (
                                                <p className="mb-1">
                                                    <span className="font-bold">PayPal:</span> {paypal}
                                                </p>
                                            )}

                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="h-5" />
                            {/* Creating a check box so that Proceed  button is only clickable if they check this check box ,we should have a description of what they are agreeing too*/}
                            <div className="flex items-center">
                                <input
                                    id="terms"
                                    type="checkbox"
                                    iconcolor="red"
                                    className="h-6 w-6 text-red-600 focus:ring-red-500 border-gray-300 rounded"
                                    onChange={(e) => setTermsChecked(e.target.checked)}
                                />
                                <label className="ml-5 block text-sm font-medium text-gray-700">
                                    I agree and confirm that the above details are correct, I also acknowledge that these changes will not be reflected immediately and will be reviewed by the admin team.
                                </label>
                            </div>
                            <div className="h-5" />
                            <Button
                                color={termsChecked ? "red" : "gray"}
                                className={` ${!termsChecked
                                    ? 'cursor-not-allowed'
                                    : 'hover:bg-red hover:text-white hover:pr-2'
                                    }  w-full md:w-1/4 float-right`}
                                buttonType="filled"
                                size="lg"
                                ripple="dark"
                                onClick={onContinueClick}
                                disabled={!termsChecked}
                            >
                                Proceed
                            </Button>
                        </div>
                    </Modal>
                    {/* Loading Modal , loading gif*/}
                    <Modal
                        showModal={loading}
                        setShowModal={setLoading}
                        onClose={() => setLoading(false)}
                        title="Please wait..."
                        size="lg"
                    >
                        <div className="flex justify-center">
                            <img alt="loading" className="h-10 w-auto" src="/images/loading.gif" />
                        </div>
                    </Modal>
                    {/* Completion modal, if this user exits or close this tab they will be redirect to profile */}
                    <Modal
                        showModal={completeModal}
                        setShowModal={setCompleteModal}
                        onClose={() => handleCloseModal()}
                        size="sm"
                    >
                        <div className="flex items-center justify-between mb-2">
                            <h3 className="text-2xl font-bold mb-2 mr-2">Your request has been successfully submitted.</h3>
                            <button
                                onClick={() => handleCloseModal()}
                                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                            >
                                <Icon name="close" size="md" />
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <img alt="loading" className="h-64 w-auto" src="/images/complete_tick.gif" />
                        </div>
                    </Modal>

                    {/* Failed to update modal this can open and close */}
                    <Modal
                        showModal={failedModal}
                        setShowModal={setFailedModal}
                        onClose={() => setFailedModal(false)}
                        size="sm"
                    >
                        <div className="flex items-center w-full justify-between">
                            <h3 className="w-full whitespace-wrap text-2xl font-bold mb-2 mr-2">Request failed </h3>
                            <button
                                onClick={() => setFailedModal(false)}
                                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                            >
                                <Icon name="close" size="md" />
                            </button>
                        </div>
                        <p>We apologize for the inconvenience, but it seems that there was an issue with submitting your request. Please kindly try again later.</p>
                        <div className="h-4" />
                        <div className="flex justify-center">
                            <img alt="loading" className="h-64 w-auto" src="/images/exit.gif" />
                        </div>
                    </Modal>
                    <div className="h-10" />
                </div>
            </div>
        </div>
    )
}
