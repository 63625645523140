import Icon from '@material-tailwind/react/Icon';
import Tooltips from '@material-tailwind/react/Tooltips';
import TooltipsContent from '@material-tailwind/react/TooltipsContent';


import helpText from 'static-data/help-text';

export default function RoyaltiesDisplay({
    isRoyaltiesFloating,
    isLocal,
    isInternational,
    royalties,
    bookFormat,
    percentageComplete,
    helpRef,
    isRoyaltiesLoading,
    setIsRoyaltiesLoading,
    paperTypesFiltered,
    setPaperTypesFiltered,
    internationalPaperTypesFiltered,
    setInternationalPaperTypesFiltered,
}) {
    return (
        <>
            <div
                style={
                    isRoyaltiesFloating
                        ? { bottom: '1em', position: 'fixed', right: '1em' }
                        : {}
                }
                className={`bg-black transition-all ${isRoyaltiesFloating ? 'floating-royalties' : ''} rounded-xl z-50 p-4 shadow-2xl md:px-5`}
            >
                <div className={isRoyaltiesFloating ? "ml-auto md:ml-5 cursor-pointer" : "ml-auto cursor-pointer"}>
                    <span ref={helpRef} className="text-gray-500">
                        <Icon className="ml-2" name="info" size="3xl" color="white" />
                    </span>
                    <Tooltips placement="top" ref={helpRef}>
                        <TooltipsContent>
                            {helpText && helpText.newBook && helpText.newBook.calculator && helpText.newBook.calculator.royaltyTooltip && helpText.newBook.calculator.royaltyTooltip[bookFormat]}
                        </TooltipsContent>
                    </Tooltips>
                </div>
                <div className={`flex items-center justify-start py-1 md:h-10 h-auto ${isRoyaltiesFloating ? "w-auto" : ""}`}>
                    {(royalties && !isRoyaltiesLoading) ? (
                        <div className="text-left px-5 grid md:grid-cols-2 w-full">
                            {royalties && royalties.localRoyalty && ((isLocal && (bookFormat === "print")) || (bookFormat === "ebook" || bookFormat === "audiobook")) && (
                                <>
                                    <p className=" text-lg text-white mt-0">
                                        <span className="fw-300 mr-3 text-white">
                                            {
                                                (bookFormat === "ebook" || bookFormat === "audiobook")
                                                    ? "Estimated Royalties"
                                                    : (percentageComplete < 33.33)
                                                        ? `Local (est.)${isRoyaltiesFloating ? '' : ' Royalty Estimation'}`
                                                        : `Local${isRoyaltiesFloating ? '' : ' Royalty Estimation'}`
                                            }
                                        </span>
                                    </p>
                                    <p className="font-bold text-lg text-white mt-0 md:text-right">
                                        {royalties && royalties.localRoyalty}
                                    </p>
                                </>
                            )}
                            {royalties && royalties.internationalRoyalty && ((isInternational && (bookFormat === "print")) || (bookFormat === "ebook" || bookFormat === "audiobook")) && (
                                <>
                                    <p className=" text-lg text-white mt-0">
                                        <span className="fw-300 mr-3 text-white3">{(percentageComplete < 33.33) ? "International (est.)" : "International"} {isRoyaltiesFloating ? "" : "Royalty Estimation"}</span>
                                    </p>
                                    <p className="font-bold text-lg text-white mt-0 md:text-right">
                                        {royalties && royalties.internationalRoyalty && (typeof royalties.internationalRoyalty === 'string') && royalties.internationalRoyalty.replace('$', 'USD')}
                                    </p>
                                </>
                            )}
                        </div>
                    ) :
                        (royalties && isRoyaltiesLoading) ? (
                            <div className="w-full h-full flex items-center justify-start">
                                <img alt="loading" className="h-10 w-auto" src="/images/loading.gif" />
                                <p className="text-lg text-white mt-0 pl-2">
                                    Not enough info
                                </p>
                            </div>
                        ) : (
                            <div className="w-full h-full flex items-center justify-start">
                                <p className="text-lg text-white mt-0">
                                    Not enough info
                                </p>
                            </div>

                        )}
                </div>
                <div className="text-lg text-white ml-5 mt-2 flex flex-col">
                    {isLocal && (bookFormat === "print") && (
                        <div className="flex mt-1 ">
                            <p className='text-gray-500'>Local Paper Options:</p>
                            <p className={paperTypesFiltered && paperTypesFiltered > 0 ? `text-green-500` : `text-red-500`}>&nbsp;{paperTypesFiltered}</p>
                        </div>
                    )}
                    {isInternational && (bookFormat === "print") && (
                        <div className="flex mt-1 ">
                            <p className='text-gray-500'>International Paper Options:</p>
                            <p className={internationalPaperTypesFiltered && internationalPaperTypesFiltered > 0 ? `text-green-500` : `text-red-500`}>&nbsp;{internationalPaperTypesFiltered}</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
