export default function Card({children, className, style}) {
    return (
        <div className={`w-full rounded-xl overflow-hidden p-4 ${className}`} style={style}>
            <div className="p-4">
                {children}
            </div>
        </div>
    )
}

