import { usePlacesWidget } from "react-google-autocomplete";

import './PlacesAutocomplete.css';

const PlacesAutocomplete = ({ placeholder, value, setValue, error, id, onFocus, disabled }) => {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      if (place.formatted_address) {
        setValue(place.formatted_address);
      }
    },
    options: {
      types: ["address"]
      // componentRestrictions: { country: "za" },
    },
  });

  const randomNumber = () => Math.random() * 10;

  return (
    <>
      <div className="w-full relative h-11">
        <input
          ref={ref}
          className="w-full h-full text-gray-800 leading-normal shadow-none outline-none focus:outline-none focus:ring-0 focus:text-gray-800 px-0 false mt-input-red-500 mt-input bg-transparent border-none undefined undefined"
          value={value}
          type="text"
          size="regular"
          placeholder=" "
          onFocus={onFocus}
          onChange={e => setValue(e.target.value)}
          id={id ? id : ("pacg" + randomNumber())}
          // onClick={showDropdown}
          // onFocus={onFocus}
          error={error}
          disabled={disabled}
        />
        {/* <label class="text-gray-400 absolute left-0 -top-0.5 w-full h-full border border-t-0 border-l-0 border-r-0 border-b-1 border-gray-300 pointer-events-none false false false false false">
          <span class="false absolute top-1/4 transition-all duration-300">{placeholder}</span>
        </label> */}
        <label className={`text-gray-400 absolute left-0 -top-0.5 w-full h-full border border-t-0 border-l-0 border-r-0 border-b-1 ${error ? 'border-red-500' : 'border-gray-300'} pointer-events-none`}>
          <span className="false absolute top-1/4 transition-all duration-300">{placeholder}</span>
        </label>
      </div>
      {error && <span className="block mt-1 text-xs text-red-500">{error}</span>}
    </>
  );
};

export default PlacesAutocomplete;