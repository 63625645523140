import {store} from 'state-pool';

export function initializeStore() {
    store.setState('orders', []);
    store.setState('order', {});

    store.setState('transactions', []);
    store.setState('transaction', {});

    store.setState('books', []);
    store.setState('book', {});

    store.setState('quotes', []);
    store.setState('quote', {});

    store.setState('documents', []);
    store.setState('document', {});

    store.setState('currentUser', null);

    store.setState('chatPreload', '');

    store.setState('resources', []);

    store.setState('sales', []);
    store.setState('voiceArtists', []);

    store.setState('prices', {});
};

export function resetState() {
    store.remove(['orders', 'order', 'transactions', 'transaction', 'books', 'book', 'quotes', 'quote', 'documents', 'document', 'currentUser', 'resources', 'sales', 'voiceArtists', 'prices'], initializeStore);
}