import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import dotEnv from 'dotenv';

import App from 'App';
import UnderConstruction from 'pages/UnderConstruction';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

dotEnv.config();

// Uncomment when pushing to git
LogRocket.init('hs66lp/boo-front');

const underConstruction = false;

ReactDOM.render(
    <React.StrictMode>
        {underConstruction ? <UnderConstruction /> :  <App />}
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorkerRegistration.register();