import { useState, useEffect, useRef } from 'react';

import PdfViewer from 'components/document-handling/PdfViewer';

import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

import './FillAndSign.css';

export default function FillAndSign({file, setFile, editable, className}) {
    const [hasInit, setHasInit] = useState(false);
    const viewerRef = useRef();

    useEffect(() => {
        if(file && !hasInit) {
            clickPosition();
        }
    }, [file]);

    async function modifyPdf(x, y) {

        const existingPdfBytes = await fetch(file).then(res => res.arrayBuffer())
    
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const { width, height } = firstPage.getSize()
        firstPage.drawText('test', {
            x: x,
            y: height - y,
            size: 10,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
    
        const pdfBytes = await pdfDoc.save()

        const pdfDocChanged = await PDFDocument.load(pdfBytes)

        const pdfDataUri = await pdfDocChanged.saveAsBase64({ dataUri: true });

        setFile(pdfDataUri);
    }

    function clickPosition() {
        if(viewerRef && viewerRef.current) {
            viewerRef.current.onclick = function(e) { 
                let rect = e.target.getBoundingClientRect();
                let x = e.clientX - rect.left; //x position within the element.
                let y = e.clientY - rect.top;  //y position within the element.

                modifyPdf(x, y);

                setHasInit(true);
            }
        }
    }

    return (
            <>
                {file && (
                    <div className={`w-full flex items-center justify-center fill-and-sign ${className ? className : ''}`}>
                        <div ref={viewerRef} className="z-50">
                            <PdfViewer 
                                style={{zIndex: -1}}
                                file={file}
                            />
                        </div>
                    </div>
                )}
            </>
    );
}
