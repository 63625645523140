import { useState, useEffect } from 'react';
import Card from 'components/cards/Card';

import { capitalize, isValidJson } from 'utils';
import { getBookFiles } from 'routes/userRoutes';

import { getBookSales, getBookOrders, archiveBook, deleteBook } from 'routes/userRoutes';

import PdfModal from 'components/document-handling/PdfModal';
import HelpText from 'components/general/HelpText';

import BookDetails from './BookDetails';

import helpText from 'static-data/help-text';

import ViewBookMenu from './ViewBookMenu';
import BookSales from './BookSales';
import BookOrders from './BookOrders';

import { useAlert } from 'hooks/useAlert';
import { useGlobal } from 'hooks/useGlobalState';

export default function ViewBook({ book, isInternational }) {
    const [selectedSection, setSelectedSection] = useState('details');
    const [viewContract, setViewContract] = useState(null);
    const [showViewContract, setShowViewContract] = useState(false);
    const [viewBook, setViewBook] = useState(null);
    const [showViewBook, setShowViewBook] = useState(false);
    const [files, setFiles] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSalesLoading, setIsSalesLoading] = useState(true);
    const [isOrdersLoading, setIsOrdersLoading] = useState(true);
    const [noCoverImage, setNoCoverImage] = useState(false);
    const [currentBookFilesId, setCurrentBookFilesId] = useState(null);
    const [sales, setSales] = useState(null);
    const [orders, setOrders] = useState(null);
    const [hasSales, setHasSales] = useState(false);
    const [hasOrders, setHasOrders] = useState(false);

    const [hasAudioFiles, setHasAudioFiles] = useState(false);
    const [audioFiles, setAudioFiles] = useState(null);

    const { newAlert } = useAlert();

    const { load } = useGlobal();

    function onArchiveClick() {
        if (!book || !(book && book.id)) return;

        archiveBook(book.id, !isInternational).then(res => {
            if (res && res.statusCode === 0) {
                newAlert('success', 'Your book has been cancelled successfully.', '');

                load(`/user/getuserbooks`, 'books');
            } else {
                newAlert('danger', 'Something went wrong, while trying to archive your book.', '');
            }
        });
    }

    function onDeleteClick() {
        if (!book || !(book && book.id)) return;

        deleteBook(book.id, !isInternational).then(res => {
            if (res && res.statusCode === 0) {
                newAlert('success', 'Your book has been deleted successfully.', '');

                load(`/user/getuserbooks`, 'books');
            } else {
                newAlert('danger', 'Something went wrong, while trying to delete your book.', '');
            }
        });
    }

    useEffect(() => {

        if (book && book.id && (book.id !== currentBookFilesId)) {
            setHasOrders(false);
            setHasSales(false);
            setFiles(null);
            setSales(null);
            setOrders(null);
            setIsLoading(true);
            setNoCoverImage(false);
            setHasAudioFiles(false);
            setAudioFiles(null);

            // setFiles(null);

            getBookFiles(book.id, isInternational, !isInternational).then(res => {
                setCurrentBookFilesId(book.id);
                if (res && res.results && Array.isArray(res.results)) {
                    setFiles([...res.results]);

                    // console.log(res.results);

                    const hasAudioFilesTemp = res.results.find(item => item && typeof item === 'object' && item.filename && item.filename.includes('.mp3'));

                    // console.log(hasAudioFilesTemp, 'has af temp');
                    if (hasAudioFilesTemp) {
                        const audioFilesArr = res.results.filter(item => item.filename.includes('.mp3'));

                        // console.log(hasAudioFilesTemp, 'has af temp arr');

                        setHasAudioFiles(true);

                        setAudioFiles([...audioFilesArr]);
                    }

                    if (res && Array.isArray(res.results) && res.results[1] && res.results[1].fileBuffer) {
                        setNoCoverImage(false);
                        setIsLoading(false);

                        return;
                    } else {
                        setIsLoading(false);
                        setNoCoverImage(true);

                        return;
                    }
                } else {
                    setIsLoading(false);
                    setNoCoverImage(true);
                }
            });

            getBookSales(book.id).then(res => {
                if (res && res.results && Array.isArray(res.results) && res.results[0] && typeof res.results[0] === 'object') {
                    setSales(res.results[0]);

                    setHasSales(true);

                    setIsSalesLoading(false);

                }
                // console.log('BOOK_SALES', res.results);
            });

            getBookOrders(book.id).then(res => {
                if (res && res.results && Array.isArray(res.results) && res.results[0] && typeof res.results[0] === 'object') {
                    setOrders([...res.results]);
                    setHasOrders(true);

                    setIsOrdersLoading(false);

                }
            });
        }

        return;

    }, [book]);

    const getBookFormatString = (str) => {

        if (typeof str !== 'string') return '';

        switch (true) {
            case str.toLowerCase().trim() === 'ebook': return 'eBook';
            default: return capitalize(str);
        }
    }

    return (
        <>
            {book && (
                <>
                    <h3 className="text-4xl font-bold">
                        {book.book_title}
                        <span className="font-thin text-xl italic ml-1">{getBookFormatString(book.format_type)} format</span>
                    </h3>
                    <div className="h-5" />
                    <div className="flex flex-col md:flex-row mb-5">
                        <div className="w-full">
                            <Card className="bg-red-500 shadow-2xl cursor-pointer">
                                <div className="flex items-center justify-center flex-col h-full py-1">
                                    <HelpText helpText={helpText && helpText.books && helpText.books.royaltyFeeTooltip} iconSize="2xl" iconColor="white" placement="top">
                                        <h1 className="text-white font-bold text-xl md:text-4xl">
                                            {book.misc && !isInternational && isValidJson(book.misc) && typeof JSON.parse(book.misc).localroyalty === 'number' ? 'R ' + Number(JSON.parse(book.misc).localroyalty).toFixed(2) : !isInternational ? 'R 0.00' : ''}
                                            {book.misc && isInternational && isValidJson(book.misc) && JSON.parse(book.misc).internationroyalty ? 'USD ' + Number(JSON.parse(book.misc).internationroyalty).toFixed(2) : isInternational ? 'USD 0.00' : ''}
                                        </h1>
                                    </HelpText>
                                    <h3 className="text-white">Royalty Fee</h3>
                                </div>
                            </Card>
                        </div>
                        {!isInternational && <div className="w-full mt-5 md:mt-auto md:ml-5">
                            <Card className="bg-red-500 shadow-2xl cursor-pointer">
                                <div className="flex items-center justify-center flex-col h-full py-1">
                                    <HelpText helpText={helpText && helpText.books && (isInternational ? helpText.books.internationaSellingPriceTooltip : helpText.books.localSellingPriceTooltip)} iconSize="2xl" iconColor="white" placement="top">
                                        <h1 className="text-white font-bold text-xl md:text-4xl">
                                            {book && book.price_sale && typeof book.price_sale === 'number' && `R ${book.price_sale.toFixed(2)}`}
                                        </h1>
                                    </HelpText>
                                    <h3 className="text-white">Local Selling Price</h3>
                                </div>
                            </Card>
                        </div>}
                        {isInternational ? <div className="w-full mt-5 md:mt-auto md:ml-5">
                            <Card className="bg-red-500 shadow-2xl cursor-pointer">
                                <div className="flex items-center justify-center flex-col h-full py-1">
                                    <HelpText helpText="The amount the consumer will pay for your book outside of South Africa" iconSize="2xl" iconColor="white" placement="top">
                                        <h1 className="text-white font-bold text-xl md:text-4xl">
                                            {book && typeof book.price_sale === 'number' ? 'USD ' + book.price_sale.toFixed(2) : ''}
                                        </h1>
                                    </HelpText>
                                    <h3 className="text-white">International Selling Price</h3>
                                </div>
                            </Card>
                        </div> : ''}
                    </div>
                    {(hasSales || hasOrders) && (
                        <ViewBookMenu
                            selectedSection={selectedSection}
                            setSelectedSection={setSelectedSection}
                            hasSales={hasSales}
                            hasOrders={hasOrders}
                        />
                    )}
                    {selectedSection === 'details' && (
                        <BookDetails
                            book={book}
                            isInternational={isInternational}
                            noCoverImage={noCoverImage}
                            isLoading={isLoading}
                            files={files}
                            setViewContract={setViewContract}
                            setShowViewContract={setShowViewContract}
                            setViewBook={setViewBook}
                            setShowViewBook={setShowViewBook}
                            audioFiles={audioFiles}
                            hasAudioFiles={hasAudioFiles}
                            onArchiveClick={onArchiveClick}
                            onDeleteClick={onDeleteClick}
                        />
                    )}
                    {selectedSection === 'sales' && hasSales && (
                        <BookSales
                            sales={sales}
                        />
                    )}

                    {selectedSection === 'orders' && hasOrders && (
                        <BookOrders
                            orders={orders}
                        />
                    )}

                    <PdfModal
                        showModal={showViewContract}
                        setShowModal={setShowViewContract}
                        file={viewContract && viewContract.contract}
                        title={viewContract && viewContract.title}
                    />
                    {viewBook && viewBook.book && <PdfModal
                        showModal={showViewBook}
                        setShowModal={setShowViewBook}
                        file={viewBook && viewBook.book}
                        title={viewBook && viewBook.title}
                    />}
                </>
            )}
        </>
    )
}
