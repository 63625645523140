import { podApi } from "api/pod";

const saveSignature = (signature: string) => {

    const data = {
        fileData: signature
    }

    try {
        podApi.post('/user/saveusersignature', data);
    } 
    catch (err) {
        console.log(err);
    }
    
}

const getSignature = async () => {
    try {
        return await podApi.get('/user/viewusersignature')
    } 
    catch (err) {
        console.log(err);
    }
    
}

export {
    saveSignature,
    getSignature
}