import { useState, useEffect } from 'react';

import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import Input from "@material-tailwind/react/Input";

import md5 from 'md5';

export default function DropdownInput({ value, setValue, placeholder, options, color, error, onFocus, onlyValue, dropdownClassName }) {
    const [rerender, setRerender] = useState(true);

    const id = md5(placeholder);

    const onOptionClick = (option) => {

        if (onlyValue) {
            const { value } = option;

            setValue(value);

            resetDropdown();

            return;
        }

        setValue(option);

        resetDropdown();
    }

    function resetDropdown() {
        setRerender(!rerender);
    }

    useEffect(() => {
        if (!rerender) setRerender(true);
    }, [rerender]);

    function showDropdown() {
        const dropdownEl = document.getElementById(id);

        if (dropdownEl) dropdownEl.click();
    }

    return (
        <>
            <Input
                outline={false}
                value={(value && !onlyValue) ? value.label : value}
                type="text"
                color="red"
                size="regular"
                placeholder={placeholder}
                onClick={showDropdown}
                onFocus={onFocus}
                error={error}
                readOnly
            />
            <div className="h-0">
                {rerender && (
                    <Dropdown
                        color={color ? color : 'red'}
                        placement="bottom-start"
                        id={id}
                        buttonType="link"
                        size="regular"
                        rounded={false}
                        block={false}
                        buttonText=""
                        ripple="light"
                        className={`hover:bg-green hover:text-white invisible h-0 p-0 m-0 ${dropdownClassName}`}
                    >
                        {options && Array.isArray(options) && options.length > 0 ? (
                            options.map(({ label, value }, i) => (
                                <DropdownItem key={label} color={color} ripple="light" onClick={() => onOptionClick({ value, label })}>
                                    {label}
                                </DropdownItem>
                            ))
                        ) : (
                            <p>No Options Available</p>
                        )}
                    </Dropdown>
                )}
            </div>
        </>
    )
}
