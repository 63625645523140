const user = {
    email: 'damian@beone.ai',
    name_first: 'dd',
    name_last: 'ww',
    is_activated: 1,
    fee_base: 1,
    balance_wallet: 0,
    balance_funds: '0',
    kyc_current: 0,
    kyc_pending: 0,
};

export default user;