import React from 'react';
import Input from '@material-tailwind/react/Input';
import TextArea from '@material-tailwind/react/Textarea'
import Icon from '@material-tailwind/react/Icon';

import Dropdown from "@material-tailwind/react/Dropdown";

import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import _ from 'lodash';

import { isValidDate, isValidJson } from 'utils';

import ChatController from './ChatController';

import EmojiPicker from 'emoji-picker-react';

import './Chat.css';

// let MESSAGES = [
//     {
//         source: 'system',
//         message: 'Hey there, welcome to Print on Demand. This is our support chat.'
//     }
// ];

export default function Chat() {
    const {
        numUnread,
        messagesRef,
        messages,
        handleFilesUpload,
        filesRef,
        message,
        setMessage,
        onSendMessageClick,
        onChatClick,
        onEmojiClick,
        showPickEmoji,
        onEmojiSelect,
        showSendImage,
        closeSendMessage,
        sendImage,
        onSendImageMessageClick,
        onOpenSendImageClick,
        loading
    } = ChatController();

    const location = useLocation();

    const dontShow = ['/new-book/audio-book', '/new-book/calculator', '/new-book/e-book', '/support', '/new-book/general-info'];

    // /public/34/chat_image_log/022022/image-022022-1648190770040.jpeg

    return (
        <>
            {!dontShow.includes(location.pathname) && <div onClick={onChatClick} className="fixed bottom-5 right-5 hover:shadow-xl text-white chat overflow-visible" style={{ zIndex: 9999, maxHeight: '70vh', overflowY: 'auto' }}>
                <Dropdown
                    color="red"
                    placement="top-start"
                    buttonText={
                        <>
                            <Icon name="message" size="4xl" />
                            {numUnread !== 0 && `(${numUnread})`}
                        </>
                    }
                    buttonType="filled"
                    size="regular"
                    rounded={true}
                    block={false}
                    ripple="light"
                    style={{ marginTop: '1em', position: 'relative', maxHeight: '70vh' }}
                >
                    <div ref={messagesRef} id="messagesScroll" className="w-96 p-5 flex flex-col max-h-96 relative" style={{ maxHeight: 'vh', overflowY: 'auto', marginBottom: '4rem' }}>
                        {messages && messages.map(({ message, source, created_at, misc }, i) => (misc && isValidJson(misc) && typeof JSON.parse(misc).imageData === 'string') ? (
                            <React.Fragment key={i}>
                                <div className={`msg ${source} relative rounded-lg w-4/5 self-${source === 'user' ? 'end' : 'start'} mb-1 bg-${source === 'user' ? 'red' : 'gray'}-500 p-4 opacity-100 text-white`}>
                                    <img src={misc && isValidJson(misc) && process.env.REACT_APP_API_PUBLIC + JSON.parse(misc).imageData} className={`w-full h-auto`} />
                                    {message && <p className="text-white text-base mt-2">{message}</p>}
                                </div>
                                <p className={`self-${source === 'user' ? 'end' : 'start'} text-xs text-gray-500 mb-5`}>{isValidDate(new Date(created_at)) && format(new Date(created_at), 'dd-MM-yyyy HH:mm')}</p>
                            </React.Fragment>
                        ) : (
                            <React.Fragment  key={i}>
                                <div className={`msg ${source} relative rounded-lg w-4/5 self-${source === 'user' ? 'end' : 'start'} mb-1 bg-${source === 'user' ? 'red' : 'gray'}-500 p-4 opacity-100 text-white`}>
                                    {message}
                                </div>
                                <p className={`self-${source === 'user' ? 'end' : 'start'} text-xs text-gray-500 mb-5`}>{isValidDate(new Date(created_at)) && format(new Date(created_at), 'dd-MM-yyyy HH:mm')}</p>
                            </React.Fragment>
                        ))}
                        {loading && <div className="w-full flex items-center justify-center">
                            <img src="/images/loading.gif" alt="Loading..." className="h-10 w-auto" />
                        </div>}
                        <div className="h-10" />
                        <input className="h-0 m-0 p-0" style={{ visibility: 'hidden' }} type="file" ref={filesRef} onClick={() => { if (filesRef && filesRef.current) filesRef.current.value = null }} onChange={e => handleFilesUpload(e)} accept=".svg,.png,.jpg,.jpeg,.jfif" />
                        <div className="rounded-lg bg-white pb-5 pl-3 pr-3 pt-2 mr-5 opacity-100 fixed bottom-0 left-0 right-0 w-full shadow-xl">
                            <form onSubmit={e => { e.preventDefault(); onSendMessageClick(); }}>
                                <div className="w-4/5">
                                    <Input
                                        outline={false}
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                        type="text"
                                        color="red"
                                        // error={error && error.email}
                                        placeholder="Your message"
                                        size="regular"
                                        onFocus={() => setMessage('')}
                                    />
                                </div>
                                <div className="flex absolute right-3 mt-1" style={{ bottom: '1.25rem' }}>
                                    <div
                                        onClick={onOpenSendImageClick}
                                        icononly="true"
                                        className="cursor-pointer mr-2 hover:bg-transparent"
                                    >
                                        <Icon color="gray" name="image" size="2xl" />
                                    </div>
                                    {/* <div
                                        className="cursor-pointer mr-2 hover:bg-transparent"
                                        onClick={onImageClick}
                                        icononly="true"
                                    >
                                        <Icon color="gray" name="music_video" size="2xl" />
                                    </div> */}
                                    {/* <div
                                        className="cursor-pointer mr-2 hover:bg-transparent"
                                        onClick={onEmojiClick}
                                        icononly="true"
                                    >
                                        <Icon color="gray" name="mood" size="2xl" />
                                    </div> */}
                                    <div
                                        className="cursor-pointer hover:bg-transparent"
                                        onClick={onSendMessageClick}
                                        icononly="true"
                                    >
                                        <Icon color="red" name="send" size="2xl" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="h-2" />
                    {showPickEmoji && <div onClick={onEmojiClick} className="absolute top-0 right-0 h-full w-full rounded-xl flex items-center justify-center" style={{ bottom: '6rem', background: '#00000070' }}>
                        <div className="shadow-lg opacity-100" onClick={(e) => e.stopPropagation()}>
                            <EmojiPicker onEmojiClick={onEmojiSelect} />
                        </div>
                    </div>}
                    {showSendImage && <div className="absolute top-0 right-0 h-full w-full rounded-xl flex items-center justify-center" style={{ bottom: '6rem', background: '#fff' }}>
                        <div className="opacity-100 w-full px-5" onClick={(e) => e.stopPropagation()}>
                            <div className="w-5 h-5 absolute top-5 right-5 cursor-pointer" onClick={closeSendMessage}>
                                <Icon name="close" size="3xl" color="gray" />
                            </div>
                            <div className="w-full flex justify-center mb-10">
                                <img className="w-auto h-40" src={sendImage} />
                            </div>
                            <div className="rounded-lg bg-transparent pb-5 mr-5 w-full px-5 opacity-100 absolute bottom-1 left-0">
                                <input
                                    className="w-full h-full border-b-2 text-gray-800 leading-normal shadow-none outline-none focus:outline-none focus:ring-0 focus:text-gray-800 px-3 pt-2.5 pb-2 bg-transparent focus:border-red-500"
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                    type="text"
                                    placeholder="Your message"
                                    onFocus={() => setMessage('')}
                                />
                                <div className="flex absolute top-2 right-8">
                                    <div
                                        className="cursor-pointer hover:bg-transparent"
                                        onClick={onSendImageMessageClick}
                                        icononly="true"
                                    >
                                        <Icon color="red" name="send" size="2xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </Dropdown>
            </div>}
        </>
    )
}
