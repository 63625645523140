import axios from "axios";

import { checkTokens } from "utils/auth";

const podApi = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
});

// request middleware
podApi.interceptors.request.use(
	async function (config) {
		const tokens = await checkTokens();

        // console.log(tokens)

		// Check if the headers object exists. If not, initialize it
		if (!config.headers) {
			config.headers = {};
		}

		if (tokens && tokens.accessToken) {
			config.headers.Authorization = `Bearer ${tokens.accessToken}`;
		} else {
			// Redirect to login or handle the case where tokens cannot be refreshed
		}

		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
// Response Interceptor
podApi.interceptors.response.use(
	async (response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			const tokens = await checkTokens();
			if (tokens && typeof tokens === "object" && tokens.accessToken) {
				originalRequest.headers.Authorization = `Bearer ${tokens.accessToken}`;
				return podApi(originalRequest);
			}
		}
		return Promise.reject(error);
	}
);

export { podApi };
