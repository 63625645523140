import { useState, useCallback, useEffect } from 'react'

import { Link, useHistory, useLocation } from 'react-router-dom'

import Button from '@material-tailwind/react/Button'
import Icon from '@material-tailwind/react/Icon'
import Card from '@material-tailwind/react/Card'
import CardBody from '@material-tailwind/react/CardBody'
import Checkbox from '@material-tailwind/react/Checkbox'

export default function Copyright({
    stage,
    setCompleted,
    completed,
    second,
    pagePathname,
}) {
    const [showModal, setShowModal] = useState(false)
    const [error, setError] = useState({})
    const [ownsPublishingRights, setOwnsPublishingRights] = useState(false);
    const [isPublicDomainWork, setIsPublicDomainWork] = useState(false);
    const [conforms, setConforms] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [validPublishingRights, setValidPublishingRights] = useState(null);

    const [publicationType, setPublicationType] = useState('');

    const [confirmCopyright, setConfirmCopyright] = useState(null);

    const history = useHistory()

    const isMobile = window.innerWidth < 767;

    useEffect(() => {
        for (let i = localStorage.length - 1; i >= 0; i--) {
            const key = localStorage.key(i);
            if (!['access-token', 'refresh-token', 'currentUser', 'activeTabInfo'].includes(key)) {
                localStorage.removeItem(key);
            }
        }
    }, [ownsPublishingRights, isPublicDomainWork]);

    const clearError = useCallback((key) => {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError({ ...tempErrors });
        }

        return;
    }, [error]);

    const onConfirmClick = () => {
        let tempErrors = {};

        if (ownsPublishingRights === true && confirmCopyright === false) {
            tempErrors.publisherRights = "Please select an option above to continue"
            setValidPublishingRights(false);
        }

        if (ownsPublishingRights === false && isPublicDomainWork === false) {
            setValidPublishingRights(false);
        }

        if (isPublicDomainWork === true) {
            if (publicationType == 'reproduction') {
                history.push(`/${pagePathname}/general-info`);
            } else if (publicationType == 'compilation') {
                history.push(`/${pagePathname}/general-info`);
            }
            else {
                setValidPublishingRights(false);
            }
        }

        if (!ownsPublishingRights) {
            setConforms(false);
            setSubmitted(true);
            return;
        } else {
            if (confirmCopyright === false) {
                setConforms(false);
                setSubmitted(true);
                return;
            }

            if (confirmCopyright === null) {
                tempErrors.confirmCopyright = "Please select an option above to continue"
                // return;
            }
        }

        // if(isPublicDomainWork) {
        //     // setConforms(false);
        //     if(publicationType === '') {
        //         setConforms(false);
        //     }
        // }

        if (tempErrors && typeof tempErrors === 'object' && (Object.keys(tempErrors).length > 0)) {
            setError(tempErrors);
            return;
        }

        setSubmitted(true);

        history.push(`/${pagePathname}/general-info`);
    }

    return (
        <div className="w-full text-left">
            <h3 className="text-4xl font-bold ">Publishing Rights</h3>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>
                <CardBody>
                    <p className="text-xl">
                        Please confirm the following before continuing with the upload process for your book:
                    </p>
                    <div className="h-4" />
                    {conforms && (
                        <>
                            {!isPublicDomainWork && (
                                <div className="mb-4 text-left flex items-center">

                                    <Checkbox
                                        color="red"
                                        value={ownsPublishingRights}
                                        className="flex-shrink-0"
                                        onChange={() => setOwnsPublishingRights(!ownsPublishingRights)}
                                        text=""
                                        id="checkbox-pulishing-rights"
                                        checked={ownsPublishingRights}
                                    />
                                    <p className="ml-1 bold">I own the copyright or hold necessary publishing rights. </p>
                                </div>
                            )}


                            {ownsPublishingRights && (
                                <div className="p-5 rounded-xl bg-gray-200 mt-2 mb-5">
                                    <h3 className="text-xl mb-5">Does your title?</h3>

                                    <div className="mb-4 text-left flex items-center">
                                        <span className="h-2 w-2 rounded-full bg-red mt-2 mr-2" style={{ justifySelf: 'flex-start', marginBottom: 'auto', flexShrink: 0 }} />
                                        <p className="ml-1">Include someone’s name, the name of a famous company or brand without a license from the owner?</p>
                                    </div>

                                    <div className="mb-4 text-left flex items-center">
                                        <span className="h-2 w-2 rounded-full bg-red mt-2 mr-2" style={{ justifySelf: 'flex-start', marginBottom: 'auto', flexShrink: 0 }} />
                                        <p className="ml-1">Include trademarks, pictures, or names of famous brands, toys, games, or companies without a license or the owner’s permission?</p>
                                    </div>

                                    <div className="mb-4 text-left flex items-center">
                                        <span className="h-2 w-2 rounded-full bg-red mt-2 mr-2" style={{ justifySelf: 'flex-start', marginBottom: 'auto', flexShrink: 0 }} />
                                        <p className="ml-1">Include pictures copied and pasted from the web or any other place without a license or the owner’s permission?</p>
                                    </div>

                                    <div className="mb-4 text-left flex items-center">
                                        <span className="h-2 w-2 rounded-full bg-red mt-2 mr-2" style={{ justifySelf: 'flex-start', marginBottom: 'auto', flexShrink: 0 }} />
                                        <p className="ml-1">Include the work of someone other than yourself without a license or the owner’s permission?</p>
                                    </div>

                                    <div className="mb-4 text-left flex items-center">
                                        <span className="h-2 w-2 rounded-full bg-red mt-2 mr-2" style={{ justifySelf: 'flex-start', marginBottom: 'auto', flexShrink: 0 }} />
                                        <p className="ml-1">Include misleading cover images or information that may cause someone to buy the title thinking it is something else?</p>
                                    </div>
                                    <div className="h-2 md:hidden block" />
                                    <h3 className="mt-2">For more information about copyright laws and frequently asked questions, visit the following sites: </h3>
                                    <div className="h-5" />
                                    <div className="h-2 md:hidden block" />
                                    <a href="https://www.copyright.gov/help/faq/faq-general.html " target="_blank" rel="noopener noreferrer" className="text-red underline ml-1">https://www.copyright.gov/help/faq/faq-general.html </a>
                                    <br />
                                    <a href="https://www.copyrightlaws.com/copyright-tips-legally-using-google-images/ " target="_blank" rel="noopener noreferrer" className="text-red underline ml-1">https://www.copyrightlaws.com/copyright-tips-legally-using-google-images/ </a>
                                    <div className="h-5" />
                                    <div className="mb-4 text-left flex items-center">
                                        <Checkbox
                                            color="red"
                                            value={confirmCopyright === true}
                                            className="flex-shrink-0"
                                            onChange={() => { setConfirmCopyright(true); clearError('confirmCopyright'); }}
                                            text=""
                                            id="checkbox-confirm-true"
                                            checked={confirmCopyright === true}
                                        />
                                        <p className="ml-1 bold">No, my title does <span className="bold">NOT</span> include any of the above.</p>
                                    </div>

                                    <div className="mb-4 text-left flex items-center">
                                        <Checkbox
                                            color="red"
                                            value={confirmCopyright === false}
                                            className="flex-shrink-0"
                                            onChange={() => { setConfirmCopyright(false); clearError('confirmCopyright'); }}
                                            text=""
                                            id="checkbox-confirm-false"
                                            checked={confirmCopyright === false}
                                        />
                                        <p className="ml-1 bold">Yes, my title includes one or many of the above.</p>
                                    </div>
                                    {error && error.confirmCopyright && <p className="text-red">{error.confirmCopyright}</p>}
                                </div>

                            )}

                            {!ownsPublishingRights && (
                                <div className="mb-4 text-left">
                                    <div className="w-full flex items-center mb-2">
                                        <Checkbox
                                            color="red"
                                            value={isPublicDomainWork}
                                            className="flex-shrink-0"
                                            onChange={() => setIsPublicDomainWork(!isPublicDomainWork)}
                                            text=""
                                            id="checkbox"
                                            checked={isPublicDomainWork}
                                        />
                                        <p className="ml-1 bold">This is a public domain.</p>
                                    </div>
                                    <a href="https://fairuse.stanford.edu/overview/public-domain/welcome/#:~:text=The%20term%20%E2%80%9Cpublic%20domain%E2%80%9D%20refers,one%20can%20ever%20own%20it." target="_blank" rel="noopener noreferrer" className="text-red underline ml-1">How does a public domain work?</a>
                                </div>
                            )
                            }

                            {error && error.publisherRights && <p className="text-red">{error.publisherRights}</p>}

                            {isPublicDomainWork && (
                                <div className="p-5 rounded-xl bg-gray-200 mt-2 mb-5">

                                    <div className="h-2" />
                                    {/* <p>Please confirm the following</p> */}
                                    <div className="h-2" />

                                    <div className="mb-4 text-left flex items-center">
                                        <Checkbox
                                            color="red"
                                            value={publicationType === 'reproduction'}
                                            className="flex-shrink-0"
                                            onChange={() => setPublicationType('reproduction')}
                                            text=""
                                            id="checkbox-is-reproduction"
                                            checked={publicationType === 'reproduction'}
                                        />
                                        <p className="ml-1 bold">This work is a reproduction.</p>
                                    </div>

                                    <div className="mb-4 text-left flex items-center">
                                        <Checkbox
                                            color="red"
                                            value={publicationType === 'compilation'}
                                            className="flex-shrink-0"
                                            onChange={() => setPublicationType('compilation')}
                                            text=""
                                            id="checkbox-is-compilation"
                                            checked={publicationType === 'compilation'}
                                        />
                                        <p className="ml-1 bold">This work is a compilation.</p>
                                    </div>
                                    {error && error.publicationType && <p className="text-red">{error.publicationType}</p>}
                                </div>

                            )}

                            <div className="h-5" />
                        </>
                    )}
                    {((!conforms && submitted) || validPublishingRights === false) && (
                        <>
                            <div className="w-full p-5 rounded-xl bg-gray-200 mt-2 mb-5 text-red">
                                <div className="w-full flex items-center">
                                    {/* <div className="rounded-full bg-red text-white flex-shrink-0 flex-grow-0 flex items-center justify-center" style={{width: 50, height: 50}}>
                                    <Icon name="warning" size="3xl" className="m-0 p-0" />
                                </div> */}
                                    <Icon name="warning" size="3xl" className="m-0 p-0" />
                                    <h3 className="mt-2 text-red" style={{ marginLeft: '1rem' }}>It appears you do not own the necessary publishing rights to proceed with this title. Please refer to the following sites: </h3>
                                </div>
                                <div className="h-5" />
                                <a href="https://www.copyrightlaws.com/copyright-tips-legally-using-google-images/" target="_blank" rel="noopener noreferrer" className="text-red underline ml-1">https://www.copyrightlaws.com/copyright-tips-legally-using-google-images/</a>
                                <br />
                                <div className="h-2" />
                                <a href="https://www.copyright.gov/help/faq/faq-general.html" target="_blank" rel="noopener noreferrer" className="text-red underline ml-1">https://www.copyright.gov/help/faq/faq-general.html</a>
                                <div className="h-2" />
                            </div>
                            <Link to="/new-book">
                                <Button
                                    color="red"
                                    className={`hover:bg-red hover:text-white  md:w-1/4`}
                                    buttonType="outline"
                                    size="lg"
                                    ripple="dark"
                                >
                                    Go Back
                                    {/* <Icon name="home" size="sm" /> */}
                                </Button>
                            </Link>
                        </>
                    )}
                </CardBody>
            </Card>
            <div className="h-10" />
            {/* <HelpTextWrapper
                helpText="Accept terms and sign to continue"
                hideHelp={acceptTerms && !!signature}
            > */}
            {<Button
                color="red"
                className={`${validPublishingRights === false ? 'cursor-not-allowed' : 'hover:bg-red hover:text-white hover:pr-2'} w-full md:w-1/4 float-right`}
                buttonType="outline"
                size="lg"
                ripple="dark"
                // disabled={!acceptTerms}
                onClick={
                    onConfirmClick
                }
                disabled={validPublishingRights === false ? true : false}
            >
                Continue
                <Icon name="arrow_forward" size="sm" />
            </Button>}
            {/* </HelpTextWrapper> */}
            <div className="h-16" />
        </div>
    )
}
