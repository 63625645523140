import {useRef} from 'react';

import {podApi} from '../api/pod';
import {useAlert} from './useAlert';

import { useLocalStorage, useEventListener } from 'usehooks-ts';

type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface QueueItem {
    type: RequestType,
    path: string,
    body?: any
};

export const useOffline = () => {
    const [networkQueue, setNetworkQueue] = useLocalStorage<QueueItem[]>(`network-queue`, []);
    const isPosting = useRef<Boolean>(false);
    
    const {newAlert} = useAlert();

    // useEventListener('online', () => postQueue());

    function addToQueue(type: RequestType, path: string, body?: any) {
        //adds to request to network queue with request type, body & path
        const newQueueItem = {
            type,
            body,
            path
        };

        setNetworkQueue([...networkQueue, newQueueItem]);
    }

    function postQueue() {
        //maps through network queue and attempts to post/get each request
        //only executes once there is connection

        if(networkQueue && Array.isArray(networkQueue) && networkQueue.length > 0 && !isPosting.current) {
            const length = networkQueue.length;
            let index = 0;
            networkQueue.map(async ({path, type, body}: QueueItem, i: number) => {
                try {
                    await podApi({
                        method: type,
                        url: path,
                        data: body && body,
                    });

                    index++;
                    newAlert('success', 'Connection restored', `Network connection has been restored, syncing data. ${((index / length) * 100).toFixed()}%`);

                    if(index === length) setNetworkQueue([]);
                } catch(err) {
                    console.log(err);
                }
            });
        }
    }

    function isOnline() {
        return navigator.onLine;
    }

    return {
        addToQueue,
        postQueue,
        isOnline
    }
}


