import Card from 'components/cards/Card';
import Icon from '@material-tailwind/react/Icon';
import { Link } from 'react-router-dom';

export default function Options() {

    return (
        <div className="flex mb-5">
            {/* <Link to="/profile" className="w-full">
                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="monetization_on" color="white" size="6xl" />
                        <h3 className="text-white">Get quote</h3>
                    </div>
                </Card>
            </Link> */}
            <Link to="/new-book/" className="w-full">
                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="add" color="white" size="6xl" />
                        <h3 className="text-white">New book</h3>
                    </div>
                </Card>
            </Link>
            <Link to="/books" className="w-full ml-5">
                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="menu_book" color="white" size="6xl" />
                        <h3 className="text-white">Your books</h3>
                    </div>
                </Card>
            </Link>
        </div>
    );
}
