import Card from 'components/cards/Card';
import Icon from '@material-tailwind/react/Icon';
import { Link } from 'react-router-dom';

export default function Options2() {

    return (
        <div className="flex mb-5">
            <Link to="/orders" className="w-full">
                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="library_books" color="white" size="6xl" />
                        <h3 className="text-white">Orders</h3>
                    </div>
                </Card>
            </Link>
            <Link to="/support" className="w-full ml-5">
                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="help" color="white" size="6xl" />
                        <h3 className="text-white">Get help</h3>
                    </div>
                </Card>
            </Link>
            {/* <Link to="/support" className="w-full ml-5">
                <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="description" color="white" size="6xl" />
                        <h3 className="text-white">Documents</h3>
                    </div>
                </Card>
            </Link> */}
        </div>
    );
}
