import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-tailwind/react/Button';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Checkbox from "@material-tailwind/react/Checkbox";
import Icon from '@material-tailwind/react/Icon';
import Input from '@material-tailwind/react/Input';
import Tippy from '@tippyjs/react';
import Dropdown from 'components/inputs/Dropdown';
import PlacesAutocomplete from 'components/inputs/PlacesAutocomplete';

import Modal from 'components/general/Modal';

import HelpText from 'components/general/HelpText';

import DropdownInput from 'components/inputs/DropdownInput';
import { useGlobalState } from 'state-pool';
import helpText from 'static-data/help-text';

import { isValidEmail, isValidJson } from 'utils';

import { is } from 'date-fns/locale';
import { set } from 'lodash';

const HELP_TEXT = {
    "is-local": 'Your bank is within South Africa',
    "is-international": 'Your bank is outside of South Africa',
};

const ACCOUNT_TYPES = [
    {
        label: "Cheque",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Cheque'
    },
    {
        label: "Savings",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Savings'
    },
];

const BANK_OPTIONS = [
    {
        label: "First National Bank",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'First National Bank'
    },
    {
        label: "Capitec Bank Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Capitec Bank Limited'
    },
    {
        label: "Standard Bank of South Africa",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Standard Bank of South Africa'
    },
    {
        label: "Nedbank Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Nedbank Limited'
    },
    {
        label: "Absa Group Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Absa Group Limited'
    },
    {
        label: "African Bank Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'African Bank Limited'
    },
    {
        label: "Discovery Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Discovery Limited'
    },
    {
        label: "Investec Bank Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Investec Bank Limited'
    },
    {
        label: "Bidvest Bank Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Bidvest Bank Limited'
    },
    {
        label: "FirstRand Bank - A subsidiary of First Rand Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'FirstRand Bank - A subsidiary of First Rand Limited'
    },
    {
        label: "Grindrod Bank Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Grindrod Bank Limited'
    },
    // {
    //     label: "Imperial Bank South Africa",
    //     helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
    //     value: 'Imperial Bank South Africa'
    // },
    {
        label: "Sasfin Bank Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Sasfin Bank Limited'
    },
    {
        label: "Ubank Limited",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'Ubank Limited'
    },
    {
        label: "TymeBank",
        helpText: "lorem ipsum dolor sit amet, consectetur adipiscing",
        value: 'TymeBank'
    },
];

const BRANCH_DETAILS = {
    "Absa Group Limited": "632 005",
    "African Bank Limited": "430 000",
    "Bidvest Bank Limited": "462 005",
    "Capitec Bank Limited": "470 010",
    "Discovery Limited": "679 000",
    "First National Bank": "250 655",
    "FirstRand Bank - A subsidiary of First Rand Limited": "250 655",
    "Grindrod Bank Limited": "223 626",
    "Investec Bank Limited": "580 105",
    "Nedbank Limited": "198 765",
    "Sasfin Bank Limited": "683 000",
    "Standard Bank of South Africa": "051 011",
    "Ubank Limited": "431010",
    "TymeBank": "678 910",
};

export default function BankDetails({
    bankName,
    setBankName,
    branchCode,
    setBranchCode,
    accountName,
    setAccountName,
    accountType,
    setAccountType,
    accountNumber,
    setAccountNumber,
    isInternationalAccount,
    setIsInternationalAccount,
    isPaypalAccount,
    setIsPaypalAccount,
    paypal,
    setPaypal,
    bankAddress,
    setBankAddress,
    ibanNumber,
    setIbanNumber,
    swiftCode,
    setSwiftCode,
    completed,
    setCompleted,
    stage,
    pagePathname,

    branchName,
    setBranchName
}) {
    const [error, setError] = useState({});

    const [hoverKey, setHoverKey] = useState(null);
    const [showFormatHelp, setShowFormatHelp] = useState(false);
    const [showRegionHelp, setShowRegionHelp] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [currentUser] = useGlobalState('currentUser');


    const history = useHistory();

    const onContinueClick = () => {

        const isValid = validate();

        if (!isValid) return;

        const next = `/${pagePathname}/accept-terms`;
        history.push(next);

        setCompleted([...completed, stage]);
    }

    const clearError = useCallback((key) => {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError({ ...tempErrors });
        }

        return;
    }, [error]);

    function onFormatOptionHover(key) {

        if (key) {
            setShowFormatHelp(true);

            setHoverKey(key);

            return;
        }

        setShowFormatHelp(false);
        setShowRegionHelp(false);

        setHoverKey(null);
    }

    useEffect(() => {
        if (bankName && typeof bankName === 'string') {
            const associatedBranchCode = BRANCH_DETAILS[bankName];

            if (associatedBranchCode) {
                setBranchCode(associatedBranchCode);
            }
        }
    }, [bankName]);

    useEffect(() => {
        if (currentUser && (typeof currentUser === 'object') && currentUser.misc && (typeof currentUser.misc === 'string') && isValidJson(currentUser.misc)) {
            const parsedMisc = JSON.parse(currentUser.misc);

            const { bankInfo } = parsedMisc;

            if (bankInfo && typeof bankInfo === 'object') {
                if (bankInfo.bank_name) {
                    setBankName(bankInfo.bank_name);
                }
                // BE spelling mistake!
                if (bankInfo.brance_code) {
                    setBranchCode(bankInfo.brance_code);
                }
                if (bankInfo.account_name) {
                    setAccountName(bankInfo.account_name);
                }
                if (bankInfo.account_type) {
                    setAccountType(bankInfo.account_type);
                }
                if (bankInfo.account_number) {
                    setAccountNumber(bankInfo.account_number);
                }
                if (bankInfo.branch_name) {
                    setBranchName(bankInfo.branch_name);
                }
                if (bankInfo.international_swift_code && bankInfo.international_swift_code !== ' ') {
                    setSwiftCode(bankInfo.international_swift_code);
                    setIsInternationalAccount(true);
                }
                if (bankInfo.international_iban_number && bankInfo.international_iban_number !== ' ') {
                    setIbanNumber(bankInfo.international_iban_number);
                    setIsInternationalAccount(true);
                }
                if (bankInfo.international_bank_address && bankInfo.international_bank_address !== ' ') {
                    setBankAddress(bankInfo.international_bank_address);
                    setIsInternationalAccount(true);
                }
                if (bankInfo.paypal) {
                    setPaypal(bankInfo.paypal);
                }
            }
        }
    }, []);

    const validate = () => {
        const nameRegex = /^(?=.{2,})[a-zA-Z]+(([',. \-()a-zA-Z ])?[a-zA-Z]*)*$/;
        //Phone number regex
        const phoneRegex = /^\+?[0-9]{8,15}$/;
        //2-30 characters, numbers, forward slash and hyphen
        const numberRegex = /^[0-9 ]{2,30}$/;
        const nameAndNumberRegex = /^[a-zA-Z0-9 ]{2,30}$/;
        const ibanNumberRegex = /^[a-zA-Z0-9 ]{2,34}$/;
        let tempErrors = {};
        //------------- Local ACCOUNT -----------------//

        if (!isInternationalAccount && !bankName.trim()) {
            tempErrors['bankName'] = `Please enter your banks name`;
        }

        if (!isInternationalAccount && !branchCode.trim()) {
            tempErrors['branchCode'] = `Please enter your banks branch code`;
        }
        if (!isInternationalAccount && branchCode.trim() && !numberRegex.test(branchCode.trim())) {
            tempErrors['branchCode'] = `Please enter a valid branch code within the range of 2-30 numbers`;
        }

        if (!isInternationalAccount && !branchName.trim()) {
            tempErrors['branchName'] = `Please enter your banks branch name`;
        }
        if (!isInternationalAccount && branchName.trim() && !nameRegex.test(branchName.trim())) {
            tempErrors['branchName'] = `Please enter a valid branch name`;
        }

        if (!isInternationalAccount && !accountName.trim()) {
            tempErrors['accountName'] = `Please enter your bank account name`;
        }
        if (!isInternationalAccount && accountName.trim() && !nameRegex.test(accountName.trim())) {
            tempErrors['accountName'] = `Please enter a valid account name`;
        }

        if (!isInternationalAccount && !accountType) {
            tempErrors['accountType'] = `Please enter your bank account type`;
        }

        if (!isInternationalAccount && !accountNumber.trim()) {
            tempErrors['accountNumber'] = `Please enter your account number`;
        }
        if (!isInternationalAccount && accountNumber.trim() && !numberRegex.test(accountNumber.trim())) {
            tempErrors['accountNumber'] = `Please enter a valid account number within the range of 2-30 numbers`;
        }

        //------------- INTERNATIONAL ACCOUNT -----------------//

        if (isInternationalAccount && !bankAddress.trim()) {
            tempErrors['bankAddress'] = `Please enter your bank address`;
        }
        if (isInternationalAccount && bankAddress.trim() && bankAddress.length < 5) {
            tempErrors['bankAddress'] = `Please enter a valid bank address`;
        }

        if (isInternationalAccount && !ibanNumber.trim()) {
            tempErrors['ibanNumber'] = `Please enter your banks IBAN number`;
        }
        if (isInternationalAccount && ibanNumber.trim() && !ibanNumberRegex.test(ibanNumber.trim())) {
            tempErrors['ibanNumber'] = `Please enter a valid IBAN number within the range of 2-34 characters and numbers`;
        }
        //Account Holder Name is optional so only if it is entered do we validate
        if (isInternationalAccount && accountName.trim() && !nameRegex.test(accountName.trim())) {
            tempErrors['accountName'] = `Please enter a valid account name`;
        }
        if (isInternationalAccount && accountNumber.trim() && !numberRegex.test(accountNumber.trim())) {
            tempErrors['accountNumber'] = `Please enter a valid account number within the range of 2-30 numbers`;
        }

        if (isInternationalAccount && !swiftCode.trim()) {
            tempErrors['swiftCode'] = `Please enter your banks swift code`;
        }
        if (isInternationalAccount && swiftCode.trim() && !nameAndNumberRegex.test(swiftCode.trim())) {
            tempErrors['swiftCode'] = `Please enter a valid swift code`;
        }

        if (isPaypalAccount && !paypal.trim()) {
            tempErrors['paypal'] = `Please enter your paypal`;
        }
        if (isInternationalAccount && paypal.trim() && !isValidEmail(paypal.trim())) {
            tempErrors['paypal'] = `Please enter a valid paypal`;
        }

        if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            const firstElementKey = Object.keys(tempErrors)[0];

            const firstEl = document.querySelector(`#${firstElementKey}`);

            if (firstEl) {
                firstEl.scrollIntoView({ behavior: "smooth", block: "start" });
            }

            setError(tempErrors);

            return false;
        }

        return true;
    }

    function _onContinueClick() {
        const isValid = validate();

        if (!isValid) return;

        setBranchCode(branchCode.trim());
        setBranchName(branchName.trim());
        setAccountName(accountName.trim());
        setAccountNumber(accountNumber.trim());
        setIbanNumber(ibanNumber.trim());
        setSwiftCode(swiftCode.trim());
        setPaypal(paypal.trim());

        setShowConfirmModal(true);
    }

    return (
        <div className="w-full text-left">
            <h2 className="ml-1 text-4xl font-bold mb-2">Your Bank Details</h2>
            <p className="text-gray-600 ml-1 text-md leading-normal mb-5">{helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.general}</p>
            <HelpText hideHelp={window.innerWidth < 767} iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.localVsInternationalTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">Is your bank account local or international?</p>
            </HelpText>
            <div className="h-5" />
            <div className="flex flex-row w-full shadow-md h-20 rounded-xl bg-white">
                <div
                    className={`w-full flex align-center justify-center rounded-xl cursor-pointer ${hoverKey === 'is-local' ? 'bg-gray-200' : ''}`}
                    onMouseOver={() => onFormatOptionHover('is-local')}
                    onMouseOut={() => onFormatOptionHover()}
                    onClick={() => setIsInternationalAccount(false)}
                >
                    <div onClick={e => e.stopPropagation()} className="h-full align-center flex">
                        <Checkbox
                            color="red"
                            value={!isInternationalAccount}
                            checked={!isInternationalAccount}
                            className="flex-shrink-0"
                            onChange={() => setIsInternationalAccount(false)}
                            text="Local"
                            id="checkbox-is-local"
                        />
                    </div>
                </div>
                <div
                    className={`w-full flex align-center justify-center rounded-xl cursor-pointer ${hoverKey === 'is-international' ? 'bg-gray-200' : ''}`}
                    onMouseOver={() => onFormatOptionHover('is-international')}
                    onMouseOut={() => onFormatOptionHover()}
                    onClick={() => setIsInternationalAccount(true)}
                >
                    <div onClick={e => e.stopPropagation()} className="h-full align-center flex">
                        <Checkbox
                            color="red"
                            value={isInternationalAccount}
                            checked={isInternationalAccount}
                            className="flex-shrink-0"
                            onChange={() => setIsInternationalAccount(true)}
                            text="International"
                            id="checkbox-is-international"
                        />
                    </div>
                </div>
            </div>
            <div className="h-0 mt-5">
                {showFormatHelp && <p className="text-gray-500 ml-1 text-md">{HELP_TEXT[hoverKey]}</p>}
            </div>
            {error && error.isInternationalAccount && <p className="text-red text-left my-4">{error.isInternationalAccount}</p>}
            <div className="h-5" />

            {!isInternationalAccount ? (
                <>
                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.bankNameTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="bankName"><span className="italic bold text-red mr-2">*</span>Bank Name</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    {/* <Tippy className="bg-white"
                        content="Some helpful information here"
                        disabled={false}
                        animation="scale"
                        placement="bottom"
                    >
                        <span> */}
                    <Card className={`max-w-3xl text-left ${currentUser && currentUser.bankInfo && currentUser.bankInfo.bank_name ? "bg-gray-400" : "bg-white"}`} >
                        <CardBody>
                            <div className="mb-4 text-left" onClick={() => clearError('bankName')}>
                                <DropdownInput color="red" className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                                    error={error && error.bankName}
                                    value={bankName}
                                    setValue={setBankName}
                                    placeholder="Your bank name"
                                    options={currentUser && currentUser.bankInfo && currentUser.bankInfo.bank_name ? null : BANK_OPTIONS}
                                    onlyValue={true}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.bank_name ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    {/* </span>
                    </Tippy> */}
                    <div className="h-5" />

                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.branchCodeTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="branchCode"><span className="italic bold text-red mr-2">*</span>Branch Code</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left">
                                <Input
                                    outline={false}
                                    value={branchCode}
                                    onChange={e => setBranchCode(e.target.value)}
                                    color="red"
                                    placeholder="E.g. 123456"
                                    size="large"
                                    type="text"
                                    onFocus={() => clearError('branchCode')}
                                    error={error && error.branchCode}
                                    // onHover={() => setHoverKey('branchCode')}
                                    disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.brance_code ? true : false}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.brance_code ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />

                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.branchNameTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="branchName"><span className="italic bold text-red mr-2">*</span>Branch Name</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left">
                                <Input
                                    outline={false}
                                    value={branchName}
                                    onChange={e => setBranchName(e.target.value)}
                                    color="red"
                                    placeholder="E.g. ABSA, Sandton"
                                    size="large"
                                    type="text"
                                    onFocus={() => clearError('branchName')}
                                    error={error && error.branchName}
                                    disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.branch_name ? true : false}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.branch_name ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />

                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.accountNameTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="accountName"><span className="italic bold text-red mr-2">*</span>Account Holder Name</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left">
                                <Input
                                    outline={false}
                                    value={accountName}
                                    onChange={e => setAccountName(e.target.value)}
                                    color="red"
                                    placeholder="E.g. John Smith"
                                    size="large"
                                    type="text"
                                    onFocus={() => clearError('accountName')}
                                    error={error && error.accountName}
                                    disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.account_name ? true : false}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.account_name ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />

                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.accountTypeTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="accountType"><span className="italic bold text-red mr-2">*</span>Account Type</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left" onClick={() => clearError('accountType')}>
                                {/* <Input
                                outline={false}
                                value={accountType}
                                onChange={e => setAccountType(e.target.value)}
                                color="red"
                                placeholder="E.g. Cheque"
                                size="large"
                                type="text"
                                onFocus={() => clearError('accountType')}
                                error={error && error.accountType}
                            /> */}
                                <DropdownInput
                                    color="red"
                                    className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                                    error={error && error.accountType}
                                    value={accountType}
                                    setValue={setAccountType}
                                    placeholder="Your account type"
                                    options={currentUser && currentUser.bankInfo && currentUser.bankInfo.account_type ? null : ACCOUNT_TYPES}
                                    onlyValue={true}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.account_type ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />

                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.accountNumberTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="accountNumber"><span className="italic bold text-red mr-2">*</span>Account Number</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left">
                                <Input
                                    outline={false}
                                    value={accountNumber}
                                    onChange={e => setAccountNumber(e.target.value)}
                                    color="red"
                                    placeholder="E.g. 1234567890"
                                    size="large"
                                    type="text"
                                    onFocus={() => clearError('accountNumber')}
                                    error={error && error.accountNumber}
                                    disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.account_number ? true : false}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.account_number ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />
                </>
            ) : (
                <>
                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.bankAddressTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id='bankAddress'><span className="italic bold text-red mr-2">*</span>Bank Address</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left">
                                <PlacesAutocomplete
                                    value={bankAddress}
                                    setValue={setBankAddress}
                                    placeholder="Start typing and select the address"
                                    error={error && error.bankAddress}
                                    id="pacg3"
                                    disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.international_bank_address ? true : false}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.international_bank_address ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />

                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.ibanTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id='ibanNumber'><span className="italic bold text-red mr-2">*</span>IBAN Number</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left">
                                <Input
                                    outline={false}
                                    value={ibanNumber}
                                    onChange={e => setIbanNumber(e.target.value)}
                                    color="red"
                                    placeholder="E.g. AL35202111090000000001234567"
                                    size="large"
                                    type="text"
                                    onFocus={() => clearError('ibanNumber')}
                                    error={error && error.ibanNumber}
                                    disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.international_iban_number ? true : false}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.international_iban_number ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />
                    <HelpText
                        iconSize="xl"
                        helpText={
                            helpText &&
                            helpText.newBook &&
                            helpText.newBook.bankDetails &&
                            helpText.newBook.bankDetails.accountNameTooltip
                        }
                    >
                        <p
                            className="text-gray-600 ml-1 text-2xl leading-normal mb-2"
                            id="accountName"
                        >
                            Account Holder Name
                        </p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left">
                                <Input
                                    outline={false}
                                    value={accountName}
                                    onChange={(e) => setAccountName(e.target.value)}
                                    color="red"
                                    placeholder="E.g. John Smith"
                                    size="large"
                                    type="text"
                                    onFocus={() => clearError('accountName')}
                                    error={error && error.accountName}
                                    disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.account_name ? true : false}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.account_name ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />

                    <HelpText
                        iconSize="xl"
                        helpText={
                            helpText &&
                            helpText.newBook &&
                            helpText.newBook.bankDetails &&
                            helpText.newBook.bankDetails.accountTypeTooltip
                        }
                    >
                        <p
                            className="text-gray-600 ml-1 text-2xl leading-normal mb-2"
                            id="accountType"
                        >
                            Account Type
                        </p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div
                                className="mb-4 text-left"
                                onClick={() => clearError('accountType')}
                            >
                                {/* <Input
                                    outline={false}
                                    value={accountType}
                                    onChange={e => setAccountType(e.target.value)}
                                    color="red"
                                    placeholder="E.g. Cheque"
                                    size="large"
                                    type="text"
                                    onFocus={() => clearError('accountType')}
                                    error={error && error.accountType}
                                /> */}
                                <DropdownInput
                                    color="red"
                                    className="w-full shadow-md h-20 justify-start bg-white text-black font-normal dropdown"
                                    error={error && error.accountType}
                                    value={accountType}
                                    setValue={setAccountType}
                                    placeholder="Your account type"
                                    options={currentUser && currentUser.bankInfo && currentUser.bankInfo.account_type ? null : ACCOUNT_TYPES}
                                    onlyValue={true}

                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.account_type ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />

                    <HelpText
                        iconSize="xl"
                        helpText={
                            helpText &&
                            helpText.newBook &&
                            helpText.newBook.bankDetails &&
                            helpText.newBook.bankDetails.accountNumberTooltip
                        }
                    >
                        <p
                            className="text-gray-600 ml-1 text-2xl leading-normal mb-2"
                            id="accountNumber"
                        >
                            Account Number
                        </p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left">
                                <Input
                                    outline={false}
                                    value={accountNumber}
                                    onChange={(e) => setAccountNumber(e.target.value)}
                                    color="red"
                                    placeholder="E.g. 1234567890"
                                    size="large"
                                    type="text"
                                    onFocus={() => clearError('accountNumber')}
                                    error={error && error.accountNumber}
                                    disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.account_number ? true : false}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.account_number ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />

                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.swiftCodeTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="swiftCode"><span className="italic bold text-red mr-2">*</span>Swift Code</p>
                        <p className="italic text-xs text-red ml-1 pt-1">required</p>
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left">
                                <Input
                                    outline={false}
                                    value={swiftCode}
                                    onChange={e => setSwiftCode(e.target.value)}
                                    color="red"
                                    placeholder="E.g. SBZAZAJJ"
                                    size="large"
                                    type="text"
                                    onFocus={() => clearError('swiftCode')}
                                    error={error && error.swiftCode}
                                    disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.international_swift_code ? true : false}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.international_swift_code ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />
                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.bankDetails && helpText.newBook.bankDetails.paypalTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">PayPal Address <span className="text-base">(The email address associated with your PayPal account)</span></p>
                        {/* <p className="italic text-xs text-red ml-1 pt-1">required</p> */}
                    </HelpText>
                    <div className="h-5" />
                    <Card className={`max-w-3xl text-left bg-white`}>
                        <CardBody>
                            <div className="mb-4 text-left">
                                <Input
                                    outline={false}
                                    value={paypal}
                                    onChange={e => setPaypal(e.target.value)}
                                    color="red"
                                    placeholder="E.g. someone@domain.com"
                                    size="large"
                                    type="text"
                                    onFocus={() => clearError('paypal')}
                                    error={error && error.paypal}
                                    disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.paypal ? true : false}
                                />
                            </div>
                            {currentUser && currentUser.bankInfo && currentUser.bankInfo.paypal ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                            <div className="h-2" />
                        </CardBody>
                    </Card>
                    <div className="h-5" />
                </>
            )
            }

            <div className="h-16" />
            <Button
                color="gray"
                className="w-full float-left mb-3 md:mb-auto md:w-1/4 hover:bg-transparent hover:opacity-20"
                buttonType="outline"
                size="lg"
                ripple="dark"
            >
                <Icon name="arrow_back" size="sm" />
                Back
            </Button>
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/4 hover:pr-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={_onContinueClick}
            >
                {completed.includes(stage) ? "Next" : "Continue"}
                <Icon name="arrow_forward" size="sm" />
            </Button>
            <div className="h-16" />
            <Modal
                showModal={showConfirmModal}
                setShowModal={setShowConfirmModal}
                size="lg"

            >
                <div className="p-5">
                    <h3 className="font-light text-2xl mb-2">
                        Please make sure the following details you entered are correct
                    </h3>
                    <div className="p-1 top-0 right-0 absolute cursor-pointer" onClick={() => setShowConfirmModal(false)}>
                        <Icon name="close" size="md" />
                    </div>
                    <p className="text-gray-500 ml-1 mb-5 text-md">We will use the exact details entered when making royalty pay-outs. We will not be held responsible if the details entered on this page are incorrect.</p>
                    {!isInternationalAccount ? <>
                        <p className="text-bold text-lg text-black">Account Number</p>
                        <p>{accountNumber}</p>
                        <div className="h-5" />
                        <p className="text-bold text-lg text-black">Bank Name</p>
                        <p>{bankName}</p>
                        <div className="h-5" />
                        <p className="text-bold text-lg text-black">Branch Code</p>
                        <p>{branchCode}</p>
                        <div className="h-5" />
                        <p className="text-bold text-lg text-black">Branch Name</p>
                        <p>{branchName}</p>
                        <div className="h-5" />
                        <p className="text-bold text-lg text-black">Account Holder Name</p>
                        <p>{accountName}</p>
                        <div className="h-5" />
                        <p className="text-bold text-lg text-black">Account Type</p>
                        <p>{accountType}</p>
                        <div className="h-5" />
                    </> : <>
                        {ibanNumber && <p className="text-bold text-lg text-black">IBAN Number</p>}
                        {ibanNumber && <p>{ibanNumber}</p>}
                        {ibanNumber && <div className="h-5" />}

                        {accountName && <>
                            <p className="text-bold text-lg text-black">Account Holder Name</p>
                            <p>{accountName}</p>
                            <div className="h-5" />
                        </>}

                        {accountNumber && <>
                            <p className="text-bold text-lg text-black">Account Number</p>
                            <p>{accountNumber}</p>
                            <div className="h-5" />
                        </>}


                        {accountType && <>
                            <p className="text-bold text-lg text-black">Account Type</p>
                            <p>{accountType}</p>
                            <div className="h-5" />
                        </>}

                        {swiftCode && <p className="text-bold text-lg text-black">Swift Code</p>}
                        {swiftCode && <p>{swiftCode}</p>}
                        {swiftCode && <div className="h-5" />}
                        <p className="text-bold text-lg text-black">Bank Address</p>
                        <p>{bankAddress}</p>
                        <div className="h-5" />
                        <p className="text-bold text-lg text-black">Account Type</p>
                        <p>{accountType}</p>
                        <div className="h-5" />
                    </>}
                    <div className="h-5" />
                    <Button
                        color="red"
                        className="w-full"
                        buttonType="filled"
                        size="lg"
                        ripple="dark"
                        onClick={onContinueClick}
                    >
                        Proceed
                    </Button>
                </div>
            </Modal>
        </div >
    )
}
