import { useEffect } from 'react';

import { setPageTitle, capitalizeSentence } from 'utils';
//New upload process
import UploadGeneral from 'components/books/print/UploadGeneral';

import RawOrPdf from 'components/books/print/RawOrPdf';
import ConfirmBleedAndCover from 'components/books/print/ConfirmBleedAndCover';
import CoverBookThumbnail from 'components/books/print/CoverBookThumbnail';
import Metadata from 'components/books/print/Metadata';
import UploadAudio from 'components/books/audio/UploadAudio';
import Applicability from 'components/books/Applicability';
import Copyright from 'components/books/PublishingRights';
import ConfirmOptions from 'components/books/print/ConfirmOptions';
import AcceptTermsSign from 'components/books/print/AcceptTermsSign';
import EmailContract from 'components/books/print/EmailContract';
import SelectPaymentMethod from 'components/books/print/SelectPaymentMethod';
import Fin from 'components/books/print/Fin';
import Calculator from 'components/books/print/Calculator';
import TitleRegionFormat from 'components/books/print/TitleRegionFormat';
import AudioBook from 'components/books/audio/AudioBook';
import Ebook from 'components/books/ebook/Ebook';
import Publisher from 'components/books/Publisher';
import BankDetails from 'components/books/BankDetails';
//import NewUploadWizard from 'components/books/print/NewUploadWizard';

export default function BookNewStages({ componentState }) {
    const {
        resources,
        filterGenres,
        currentUser,
        bookFormat,
        stage,
        pagePathname,
        submit,
        setBookFormat,
        title,
        setTitle,
        numPages,
        setNumPages,
        size,
        setSize,
        paperSide,
        setPaperSide,
        completed,
        setCompleted,
        acceptTerms,
        setAcceptTerms,
        signature,
        setSignature,
        rawOrPdf,
        setRawOrPdf,
        acceptBleed,
        setAcceptBleed,
        acceptCover,
        setAcceptCover,
        cover,
        setCover,
        thumbnail,
        setThumbnail,
        bookDocument,
        setBookDocument,
        paymentMethod,
        setPaymentMethod,
        localInternational,
        setLocalInternational,
        localPaperType,
        setLocalPaperType,
        localCoverPaperType,
        setLocalCoverPaperType,
        internationalPaperType,
        setInternationalPaperType,
        internationalCoverPaperType,
        setInternationalCoverPaperType,
        bookPrice,
        setBookPrice,
        royalties,
        setRoyalties,
        isRoyaltiesLoading,
        setIsRoyaltiesLoading,
        getUserDetails,
        costPerBook,
        color,
        setColor,
        pageFinish,
        setPageFinish,
        localBookId,
        setLocalBookId,
        internationalBookId,
        setInternationalBookId,
        isLocal,
        setIsLocal,
        isInternational,
        setIsInternational,
        voiceGender,
        setVoiceGender,
        voiceAccent,
        setVoiceAccent,
        coverNeeded,
        setCoverNeeded,
        thumbnailNeeded,
        setThumbnailNeeded,
        formattingNeeded,
        setFormattingNeeded,
        bleedingNeeded,
        setBleedingNeeded,
        intCoverOption,
        setIntCoverOption,
        intCoverType,
        setIntCoverType,
        isbnNumber,
        setIsbnNumber,
        isbnNumberNeeded,
        setIsbnNumberNeeded,
        spineOption,
        setSpineOption,
        subtitle,
        setSubtitle,
        author,
        setAuthor,
        contributors,
        setContributors,
        publicationDate,
        setPublicationDate,
        onSaleDate,
        setOnSaleDate,
        language,
        setLanguage,
        genre,
        setGenre,
        keywords,
        setKeywords,
        bisacCode,
        setBisacCode,
        shortDescription,
        setShortDescription,
        longDescription,
        setLongDescription,
        mainDescription,
        setMainDescription,
        copyrightHolder,
        setCopyrightHolder,
        copyrightDate,
        setCopyrightDate,
        recommendedAudience,
        setRecommendedAudience,
        indicatedAudienceWarning,
        setInicatedAudienceWarning,
        recordingRequired,
        setRecordingRequired,
        numChapters,
        setNumChapters,
        audioFiles,
        setAudioFiles,
        publisherName,
        setPublisherName,
        publisherRegId,
        setPublisherRegId,
        publisherPhysicalAddress,
        setPublisherPhysicalAddress,
        publisherPostalAddress,
        setPublisherPostalAddress,
        publisherTelephone,
        setPublisherTelephone,
        publisherEmail,
        setPublisherEmail,
        publisherContactPerson,
        setPublisherContactPerson,
        signedPlace,
        setSignedPlace,
        initials,
        setInitials,
        bankName,
        setBankName,
        branchCode,
        setBranchCode,
        accountType,
        setAccountType,
        accountName,
        setAccountName,
        accountNumber,
        setAccountNumber,
        bankAddress,
        setBankAddress,
        ibanNumber,
        setIbanNumber,
        swiftCode,
        setSwiftCode,
        paypal,
        setPaypal,
        isInternationalAccount,
        setIsInternationalAccount,
        isPaypalAccount,
        setIsPaypalAccount,
        bookHeight,
        setBookHeight,
        bookWidth,
        setBookWidth,

        canSupportCoverTypes,
        canSupportPaperType,

        canSupportIntCoverTypes,

        orientation,
        setOrientation,

        coverFinish,
        setCoverFinish,

        internationalBookPrice,
        setInternationalBookPrice,
        heavyGraphics,
        setHeavyGraphics,
        isSelfPublisher,
        setIsSelfPublisher,
        voiceArtistName,
        setVoiceArtistName,
        spotUvNeeded,
        setSpotUvNeeded,

        duplexNeeded,
        setDuplexNeeded,
        softTouchMattLaminationNeeded,
        setSoftTouchMattLaminationNeeded,
        spiralBoundNeeded,
        setSpiralBoundNeeded,

        revisionsNeeded,
        setRevisionsNeeded,

        duplexNeededInt,
        setDuplexNeededInt,
        perforationNeededInt,
        setPerforationNeededInt,
        customRecordingRequired,
        setCustomRecordingRequired,

        binding,
        setBinding,

        conversionNeeded,
        setConversionNeeded,

        branchName,
        setBranchName,

        numPagesLimitExceeded,
        setNumPagesLimitExceeded,
        //For Calculator, to display the paper options this is for after they have inputted the number of pages
        paperTypesFiltered,
        setPaperTypesFiltered,
        internationalPaperTypesFiltered,
        setInternationalPaperTypesFiltered,
    } = componentState;

    useEffect(() => {
        setPageTitle('New Book', capitalizeSentence(stage.replace(/-/g, ' ')));
    }, [stage]);

    const STAGES = {
        "publishing-rights": (
            <Copyright
                pagePathname={pagePathname}
            />
        ),
        // "upload-book-general": (
        //     <UploadGeneral
        //         options={resources}
        //         setCompleted={setCompleted}
        //         completed={completed}
        //         title={title}
        //         setTitle={setTitle}
        //         stage={stage}
        //         genre={genre}
        //         bisacCode={bisacCode}
        //     />
        // ), "new-page": (
        //     <NewUploadWizard
        //         options={resources}
        //         setCompleted={setCompleted}
        //         completed={completed}
        //         title={title}
        //         setTitle={setTitle}
        //         stage={stage}
        //         genre={genre}
        //         bisacCode={bisacCode}
        //     />
        // ),
        "general-info": (
            <TitleRegionFormat
                options={resources}
                filterGenres={filterGenres}
                setCompleted={setCompleted}
                completed={completed}
                title={title}
                setTitle={setTitle}
                localInternational={localInternational}
                setLocalInternational={setLocalInternational}
                setBookFormat={setBookFormat}
                bookFormat={bookFormat}
                pagePathname={pagePathname}
                stage={stage}
                isLocal={isLocal}
                setIsLocal={setIsLocal}
                isInternational={isInternational}
                setIsInternational={setIsInternational}
                isbnNumber={isbnNumber}
                setIsbnNumber={setIsbnNumber}
                isbnNumberNeeded={isbnNumberNeeded}
                setIsbnNumberNeeded={setIsbnNumberNeeded}
                genre={genre}
                setGenre={setGenre}
                heavyGraphics={heavyGraphics}
                setHeavyGraphics={setHeavyGraphics}
                bisacCode={bisacCode}
                setBisacCode={setBisacCode}

                duplexNeeded={duplexNeeded}
                setDuplexNeeded={setDuplexNeeded}
                softTouchMattLaminationNeeded={softTouchMattLaminationNeeded}
                setSoftTouchMattLaminationNeeded={setSoftTouchMattLaminationNeeded}
                spiralBoundNeeded={spiralBoundNeeded}
                setSpiralBoundNeeded={setSpiralBoundNeeded}
                spotUvNeeded={spotUvNeeded}
                setSpotUvNeeded={setSpotUvNeeded}

                duplexNeededInt={duplexNeededInt}
                setDuplexNeededInt={setDuplexNeededInt}
                perforationNeededInt={perforationNeededInt}
                setPerforationNeededInt={setPerforationNeededInt}

                author={author}
                setAuthor={setAuthor}
                contributors={contributors}
                setContributors={setContributors}
                // For Calculator
                setBookPrice={setBookPrice}
                bookPrice={bookPrice}
                numPages={numPages}
                setNumPages={setNumPages}
                paperTypesFiltered={paperTypesFiltered}
                setPaperTypesFiltered={setPaperTypesFiltered}
                internationalPaperTypesFiltered={internationalPaperTypesFiltered}
                setInternationalPaperTypesFiltered={setInternationalPaperTypesFiltered}
                size={size}
                setSize={setSize}
                paperSide={paperSide}
                setPaperSide={setPaperSide}
                localPaperType={localPaperType}
                setLocalPaperType={setLocalPaperType}
                localCoverPaperType={localCoverPaperType}
                setLocalCoverPaperType={setLocalCoverPaperType}
                internationalPaperType={internationalPaperType}
                setInternationalPaperType={setInternationalPaperType}
                internationalCoverPaperType={internationalCoverPaperType}
                setInternationalCoverPaperType={setInternationalCoverPaperType}
                royalties={royalties}
                setRoyalties={setRoyalties}
                isRoyaltiesLoading={isRoyaltiesLoading}
                setIsRoyaltiesLoading={setIsRoyaltiesLoading}
                color={color}
                setColor={setColor}
                pageFinish={pageFinish}
                setPageFinish={setPageFinish}
                intCoverOption={intCoverOption}
                setIntCoverOption={setIntCoverOption}
                intCoverType={intCoverType}
                setIntCoverType={setIntCoverType}
                spineOption={spineOption}
                setSpineOption={setSpineOption}
                bookWidth={bookWidth}
                setBookWidth={setBookWidth}
                bookHeight={bookHeight}
                setBookHeight={setBookHeight}
                canSupportCoverTypes={canSupportCoverTypes}
                canSupportPaperType={canSupportPaperType}
                canSupportIntCoverTypes={canSupportIntCoverTypes}
                orientation={orientation}
                setOrientation={setOrientation}
                coverFinish={coverFinish}
                setCoverFinish={setCoverFinish}
                internationalBookPrice={internationalBookPrice}
                setInternationalBookPrice={setInternationalBookPrice}
                binding={binding}
                setBinding={setBinding}
                numPagesLimitExceeded={numPagesLimitExceeded}
                //Page three
                submit={submit}
                book={{
                    title,
                    bookFormat,
                    numPages,
                    size,
                    genre,
                    paperSide,
                    localPaperType,
                    localCoverPaperType,
                    costPerBook,
                    voiceGender,
                    voiceAccent,
                    internationalCoverPaperType,
                    internationalPaperType,
                    localCoverPaperType,
                    localPaperType,
                    intCoverOption,
                    intCoverType,
                    isbnNumberNeeded,
                    isbnNumber,
                    recordingRequired,
                    bookHeight,
                    bookWidth,
                    voiceArtistName,
                    genre,
                    setGenre,
                    heavyGraphics,
                    setHeavyGraphics,
                    bisacCode,
                    setBisacCode,
                    spotUvNeeded,
                    setSpotUvNeeded,
                    duplexNeeded,
                    setDuplexNeeded,
                    softTouchMattLaminationNeeded,
                    setSoftTouchMattLaminationNeeded,
                    spiralBoundNeeded,
                    setSpiralBoundNeeded,
                    duplexNeededInt,
                    setDuplexNeededInt,
                    perforationNeededInt,
                    setPerforationNeededInt,
                    bisacCode
                }}

            />
        ),
        // "calculator": (
        //     <Calculator
        //         options={resources}
        //         setCompleted={setCompleted}
        //         completed={completed}
        //         title={title}
        //         setTitle={setTitle}
        //         localInternational={localInternational}
        //         setLocalInternational={setLocalInternational}
        //         setBookFormat={setBookFormat}
        //         bookFormat={bookFormat}
        //         bookPrice={bookPrice}
        //         setBookPrice={setBookPrice}
        //         numPages={numPages}
        //         setNumPages={setNumPages}
        //         size={size}
        //         setSize={setSize}
        //         paperSide={paperSide}
        //         setPaperSide={setPaperSide}
        //         localPaperType={localPaperType}
        //         setLocalPaperType={setLocalPaperType}
        //         localCoverPaperType={localCoverPaperType}
        //         setLocalCoverPaperType={setLocalCoverPaperType}
        //         internationalPaperType={internationalPaperType}
        //         setInternationalPaperType={setInternationalPaperType}
        //         internationalCoverPaperType={internationalCoverPaperType}
        //         setInternationalCoverPaperType={setInternationalCoverPaperType}
        //         royalties={royalties}
        //         pagePathname={pagePathname}
        //         stage={stage}
        //         color={color}
        //         setColor={setColor}
        //         pageFinish={pageFinish}
        //         setPageFinish={setPageFinish}
        //         isLocal={isLocal}
        //         isInternational={isInternational}
        //         intCoverOption={intCoverOption}
        //         setIntCoverOption={setIntCoverOption}
        //         intCoverType={intCoverType}
        //         setIntCoverType={setIntCoverType}
        //         spineOption={spineOption}
        //         setSpineOption={setSpineOption}
        //         bookWidth={bookWidth}
        //         setBookWidth={setBookWidth}
        //         bookHeight={bookHeight}
        //         setBookHeight={setBookHeight}
        //         canSupportCoverTypes={canSupportCoverTypes}
        //         canSupportPaperType={canSupportPaperType}
        //         canSupportIntCoverTypes={canSupportIntCoverTypes}

        //         orientation={orientation}
        //         setOrientation={setOrientation}

        //         coverFinish={coverFinish}
        //         setCoverFinish={setCoverFinish}

        //         internationalBookPrice={internationalBookPrice}
        //         setInternationalBookPrice={setInternationalBookPrice}
        //         heavyGraphics={heavyGraphics}
        //         setHeavyGraphics={setHeavyGraphics}

        //         binding={binding}
        //         setBinding={setBinding}

        //         spiralBoundNeeded={spiralBoundNeeded}

        //         numPagesLimitExceeded={numPagesLimitExceeded}
        //     />
        // ),
        // "confirm-details": (
        //     <ConfirmOptions
        //         setCompleted={setCompleted}
        //         completed={completed}
        //         isLocal={isLocal}
        //         isInternational={isInternational}
        //         book={{
        //             title,
        //             bookFormat,
        //             numPages,
        //             size,
        //             genre,
        //             paperSide,
        //             localPaperType,
        //             localCoverPaperType,
        //             costPerBook,
        //             voiceGender,
        //             voiceAccent,
        //             internationalCoverPaperType,
        //             internationalPaperType,
        //             localCoverPaperType,
        //             localPaperType,
        //             intCoverOption,
        //             intCoverType,
        //             isbnNumberNeeded,
        //             isbnNumber,
        //             recordingRequired,
        //             bookHeight,
        //             bookWidth,
        //             voiceArtistName,
        //             genre,
        //             setGenre,
        //             heavyGraphics,
        //             setHeavyGraphics,
        //             bisacCode,
        //             setBisacCode,
        //             spotUvNeeded,
        //             setSpotUvNeeded,
        //             duplexNeeded,
        //             setDuplexNeeded,
        //             softTouchMattLaminationNeeded,
        //             setSoftTouchMattLaminationNeeded,
        //             spiralBoundNeeded,
        //             setSpiralBoundNeeded,
        //             duplexNeededInt,
        //             setDuplexNeededInt,
        //             perforationNeededInt,
        //             setPerforationNeededInt,
        //             bisacCode
        //         }}
        //         submit={submit}
        //         pagePathname={pagePathname}
        //         stage={stage}
        //         isLocal={isLocal}
        //         isInternational={isInternational}
        //     />
        // ),
        "publisher-info": (
            <Publisher
                publisherName={publisherName}
                setPublisherName={setPublisherName}
                publisherRegId={publisherRegId}
                setPublisherRegId={setPublisherRegId}
                publisherPhysicalAddress={publisherPhysicalAddress}
                setPublisherPhysicalAddress={setPublisherPhysicalAddress}
                publisherTelephone={publisherTelephone}
                setPublisherTelephone={setPublisherTelephone}
                publisherPostalAddress={publisherPostalAddress}
                setPublisherPostalAddress={setPublisherPostalAddress}
                publisherEmail={publisherEmail}
                setPublisherEmail={setPublisherEmail}
                publisherContactPerson={publisherContactPerson}
                setPublisherContactPerson={setPublisherContactPerson}
                setCompleted={setCompleted}
                completed={completed}
                pagePathname={pagePathname}
                isSelfPublisher={isSelfPublisher}
                setIsSelfPublisher={setIsSelfPublisher}
                setLocalBookId={setLocalBookId}
                setInternationalBookId={setInternationalBookId}
            />
        ),
        "bank-details": (
            <BankDetails
                bankName={bankName}
                setBankName={setBankName}
                branchCode={branchCode}
                setBranchCode={setBranchCode}
                accountName={accountName}
                setAccountName={setAccountName}
                accountType={accountType}
                setAccountType={setAccountType}
                accountNumber={accountNumber}
                setAccountNumber={setAccountNumber}
                bankAddress={bankAddress}
                setBankAddress={setBankAddress}
                ibanNumber={ibanNumber}
                setIbanNumber={setIbanNumber}
                swiftCode={swiftCode}
                setSwiftCode={setSwiftCode}
                stage={stage}
                setCompleted={setCompleted}
                completed={completed}
                isInternationalAccount={isInternationalAccount}
                setIsInternationalAccount={setIsInternationalAccount}
                isPaypalAccount={isPaypalAccount}
                setIsPaypalAccount={setIsPaypalAccount}
                paypal={paypal}
                setPaypal={setPaypal}
                pagePathname={pagePathname}

                branchName={branchName}
                setBranchName={setBranchName}
            />
        ),
        "accept-terms": (
            <AcceptTermsSign
                initials={initials}
                setInitials={setInitials}
                signedPlace={signedPlace}
                setSignedPlace={setSignedPlace}
                localBookId={localBookId}
                internationalBookId={internationalBookId}
                setCompleted={setCompleted}
                completed={completed}
                acceptTerms={acceptTerms}
                setAcceptTerms={setAcceptTerms}
                signature={signature}
                setSignature={setSignature}
                stage={stage}
                pagePathname={pagePathname}
                audioFiles={audioFiles}
                formDetails={{
                    publisherName,
                    publisherRegId,
                    publisherPhysicalAddress,
                    publisherPostalAddress,
                    publisherTelephone,
                    publisherTelephone,
                    publisherEmail,
                    publisherContactPerson,
                    isSelfPublisher,
                    printName: currentUser && currentUser.firstname && currentUser.lastname && `${currentUser.firstname} ${currentUser.lastname}`,
                    signedPlace,
                    bankName,
                    branchCode,
                    accountName,
                    accountType,
                    accountNumber,
                    bankAddress,
                    ibanNumber,
                    swiftCode,
                    paypal,
                    initials,
                    author,
                    contributors,
                    branchName,


                }}
            />
        ),
        "contract": (
            <EmailContract
                setCompleted={setCompleted}
                completed={completed}
                stage={stage}
                pagePathname={pagePathname}
                recordingRequired={recordingRequired}
                bookFormat={bookFormat}
            />
        ),
        "upload-type": (
            <RawOrPdf
                setCompleted={setCompleted}
                completed={completed}
                rawOrPdf={rawOrPdf}
                setRawOrPdf={setRawOrPdf}
                stage={stage}
                pagePathname={pagePathname}
                bookFormat={bookFormat}
                setLocalBookId={setLocalBookId}
                setInternationalBookId={setInternationalBookId}
            />
        ),
        "metadata": (
            <Metadata
                subtitle={subtitle}
                setSubtitle={setSubtitle}
                author={author}
                setAuthor={setAuthor}
                contributors={contributors}
                setContributors={setContributors}
                publicationDate={publicationDate}
                setPublicationDate={setPublicationDate}
                onSaleDate={onSaleDate}
                setOnSaleDate={setOnSaleDate}
                language={language}
                setLanguage={setLanguage}
                genre={genre}
                setGenre={setGenre}
                keywords={keywords}
                setKeywords={setKeywords}
                bisacCode={bisacCode}
                setBisacCode={setBisacCode}
                shortDescription={shortDescription}
                setShortDescription={setShortDescription}
                longDescription={longDescription}
                setLongDescription={setLongDescription}
                mainDescription={mainDescription}
                setMainDescription={setMainDescription}
                copyrightHolder={copyrightHolder}
                setCopyrightHolder={setCopyrightHolder}
                copyrightDate={copyrightDate}
                setCopyrightDate={setCopyrightDate}
                recommendedAudience={recommendedAudience}
                setRecommendedAudience={setRecommendedAudience}
                indicatedAudienceWarning={indicatedAudienceWarning}
                setInicatedAudienceWarning={setInicatedAudienceWarning}
                setCompleted={setCompleted}
                completed={completed}
                stage={stage}
                pagePathname={pagePathname}

                bookFormat={bookFormat}
            />
        ),
        "confirm-bleed-cover": (
            <ConfirmBleedAndCover
                setCompleted={setCompleted}
                completed={completed}
                acceptBleed={acceptBleed}
                setAcceptBleed={setAcceptBleed}
                acceptCover={acceptCover}
                setAcceptCover={setAcceptCover}
                stage={stage}
                pagePathname={pagePathname}
                rawOrPdf={rawOrPdf}
                coverNeeded={coverNeeded}
                thumbnailNeeded={thumbnailNeeded}
                formattingNeeded={formattingNeeded}
                bleedingNeeded={bleedingNeeded}
                setCoverNeeded={setCoverNeeded}
                setThumbnailNeeded={setThumbnailNeeded}
                setFormattingNeeded={setFormattingNeeded}
                setBleedingNeeded={setBleedingNeeded}
                spotUvNeeded={spotUvNeeded}
                setSpotUvNeeded={setSpotUvNeeded}
                revisionsNeeded={revisionsNeeded}
                setRevisionsNeeded={setRevisionsNeeded}
                isLocal={isLocal}

                duplexNeededInt={duplexNeededInt}
                setDuplexNeededInt={setDuplexNeededInt}
                perforationNeededInt={perforationNeededInt}
                setPerforationNeededInt={setPerforationNeededInt}
                bookFormat={bookFormat}
                heavyGraphics={heavyGraphics}
                setHeavyGraphics={setHeavyGraphics}
                conversionNeeded={conversionNeeded}
                setConversionNeeded={setConversionNeeded}
            />
        ),
        "upload-assets": (
            <CoverBookThumbnail
                localBookId={localBookId}
                internationalBookId={internationalBookId}
                rawOrPdf={rawOrPdf}
                setCompleted={setCompleted}
                completed={completed}
                cover={cover}
                setCover={setCover}
                thumbnail={thumbnail}
                setThumbnail={setThumbnail}
                bookDocument={bookDocument}
                setBookDocument={setBookDocument}
                stage={stage}
                pagePathname={pagePathname}
                enteredNumPages={numPages}
                coverNeeded={coverNeeded}
                thumbnailNeeded={thumbnailNeeded}
                formattingNeeded={formattingNeeded}
                bleedingNeeded={bleedingNeeded}
                subtitle={subtitle}
                author={author}
                contributors={contributors}
                publicationDate={publicationDate}
                onSaleDate={onSaleDate}
                language={language}
                genre={genre}
                keywords={keywords}
                bisacCode={bisacCode}
                shortDescription={shortDescription}
                longDescription={longDescription}
                mainDescription={mainDescription}
                copyrightHolder={copyrightHolder}
                copyrightDate={copyrightDate}
                recommendedAudience={recommendedAudience}
                indicatedAudienceWarning={indicatedAudienceWarning}
                audioFiles={audioFiles}
                bookFormat={bookFormat}
                spotUvNeeded={spotUvNeeded}
                heavyGraphics={heavyGraphics}
                setHeavyGraphics={setHeavyGraphics}
                conversionNeeded={conversionNeeded}
                setConversionNeeded={setConversionNeeded}
            />
        ),
        "accept-terms-additional": (
            <AcceptTermsSign
                initials={initials}
                setInitials={setInitials}
                signedPlace={signedPlace}
                setSignedPlace={setSignedPlace}
                localBookId={localBookId}
                internationalBookId={internationalBookId}
                setCompleted={setCompleted}
                completed={completed}
                acceptTerms={acceptTerms}
                setAcceptTerms={setAcceptTerms}
                signature={signature}
                setSignature={setSignature}
                stage={stage}
                pagePathname={pagePathname}
                second={true}
                formDetails={{
                    publisherName,
                    publisherRegId,
                    publisherPhysicalAddress,
                    publisherPostalAddress,
                    publisherTelephone,
                    publisherTelephone,
                    publisherEmail,
                    publisherContactPerson,
                    printName: currentUser && currentUser.firstname && currentUser.lastname && `${currentUser.firstname} ${currentUser.lastname}`,
                    signedPlace,
                    bankName,
                    branchCode,
                    accountName,
                    accountType,
                    accountNumber,
                    bankAddress,
                    ibanNumber,
                    swiftCode,
                    paypal,
                    initials,

                    contributors,
                    author
                }}
            />
        ),
        "payment-method": (
            <SelectPaymentMethod
                setCompleted={setCompleted}
                completed={completed}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                stage={stage}
                pagePathname={pagePathname}
                costPerBook={costPerBook}
                title={title}
            />
        ),
        "submission-complete": (
            <Fin
                stage={stage}
                pagePathname={pagePathname}
                getUserDetails={getUserDetails}
            />
        ),
        // "e-book": (
        //     <Ebook
        //         numPages={numPages}
        //         setNumPages={setNumPages}
        //         localInternational={localInternational}
        //         bookPrice={bookPrice}
        //         setBookPrice={setBookPrice}
        //         royalties={royalties}
        //         pagePathname={pagePathname}
        //         setCompleted={setCompleted}
        //         completed={completed}
        //         stage={stage}
        //         internationalBookPrice={internationalBookPrice}
        //         setInternationalBookPrice={setInternationalBookPrice}
        //     />
        // ),
        // "audio-book": (
        //     <AudioBook 
        //         numPages={numPages}
        //         setNumPages={setNumPages}
        //         localInternational={localInternational}
        //         bookPrice={bookPrice}
        //         setBookPrice={setBookPrice}
        //         voiceGender={voiceGender}
        //         setVoiceGender={setVoiceGender}
        //         royalties={royalties}
        //         voiceAccent={voiceAccent}
        //         setVoiceAccent={setVoiceAccent}
        //         pagePathname={pagePathname}
        //         setCompleted={setCompleted}
        //         completed={completed}
        //         stage={stage}
        //         recordingRequired={recordingRequired}
        //         setRecordingRequired={setRecordingRequired}
        //         internationalBookPrice={internationalBookPrice}
        //         setInternationalBookPrice={setInternationalBookPrice}
        //         setVoiceArtistName={setVoiceArtistName}
        //         customRecordingRequired={customRecordingRequired}
        //         setCustomRecordingRequired={setCustomRecordingRequired}
        //     />
        // ),
        // "upload-audio": (
        //     <UploadAudio
        //         localBookId={localBookId}
        //         internationalBookId={internationalBookId}
        //         setCompleted={setCompleted}
        //         completed={completed}
        //         numChapters={numChapters}
        //         setNumChapters={setNumChapters}
        //         audioFiles={audioFiles}
        //         setAudioFiles={setAudioFiles}
        //         stage={stage}
        //         pagePathname={pagePathname}
        //     />
        // ),
        "applicability": (
            <Applicability
                stage={stage}
                setCompleted={setCompleted}
                completed={completed}
            />
        ),
    };

    return (
        <>
            {STAGES[stage]}
        </>
    );
}
