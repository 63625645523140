import { useState, useRef, useEffect, useCallback } from 'react';

import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Paragraph from "@material-tailwind/react/Paragraph";

import Modal from 'components/general/Modal';

import HelpText from 'components/general/HelpText';

import { useHistory } from 'react-router-dom';

import { useGlobalState } from 'state-pool';

import { lengthLimiter } from 'utils';


export default function Calculator({
    numPages,
    setNumPages,
    bookPrice,
    setBookPrice,
    royalties,
    completed,
    setCompleted,
    pagePathname,
    stage,
    internationalBookPrice,
    setInternationalBookPrice,
    error,
    setError,
    showAllErrors,
    setShowAllErrors,
    focusedField,
    //Careful one is a function and one is a state
    onFocusField,
}) {
    const [showNegativeRoyaltyError, setShowNegativeRoyaltyError] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const helpRef = useRef();
    const history = useHistory();
    const [prices] = useGlobalState('prices');

    const ebookPricing = prices && typeof prices === 'object' && prices.ebook;

    // console.log(ebookPricing);

    const clearError = useCallback((key) => {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError({ ...tempErrors });
        }

        return;

    }, [error]);

    useEffect(() => {

        // if(paperType) clearError('paperType');

        // if(paperSide) clearError('paperSide');

        // if(coverPaperType) clearError('coverPaperType');

        // if(size) clearError('size');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // paperSide, paperType, coverPaperType, size
    ]);

    return (
        <div className="w-full text-left">
            <h2 className="ml-1 text-4xl font-bold mb-1">eBook Royalty Calculator</h2>
            <p className="text-red cursor-pointer ml-1" onClick={() => setShowMoreInfo(true)}>How are royalties calculated?</p>
            <div className="h-5" />
            <div className="flex">
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2"><span className="italic bold text-red mr-2">*</span>Number of Pages</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </div>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={numPages}
                            onChange={e => {
                                const value = e.target.value;
                                if (!isNaN(value) && Number(value) >= 0) {
                                    lengthLimiter(value, numPages, 9, setNumPages);
                                } else if (value === '') {
                                    lengthLimiter(value, numPages, 9, setNumPages);
                                }
                            }}
                            color="red"
                            placeholder="Number of pages"
                            size="large"
                            type="text"
                            //step="1"
                            onWheel={(e) => e.target.blur()}
                            error={showAllErrors && error && error.numPages || focusedField === 'numPages' && error && error.numPages || null}
                            onFocus={() => onFocusField('numPages')}
                            tabIndex={1}
                        />
                    </div>
                </CardBody>
            </Card>
            <p className="text-gray-500 text-xs mt-2">*Please note that this number must match the page count in your uploaded book document.</p>
            <div className="h-5" />
            <HelpText iconSize="2xl" helpText="This is the selling price of your book, you can change this price and see your royalty fee either increase or decrease in the bottom left corner.">
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2"><span className="italic bold text-red mr-2">*</span>Book Price</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>
                <CardBody>
                    <div className="mb-4 text-left flex">
                        <span className="mt-2 mr-4 font-light text-2xl">USD</span>
                        <Input
                            outline={false}
                            value={internationalBookPrice}
                            onChange={(e) => { if (e.target.value.match(/^\d{0,8}(\.\d{0,2})?$/)) setInternationalBookPrice(e.target.value) }}
                            color="red"
                            placeholder={`Book price`}
                            size="large"
                            type="text"
                            error={showAllErrors && error && error.internationalBookPrice || focusedField === 'internationalBookPrice' && error && error.internationalBookPrice || null}
                            onFocus={() => onFocusField('bookPrice')}
                            onWheel={(e) => e.target.blur()}
                        />
                    </div>
                </CardBody>
            </Card>
            {/* <div className="h-16" />
                <Button
                    color="red"
                    className="hover:bg-red hover:text-white hover:bg-red w-full float-left md:w-1/4 hover:pl-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={() => history.goBack()}
                >
                    <Icon name="arrow_back" size="sm" />
                    Back
                </Button> 
                <Button
                    color="red"
                    className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/4 hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={onContinueClick}
                >
                    {completed.includes(stage) ? "Next" : "Continue"}
                    <Icon name="arrow_forward" size="sm" />
                </Button> */}
            <div className="h-8" />
            <Modal
                showModal={showNegativeRoyaltyError}
                setShowModal={setShowNegativeRoyaltyError}
                size="lg"
            >
                <div className="p-5">
                    <h3 className="font-light text-xl mb-5">
                        Your royalty fees, based on your selected options is a negative value.
                    </h3>
                    <Paragraph>
                        Try editing book price, paper type and cover type until you get a positive royalty fee before moving on.
                    </Paragraph>
                    <div className="h-5" />
                    <Button
                        color="red"
                        className="w-full"
                        buttonType="filled"
                        size="lg"
                        ripple="dark"
                        onClick={() => setShowNegativeRoyaltyError(false)}
                    >
                        Okay
                    </Button>
                </div>
            </Modal>
            <Modal
                showModal={showMoreInfo}
                setShowModal={setShowMoreInfo}
                size="lg"
            >
                <div className="p-5">
                    <p className="text-bold text-lg text-black">Conversion costs</p>
                    <div className="h-2" />
                    <p className="text-bold text-black">Standard conversion</p>
                    <p>
                        Supplied as InDesign files or unlocked pdf’s
                        Reflowable ePub where content is text
                    </p>
                    {/* TODO add below to pricing list */}
                    <p className="text-bold text-black">R {ebookPricing && ebookPricing.conversionFixed}</p>
                    <div className="h-5" />
                    <p>
                        Media Rich ePub including videos and other media
                        Fixed ePub where content includes many diagrams <br />
                        and images<br />
                        Prices subject to review of content
                    </p>
                    <p className="text-bold text-black">TBC</p>
                    <div className="h-5" />
                    <p className="text-bold text-lg text-black">Upload Fee</p>
                    <p>R {ebookPricing && ebookPricing.uploadFee ? ebookPricing.uploadFee : '299'}</p>
                    {/* <div className="h-5" />
                    <p className="text-bold text-lg text-black">Annual Market Access Fee</p>
                    <p>
                        We will cover the first year, thereafter it is R {ebookPricing && ebookPricing.annumfee} per annum <br/>
                        (to billed on the anniversary of the upload)
                    </p> */}
                    <div className="h-5" />
                    <p className="text-bold text-lg text-black">Participating Partners</p>
                    <div className="h-2" />
                    <p className="text-bold text-black">Retail</p>
                    <div className="h-2" />
                    <p>
                        Amazon, Google, Barnes and Noble, De Marque, eBooks.com,
                        Fable, Gardners, Glose, Hummingbird, ITSO, Libreka, Libri.
                        de, <br /> Kobo, Perusall, Publica, Redshelf, Spoonread, Vitalsource,
                        WOOK, Check
                    </p>
                    <div className="h-2" />
                    <p className="text-bold text-black">Agency</p>
                    <p>
                        Apple
                    </p>
                    <div className="h-5" />
                    <p className="text-bold text-lg text-black">Royalties</p>
                    <p>
                        Wholesale discount required is 50%. Royalties are calculated at <br />
                        70% of funds received after wholesale discount.
                    </p>
                    <div className="h-5" />
                    <Button
                        color="red"
                        className="w-full"
                        buttonType="filled"
                        size="lg"
                        ripple="dark"
                        onClick={() => setShowMoreInfo(false)}
                    >
                        Okay
                    </Button>
                </div>
            </Modal>
        </div>
    );
}
