import {
    useCallback,
    // useRef,
    useEffect,
    useState
} from 'react';

import Button from '@material-tailwind/react/Button';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Checkbox from '@material-tailwind/react/Checkbox';
import Icon from '@material-tailwind/react/Icon';
import Input from '@material-tailwind/react/Input';
import Label from "@material-tailwind/react/Label";
import Paragraph from "@material-tailwind/react/Paragraph";
import Modal from 'components/general/Modal';

// import Modal from 'components/general/Modal';
import HelpText from 'components/general/HelpText';

import { useHistory } from 'react-router-dom';

import helpText from 'static-data/help-text';

import PlacesAutocomplete from 'components/inputs/PlacesAutocomplete';

import CountrySelectInput from 'components/inputs/PhoneNumber';

import { set } from 'lodash';
import { useGlobalState } from 'state-pool';
import { isValidEmail } from 'utils';

// import DropdownInput from 'components/inputs/DropdownInput';

export default function Publisher({
    publisherName,
    setPublisherName,
    publisherRegId,
    setPublisherRegId,
    publisherPhysicalAddress,
    setPublisherPhysicalAddress,
    publisherTelephone,
    setPublisherTelephone,
    publisherPostalAddress,
    setPublisherPostalAddress,
    publisherEmail,
    setPublisherEmail,
    publisherContactPerson,
    setPublisherContactPerson,
    completed,
    setCompleted,
    stage,
    pagePathname,
    isSelfPublisher,
    setIsSelfPublisher,
}) {
    const [error, setError] = useState({});

    const isComplete = completed.includes(stage);
    const [showBackModal, setShowBackModal] = useState(false);

    const [currentUser] = useGlobalState('currentUser');

    const history = useHistory();

    const onContinueClick = () => {
        // console.log('Submitted publisher details:', {
        //     publisherName,
        //     publisherRegId,
        //     publisherPhysicalAddress,
        //     publisherTelephone,
        //     publisherPostalAddress,
        //     publisherEmail,
        //     publisherContactPerson,
        //     completed,
        //     isSelfPublisher
        // });
        let tempErrors = {};

        const nameRegex = /^(?=.{2,})[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        //Phone number regex
        const phoneRegex = /^\+?[0-9]{8,15}$/;
        //2-30 characters, numbers, forward slash and hyphen
        const idRegex = /^[0-9 a-zA-Z/-]{2,30}$/;
        const phoneNumberRegex = /^\+[1-9]\d{1,14}$/;


        if (!publisherName.trim()) {
            tempErrors['publisherName'] = `Please enter the publishers name`;
        }

        if (!publisherRegId.trim()) {
            tempErrors['publisherRegId'] = `Please enter the publishers registration ID`;
        }
        if (publisherRegId.trim() && !idRegex.test(publisherRegId.trim())) {
            tempErrors['publisherRegId'] = `Please enter a valid registration ID within the range of 2-30 characters`;
        }

        if (!publisherPhysicalAddress.trim()) {
            tempErrors['publisherPhysicalAddress'] = `Please select the publishers physical address`;
        }
        if (publisherPhysicalAddress.trim() && publisherPhysicalAddress.length < 5) {
            tempErrors['publisherPhysicalAddress'] = `Please enter a valid physical address`;
        }

        if (!publisherPostalAddress.trim()) {
            tempErrors['publisherPostalAddress'] = `Please select the publishers postal address`;
        }

        if (publisherPostalAddress.trim() && publisherPostalAddress.length < 5) {
            tempErrors['publisherPostalAddress'] = `Please enter a valid postal address`;
        }

        if (!publisherTelephone) {
            tempErrors['publisherTelephone'] = `Please enter the publishers telephone number`;
        }

        if (publisherTelephone && !phoneRegex.test(publisherTelephone)) {
            tempErrors['publisherTelephone'] = `Please enter a valid telephone number`;
        }

        if (!publisherEmail.trim()) {
            tempErrors['publisherEmail'] = `Please enter the publishers email`;
        }

        if (publisherEmail.trim() && !isValidEmail(publisherEmail.trim())) {
            tempErrors['publisherEmail'] = `Please enter a valid email`;
        }

        if (!publisherContactPerson.trim()) {
            tempErrors['publisherContactPerson'] = `Please enter the publishers contact person`;
        }

        if (publisherContactPerson.trim() && !nameRegex.test(publisherContactPerson.trim())) {
            tempErrors['publisherContactPerson'] = `Please enter a valid author name with no special characters and numbers`;
        }

        if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {

            const firstElementKey = Object.keys(tempErrors)[0];

            const firstEl = document.querySelector(`#${firstElementKey}`);

            if (firstEl) {
                firstEl.scrollIntoView({ behavior: "smooth", block: "start" });
            }

            setError(tempErrors);

            return;
        }

        setPublisherName(publisherName.trim());
        setPublisherRegId(publisherRegId.trim());
        setPublisherPhysicalAddress(publisherPhysicalAddress.trim());
        setPublisherTelephone(publisherTelephone.trim());
        setPublisherPostalAddress(publisherPostalAddress.trim());
        setPublisherEmail(publisherEmail.trim());
        setPublisherContactPerson(publisherContactPerson.trim());
        

        // confirm-bleed-cover

        const next = `/${pagePathname}/bank-details`;
        //  : `/${pagePathname}/upload-assets`;

        history.push(next);

        setCompleted([...completed, stage]);

    }

    const clearError = useCallback((key) => {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError({ ...tempErrors });
        }

        return;
    }, [error]);

    useEffect(() => {
        if (typeof currentUser?.bankInfo?.isSelfPublisher === 'boolean') {
            setIsSelfPublisher(currentUser.bankInfo.isSelfPublisher);
        }
    }, []);  // Empty dependency array ensures this runs only once when the component mounts    

    useEffect(() => {
        if (isSelfPublisher && currentUser && typeof currentUser === 'object' && currentUser.name_first && currentUser.name_last && currentUser.email) {
            const fullName = currentUser.name_first + ' ' + currentUser.name_last;
            setPublisherName(fullName);

            setPublisherContactPerson(fullName);

            setPublisherEmail(currentUser.email);
        } else {
            if (!publisherName) setPublisherName('');

            if (!publisherContactPerson) setPublisherContactPerson('');

            if (!publisherEmail) setPublisherEmail('');
        }
    }, [isSelfPublisher]);

    useEffect(() => {
        if (currentUser && typeof currentUser === "object" && currentUser.bankInfo && typeof currentUser.bankInfo === "object") {
            if (currentUser.bankInfo.publish_name) {
                setPublisherName(currentUser.bankInfo.publish_name);
            }
            if (currentUser.bankInfo.reg_id_no) {
                setPublisherRegId(currentUser.bankInfo.reg_id_no);
            }
            if (currentUser.bankInfo.physical_address) {
                setPublisherPhysicalAddress(currentUser.bankInfo.physical_address);
            }
            if (currentUser.bankInfo.telephone) {
                setPublisherTelephone(currentUser.bankInfo.telephone);
            }
            if (currentUser.bankInfo.postal_address) {
                setPublisherPostalAddress(currentUser.bankInfo.postal_address);
            }
            if (currentUser.bankInfo.email) {
                setPublisherEmail(currentUser.bankInfo.email);
            }
            if (currentUser.bankInfo.contact_person) {
                setPublisherContactPerson(currentUser.bankInfo.contact_person);
            }
        } else if ((currentUser == null || currentUser == undefined || currentUser == "") && (publisherName == "" && publisherRegId == "" && publisherPhysicalAddress == "" && publisherTelephone == "" && publisherPostalAddress == "" && publisherEmail == "" && publisherContactPerson == "")) {
            setPublisherName('');
            setPublisherRegId('');
            setPublisherPhysicalAddress('');
            setPublisherTelephone('');
            setPublisherPostalAddress('');
            setPublisherEmail('');
            setPublisherContactPerson('');
        }
    }, []);
    /* ------------------------Route Control ------------------------ */
    const goHome = () => {
        history.push('/dashboard');
    };

    //Block the user from going back using router-dom 
    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (action === 'POP') { // only block backward navigation
                setShowBackModal(true);
                return false; // prevent navigation
            }
        });

        return () => {
            unblock();
        };
    }, []);
    /* ------------------------Route Control ------------------------ */

    return (
        <div className="w-full text-left">
            <h2 className="ml-1 text-4xl font-bold mb-5">Publisher Details</h2>
            {currentUser && (currentUser.bankInfo?.isSelfPublisher === true || currentUser.bankInfo?.isSelfPublisher === false || typeof currentUser.bankInfo?.isSelfPublisher !== 'undefined') ? "" :
                <>
                    <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.selfPublisherTooltip}>
                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="isSelfPublisher">Are you a self-publisher?</p>
                    </HelpText>
                    <div className="h-5" />
                    <div className="flex flex-row w-full shadow-md h-20 rounded-xl bg-white">
                        <div
                            className={`w-full flex align-center justify-center rounded-xl cursor-pointer`}
                        // onClick={() => setIsSelfPublisher(!isSelfPublisher)}
                        >
                            <div onClick={e => e.stopPropagation()} className="h-full align-center flex justify-end pl-8 md:pl-0">
                                <Checkbox
                                    color="red"
                                    checked={isSelfPublisher}
                                    className="flex-shrink-0"
                                    onChange={() => setIsSelfPublisher(prev => !prev)}
                                    text="Indicate here if you are a self publisher"
                                    id="checkbox-self-publisher"
                                />
                            </div>
                        </div>
                    </div>
                    <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.selfPublisherUnder}</p>

                    <div className="h-5" />
                </>
            }

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.publisherNameTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="publisherName"><span className="italic bold text-red mr-2">*</span>Publisher Name</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={publisherName}
                            onChange={e => setPublisherName(e.target.value)}
                            color="red"
                            placeholder="E.g. Penguin Books"
                            size="large"
                            type="text"
                            onFocus={() => clearError('publisherName')}
                            error={error && error.publisherName}
                            disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.publish_name ? true : false}
                        />
                    </div>
                    {currentUser && currentUser.bankInfo && currentUser.bankInfo.publish_name ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.publisherRegistrationIdTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="publisherRegId"><span className="italic bold text-red mr-2">*</span>Company Registration or ID Number</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={publisherRegId}
                            onChange={e => setPublisherRegId(e.target.value)}
                            color="red"
                            placeholder={isSelfPublisher ? "E.g. 9012456547894" : "E.g. 12345"}
                            size="large"
                            type="text"
                            onFocus={() => clearError('publisherRegId')}
                            error={error && error.publisherRegId}
                            disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.reg_id_no ? true : false}
                        />
                    </div>
                    {currentUser && currentUser.bankInfo && currentUser.bankInfo.reg_id_no ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.publisherPhysicalAddressTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="publisherPhysicalAddress"><span className="italic bold text-red mr-2">*</span>Publisher Physical Address</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <PlacesAutocomplete
                            value={publisherPhysicalAddress}
                            setValue={setPublisherPhysicalAddress}
                            placeholder="Enter the physical address"
                            onFocus={() => clearError('publisherPhysicalAddress')}
                            error={error && error.publisherPhysicalAddress}
                            id="pacg-pub-phys"
                            disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.physical_address ? true : false}
                        />
                    </div>
                    {currentUser && currentUser.bankInfo && currentUser.bankInfo.physical_address ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.publisherPhysicalAddressUnder}</p>}
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.publisherPostalAddressTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="publisherPostalAddress"><span className="italic bold text-red mr-2">*</span>Publisher Postal Address</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white relative`}>
                <CardBody>
                    {currentUser && currentUser.bankInfo && currentUser.bankInfo.postal_address ?
                        null
                        :
                        <div className="mb-8 text-left">
                            <Checkbox
                                color="red"
                                // value={acceptTerms}
                                className="flex-shrink-0"
                                onChange={() => setPublisherPostalAddress(publisherPhysicalAddress)}
                                text="Same as physical address"
                                id="checkbox-copy-address"
                            // checked={acceptTerms}
                            />
                        </div>
                    }
                    <div className="mb-4 text-left">
                        <PlacesAutocomplete
                            value={publisherPostalAddress}
                            setValue={setPublisherPostalAddress}
                            placeholder="Enter the postal address"
                            onFocus={() => clearError('publisherPostalAddress')}
                            error={error && error.publisherPostalAddress}
                            id="pacg2"
                            disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.postal_address ? true : false}
                        />
                    </div>
                    {currentUser && currentUser.bankInfo && currentUser.bankInfo.postal_address ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : <p className="text-gray-500 text-xs mt-2">{helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.publisherPostalAddressUnder}</p>}
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.publisherTelephoneTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="publisherTelephone"><span className="italic bold text-red mr-2">*</span>Publisher Telephone</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <CountrySelectInput
                            value={publisherTelephone}
                            setValue={setPublisherTelephone}
                            placeholder="E.g. (+27)123456789"
                            error={error && error.publisherTelephone}
                            onFocus={() => clearError('publisherTelephone')}
                            id="publisherTelephone"
                            disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.telephone ? true : false}
                        />
                    </div>
                    {currentUser && currentUser.bankInfo && currentUser.bankInfo.telephone ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.publisherEmailTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="publisherEmail"><span className="italic bold text-red mr-2">*</span>Publisher Email</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={publisherEmail}
                            onChange={e => setPublisherEmail(e.target.value)}
                            color="red"
                            placeholder="E.g. johnsmith@domain.com"
                            size="large"
                            type="text"
                            onFocus={() => clearError('publisherEmail')}
                            error={error && error.publisherEmail}
                            disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.email ? true : false}
                        />
                    </div>
                    {currentUser && currentUser.bankInfo && currentUser.bankInfo.email ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <HelpText iconSize="xl" helpText={helpText && helpText.newBook && helpText.newBook.publisherInfo && helpText.newBook.publisherInfo.publisherContactPersonTooltip}>
                <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2" id="publisherContactPerson"><span className="italic bold text-red mr-2">*</span>Publisher Contact Person</p>
                <p className="italic text-xs text-red ml-1 pt-1">required</p>
            </HelpText>
            <div className="h-5" />
            <Card className={`max-w-3xl text-left bg-white`}>
                <CardBody>
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={publisherContactPerson}
                            onChange={e => setPublisherContactPerson(e.target.value)}
                            color="red"
                            placeholder="E.g. John Smith"
                            size="large"
                            type="text"
                            onFocus={() => clearError('publisherContactPerson')}
                            error={error && error.publisherContactPerson}
                            disabled={currentUser && currentUser.bankInfo && currentUser.bankInfo.contact_person ? true : false}
                        />
                    </div>
                    {currentUser && currentUser.bankInfo && currentUser.bankInfo.contact_person ? <span className="text-gray-400 text-sm italic leading-normal">If you wish to change these details, please navigate to the "Profile" section and select the "Update Request" option.</span> : null}
                    <div className="h-2" />
                </CardBody>
            </Card>
            <div className="h-5" />

            <div className="h-16" />
            <Button
                color="red"
                className="hover:bg-red hover:text-white hover:bg-red w-full float-right md:w-1/4 hover:pr-2"
                buttonType="outline"
                size="lg"
                ripple="dark"
                onClick={onContinueClick}
            >
                {completed.includes(stage) ? "Next" : "Continue"}
                <Icon name="arrow_forward" size="sm" />
            </Button>
            <div className="h-16" />
            {/* -------------------Modal---------------------- */}
            {/* Back navigation */}
            <Modal
                showModal={showBackModal}
                setShowModal={setShowBackModal}
                size="md"
            >
                <div className="p-5">
                    <h3 className="font-light text-xl mb-5">
                        Do you wish to go back?
                    </h3>
                    <Paragraph>
                        Your book specifications have been finalized and cannot be altered at this stage. Should you wish to modify these details, you will need to initiate the upload process anew.
                    </Paragraph>
                    <div className="h-5" />
                    <Button
                        color="red"
                        className="w-full"
                        buttonType="filled"
                        size="lg"
                        ripple="dark"
                        onClick={() => setShowBackModal(false)}
                    >
                        No, stay here
                    </Button>
                    <div className="h-5" />
                    <Button
                        color="gray"
                        className="w-full"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={() => goHome()}
                    >
                        Yes, take me home
                    </Button>
                </div>
            </Modal>
            {/* -------------------Modal---------------------- */}
        </div>
    );
}
