import { initial, set } from "lodash";
import { rectanglesAreEqual } from "pdf-lib";
import { useEffect, useRef, useState } from "react";

let bookObject: any;

export const useWizardController = () => {
  // const bookObject: any = localStorage.getItem('bookObject');
  const [bookObject, setBookObject] = useState({}); // Only state declaration
  //const issues: any[] = [];
  let issues: any = {};
  //Data is being process on every change lets create a processing variable which will run the process once and then again on the last change
  const [processing, setProcessing] = useState(false);
  const warnings: string[] = [];
  let processingData: number = 0;
  console.log("Initial");
  //Rules have to be created to validate the fields

  const fieldValidationConfig: any = {
    title: {
      minLength: 3,
      maxLength: 30,
      required: true,
      pattern: /^[a-zA-Z0-9 !@#$%^&*()_+,\-./:;<=>?@[\]^`{|}~]*$/,
    },
    format: {
      required: true,
    },
    salesRegion: {
      required: true,
    },
    genre: {
      required: true,
    },
    isbnRequired: {
      required: true,
    },
    isbn: {
      length: 13,
      required: false,
      pattern: /^[0-9]+$/,
    },
    pageCount: {
      required: true,
      pattern: /^[0-9]+$/,
    },
    bookDimensions: {
      required: true,
    },
    colourOption: {
      required: true,
    },
    coverFinish: {
      required: true,
    },
    // Add other fields here...
  };

  //this is in my useWizardController.tsx file
  //This function run on the initial load of the page  and when a user freshes the page
  async function loadBook() {
    //const localBookString = localStorage.getItem('bookObject');
    console.log(1, "load");
    let localBook;
    try {
      // Attempt to parse the localBookString
      // localBook = localBookString ? JSON.parse(localBookString) : null;
    } catch (e) {
      // If parsing fails, log the error and set localBook to null
      console.error("Error parsing localBookString:", e);
      localBook = null;
    }

    // Initialize bookObject
    // let bookObject = {
    //      title: null,
    //      format: null,
    //      regionLocal: null,
    //      regionInternational: null,
    //      genre: null,
    //      isbnRequired: null,
    //      isbn: null,
    //      pageCount: null,
    //      bookDimensions: null,
    //      colourOption: null,
    //      coverFinish: null,
    // };

    // // If localBook is not null, populate the bookObject
    // if (localBook) {
    //      bookObject = {
    //           title: localBook.title || null,
    //           format: localBook.format || null,
    //           regionLocal: localBook.regionLocal || null,
    //           regionInternational: localBook.regionInternational || null,
    //           genre: localBook.genre || null,
    //           isbnRequired: localBook.isbnRequired || null,
    //           isbn: localBook.isbn || null,
    //           pageCount: localBook.pageCount || null,
    //           bookDimensions: localBook.bookDimensions || null,
    //           colourOption: localBook.colourOption || null,
    //           coverFinish: localBook.coverFinish || null,
    //      };
    // }

    return bookObject;
  }

  //this function runs when  a user clicks the continue button and when the user changes the value of a field it store the data in local storage
  async function storeBook(key: any, value: any) {
    console.log(processingData, "store");

    if (processingData === 0) {
      // First, let's retrieve the existing bookObject from localStorage
      processingData = 1;
      const localBookString = localStorage.getItem("bookObject");
      let existingBookObject = {};
      console.log("localBookString:", localBookString);
      try {
        existingBookObject = localBookString ? JSON.parse(localBookString) : {};
      } catch (e) {
        console.error("Error parsing localBookString:", e);
      }

      // Then, merge it with the new key-value pair
      const newBookObject = { ...existingBookObject, [key]: value };

      // Log before storing
      console.log("Before storing:", typeof newBookObject, newBookObject);

      // Now, stringify and store the updated object back into localStorage
      // localStorage.setItem('bookObject', JSON.stringify(newBookObject));

      // Log to see if it's correctly stored
      let temp = localStorage.getItem("bookObject");
      console.log("After storing:", temp);

      //setBookObject(newBookObject);
      //bookObject = newBookObject;

      await processBook(newBookObject);
    } else {
      console.log("Process running");
    }
  }

  async function processBook(bookObject: any) {
    processingData = 2;
    const nameRegex = /^[a-zA-Z0-9 !@#$%^&*()_+,\-./:;<=>?@[\]^`{|}~]*$/;
    const stringRegex = /^[a-zA-Z]+$/;
    const numberRegex = /^[0-9]+$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Clear issues
    issues = {};
    // Title Check
    if (!bookObject?.title) {
      addToIssues("Title", "Title is required");
    }
    if (bookObject?.title && !nameRegex.test(bookObject.title))
      addToIssues("Title", "Title must be a valid string");
    if (bookObject?.title && bookObject.title.length < 3)
      addToIssues("Title", "Title must be greater than 3 characters");
    if (bookObject?.title && bookObject.title.length > 30)
      addToIssues("Title", "Title must be less than 30 characters");
    // Format Check
    if (!bookObject?.format) addToIssues("Format", "Format is required");
    if (
      bookObject?.format &&
      (bookObject?.format !== "Print" || bookObject?.format !== "Ebook")
    )
      addToIssues(
        "Format",
        "Invalid book Format. Please select Print or Ebook"
      );
    // Sales Region  is split into two fields RegionLocal and RegionInternational
    if (!bookObject?.regionLocal)
      addToIssues("RegionLocal", "Region Local is required");
    if (!bookObject?.regionInternational)
      addToIssues("RegionInternational", "Region International is required");
    // Genre Check
    if (!bookObject?.genre) addToIssues("Genre", "Genre is required");

    // ISBN Required Check
    if (!bookObject?.isbnRequired)
      addToIssues("ISBNRequired", "ISBN Required is required");
    // ISBN Check
    if (bookObject?.isbn && bookObject.isbn.length !== 13)
      addToIssues("ISBN", "ISBN must be 13 characters");
    if (bookObject?.isbn && !numberRegex.test(bookObject.isbn))
      addToIssues("ISBN", "ISBN must be a valid number");
    //Second Page
    // Page Count Check
    if (!bookObject?.pageCount)
      addToIssues("PageCount", "Page Count is required");
    if (bookObject?.pageCount && !numberRegex.test(bookObject.pageCount))
      addToIssues("PageCount", "Page Count must be a valid number");
    // Book Dimensions Check
    if (!bookObject?.bookDimensions)
      addToIssues("BookDimensions", "Book Dimensions are required");
    // Colour Option Check
    if (!bookObject?.colourOption)
      addToIssues("ColourOption", "Colour Option is required");
    // Cover Finish Check
    if (!bookObject?.coverFinish)
      addToIssues("CoverFinish", "Cover Finish is required");

    // const fieldKeys = Object.keys(fieldValidationConfig);
    // for (let i = 0; i < fieldKeys.length; i++) {
    //      const field = fieldKeys[i];
    //      const config = fieldValidationConfig[field];
    //      const value = bookObject[field] || ""; // default to empty string if value is null or undefined
    //      //Check for required fields and if the field is not required and the value is empty skip the rest of the validation
    //      //But if the users does full in information then the rest of the validation will run
    //      if (config.required === true || value.length > 0) {

    //           if (config.required && value.length === 0) {
    //                addToIssues(field, `${field} is required`);
    //           }
    //           if (config.pattern && !config.pattern.test(value)) {
    //                addToIssues(field, `${field} must be a valid string`);
    //           }

    //           if (config.maxLength && value.length > config.maxLength) {
    //                addToIssues(field, `${field} must be less than ${config.maxLength} characters`);
    //           }

    //           if (config.minLength && value.length < config.minLength) {
    //                addToIssues(field, `${field} must be greater than ${config.minLength} characters`);
    //           }

    //           if (config.length && value.length !== config.length) {
    //                addToIssues(field, `${field} must be ${config.length} characters`);
    //           }
    //      }
    // }

    console.log(issues);
    processingData = 0;
  }

  function addToIssues(field: any, message: any) {
    if (!issues[field]) {
      issues[field] = [];
    }
    issues[field].push(message);
  }

  async function validate(newChanges: any) {}

  return {
    bookObject,
    //setBookObject,
    loadBook,
    processBook,
    storeBook,
    issues,
  };
};
