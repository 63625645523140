const helpText = {
    navBar: {
        addToBalance: '',
    },
    books: {
        royaltyFeeTooltip: 'The amount you will receive from each order of your book.',
        localSellingPriceTooltip: 'The amount the consumer will pay for your book within South Africa.',
        internationaSellingPriceTooltip: 'The amount the consumer will pay for your book outside of South Africa'
    },
    newBook: {
        generalInfo: {
            bookTitleTooltip: 'This is the name of your book and will be used as the product title when displayed on ecommerce sites such as Takealot.',
            bookFormatTooltip: "Select which format you'd like to publish your book in. You can publish the same book in two formats but you will have to go through this process for each format.",
            bookFormat: {
                print: "",
                audio: "",
                international: ""
            },
            salesRegionTooltip: "This is the market you will be selling your book to. You can choose either one or both, if both are selected you will receive 2 different royalty fees which are dependant on the region of the sale.",
            salesRegion: {
                local: "",
                international: ""
            },
            genreTooltip: "This is the category the content of your book falls into.",
            spotUvUnder: "Spot UV refers to the application of UV Coating to the cover of your book. Spot UV is a creative way to add depth and contrast through varying levels of sheen and texture.",
            spotUvTooltip: "Spot UV is applied per book and therefore will influence the royalty calculation of your book.",
            duplexCoverTooltip: "Duplex cover's have a cost of R2.50 per book. This cost is factored in to the royalty calculation.",
            duplexCoverUnder: "Besides having graphics on the inside of your book cover (think of repeating designs in a children's book) you can also put an author photo along with a short author bio in that spot, freeing up precious cover space. More experienced authors could list previously published works there.",
            duplexCoverIntTooltip: "Duplex cover's have a cost of ¢0.25 per book. This cost is factored in to the royalty calculation.",
            duplexCoverIntUnder: "Besides having graphics on the inside of your book cover (think of repeating designs in a children's book) you can also put an author photo along with a short author bio in that spot, freeing up precious cover space. More experienced authors could list previously published works there.",
            perforationTooltip: "Perforation has a cost of ¢ 0.0025 per book. This cost is factored in to the royalty calculation.",
            perforationUnder: "Perforated paper is a sheet of paper or card stock that has perforated lines of very small holes punched into the paper. This perforation allows parts of the sheet to be separated simply by folding and tearing along the dotted line.",

            softTouchLaminationTooltip: "Soft touch has a cost of R3.00 per cover printed. This cost is factored into the royalty calculation.",
            softTouchLaminationUnder: "Soft touch has a cost of R3.00 per cover printed. This cost is factored in to the royalty calculation.",

            spiralBoundTooltip: "Spiral bound book's have a cost of R12.00 per book printed. This cost is factored in to the royalty calculation.",
            spiralBoundUnder: "Spiral Binding, is a method for joining the pages and cover of a book. It utilizes a metal coil that is inserted and twisted through small holes punched along the spinal edge of the book's cover and pages.",

            isbnTooltip: "The International Standard Book Number is a numeric commercial book identifier that is intended to be unique. ISBN numbers are 13 digits long. Publishers purchase ISBNs from an affiliate of the International ISBN Agency. An ISBN is assigned to each separate edition and variation of a publication.",
            isbnUnder: "*Please note that requiring an ISBN number to be created has a charge of R250 and will automatically be added to your cart, if you already have one leave this field unchecked and add yours in the input below.",
            isbnEnterUnder: "If you already have an ISBN number for your book, enter it here. Otherwise indicate above that you require Print on Demand to create one for you.",

            heavyGraphicsTooltip: "Does your book contain many images or illustrations?.",
            heavyGraphicsUnder: ""
        },
        confirmOptions: {
            infoTop: "*These are the options you chose in the previous step. If you are not happy with anything, you can go back and change them before proceeding. After this step, you will not be able to change any book options."
        },
        calculator: {
            royaltyTooltip: {
                print: "This is an estimate of the royalty amount you will receive per book sold based on the options you choose on this calculator.",
                ebook: "Please note this is an estimate of the royalty amount you will receive per book.",
                audiobook: "Please note this is an estimate of the royalty amount you will receive per book"
            },
            numberOfPagesUnder: "*Please note that this number must match the page count in your uploaded book document.",
            bookSizeUnder: "*The physical size your book will be printed in.",
            bindingUnder: "*The way in which pages are bound on your book.",
            bookDimensionsUnder: "*The physical size your book will be printed in.",
            spineThickness: {
                three: "*3mm – no text on the spine.",
                four: "*4mm – can allow for text on the spine"
            },
            printColorUnder: "*Decide whether the text and images in your book will be printed in black & white or colour. If your book is black and white with just one colour, please select colour.",
            orientationUnder: "*Decide whether your book will be in portrait or landscape.",
            coverFinishUnder: "*This will be the texture of the cover of your printed book.",

            pageFinishUnder: "*This is the texture of the pages in your printed book.",
            bookPriceTooltip: "This is the selling price of your book; you can change this price and see your royalty fee either increase or decrease in the bottom left corner. Please note that all options up to paper type must be filled in for your royalty fee to show. Based on demand, the platform has the right to alter this price in order to increase sales.",
            localMarketPaperTypeUnder: "*The type of paper, i.e., the thickness of the paper and ink type that will be used to produce your book in the South African market.",
            localMarketCoverTypeUnder: "*The cover that will be used to produce your book in the South African market.",
            internationalCoverOptionUnder: "*The specific cover option you would like on your internationally sold book.",
            internationalCoverTypeUnder: "*The cover you would like on your internationally sold book.",
            internationalMarketPaperType: "*The cover you would like on your internationally sold book"
        },
        publisherInfo: {
            selfPublisherTooltip: "Self-publishing is the publication of media by its author without the involvement of an established publisher.",
            publisherNameTooltip: "The company name of your publisher.",
            publisherRegistrationIdTooltip: "The registration ID of your publisher, if you are a self-publisher you may enter your own ID number.",
            publisherPhysicalAddressTooltip: "The address of your publisher, if you are a self-publisher, enter your own physical address.",
            publisherPhysicalAddressUnder: "E.g. 11 Street Name, Suburb, Town/City.",

            publisherPostalAddressTooltip: "The address where mail can be sent to.",
            publisherPostalAddressUnder: "E.g. 11 Street Name, Suburb, Town/City.",

            publisherTelephoneTooltip: "The publisher’s cell/telephone number. If you are a self-publisher, enter your own cell/telephone number.",
            publisherEmailTooltip: "The email address of your publisher. If you are a self-publisher, enter your own email address.",
            publisherContactPersonTooltip: "The name of the contact person who will be reached from the above-entered contact details.",
        },
        bankDetails: {
            general: "We require your banking details for royalty payouts.",
            localVsInternationalTooltip: "Select local if your bank is within South Africa. If not, select international.",
            localVsInternational: {
                localUnder: "Your bank is within South Africa.",
                internationalUnder: "Your bank is outside of South Africa.",
            },
            bankNameTooltip: "The company name of your bank.",
            branchCodeTooltip: "A branch code is a unique identifying code for a given branch of a bank. Each branch of a bank is differentiated by its branch code. If you do not know your branch code, you can try Google your branch code, otherwise, contact your bank to find out.",
            branchNameTooltip: "A branch name is the bank name and the place where the bank is located.",
            accountNameTooltip: "This is the account holder’s name, for example, if your account was opened in your name and your name was John Smith, your account name would be John Smith. If it is a business account it will be the name of the company.",
            accountTypeTooltip: "The type of bank account. E.g., Savings or Cheques.",
            accountNumberTooltip: "Your bank accounts number - 8 to 12 digits that uniquely identify your account. Contact your bank if you are unsure. Please make sure this is correct as it will directly affect the ability to send you royalty pay-outs.",
            paypalTooltip: "Not required. Your PayPal address. Usually, the email of the PayPal account.",
            bankAddressTooltip: "The physical address of the bank where your account is held.",
            ibanTooltip: "An International Bank Account Number (IBAN), is an internationally recognised code that is used when sending or receiving international payments. Comprising of 34 letters and numbers, your IBAN number is a combination of your account number and sort code written in a standardised format.",
            swiftCodeTooltip: "A SWIFT code is a code used to identify the country, bank, and branch to which an account is registered to.",
        },
        acceptTermsSign: {
            signedPlaceTooltip: "The location/area at which you are signing this document - where you are right now.",
            signatureTooltip: "",
            initialsTooltip: ""
        },
        email: "",
        rawOrPdf: {
            rawTooltip: "Selecting this option allows you to upload your file in .docx format in the following step.",
            pdfTooltip: "Selecting this option allows you to upload your file in .pdf format in the following step.",
        },
        additionalDetails: {
            authorTooltip: "The full name of the author of your book.",
            contributorsTooltip: "Full names and roles of any contributors your book has.",
            keywordsTooltip: "A word or a group of words that match the description or describe your book.",
            publicationDateTooltip: "The date (future or past) your book is published.",
            onSaleDateTooltip: "The date (future or past) your book goes on sale.",
            languageTooltip: "The language your book is written in.",
            subTitleTooltip: "The sub title of your book - if your book has one.",
            bisacCodeTooltip: "BISAC codes are 9-character alphanumeric codes that tell book retailers, distributors, and librarians what categories and subcategories a book belongs in. This is prepopulated based on the genre you chose.",
            shortDescriptionTooltip: "Describe your book in simple, straightforward, and consumer-friendly terms. Your short description should be at least 150-200 words long.",
            mainDescriptionTooltip: "Describe your book in simple, straightforward, and consumer-friendly terms. Your short description should be at least 200-512 words long.",
            longDescriptionTooltip: "Describe your book in simple, straightforward, and consumer-friendly terms. Your short description should be at least 512-4000 words long.",
            copyrightHolderTooltip: "Usually, the author of the creative work is the owner of the copyright. However, in the publishing industry, the owner of the copyright may be the publishing company due to an agreement between the author and the publisher.",
            copyrightDateTooltip: "The date the copyright of your book was registered.",
            recommendedAudienceTooltip: "A target audience is whom you write your book for. It’s the group of people who would be the most helped or entertained by your book. Your readership might extend past your intended target audience, but the target audience is who you intentionally aim for.",
            indicatedAudienceWarningTooltip: "Some common and critical warnings are graphic violence, sexual assault, child abuse, racism, scenes of homophobia, and animal abuse. Making a reader aware of these things before they engage with the material can help prevent episodes of post-traumatic stress or other anxieties.",
        },
        confirmBleedCover: {

        },
        uploadAssets: {
            coverImageTooltip: "",
            thumbnailTooltip: "",
            bookTooltip: "",
        }
    },

};

export default helpText;