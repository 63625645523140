import { useState, useEffect, useRef } from 'react'

import { useGlobalState } from 'state-pool'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { setPageTitle } from 'utils';
// import { useHistory, useLocation } from 'react-router-dom'

import { getBooksForRenewal, resignContract } from 'routes/userRoutes';

import Button from '@material-tailwind/react/Button'
import Icon from '@material-tailwind/react/Icon'
import Card from '@material-tailwind/react/Card'
import CardBody from '@material-tailwind/react/CardBody'
import Checkbox from '@material-tailwind/react/Checkbox'

// import { podApi } from 'api/pod'

import HelpTextWrapper from 'components/general/HelpTextWrapper'
import FillAndSign from 'components/fill-and-sign/FillAndSign'
import PdfModal from 'components/document-handling/PdfModal'
import HelpText from 'components/general/HelpText'
import PlacesAutocomplete from 'components/inputs/PlacesAutocomplete'
import SignatureModal from 'components/sign/SignatureModal'
import Chat from 'components/chat/Chat';

import { useHistory } from 'react-router';

import { useAlert } from 'hooks/useAlert';

import { parseQueryString } from 'utils';
import CustomDropdown from 'components/inputs/Dropdown';
import DropdownInput from 'components/inputs/DropdownInput';

export default function ResignContract() {
    const [showModal, setShowModal] = useState(false)
    const [contract, setContract] = useState('')
    const [showPdf, setShowPdf] = useState(false);
    const [showContract, setShowContract] = useState(false)
    const [showInitialsModal, setShowInitialsModal] = useState(false)
    const [renewalBooks, setRenewalBooks] = useState([])
    const [activeBook, setActiveBook] = useState({ label: '', value: {} });

    const [acceptTerms, setAcceptTerms] = useState(false);
    const [signature, setSignature] = useState('');
    const [initials, setInitials] = useState('');
    const [signedPlace, setSignedPlace] = useState('');

    const [error, setError] = useState({})

    // const [error, setError] = useState({});
    const [currentUser] = useGlobalState('currentUser')
    const viewerRef = useRef()

    const history = useHistory();

    const [bookDetails, setBookDetails] = useState(null);

    const { newAlert } = useAlert();

    const [books] = useGlobalState('books');

    useEffect(() => {

        if (!bookDetails && books && typeof books === 'object' && books.localBooks && books.internationalBooks) {
            const pageQueries = parseQueryString();

            if (pageQueries && pageQueries.bookID && pageQueries.islocal) {
                const { bookID, islocal } = pageQueries;

                const region = islocal === 'true' ? 'localBooks' : 'internationalBooks';

                const foundBook = books[region] && books[region].find(book => book.id == bookID);
                // console.log('FB', foundBook, books)

                if (foundBook) setBookDetails({ ...foundBook });
            }
        }
    }, [books]);

    useEffect(() => {
        if (contract) {
            setShowContract(true);
        }
    }, [contract])

    const redirectToDashboard = (renewalBooksLength) => {
        if (renewalBooksLength <= 0) {
            const user = JSON.parse(localStorage.getItem('currentUser'))
            const updatedUser = { ...user, user_status: 1 };
            localStorage.setItem('currentUser', JSON.stringify({ ...updatedUser }));
            window.location = '/dashboard'
        }
    }

    const checkBooksForRenewal = () => {
        getBooksForRenewal().then(res => {
            redirectToDashboard(res.results?.length || 0);
            setRenewalBooks(res.results.map((val, i) => {
                return {
                    label: val.book_title,
                    value: val
                }
            }
            )
            )
        })
    }

    const onContinueClick = async () => {
        const book = activeBook.value
        const isLocal = !JSON.parse(book.misc)?.internationroyalty ? true : false

        if (book && book.id && initials && signature) {
            const { id } = book;

            resignContract(id, isLocal, signedPlace, signature, initials).then(res => {
                if (res && res.statusCode === 0) {
                    newAlert('success', `Contract for book ${book && book.book_title} re-signed successfully. `, '');
                    setSignature('');
                    setInitials('');
                    setSignedPlace('');
                    setAcceptTerms(false);
                    checkBooksForRenewal();
                    // history.push('/checkout/cart');
                } else {
                    newAlert('danger', `Failed to re-sign contract`, '');
                }
            });
        }
    }

    function openPdfModal() {
        setShowPdf(true)
    }

    useEffect(async () => {
        if (activeBook) {
            try {
                const pdfDocChanged = await PDFDocument.load(activeBook.value.contractInfo)
                const pdfDataUri = await pdfDocChanged.saveAsBase64({ dataUri: true })
                setContract(pdfDataUri)
            } catch (e) {

            }
        }
    }, [activeBook])

    useEffect(() => {
        checkBooksForRenewal()
    }, [])

    return (
        <div className="w-full text-left">
            <div className="h-5" />
            <Card className={`max-w-3xl text-left`}>
                <CardBody>
                    {/* TODO: for book "Test" */}
                    <h3 className="font-light text-2xl text-black">There were changes to your contract for books</h3>
                    <div className="h-5" />
                    <p className="text-md">
                        Please review your contract and re-sign after reviewing the changes. If you are not satisfied please contact customer support at
                        <a target="_blank" rel="noreferrer" href="mailto:bo1@printondemand.co.za" className="text-red font-bold text-md">{' '}bo1@printondemand.co.za</a>
                    </p>
                    <div className="h-5" />
                    <div className='w-full'>
                        {renewalBooks.length === 0 ? (
                            // display a loading icon if renewalBooks is null
                            <img alt="loading" className="h-10 w-auto" src="/images/loading.gif" />

                        ) : (
                            <DropdownInput options={renewalBooks} value={activeBook} setValue={setActiveBook} placeholder={'Renewal Books'} color={'red'} />
                        )}
                    </div>
                    <div className="h-5" />
                    {/* {contract && <FillAndSign file={contract} setFile={setContract} />} */}
                    <HelpText iconColor={activeBook && activeBook.label == '' ? "red" : "gray"} iconSize="sm" helpText="Please select the book you wish to sign for before accepting Print on Demand's Terms & Conditions.">
                        <div className="flex text-left mb-2">

                            <Checkbox
                                color="red"
                                value={acceptTerms}
                                className="flex-shrink-0"
                                onChange={() => setAcceptTerms(!acceptTerms)}
                                text={
                                    <div className="flex items-center">
                                        I have read through and accept Print on Demand's{' '}
                                    </div>
                                }
                                id="checkbox"
                                checked={acceptTerms}
                                disabled={activeBook && activeBook.label == '' ? true : false}
                            />
                            <span className="text-red font-bold text-lg cursor-pointer bg-white" style={{ background: "white" }} onClick={() => openPdfModal()}>
                                &nbsp;Terms & Conditions
                            </span>
                        </div>
                    </HelpText>
                    <HelpText iconSize="xl" helpText="Enter the location where the signing occurred.">
                        <p className="text-gray-600  text-2xl leading-normal">Signed Place</p>
                    </HelpText>
                    <div className="h-5" />
                    <div className="mb-4 text-left">
                        <PlacesAutocomplete
                            value={signedPlace}
                            setValue={setSignedPlace}
                            placeholder="Select the signed place"
                            error={error && error.signedPlace}
                            id="pacg-sign-1"
                        />
                    </div>
                    <div className="h-2" />
                    <div className="h-8" />
                    <div className="w-full grid grid-cols-1 gap-5 md:grid-cols-2">
                        <HelpTextWrapper
                            placement="top"
                            width="100%"
                            helpText={!signature ? "Click to draw your signature" : "Click to change your signature"}
                        >
                            <div className="rounded-xl border-gray-300 border-2  cursor-pointer p-5" onClick={() => setShowModal(true)}>
                                {signature ? (
                                    <>
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                                            Your Signature
                                        </p>
                                        <img
                                            className="h-32 w-auto"
                                            alt="user_signature"
                                            src={signature}
                                        />
                                        <div className="h-5" />
                                    </>
                                ) : (
                                    <div className="w-full flex flex-col items-center justify-center h-48" >
                                        <Icon name="create" size="6xl" color="gray" />
                                        <div className="h-2" />
                                        <p>Click to sign</p>
                                    </div>
                                )}
                            </div>
                        </HelpTextWrapper>
                        <HelpTextWrapper
                            placement="top"
                            width="100%"
                            helpText={!signature ? "Click to draw your initials" : "Click to change your initials"}
                        >
                            <div className="rounded-xl border-gray-300 border-2  cursor-pointer p-5" onClick={() => setShowInitialsModal(true)}>
                                {initials ? (
                                    <>
                                        <p className="text-gray-600 ml-1 text-2xl leading-normal mb-2">
                                            Your Initials
                                        </p>
                                        <img
                                            className="h-32 w-auto"
                                            alt="user_initials"
                                            src={initials}
                                        />
                                        <div className="h-5" />
                                    </>
                                ) : (
                                    <div className="w-full  flex flex-col items-center justify-center h-48" >
                                        <Icon name="create" size="6xl" color="gray" />
                                        <div className="h-2" />
                                        <p>Click to sign initials</p>
                                    </div>
                                )}
                            </div>
                        </HelpTextWrapper>
                    </div>
                    <div className="h-8" />
                    <HelpTextWrapper
                        helpText="Accept terms and sign to continue"
                        hideHelp={acceptTerms && !!signature}
                    >
                        <Button
                            color="red"
                            className={` ${!acceptTerms || !signature
                                ? 'cursor-not-allowed'
                                : 'hover:bg-red hover:text-white hover:pr-2'
                                }  w-full md:w-1/4 float-right`}
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            disabled={!acceptTerms || !signature}
                            onClick={
                                onContinueClick
                            }
                        >
                            submit
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                    </HelpTextWrapper>
                    <div className="h-12" />
                </CardBody>
            </Card>
            <Chat />
            <div className="h-16" />
            <SignatureModal
                show={showModal}
                setShow={setShowModal}
                save={(sigBase64) => setSignature(sigBase64)}
                title="Your Signature"
                subText="Draw your signature and click save"
            />
            <SignatureModal
                show={showInitialsModal}
                setShow={setShowInitialsModal}
                save={(sigBase64) => setInitials(sigBase64)}
                title="Your Initials"
                subText="Draw your initials and click save"
            />
            {
                <PdfModal
                    file={contract}
                    title={
                        currentUser && currentUser.firstname + ' ' + currentUser.lastname
                    }
                    showModal={showContract}
                    setShowModal={setShowContract}
                />
            }
            <PdfModal
                file={`/assets/pdf/Terms_and_Conditions_Print_On_Demand.pdf`}
                title={`Terms_and_Conditions_Print_On_Demand.pdf`}
                showModal={showPdf}
                setShowModal={setShowPdf}
            />
        </div>
    )
}
