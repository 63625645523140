import {useRef, useState} from 'react';

import CardBody from '@material-tailwind/react/CardBody';
// import Button from '@material-tailwind/react/Button';
// import Icon from '@material-tailwind/react/Icon';
// import Tooltips from "@material-tailwind/react/Tooltips";
// import TooltipsContent from "@material-tailwind/react/TooltipsContent";

import Modal from 'components/general/Modal';

import AddCoupon from 'components/add-coupon/AddCoupon';

import { useGlobalState } from 'state-pool';
import { numberWithSpaces } from 'utils';

export default function BalanceInfo() {
    const depositLocalRef = useRef();
    const [showAddCouponModal, setShowAddCouponModal] = useState(false);

    const [currentUser] = useGlobalState('currentUser');

    const getBalance = () => {
        if(currentUser && currentUser.balance) {
            const balance = numberWithSpaces(currentUser.balance);

            return `${balance}`;
        }

        return '0.00';
    }

    return (
        <>
            <div className="w-full rounded-xl p-10 bg-white shadow-2xl">
                <CardBody>
                    <div className="relative flex items-center h-4">
                        {/* <img alt="rsa_flag" src="/images/flags/rsa.jpg" className="h-10 w-auto rounded-full mr-5" /> */}
                        <div>
                            <h5 className="text-gray-500 font-light tracking-wide m-0 p-0 uppercase">Balance</h5>
                            <span className="text-xl font-ligh m-0 p-0">R {getBalance()}</span>
                        </div>
                        {/* <Button
                            color="gray"
                            className="ml-auto self-end"
                            buttonType="filled"
                            size="sm"
                            iconOnly
                            rounded
                            ripple="light"
                            onClick={() => setShowAddCouponModal(true)}
                            ref={depositLocalRef}
                        >
                            <Icon name="add" size="2xl" color="white" />
                        </Button>
                        <Tooltips placement="top" ref={depositLocalRef}>
                            <TooltipsContent>Add to your balance</TooltipsContent>
                        </Tooltips> */}
                    </div>
                </CardBody>
            </div>
            <Modal 
                showModal={showAddCouponModal}
                setShowModal={setShowAddCouponModal}
                // title={`Withdraw from fund - ${fund && fund.name} to your wallet`}
                size="regular"
            >   
                <AddCoupon 
                    setShowModal={setShowAddCouponModal}
                />
            </Modal>
        </>
    );
}