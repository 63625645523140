import {useState} from 'react';

import { Paragraph } from "@material-tailwind/react";
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { useGlobalState } from 'state-pool';

import { podApi } from 'api/pod';
import { useAuth } from 'hooks/useAuth';

export default function AddCoupon({setShowModal}) {
    const [code, setCode] = useState('');
    const { getUserDetails } = useAuth();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [currentUser] = useGlobalState('currentUser');

    function submit() {
        if(!code) {
            setError('Coupon code can not be blank');

            return;
        }

        const body = {
            coupon_code: code
        }

        podApi.post('/user/addcoupon', body).then(res => {
            if(res && res.data && res.data.description && res.data.description === 'Successful') {
                // refresh user Details
                getUserDetails();
                setSuccess(true);
            } else {
                setError('Something went wrong when adding your coupon, please check your spelling. Otherwise contact support bo1@printondemand.co.za');
            }
        });
    }

    return (
        <>
            {!success ? <>
                <h2 className="text-4xl font-bold">Enter Your Coupon Code</h2>
                <div className="h-4" />
                <Paragraph>
                    Once successfully submitted, the coupons amount will be automatically added to you balance.
                </Paragraph>
                <div className="mb-4 text-left">
                    <Input
                        outline={false}
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        type="text"
                        color="red"
                        placeholder="Enter your coupon code here"
                        size="regular"
                        error={error}
                    />
                </div>
                <div className="h-8" />
                <Button
                    color=""
                    className="border-black w-full hover:bg-primary hover:text-white hover:border-primary"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={submit}
                >
                    Submit coupon code
                    <Icon name="arrow_forward" size="sm" />
                </Button>
                <div className="h-2" />
            </> : 
            <div className="w-full h-full flex flex-col align-center justify-center">
                <h3 className="text-4xl font-bold font-bold">Your Balance{" "}<span className="font-light">R{currentUser && currentUser.balance}</span></h3>
                <div className="h-5" />
                <Paragraph>
                    Coupon was added successfully!
                </Paragraph>
                <div className="h-5" />
                <Button
                    color="red"
                    className="hover:bg-red hover:text-white w-full hover:shadow-xl hover:pr-2 mb-5"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={() => {setShowModal(false); setSuccess(false); setCode('');}}
                >
                    Okay
                </Button>
            </div>}
        </>
    );
}

