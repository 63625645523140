import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import PdfModal from 'components/document-handling/PdfModal';
import { useState } from 'react';

export default function Legal() {
    const [showPdf, setShowPdf] = useState(false);
    const [pdfDoc, setPdfDoc] = useState('');

    function openPdfModal(pdfType) {
        if (pdfType === "Terms") {
            setPdfDoc('Terms_and_Conditions_Print_On_Demand.pdf');
            setShowPdf(true);
        } else if (pdfType === "Privacy") {
            setPdfDoc('Privacy_Policy_Print_On_Demand.pdf');
            setShowPdf(true);
        } else if (pdfType === "Data") {
            setPdfDoc('Data_Protection_Print_On_Demand.pdf');
            setShowPdf(true);
        } else {
            setPdfDoc('Terms_of_Use_Print_On_Demand.pdf');
            setShowPdf(true);
        }
    }

    return (
        <div className="mt-5">
            <Card className="mb-2 transition group">
                <CardBody>
                    <div className="relative h-5 flex justify-between cursor-pointer" onClick={() => openPdfModal("Terms")}>
                        <div className="inline left">
                            <span className="text-sm text-gray-900 font-light">Terms and Conditions</span>
                        </div>
                        <Icon name="arrow_forward" size="2xl" color="black" />
                    </div>
                </CardBody>
            </Card>
            <Card className="mb-2 transition  group">
                <CardBody>
                    <div className="relative h-5 flex justify-between cursor-pointer" onClick={() => openPdfModal("Privacy")}>
                        <div className="inline left">
                            <span className="text-sm text-gray-900 font-light">Privacy Policy</span>
                        </div>
                        <Icon name="arrow_forward" size="2xl" color="black" />
                    </div>
                </CardBody>
            </Card>
            <Card className="mb-2 transition  group">
                <CardBody>
                    <div className="relative h-5 flex justify-between cursor-pointer" onClick={() => openPdfModal("Data")}>
                        <div className="inline left">
                            <span className="text-sm text-gray-900 font-light">Data Protection</span>
                        </div>
                        <Icon name="arrow_forward" size="2xl" color="black" />
                    </div>
                </CardBody>
            </Card>
            <Card className="mb-2 transition group">
                <CardBody>
                    <div className="relative h-5 flex justify-between cursor-pointer" onClick={() => openPdfModal("Use")}>
                        <div className="inline left">
                            <span className="text-sm text-gray-900 font-light">Terms of Use</span>
                        </div>
                        <Icon name="arrow_forward" size="2xl" color="black" />
                    </div>
                </CardBody>
            </Card>
            <PdfModal
                file={`/assets/pdf/${pdfDoc}`}
                title={`${pdfDoc}`}
                showModal={showPdf}
                setShowModal={setShowPdf}
            />
        </div>
    );
}
