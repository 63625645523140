import {useState} from 'react';

import Input from "@material-tailwind/react/Input";
import Icon from "@material-tailwind/react/Icon";
import Button from "@material-tailwind/react/Button";
import Paragraph from "@material-tailwind/react/Paragraph";

import { useGlobalState } from 'state-pool';

import { podApi } from 'api/pod';
import { useAuth } from 'hooks/useAuth';

import {verifyCoupon} from 'routes/userRoutes';

export default function CouponDropdown({setCouponId, setCouponDetails, removeCoupon}) {
    const [coupon, setCoupon] = useState('');
    const [error, setError] = useState('');
    const [showSubmitCoupon, setShowSubmitCoupon] = useState(false);

    const { getUserDetails } = useAuth();
    const [success, setSuccess] = useState(false);
    const [currentUser] = useGlobalState('currentUser');

	const removeCouponOnClick = () => {
		removeCoupon();
		setCoupon('');
		setSuccess(false);
		setShowSubmitCoupon(false);
	}

    function submit() {
        if(!coupon) {
            setError('Coupon code can not be blank');

            return;
        }

        verifyCoupon(coupon).then(res => {
            if(res && (typeof res.statusCode === 'number') && res.statusCode === 0) {
                if(res.couponId) setCouponId(res.couponId);

                if(res.type && res.amount) {
                    const {type, amount} = res;

                    const details = {type, amount};

                    setCouponDetails(details);
                }
                // refresh user Details
                // getUserDetails();
                setSuccess(true);

                return;
            } else {
                setError('Something went wrong when adding your coupon, please check your spelling. Otherwise contact support bo1@printondemand.co.za');

                return;
            }
        });
    }

    return (
        <div>
            <div
                className='grid grid-cols-3 gap-4 w-full justify-items-stretch mt-4 border-t-2 border-black pt-2 cursor-pointer'
                onClick={() => setShowSubmitCoupon(!showSubmitCoupon)}
            >
                <div className='m-0 p-0'>
                    <p className='text-black'>Enter Coupon</p>
                </div>
                <div />
                <div className='flex items-center justify-end'>
                    <Icon
                        size='xl'
                        color='black'
                        name={
                            !showSubmitCoupon
                                ? "arrow_drop_down"
                                : "arrow_drop_up"
                        }
                    />
                </div>
            </div>
            {showSubmitCoupon && !success && (
                <>
                    <div className='my-4 text-left'>
                        <Input
                            outline={false}
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                            type='text'
                            color='red'
                            error={error}
                            placeholder='Have a coupon? Enter your coupon here'
                            size='regular'
                            onFocus={() => setError("")}
                        />
                    </div>
                    {error && <div className='h-8' />}
                    <div className='mt-2'>
                        <Button
                            color='green'
                            className='w-full col-span-3'
                            buttonType='outline'
                            size='lg'
                            ripple='dark'
                            onClick={submit}
                        >
                            Submit Coupon
                        </Button>
                    </div>
                </>
            )}
            {success && (
                <div className='w-full h-full flex flex-col align-center justify-center'>
                    {/* <h3 className="text-4xl font-bold font-bold">Your Balance{" "}<span className="font-light">R{currentUser && currentUser.balance}</span></h3> */}
                    <div className='h-5' />
                    <div className='w-full flex'>
                        <div className='w-1/2 text-green-800'>
                            Coupon "{coupon}" was applied successfully
                        </div>
                        <div
                            className='flex w-1/2 justify-end items-center cursor-pointer'
                            onClick={removeCouponOnClick}
                        >
                            <Icon name='close' color='red' size='xl' />
                            <small className='text-red'>Remove Coupon</small>
                        </div>
                    </div>

                    {/* <Button
                        color="red"
                        className="hover:bg-red hover:text-white w-full hover:shadow-xl hover:pr-2 mb-5"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        // onClick={() => {setShowModal(false); setSuccess(false); setCode('');}}
                    >
                        Proceed
                        <Icon color="black" name="arrow_forward" size="sm" />
                    </Button> */}
                </div>
            )}
        </div>
    );
}
