import { Paragraph } from "@material-tailwind/react";
import { Link } from "react-router-dom";

import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

export default function SuccessfulSubmissiion() {

    return (
        <div className="max-w-3xl w-full text-center">
            <Icon color="red" name="check_circle" size="9xl" />
            <div className="h-5" />
            <h3 className="text-4xl font-bold mb-3">Password reset</h3>
            <Paragraph>
                Your password was reset successfully
            </Paragraph>
            <Link to="/login">
                <Button
                    color="red"
                    className="w-full hover:bg-blue hover:text-white hover:bg-red hover:shadow-xl hover:pr-2"
                    buttonType="outline"
                    size="lg"
                    ripple="dark"
                    onClick={() => {}}
                >
                    Log in now
                    <Icon name="arrow_forward" size="sm" />
                </Button>
            </Link>
        </div>
    );
}
