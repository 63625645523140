import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

export default function UpdateEmail() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();

    function onDoneClick() {

    }

    return (
        <div className="h-screen px-3 md:px-8 flex relative justify-center">
            <div className=" absolute left-1 top-5 md:top-3">
                <Button
                    rounded
                    className="hover:pl-3 text-red"
                    buttonType="link"
                    size="lg"
                    ripple="dark"
                    onClick={() => history.goBack()}
                >
                    <Icon name="arrow_back" size="sm" />
                    Back
                </Button>
            </div>
            <div className="mt-20">
                <h3 className="text-4xl font-bold">Update email address</h3>
                <div className="h-2" />
                <Paragraph >
                    Enter your new email address below
                </Paragraph>
                <Card className="max-w-3xl text-left">
                    <div className="h-2" />
                    <CardBody>
                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                type="text"
                                color="red"
                                placeholder="Your new email"
                                size="regular"
                            />
                        </div>

                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type="password"
                                color="red"
                                placeholder="Password"
                                size="regular"
                            />
                        </div>

                    </CardBody>
                    <CardFooter>
                        <Button
                            color="red"

                            className="hover:bg-red hover:text-white w-full hover:shadow-xl hover:pr-2"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={onDoneClick}
                        >
                            Done
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                        <div className="h-5" />
                        <Paragraph >
                            When clicking done, you'll be logged out of the system.
                        </Paragraph>
                        <Paragraph >
                            We'll send an email to the new email provided, click on the link emailed to you to log back in to the system.
                        </Paragraph>
                    </CardFooter>
                </Card>
                <div className="h-10" />
            </div>
        </div>
    );
}
